import { faCheckCircle, faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Configs from '../../../../config';
import { MiscContext } from '../../../../context/misc.context';
import Enums from '../../../../enums';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import PatientDataService from '../../../../services/patientData.service';
import IconButton from '../../../_controls/IconButton/IconButton';
import '../../Popup.sass';

const AddPatientHealthConcern = () => {

    // <> Context
    const { addAlert, openPopup, openPopupState, setOpenPopup, setOpenPopupState } = useContext(MiscContext);

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [failure, setFailure] = useState(null);

    // <> History
    const history = useHistory();
    
    // <> Form
    const { handleSubmit, register, reset, formState: { errors } } = useForm();

    // <> Actions
    async function onSubmit(data) {
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // Params
            const params = { 
                patientId: openPopupState.patientId,
                date: moment(data.date).format(Configs.formats.apiDateTime),
                ...data
            }

            // -> Add
            await PatientDataService.add(Enums.PatientData.HealthConcerns, params);
            
            // -> Callback
            setSuccess('Patient health concern added successfully');
            if(openPopupState.onSuccess) openPopupState.onSuccess();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        setOpenPopup('');
        setOpenPopupState({});
        setSuccess('');
        setFailure('');
        reset();
    }

    function dismissFailure() {
        setFailure('');
    }

    // <> JSX
    return (
        <div className={`popup ${openPopup == Enums.PopupTags.AddPatientHealthConcern ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container">

                {/* Title */}
                <div className="popup__container__title">Add Patient Health Concern</div>
                <div className="popup__container__subtitle">for patient {openPopupState.patientName}</div>

                {openPopupState && <form onSubmit={handleSubmit(onSubmit)} className="popup__container__form">

                   {/* Concern */}
                   <div className="popup__container__form__input">
                        <label className="popup__container__form__input__label">Concern</label>
                        <input 
                            className="popup__container__form__input__control" 
                            {...register('concern', { required: 'concern required' })}
                            />
                        <label className="popup__container__form__input__error">{errors?.concern?.message}</label>
                    </div>

                    {/* Date */}
                    <div className="popup__container__form__input">
                        <label className="popup__container__form__input__label">Date</label>
                        <input 
                            className="popup__container__form__input__control" 
                            type="date"
                            {...register('date', { required: 'date required' })}
                            />
                        <label className="popup__container__form__input__error">{errors?.date?.message}</label>
                    </div>

                    {/* Submit Btn */}
                    <div className="popup__container__form__submit-btn">
                        <IconButton
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={() => handleSubmit(onSubmit)}
                            isLoading={isLoading} />
                    </div>

                </form>}

                {/* Success Pane */}
                {success && (
                    <div className="popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="popup__container__success-pane__icon" />
                        <div className="popup__container__success-pane__message">{success}</div>
                        <button className="popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="popup__container__failure-pane__icon" />
                        <div className="popup__container__failure-pane__message">{failure}</div>
                        <button className="popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={dismissFailure}>Back</button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default AddPatientHealthConcern;