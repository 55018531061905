/**
 * @description Table paginator controls
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';


// <> Controls Component
const Controls = ({pages, pageNum, setPageNum, pageSize, setPageSize, isLoading = false}) => {

    // <> States
    const [pageBtns, setPageBtns] = useState([]);

    // <> Pagination Buttons Renderer
    useEffect(() => {
        let btns = [];

        if(pages <= 5) { 
            for(let i = 1; i <= pages; i++) btns = [...btns, i];
        } else {
            if(pageNum <= 1) {
                btns = [1, 2, 3, -1, pages];
            } else if(pageNum >= pages-2){
                btns = [1, -1, pages-2, pages-1, pages];
            } else {
                btns = [1, -1, pageNum, pageNum+1, pageNum+2 ,-1, pages];
            }
        }

        setPageBtns(btns);
    }, [pages, pageNum]);

    // <> Actions
    const nextPage = () => {
        setPageNum(prev => prev + 1);
    }

    const prevPage = () => {
        setPageNum(prev => prev - 1);
    }

    const firstPage = () => {
        setPageNum(prev => 0);
    }

    const lastPage = () => {
        setPageNum(prev => pages-1);
    }

    const choosePage = (num) => {
        setPageNum(num);
    }

    return (<div className="controls">
        <button 
            className={`pg-btn pg-btn-sm hide-for-mobile ${isLoading ? 'shift-left' : ''}`}
            disabled={pageNum <= 0}
            onClick={firstPage}
            >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </button>
        <button 
            className={`pg-btn pg-btn-sm ${isLoading ? 'shift-left' : ''}`}
            disabled={pageNum <= 0}
            onClick={prevPage}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        {pageBtns && pageBtns.map((btn, index) => {
            if(btn != -1) return(
                <button 
                    key={index}
                    className={`pg-btn pg-btn-md hide-for-mobile ${pageNum == btn-1 ? 'pg-btn--active' : ''}`}
                    onClick={() => choosePage(btn-1)}
                    >
                        {btn}
                </button>
            )
            else return (
                <span key={index} className="elip hide-for-mobile">...</span>
            )
        })}
        

        <button 
            className={`pg-btn pg-btn-sm ${isLoading ? 'shift-right' : ''}`}
            disabled={pageNum >= pages-1}
            onClick={nextPage}
            >
                <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <button 
            className={`pg-btn pg-btn-sm hide-for-mobile ${isLoading ? 'shift-right' : ''}`}
            disabled={pageNum >= pages-1}
            onClick={lastPage}
            >
                <FontAwesomeIcon icon={faAngleDoubleRight} />
        </button>

        <select className="selector pg-page-selector" value={pageSize} onChange={ev => setPageSize(ev.target.value)}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
        
    </div>)
}


/**
 * Exports
 */
export default Controls;