import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MiscContext } from '../../context/misc.context';
import { UserContext } from '../../context/user.context';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import EventService from '../../services/event.service';
import TablePaginated from '../_tables/TablePaginated/TablePaginated';
import './EventsTable.sass';
import * as Presenter from './EventsTable.presenter';
import Enums from '../../enums';
import Configs from '../../config.js';
import MasterDataService from '../../services/masterData.service';
import BasicSelector from '../_controls/BasicSelector/BasicSelector';
import moment from 'moment';
import ManageUserService from '../../services/manageUser.service';
import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import IconButton from '../_controls/IconButton/IconButton';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import BasicMultiSelector from '../_controls/BasicMultiSelector/BasicMultiSelector';
import EventFilters from '../EventFilters/EventFilters';
import BrowserStorage from '../../utils/browser.storage';


const EventsTable = ({ patientId = null, physicianId = null, coordinatorId = null, facilityId = null, colDefinitions = null, status = null }) => {

    // <> Contexts
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState(null);
    const [searchResultsCount, setSearchResultsCount] = useState(0);
    const [forceRefresh, setForceRefresh] = useState(0);
    const [filters, setFilters] = useState({});

    // <> Actions
    async function getPage(searchKey, searchQuery, sortKey, sortIsAsc, pageNum, pageSize) {

        try {

            console.log('GET PAGE')

            if(!user || !user.loggedIn) return;

            // -> Loader ON
            setIsLoading(true);
    
            // -> Fetch Search Results
            const foundEventsRes = await EventService.search({
                searchCol: searchKey, 
                searchQuery: searchQuery, 
                sortCol: sortKey, 
                sortIsAsc: sortIsAsc, 
                skip: pageNum * pageSize, 
                limit: parseInt(pageSize), 
                physicianId: filters?.physicianId, 
                coordinatorId: filters?.coordinatorId, 
                patientId: patientId, 
                facilityId: filters?.facilityId,
                fromDate: filters?.fromDate ? moment(filters.fromDate).format(Configs.formats.apiDateTime) : null, 
                toDate: filters?.toDate ? moment(filters.toDate).format(Configs.formats.apiDateTime) : null,
                status: isOnlyPhysician() ? Enums.PhysicianEventStatusVisibility : filters?.status,
                isEmergency: filters?.isEmergency,
                eventTypeId: filters?.eventTypeId
            });

            const foundEvents = foundEventsRes.data.data.events;
            const foundEventsCount = foundEventsRes.data.data.eventsCount;
    
            // -> Map Results To Desired Display Values
            const eventsPresentable = Presenter.presentData(foundEvents, openReschedulePopup, refresh, isOnlyPhysician());
    
            // -> Set Data
            setEvents(eventsPresentable);
    
            // -> Set Total Results Count
            setSearchResultsCount(foundEventsCount);
            
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {

            // -> Loader OFF
            setIsLoading(false);
        }
    }

    
    // <> Helpers
    function goToEventDetails(eventId) {
        history.push('/event/details', { eventId });
    }

    function openReschedulePopup(eventId) {
        setOpenPopupState({
            eventId,
            onSuccess: refresh
        })
        setOpenPopup(Enums.PopupTags.RescheduleEvent);
    }

    function refresh() {
        // forces the paginated table to trigger a fetch of the data with the same filters selected
        setForceRefresh(prev => prev + 1);
    }

    function getEventFilterDefaults() {
        return JSON.parse(BrowserStorage.get(Enums.BrowserStorage.EventFilters.Key));
    }

    // <> JSX
    return (
        <div className="events-table">

            {/* Filters */}
            <EventFilters defaults={getEventFilterDefaults()} onChangeFn={setFilters} />

            {/* Table */}
            <TablePaginated
                getPage={getPage}
                items={events}
                matchesCount={searchResultsCount}
                columnDefinitions={colDefinitions ?? Presenter.tableColumnDefinitions()}
                detailsAction={goToEventDetails}
                isLoading={isLoading}
                refreshTriggers={[filters, user, forceRefresh]} />
            
        </div>
    );
};

export default EventsTable;