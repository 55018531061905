import axios from 'axios';
import Configs from '../config';


function getPhysicianFacilities(physicianId) {
    return axios.post(`${Configs.apiUrl}/physicianFacilities/getPhysicianFacilities`, { physicianId });
}

function addAssociation(physicianId, facilityId) {
    return axios.post(`${Configs.apiUrl}/physicianFacilities/addAssociation`, { physicianId, facilityId });
}

function deleteAssociation(associationId) {
    return axios.post(`${Configs.apiUrl}/physicianFacilities/deleteAssociation`, { associationId });
}


/**
 * Exports
 */
const PhysicianFacilityService = {
    getPhysicianFacilities,
    addAssociation,
    deleteAssociation
}
export default PhysicianFacilityService;