import React, { Component } from 'react';
import * as uuid from 'uuid';

const MiscContext = React.createContext()

class MiscProvider extends Component {
  
  // Context state
  defaultMisc = {
    isMobileSideMenuOpen: false,
    isAddEventPopupOpen: false,
    addEventPopupState: {},
    isAddUserPopupOpen: false,
    addUserSuccessFn: null,
    alerts: [],
    addPatientDataOpenedPopup: '',
    addPatientDataOpenedPopupState: {},
    addMasterDataOpenedPopup: '',
    addMasterDataOpenedPopupState: {},
    openPopup: '',
    openPopupState: {},
    pageTitle: 'Overview'
  }

  state = {
    ...this.defaultMisc
  }

  // Method to update state
  toggleSideMenu = () => {
    this.setState((prevState) => ({ ...prevState, isMobileSideMenuOpen: !prevState.isMobileSideMenuOpen }));
  }
  openSideMenu = () => {
    this.setState((prevState) => ({ ...prevState, isMobileSideMenuOpen: true }));
  }
  closeSideMenu = () => {
    this.setState((prevState) => ({ ...prevState, isMobileSideMenuOpen: false }));
  }
  setPageTitle = title => {
    this.setState((prevState) => ({ ...prevState, pageTitle: title }));
  }

  toggleAddEventPopup = () => {
    this.setState((prevState) => ({ ...prevState, isAddEventPopupOpen: !prevState.isAddEventPopupOpen }));
  }
  setAddEventPopupState = (stateData) => {
    this.setState((prevState) => ({ ...prevState, addEventPopupState: stateData }));
  }
  toggleAddUserPopup = () => {
    this.setState((prevState) => ({ ...prevState, isAddUserPopupOpen: !prevState.isAddUserPopupOpen }));
  }
  setAddUserSuccessFn = (callback) => {
    this.setState((prevState) => ({ ...prevState, addUserSuccessFn: callback }));
  }

  clearMisc = () => {
    this.setState((prevState) => ({ ...this.defaultMisc }));
  }

  /**
   * 
   * @param {{level: string, message: string, ttl: number?}} newAlert 
   */
  addAlert = (newAlert) => {
    this.setState((prevState) => {
      if(newAlert.preventDuplicates && prevState?.alerts?.some(a => a.message == newAlert.message)) return prevState;
      return { ...prevState, alerts: [...prevState.alerts, {id: uuid.v4(), ...newAlert}] }
    });
  }
  removeAlert = (alertId) => {
    this.setState((prevState) => ({ ...prevState, alerts: prevState.alerts.filter(a => a.id != alertId) }));
  }
  clearAlerts = () => {
    this.setState((prevState) => ({ ...prevState, alerts: [] }));
  }

  setPatientDataOpenedPopup = (tag) => {
    this.setState((prevState) => ({ ...prevState, addPatientDataOpenedPopup: tag }));
  }
  setPatientDataOpenedPopupState = (stateData) => {
    this.setState((prevState) => ({ ...prevState, addPatientDataOpenedPopupState: stateData }));
  }

  setMasterDataOpenedPopup = (tag) => {
    this.setState((prevState) => ({ ...prevState, addMasterDataOpenedPopup: tag }));
  }
  setMasterDataOpenedPopupState = (stateData) => {
    this.setState((prevState) => ({ ...prevState, addMasterDataOpenedPopupState: stateData }));
  }

  setOpenPopup = (tag) => {
    this.setState((prevState) => ({ ...prevState, openPopup: tag }));
  }
  setOpenPopupState = (stateData) => {
    this.setState((prevState) => ({ ...prevState, openPopupState: stateData }));
  }

  render() {
    const { children } = this.props;
    const { 
      toggleSideMenu, 
      openSideMenu, 
      closeSideMenu, 
      toggleAddEventPopup, 
      toggleAddUserPopup,
      setAddUserSuccessFn,
      clearMisc, 
      addAlert, 
      removeAlert, 
      clearAlerts, 
      setPatientDataOpenedPopup,
      setPatientDataOpenedPopupState,
      setMasterDataOpenedPopup,
      setMasterDataOpenedPopupState,
      setAddEventPopupState,
      setOpenPopup,
      setOpenPopupState,
      setPageTitle
    } = this;

    return (
      <MiscContext.Provider
        value={{
          ...this.state,
          toggleSideMenu,
          openSideMenu,
          closeSideMenu,
          toggleAddEventPopup,
          toggleAddUserPopup,
          setAddUserSuccessFn,
          clearMisc,
          addAlert,
          removeAlert,
          clearAlerts,
          setPatientDataOpenedPopup,
          setPatientDataOpenedPopupState,
          setMasterDataOpenedPopup,
          setMasterDataOpenedPopupState,
          setAddEventPopupState,
          setOpenPopup,
          setOpenPopupState,
          setPageTitle
        }}
      >
        {children}
      </MiscContext.Provider>
    )
  }
}

export { MiscProvider, MiscContext };