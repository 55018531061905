import Configs from '../../../../config.js';
import { TableColumn, TableColumns } from '../../../../components/_tables/TableColumns';
import moment from 'moment';


// Map Patient Data to Desired Display Values
function presentData(allergies) {

    return allergies.map(allergy => {
        allergy.allergyDate = moment(allergy.allergyDate).format(Configs.formats.date);

        return allergy;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const nameCol = new TableColumn()
        .setKey('name')
        .setDisplayName('Name')
        .setEditKey('name')

    const statusCol = new TableColumn()
        .setKey('status')
        .setDisplayName('Status')
        .setEditKey('status')

    const allergyDateCol = new TableColumn()
        .setKey('allergyDate')
        .setType('date')
        .setDisplayName('Allergy Date')
        .setEditKey('allergyDate')

    const reactionCol = new TableColumn()
        .setKey('reaction')
        .setDisplayName('Reaction')
        .setEditKey('reaction')

    const rxNormIdCol = new TableColumn()
        .setKey('rxNormId')
        .setDisplayName('RX Norm ID')
        .setEditKey('rxNormId')

    const tableCols = new TableColumns([
        nameCol,
        statusCol,
        allergyDateCol,
        reactionCol,
        rxNormIdCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}