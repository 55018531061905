import Configs from '../../../config.js';
import { TableColumn, TableColumns } from '../../../components/_tables/TableColumns';
import moment from 'moment';


// Map Data to Desired Display Values
function presentData(data) {

    return data.map(d => {
        d.createdOn = moment(d.createdOn).format(Configs.formats.dateTime);

        return d;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const codeCol = new TableColumn()
        .setKey('code')
        .setDisplayName('Code')
        .setEditKey('code')

    const nameCol = new TableColumn()
        .setKey('name')
        .setDisplayName('Name')
        .setEditKey('name')

    const descriptionCol = new TableColumn()
        .setKey('description')
        .setDisplayName('Description')
        .setEditKey('description')

    const physicianCol = new TableColumn()
        .setKey('physician')
        .setDisplayName('Physician')
        .setEditKey('physician')

    const tableCols = new TableColumns([
        codeCol,
        nameCol,
        descriptionCol,
        physicianCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}