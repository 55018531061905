import Configs from '../../../config.js';
import { TableColumn, TableColumns } from '../../../components/_tables/TableColumns';
import moment from 'moment';
import Tag from '../../../components/Tag/Tag';
import path from 'path';
import IconButton from '../../../components/_controls/IconButton/IconButton.js';
import { faUpload } from '@fortawesome/free-solid-svg-icons';


// Map Data to Desired Display Values
function presentData(data, openChangeFilePopup) {

    return data.map(d => {
        d.createdOn = moment(d.createdOn).format(Configs.formats.dateTime);
        const labelExtraStyles = { display: 'block', width: '100%', textAlign: 'center' };
        d.hasFile = (<>
            {d.file && <a href={Configs.apiPublicFolderUrl + d.file} target="_blank" rel="noreferrer" download><Tag tagStyle={'green'} size={'xsm'} text={d.file.replace(/^.*[\\\/]/, '')} extraStyles={labelExtraStyles} /></a>}
            {!d.file && <Tag tagStyle={'gray'} size={'xsm'} text={'No'} extraStyles={labelExtraStyles} />}
        </>)

        d.changeFile = (
            <IconButton
                text={'Change File'}
                icon={faUpload}
                size={'xsm'}
                onClickFn={() => openChangeFilePopup(d.id, d.file?.replace(/^.*[\\\/]/, ''))} />
        )

        return d;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const titleCol = new TableColumn()
        .setKey('title')
        .setDisplayName('Title')
        .setEditKey('title')

    const descriptionCol = new TableColumn()
        .setKey('description')
        .setDisplayName('Description')
        .setEditKey('description')

    const fileCol = new TableColumn()
        .setKey('hasFile')
        .setDisplayName('File')
        .setCustomStyles({ width: '1%' })

    const changeFileCol = new TableColumn()
        .setKey('changeFile')
        .setDisplayName('Change File')
        .setCustomStyles({ width: '1%' })

    const tableCols = new TableColumns([
        titleCol,
        descriptionCol,
        fileCol,
        changeFileCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}