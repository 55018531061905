import moment from 'moment';
import Enums from '../../../enums';


function presentMonthEvents(events) {
    return events.map(event => { 

        event.dateFrom = moment(event.dateFrom);
        event.dateTo = moment(event.dateTo);

        return event;
    });
}

function statusesForSelector() {
    return [
        {
            value: Enums.EventStatuses.Pending,
            label: 'Pending'
        },
        {
            value: Enums.EventStatuses.BoardingSlipGenerated,
            label: 'Boarding Slip Generated'
        },
        {
            value: Enums.EventStatuses.WaitingForConfirmation,
            label: 'Waiting For Confirmation'
        },
        {
            value: Enums.EventStatuses.Confirmed,
            label: 'Confirmed'
        },
        {
            value: Enums.EventStatuses.Completed,
            label: 'Completed'
        },
    ]
}

function formatFacilitiesForSelector(facilitiesArg) {
    return facilitiesArg.map(f => { return { value: f.id, label: f.name } });
}

function formatCoordinatorsForSelector(coordinators) {
    return coordinators.map(c => { return { value: c.id, label: c.name } });
}

function formatPhysiciansForSelector(physiciansArg, myId = null) {
    let result = physiciansArg.map(p => { return { value: p.id, label: p.name } });

    if(myId) {
        result = result.filter(i => i.value != myId);
        result.unshift({ value: myId, label: 'Me' });
    }

    return result;
}

function rtmuForSelector() {
    return [
        {
            value: 0,
            label: 'No'
        },
        {
            value: 1,
            label: 'Yes'
        }
    ]
}

function formatEventTypesForSelector(types) {
    return types.map(t => { return { value: t.id, label: t.name } });
}

/**
 * Exports
 */
export {
    presentMonthEvents,
    statusesForSelector,
    formatFacilitiesForSelector,
    formatCoordinatorsForSelector,
    formatPhysiciansForSelector,
    rtmuForSelector,
    formatEventTypesForSelector
}