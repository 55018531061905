import { faCheckCircle, faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Configs from '../../../config';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import EventClearanceService from '../../../services/event.clearance.service';
import EventPatientCommunicationService from '../../../services/event.patientCommunication.service';
import MasterDataService from '../../../services/masterData.service';
import Loader from '../../Loader/Loader';
import DropdownSearch from '../../_controls/DropdownSearch/DropdownSearch';
import IconButton from '../../_controls/IconButton/IconButton';
import '../Popup.sass';

const AddEventPatientCommunication = () => {

    // <> Context
    const { addAlert, openPopup, openPopupState, setOpenPopup, setOpenPopupState } = useContext(MiscContext);

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [patientCommunications, setPatientCommunications] = useState(null);
    const [comName, setComName] = useState('');
    const [selectedComId, setSelectedComId] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [success, setSuccess] = useState(null);
    const [failure, setFailure] = useState(null);

    // <> History
    const history = useHistory();
    
    // <> Form
    const { handleSubmit, register, reset, setValue, getValues, formState: { errors } } = useForm({ mode: 'onChange' });

    // <> Effects
    useEffect(() => {
        if(openPopup == Enums.PopupTags.AddEventPatientCommunication) getPatientCommunications();
    }, [openPopup])

    // <> Actions
    async function getPatientCommunications() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data From API
            const comsResponse = await MasterDataService.getTable(Enums.MasterData.PatientCommunication);
            const comsRes = comsResponse.data.data.masterData;
    
            // -> Set States
            setPatientCommunications(comsRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function onSubmit(data) {
        
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // Params
            const formData = new FormData();
            formData.append('eventId', openPopupState.eventId);
            if(data.description) formData.append('description', data.description);

            if(comName) {
                formData.append('title', comName);
            } else {
                formData.append('title', data.title);
                formData.append('patientCommunicationId', data.patientCommunicationId);
                if(data.file) formData.append('file', data.file);
            }

            if(selectedFile) formData.append('newFile', selectedFile);

            // -> Add
            await EventPatientCommunicationService.addOne(formData);
            
            // -> Callback
            setSuccess('Event patient communication added successfully');
            if(openPopupState.onSuccess) openPopupState.onSuccess();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        setOpenPopup('');
        setOpenPopupState({});
        setSuccess('');
        setFailure('');
        setComName('');
        setSelectedComId(null);
        setSelectedFile(null);
        reset();
    }

    function dismissFailure() {
        setFailure('');
    }

    function onCommunicationPicked(comId) {

        const selectedCom = patientCommunications.find(c => c.id == comId);

        setValue('patientCommunicationId', selectedCom.id);
        if(selectedCom?.title) setValue('title', selectedCom.title);
        if(selectedCom?.description) setValue('description', selectedCom.description);
        if(selectedCom?.file) setValue('file', selectedCom.file);

        setSelectedComId(comId);

    }

    function onFileChosen(ev) {

        console.log(ev.target.files);
        const chosenFile = ev.target.files[0];
        setSelectedFile(chosenFile);

    }

    // <> JSX
    return (
        <div className={`popup ${openPopup == Enums.PopupTags.AddEventPatientCommunication ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container">

                {/* Loader */}
                <Loader isLoading={isLoading} />

                {/* Title */}
                <div className="popup__container__title">Add New Patient Communication</div>

                {openPopupState && <form onSubmit={handleSubmit(onSubmit)} className="popup__container__form">

                    {/* Patient Communication */}
                    {patientCommunications && <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Patient Communication</div>
                        <DropdownSearch
                            options={patientCommunications.map(c => { return { value: c.id, label: c.title }})}
                            value={selectedComId}
                            setter={onCommunicationPicked}
                            onManualInputFn={val => setComName(val)}
                            />
                    </div>}
                    <div className="popup__container__form__input__error">{errors?.title?.message}</div>

                    {/* Description */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Description</div>
                        <textarea 
                            className="popup__container__form__input__control"
                            rows="3"
                            {...register('description', { minLength: { value: 20, message: 'description must be at least 20 characters long' }})}></textarea>
                        <div className="popup__container__form__input__error">{errors?.description?.message}</div>
                    </div>

                    {/* File */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">File</div>
                        <div className="popup__container__form__input__value">{patientCommunications?.find(c => c.id == selectedComId)?.file || 'N/A'}</div>
                        <input 
                            type="file"
                            className="popup__container__form__input__control"
                            {...register('newFile')}
                            onChange={onFileChosen} />
                        <div className="popup__container__form__input__error">{errors?.newFile?.message}</div>
                    </div>

                    {/* Submit Btn */}
                    <div className="popup__container__form__submit-btn">
                        <IconButton
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={() => handleSubmit(onSubmit)}
                            isDisabled={!getValues('title') && !comName}
                            isLoading={isLoading} />
                    </div>

                    {/* Hidden Fields */}
                    <input type="hidden" {...register('patientCommunicationId')} />
                    <input type="hidden" {...register('file')} />

                </form>}

                {/* Success Pane */}
                {success && (
                    <div className="popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="popup__container__success-pane__icon" />
                        <div className="popup__container__success-pane__message">{success}</div>
                        <button className="popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="popup__container__failure-pane__icon" />
                        <div className="popup__container__failure-pane__message">{failure}</div>
                        <button className="popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={dismissFailure}>Back</button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default AddEventPatientCommunication;