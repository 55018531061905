import axios from "axios";
import HttpErrors from '../errors/HttpErrors';
import BrowserStorage from "./browser.storage";
import Enums from '../enums';
import UserService from '../services/user.service';

// Axios Interceptor
const requestHandler = (request) => {

  // -> Add Content Type
  request.headers["Content-Type"] = "application/json";

  // -> Add Access Token if Set
  const token = BrowserStorage.get(Enums.BrowserStorage.AccessToken.Key);
  if (token) request.headers["access-token"] = token;

  // End
  return request;
};

const responseHandler = (response) => {
  return response;
};

/**
 * Error
 *     !sent
 *         return promise rejection error
 *     sent + !responded ?
 *         return promise rejection error
 *     sent + responded ?
 *         !401 ? return promise rejection error
 *         401 & no refresh token ? login
 *         401 & refresh token ? REFRESH
 *             !sent
 *                 return promise rejection error
 *             sent + !responded ?
 *                 return promise rejection error
 *             sent + responded ?
 *                 200 ? update token and retry original request
 *                 477 ? invalid refresh token, relogin
 *                 * ? unknown case, relogin
 *         401 on retry ? relogin
 *
 */

const UnauthorizedHandler = async (error) => {

	// -> Set error as being retried
	error.config._retry = true;

	// -> Get refresh token
	const refreshToken = BrowserStorage.get(Enums.BrowserStorage.RefreshToken.Key);

	// Sanity checks, throw error in the following cases:
	// ? If no refresh token in browser storage, then user must log in again
	if (!refreshToken) throw new HttpErrors.UnauthenticatedHttpError('must login', error);

	console.log('getting refresh token');
	// -> Call the Refresh Token endpoint
	const refreshResult = await UserService.refresh(refreshToken);

	// -> Extract the access token from the response
	const newAccessToken = refreshResult.data.data.accessToken;

	// -> Store new access token into browser storage
	BrowserStorage.set(Enums.BrowserStorage.AccessToken.Key, newAccessToken);

	// -> Set the new access token into the error config's authorization header
	error.config.headers['access-token'] = newAccessToken;

	// -> Return an axios promise with the new config
	return axios(error.config);

};

const errorHandler = async (error) => {

	// ? If no request sent
	// ? If no response returned
	// ? If "Invalid Refresh Token" response status
	// ? Unknown
	if (!error.request)                                                                             throw new HttpErrors.RequestNotSentHttpError(error.message, error);
	else if (!error.response)                                                                       throw new HttpErrors.ResponseNotReceivedHttpError(error.message, error);
	else if(error.response.status === Enums.HttpCodes.Unauthorized && error.config._retry)          throw new HttpErrors.UnauthenticatedHttpError('must login', error);
	else if(error.response.status === Enums.HttpCodes.Unauthorized && !error.config._retry)         return UnauthorizedHandler(error);
	else if(error.response.status === Enums.HttpCodes.InvalidRefreshToken)                          throw new HttpErrors.UnauthenticatedHttpError('must login', error);
	else if(error.response.status === Enums.HttpCodes.InternalServerError)                          throw new HttpErrors.InternalServerHttpError(error.response.data.message, error);
	else                                                                                            throw new HttpErrors.HttpResponseError(error.response.data.message, error, error.response.status);
  
};

axios.interceptors.request.use(
  config => requestHandler(config),
  error => Promise.reject({sent: false, responded: false, error})
);
axios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
