import axios from 'axios';
import Configs from '../config.js';
import TokenStorageHelper from '../utils/browser.storage';


function addUser({ email, name, password, phone, fax }) {
    return axios.post(`${Configs.apiUrl}/manageUsers/addUser`, { email, name, password, phone, fax });
}

function search(searchCol, searchQuery, sortCol, sortIsAsc, skip, limit) {
    return axios.post(`${Configs.apiUrl}/manageUsers/search`, { searchCol, searchQuery, sortCol, sortIsAsc, skip, limit });
}

function searchWithRole(searchCol, searchQuery, sortCol, sortIsAsc, skip, limit, role) {
    return axios.post(`${Configs.apiUrl}/manageUsers/searchWithRole`, { searchCol, searchQuery, sortCol, sortIsAsc, skip, limit, role });
}

function getUserDetails(userId) {
    return axios.post(`${Configs.apiUrl}/manageUsers/getUserDetails`, { userId });
}

function updateUserDetails(userId, { email, name, phone, fax, roles }) {
    return axios.post(`${Configs.apiUrl}/manageUsers/updateUserDetails`, { userId, email, name, phone, fax, updateRoles: true, roles });
}

function updateFavoriteEventType(userId, { eventTypeId }) {
    return axios.post(`${Configs.apiUrl}/manageUsers/updateFavoriteEventType`, { userId, eventTypeId });
}

function getUsersWithRole(role) {
    return axios.post(`${Configs.apiUrl}/manageUsers/getUsersWithRole`, { role });
}

function getPhysiciansWithDetails() {
    return axios.get(`${Configs.apiUrl}/manageUsers/getPhysiciansWithDetails`);
}

function getAll() {
    return axios.get(`${Configs.apiUrl}/manageUsers/getAll`);
}


/**
 * Exports
 */
const ManageUserService = {
    addUser,
    search,
    searchWithRole,
    getUserDetails,
    updateUserDetails,
    getUsersWithRole,
    getPhysiciansWithDetails,
    getAll,
    updateFavoriteEventType
}
export default ManageUserService;