import axios from 'axios';
import Configs from '../config';


function addOne({ eventId, userId, description, groupName, assigneeId, dueDate }) {
    return axios.post(`${Configs.apiUrl}/events/checklist/addOne`, { eventId, userId, description, groupName, assigneeId, dueDate });
}

function updateOne({ itemId, description, assigneeId, dueDate, status }) {
    return axios.put(`${Configs.apiUrl}/events/checklist/updateOne`, { itemId, description, assigneeId, dueDate, status });
}

function get(eventId) {
    return axios.post(`${Configs.apiUrl}/events/checklist/get`, { eventId });
}

function deleteOne(itemId) {
    return axios.post(`${Configs.apiUrl}/events/checklist/deleteOne`, { itemId });
}


/**
 * Exports
 */
const EventChecklistService = {
    addOne,
    updateOne,
    get,
    deleteOne
}
export default EventChecklistService;