import React from 'react';
import './UserSlip.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';

export default function UserSlip({username, roles}) {

    // <> History
    const history = useHistory();

    // <> Helpers
    function goToProfile() {
        history.push('/settings/profile');
    }

    // <> JSX
    return (
        <div className="user-slip" onClick={goToProfile}>
            <div className="avatar">
                <FontAwesomeIcon icon={faUserCircle} />
            </div>
            <div className="info">
                <div className="username">{username}</div>
                <div className="role">{roles.join(', ')}</div>
            </div>
        </div>
    )
}
