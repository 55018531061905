import { faCheck, faLink, faPencilAlt, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import IconButton from '../../../components/_controls/IconButton/IconButton';
import ManageUserService from '../../../services/manageUser.service';
import './ManageUser.sass';
import * as Presenter from './ManageUser.presenter';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../../context/misc.context';
import { useForm } from 'react-hook-form';
import Configs from '../../../config.js';
import PhysicianFacilityService from '../../../services/physicianFacility.service';
import BasicSelector from '../../../components/_controls/BasicSelector/BasicSelector';
import MasterDataService from '../../../services/masterData.service';
import Enums from '../../../enums';
import PhoneNumberInput from '../../../components/_controls/PhoneNumberInput/PhoneNumberInput';
import StringFormatter from '../../../utils/string.formats';
import Loader from '../../../components/Loader/Loader';


const ManageUser = () => {

    // <> Params
    const { id } = useParams();

    // <> History
    const history = useHistory();

    // <> Context
    const { addAlert } = useContext(MiscContext);

    // <> States
    const [isLoading, setIsLoading] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [inEditMode, setInEditMode] = useState(false);
    const [eventTypes, setEventTypes] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        getUserDetails();
    }, [])

    // <> Actions
    async function getUserDetails() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Fetch User Details Promise
            const userDetailsPromise = ManageUserService.getUserDetails(id);
            
            // -> Fetch Facilities Promise
            const masterDataPromise = MasterDataService.getTables([
                Enums.MasterData.EventType
            ]);

            // -> Run Promises
            const responses = await Promise.all([userDetailsPromise, masterDataPromise]);

            // -> Extract Data
            const userDetailsRes = responses[0].data.data.userDetails;
            const userRolesRes = responses[0].data.data.userRoles;
            const eventTypesRes = responses[1].data.data[Enums.MasterData.EventType];

            // -> Present the Data
            const userDetailsPresentable = Presenter.presentUserDetails(userDetailsRes, userRolesRes);

            // -> Set State
            setUserDetails(userDetailsPresentable);
            setUserRoles(userRolesRes);
            setEventTypes(eventTypesRes);

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function updateUserDetails(data) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Fetch User Details
            if(data.email == userDetails.email) data.email = null;
            if(data.phone == userDetails.phone) data.phone = null;
            if(!Array.isArray(data.roles)) data.roles = [data.roles];
            await ManageUserService.updateUserDetails(id, data);

            // -> Success Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'User successfully updated'
            })
            
            // -> Reload Data
            getUserDetails();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setIsLoading(false);
        }
        finally {
            setInEditMode(false);
            reset();
        }
    }

    async function updateFavoriteEventType(eventTypeId) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Update
            const params = {
                eventTypeId: !eventTypeId || eventTypeId == 0 ? null : eventTypeId
            };
            await ManageUserService.updateFavoriteEventType(id, params);

            // -> Success Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'User successfully updated'
            })
            
            // -> Reload Data
            getUserDetails();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setIsLoading(false);
        }
        finally {
            setInEditMode(false);
            reset();
        }
    }


    // <> JSX
    return (
        <div className="manage-user-page">

            {/* Loader */}
            <Loader isLoading={isLoading} />
            
            {/* TOP */}
            <div className="manage-user-page__top">
                <div className="manage-user-page__title">
                    <span>Manage user </span>
                    <span className="primary-color">{userDetails?.email}</span>
                </div>
                <div className="manage-user-page__actions">
                    <IconButton
                        text={'Delete Account'}
                        icon={faTrash}
                        btnStyle={'danger'}
                        size={'md'}
                        onClickFn={() => {}} />
                </div>
            </div>

            {/* General Section */}
            <div className="manage-user-page__section">

                <div className="manage-user-page__section__content">
                    <div className="manage-user-page__section__content__2pan">

                        {/* General Info */}
                        <div className="manage-user-page__section__content__2pan__left">
                            <div className="manage-user-page__section__title">General</div>

                            {/* User Details */}
                            {userDetails && (
                                <form onSubmit={handleSubmit(updateUserDetails)}>
                                    <div className="manage-user-page__list">

                                        {/* ID */}
                                        <div className="manage-user-page__list__item">
                                            <div className="manage-user-page__list__item__label">ID</div>
                                            <div className="manage-user-page__list__item__value">{userDetails.id}</div>
                                        </div>

                                        {/* Email */}
                                        <div className="manage-user-page__list__item">
                                            <div className="manage-user-page__list__item__label">Email</div>
                                            {!inEditMode && <div className="manage-user-page__list__item__value">{userDetails.email}</div>}
                                            {inEditMode && <input className="manage-user-page__list__item__control" defaultValue={userDetails.email} {...register('email', { required: 'email required' })} />}
                                        </div>

                                        {/* Name */}
                                        <div className="manage-user-page__list__item">
                                            <div className="manage-user-page__list__item__label">Name</div>
                                            <div hidden={inEditMode} className="manage-user-page__list__item__value">{userDetails.name}</div>
                                            <input hidden={!inEditMode} className="manage-user-page__list__item__control" defaultValue={userDetails.name} {...register('name', { required: 'name required' })} />
                                        </div>

                                        {/* Phone */}
                                        <div className="manage-user-page__list__item">
                                            <div className="manage-user-page__list__item__label">Phone Number</div>
                                            {!inEditMode && <div className="manage-user-page__list__item__value">{StringFormatter.formatPhone(userDetails.phone)}</div>}
                                            {inEditMode && <PhoneNumberInput addClassName={'manage-user-page__list__item__control'} defaultValue={userDetails.phone} extra={register('phone', { required: 'phone required' })} />}
                                        </div>

                                        {/* Fax */}
                                        <div className="manage-user-page__list__item">
                                            <div className="manage-user-page__list__item__label">Fax Number</div>
                                            <div hidden={inEditMode} className="manage-user-page__list__item__value">{StringFormatter.formatPhone(userDetails.fax)}</div>
                                            {inEditMode && <PhoneNumberInput addClassName={'manage-user-page__list__item__control'} defaultValue={userDetails.fax} extra={register('fax')} />}
                                        </div>

                                        {/* Roles */}
                                        <div className="manage-user-page__list__item">
                                            <div className="manage-user-page__list__item__label">Roles</div>
                                            {!inEditMode && <div className="manage-user-page__list__item__value">{userDetails.roles}</div>}
                                            {inEditMode && (
                                                <div className="manage-user-page__list__item__input">
                                                    <div className="manage-user-page__list__item__input__checkbox">
                                                        <input type="checkbox" value={Configs.userRoles.ADMIN} {...register('roles')} defaultChecked={userRoles.includes('admin')} />
                                                        <span>admin</span>
                                                    </div>
                                                    <div className="manage-user-page__list__item__input__checkbox">
                                                        <input type="checkbox" value={Configs.userRoles.COORDINATOR} {...register('roles')} defaultChecked={userRoles.includes('coordinator')} />
                                                        <span>coordinator</span>
                                                    </div>
                                                    <div className="manage-user-page__list__item__input__checkbox">
                                                        <input type="checkbox" value={Configs.userRoles.PHYSICIAN} {...register('roles')} defaultChecked={userRoles.includes('physician')} />
                                                        <span>physician</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* Errors */}
                                        <div hidden={!inEditMode}>
                                            {Object.keys(errors).map((fieldName) => (
                                                <div className="error-msg">{errors[fieldName]?.message}</div>
                                            ))}
                                        </div>


                                        {/* Actions */}
                                        <div className="manage-user-page__list__actions">
                                            {!inEditMode && <IconButton
                                                text={'Edit'} 
                                                icon={faPencilAlt}
                                                btnStyle={'faded'}
                                                onClickFn={() => setInEditMode(true)} />}
                                            {inEditMode && <IconButton
                                                text={'Cancel'}
                                                icon={faTimes}
                                                btnStyle={'faded'}
                                                onClickFn={() => setInEditMode(false)} />}
                                            {inEditMode && <IconButton
                                                text={'Save'}
                                                icon={faCheck}
                                                btnStyle={'success'}
                                                onClickFn={handleSubmit(updateUserDetails)} />}
                                        </div>
                                    </div>

                                    
                                </form>
                            )}
                        </div>
                        
                    </div>
                </div>


            </div>

            {/* Select Coordinator Favorite Department */}
            <div className="manage-user-page__section">
                <div className="manage-user-page__section__content">
                    <div className="manage-user-page__section__title">Favorite Department</div>
                    {eventTypes && userDetails && <BasicSelector
                        options={Presenter.eventTypesOptions(eventTypes)}
                        defaultValue={userDetails.favoriteEventTypeId}
                        setter={updateFavoriteEventType} />}
                </div>
            </div>

        </div>
    );
};

export default ManageUser;