import Configs from '../../../config.js';
import { TableColumn, TableColumns } from '../../../components/_tables/TableColumns';
import moment from 'moment';
import Tag from '../../../components/Tag/Tag.js';
import Enums from '../../../enums.js';


// Map Data to Desired Display Values
function presentData(data, setAsDefault) {

    return data.map(d => {
        d.createdOn = moment(d.createdOn).format(Configs.formats.dateTime);

        return d;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions(facilities, eventTypes) {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const facilityCol = new TableColumn()
        .setType('select')
        .setOptions(facilities.map(f => { return { value: f.id, label: f.name } }))
        .setKey('facilityId')
        .setDisplayName('Facility')
        .setEditKey('facilityId')

    const eventTypeCol = new TableColumn()
        .setType('select')
        .setOptions(eventTypes.map(e => { return { value: e.id, label: e.name } }))
        .setKey('eventTypeId')
        .setDisplayName('Department')
        .setEditKey('eventTypeId')

    const linkCol = new TableColumn()
        .setType('select')
        .setOptions(getPdfOptions())
        .setKey('linkId')
        .setDisplayName('Link')
        .setEditKey('linkId')

    const tableCols = new TableColumns([
        facilityCol,
        eventTypeCol,
        linkCol
    ])

    return tableCols;

}

function getPdfOptions() {
    const keys = Object.keys(Enums.PdfTypes);
    const options = [];
    for(let i = 0; i < keys.length; i++) {
        options.push({
            value: Enums.PdfTypes[keys[i]].id,
            label: Enums.PdfTypes[keys[i]].label,
        })
    }
    return options;
}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}