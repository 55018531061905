import React, { useContext, useEffect, useRef, useState } from 'react';
import './EventDiagnosis.sass';
import '../EventForms.sass';
import DropdownSearch from '../../../../components/_controls/DropdownSearch/DropdownSearch';
import DropdownExternalSearch from '../../../../components/_controls/DropdownExternalSearch/DropdownExternalSearch';
import { faCheck, faDiagnoses, faFileMedical, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../../../context/misc.context';
import { useHistory } from 'react-router';
import DiagnosisService from '../../../../services/history.service copy';
import EventService from '../../../../services/event.service';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import Loader from '../../../../components/Loader/Loader';
import EventDiagnosisService from '../../../../services/event.diagnosis.service';
import EditableField from '../../../../components/_controls/EditableField/EditableField';
import Enums from '../../../../enums';
import { UserContext } from '../../../../context/user.context';

const EventDiagnosis = ({ eventDetails, refresh }) => {

    // <> Context
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { isOnlyPhysician } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [foundDiagnosis, setFoundDiagnosis] = useState([]);

    // <> Refs
    const searchComponentRef = useRef();

    // <> Actions
    async function searchDiagnosis(query) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data From API
            const diagnosisResponse = await DiagnosisService.search({ query, limit: 10 });;
            const diagnosisRes = diagnosisResponse.data.data.diagnosis;

            // -> Add Formatted Description
            const diagnosisFormatted = diagnosisRes.map(d => {
                d.formattedDescription = `(${d.code}) ${d.description} ${d.tags ? `[tags: ${d.tags}]` : ''}`;
                return d;
            });
    
            // -> Set State
            setFoundDiagnosis(diagnosisFormatted);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function addDiagnosis(selectedDiagnosis, manualDiagnosis) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            const params = {
                eventId: eventDetails.id
            }

            if(manualDiagnosis) {
                params.diagnosisDescription = manualDiagnosis;
            }
            else {
                params.diagnosisId = selectedDiagnosis.id;
                params.diagnosisCode = selectedDiagnosis.code;
                params.diagnosisDescription = selectedDiagnosis.description;
            }

            // -> Add
            await EventDiagnosisService.addOne(params);
            
            // -> Refresh Event
            refresh();
            reset();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateDiagnosis(id, updateCols) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            const params = {
                id
            }
            if(updateCols.diagnosisCode) params.diagnosisCode = updateCols.diagnosisCode;
            if(updateCols.diagnosisDescription) params.diagnosisDescription = updateCols.diagnosisDescription;

            // -> Add
            await EventDiagnosisService.updateOne(params);
            
            // -> Refresh Event
            refresh();
            reset();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function deleteDiagnosis(eventDiagnosisId) {

        // -> Confirm
        setOpenPopupState({
            level: 'danger',
            message: 'Are you sure you want to delete this event diagnosis?',
            onOk: async () => {

                try{
                    // -> Turn Loader ON
                    setIsUpdating(true);

                    // -> Delete
                    await EventDiagnosisService.deleteOne(eventDiagnosisId);
                    
                    // -> Refresh Event
                    refresh();
                }
                catch(e) {
                    GlobalHttpErrorHandler(e, history, addAlert);
                }
                finally {
                    setIsUpdating(false);
                }
            }
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    // <> Helpers
    function reset() {
        setFoundDiagnosis([]);
        searchComponentRef.current.resetComponent();
    }

    // <> JSX
    return (
        <div className="event-diagnosis">

            <Loader isLoading={isUpdating} />

            {eventDetails && <>

                {/* Title */}
                <div className="event-diagnosis__title">Diagnosis</div>

                {/* List */}
                <div className="event-diagnosis__list">
                    {eventDetails.diagnosis && eventDetails.diagnosis.map((d, index) => (
                        <div key={index} className="event-diagnosis__list__item">

                            <div className="event-diagnosis__list__item__code">
                                <EditableField
                                    isEditable={!isOnlyPhysician()}
                                    value={d.diagnosisCode}
                                    shownValue={d.diagnosisCode || 'Manual'}
                                    save={val => updateDiagnosis(d.id, { diagnosisCode: val })} />
                            </div>
                            
                            <div className="event-diagnosis__list__item__text">
                                <EditableField
                                    isEditable={!isOnlyPhysician()}
                                    value={d.diagnosisDescription}
                                    rules={{ required: 'diagnosis description required' }}
                                    save={val => updateDiagnosis(d.id, { diagnosisDescription: val })} />
                            </div>

                            {!isOnlyPhysician() && <div className="event-diagnosis__list__item__delete">
                                <IconButton
                                    text={'Delete'}
                                    icon={faTimes}
                                    btnStyle={'danger'}
                                    onClickFn={() => deleteDiagnosis(d.id)}
                                    hideTextOnMobile={true}
                                    size={'sm'}
                                    isLoading={isUpdating} />
                            </div>}

                        </div>
                    ))}
                </div>

                {/* No Items Message */}
                {eventDetails?.diagnosis?.length == 0 && <div className="event-diagnosis__empty">
                    No diagnosis added
                </div>}

                {/* Add */}
                {!isOnlyPhysician() && <div className="event-diagnosis__add">
                    <div className="event-diagnosis__add__label">Add a new diagnosis: </div>
                    <div className="event-diagnosis__add__selector">
                        <DropdownExternalSearch
                            ref={searchComponentRef}
                            search={searchDiagnosis}
                            matches={foundDiagnosis}
                            displayKey={'formattedDescription'}
                            hideSelection={true}
                            setter={val => addDiagnosis(val, null)}
                            reset={() => setFoundDiagnosis([])}
                            icon={faDiagnoses}
                            onManualInputFn={manual => addDiagnosis(null, manual)}
                            isLoading={isLoading} />

                    </div>
                </div>}

            </>}
        </div>
    );
};

export default EventDiagnosis;