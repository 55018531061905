import Configs from '../../../../config.js';
import { TableColumn, TableColumns } from '../../../../components/_tables/TableColumns';
import moment from 'moment';


function presentData(items) {

    return items.map(item => {
        item.date = moment(item.date).format(Configs.formats.date);

        return item;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const rxNormId = new TableColumn()
        .setKey('rxNormId')
        .setDisplayName('RX Norm ID')
        .setEditKey('rxNormId')

    const name = new TableColumn()
        .setKey('name')
        .setDisplayName('Name')
        .setEditKey('name')

    const instructions = new TableColumn()
        .setKey('instructions')
        .setDisplayName('Instructions')
        .setEditKey('instructions')

    const quantity = new TableColumn()
        .setKey('quantity')
        .setDisplayName('Quantity')
        .setEditKey('quantity')

    const doseForm = new TableColumn()
        .setKey('doseForm')
        .setDisplayName('Dose Form')
        .setEditKey('doseForm')

    const date = new TableColumn()
        .setKey('date')
        .setType('date')
        .setDisplayName('Date')
        .setEditKey('date')

    const expirationDate = new TableColumn()
        .setKey('expirationDate')
        .setType('date')
        .setDisplayName('Expiration Date')
        .setEditKey('expirationDate')

    const currentStatus = new TableColumn()
        .setKey('currentStatus')
        .setDisplayName('Current Status')
        .setEditKey('currentStatus')

    const type = new TableColumn()
        .setKey('type')
        .setDisplayName('Type')
        .setEditKey('type')

    const tableCols = new TableColumns([
        rxNormId,
        name,
        instructions,
        quantity,
        doseForm,
        date,
        expirationDate,
        currentStatus,
        type
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}