import React from 'react';
import './PatientEvents.sass';
import EventsTable from '../../../../components/EventsTable/EventsTable';
import { useLocation } from 'react-router';

const PatientEvents = () => {

    // <> Location & State Variables Passed
    const location = useLocation();
    const { patientId, patientName } = location.state;

    // <> JSX
    return (
        <div className="patient-events">

            {/* Top */}
            <div className="patient-events__top">

                {/* Patient Name */}
                <div className="patient-events__top__title">Patient events for <span className="primary-color">{patientName}</span></div>

            </div>

            {/* Patient Events */}
            <div className="patient-events__container">

                {/* Events Table */}
                <EventsTable patientId={patientId} />

            </div>

            {/* Actions */}
            {/* <div className="patient-events__actions">
                <div className="patient-events__actions__left">
                    
                </div>

                <div className="patient-events__actions__right">
                    
                </div>
            </div> */}

        </div>
    );
};

export default PatientEvents;