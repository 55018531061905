import Configs from '../../../config.js';
import moment from 'moment';
import StringFormatter from '../../../utils/string.formats.js';


function presentUserDetails(userDetails, userRoles) {

    const userDetailsPresentable = userDetails;

    userDetailsPresentable.createdOn = moment(userDetailsPresentable.createdOn).format(Configs.formats.date);
    userDetailsPresentable.roles = userRoles?.length > 0 ? userRoles.join(', ') : 'None';

    return userDetailsPresentable;
}


function facilitiesOptions(facilities) {
    return facilities.map(f => {
        return {
            label: f.name,
            value: f.id
        }
    })
}

function eventTypesOptions(et) {
    return et.map(f => {
        return {
            label: f.name,
            value: f.id
        }
    })
}

/**
 * Exports
 */
export {
    presentUserDetails,
    facilitiesOptions,
    eventTypesOptions
}