// <> Formatters
function formatFacilities(facilitiesArg) {
    return facilitiesArg.map(f => { return { value: f.id, label: f.name } });
}
function formatPhysicians(physiciansArg) {
    return physiciansArg.map(p => { return { value: p.id, label: p.name } });
}
function formatEventTypes(eventTypesArg) {
    return eventTypesArg.map(p => { return { value: p.id, label: p.name } });
}
function formatPriorities(prioritiesArg) {
    return prioritiesArg.map(p => { return { value: p.id, label: p.name } });
}

/**
 * Exports
 */
export {
    formatFacilities,
    formatPhysicians,
    formatEventTypes,
    formatPriorities
}