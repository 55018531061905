import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './BasicMultiSelector.sass';



/**
 * a basic dropdown selector
 * @param {{ name: string, options: [{ value, label }], value: number, setter: function, enableDefault: boolean? }} params 
 */
const BasicMultiSelector = ({ defaults = null, options, setter, enableDefault = true, defaultOptionText = '-- Select --' }) => {

    // <> States
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState(defaults || options.map(o => o.value));

    // <> Effects
    useEffect(() => {
        setter(selectedValues);
    }, [selectedValues]);

    // <> Helpers
    function selectDefaultValue() {
        setSelectedValues(options.map(o => o.value));
    }

    function toggleValue(val) {
        if(!selectedValues) setSelectedValues([val]);
        else if(selectedValues.includes(val) && selectedValues.length == 1) setSelectedValues(null);
        else if(selectedValues.includes(val)) setSelectedValues(prev => prev.filter(v => v != val));
        else setSelectedValues(prev => [...prev, val]);
    }

    // <> JSX
    return (
        <div className="basic-multi-selector">

            {/* Overlay */}
            {isOpen && <div className="basic-multi-selector__overlay" onClick={() => setIsOpen(false)}></div>}

            {/* Toggle */}
            <div className="basic-multi-selector__toggle" onClick={() => setIsOpen(prev => !prev)}>
                <div className="basic-multi-selector__toggle__label">{selectedValues?.length && selectedValues.length > 0 ? `${selectedValues.length} selected` : defaultOptionText}</div>
                <div className="basic-multi-selector__toggle__icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
            </div>

            {/* Options */}
            {isOpen && <div className="basic-multi-selector__options">

                {/* Default Option */}
                {enableDefault && (
                    <div className="basic-multi-selector__option" onClick={selectDefaultValue}>
                        {defaultOptionText}
                    </div>
                )}

                {/* Options */}
                {options.map((o, i) => (
                    <div key={i} className="basic-multi-selector__option" onClick={() => toggleValue(o.value)}>
                        <div className="basic-multi-selector__option__checkbox">
                            <input type="checkbox" checked={selectedValues != null && selectedValues?.includes(o.value)} />
                        </div>
                        <div className="basic-multi-selector__option__label">
                            {o.label}
                        </div>
                        
                    </div>
                ))}

            </div>}
        </div>
    );
};

export default BasicMultiSelector;