import React, { useContext, useEffect, useState } from 'react';
import * as Presenter from './SettingsDefaults.presenter';
import '../SettingsPane.sass';
import BrowserStorage from '../../../utils/browser.storage';
import Enums from '../../../enums';
import { UserContext } from '../../../context/user.context';
import Toggle from '../../../components/_controls/Toggle/Toggle';
import BasicSelector from '../../../components/_controls/BasicSelector/BasicSelector';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../../context/misc.context';
import { useHistory } from 'react-router-dom';
import MasterDataService from '../../../services/masterData.service';
import ManageUserService from '../../../services/manageUser.service';
import Configs from '../../../config';
import EventsFilters from '../../../components/EventFilters/EventFilters';

const SettingsDefaults = () => {

    // <> Contexts
    const { addAlert } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [startup, setStartup] = useState(BrowserStorage.get(Enums.BrowserStorage.StartupPage.Key) || 0);

    // <> Actions
    function changeStartupPage(newPage) {
        BrowserStorage.set(Enums.BrowserStorage.StartupPage.Key, newPage);
    }

    // <> Helper
    function onPageSelected(page) {
        setStartup(page);
        changeStartupPage(page);
    }

    function saveFilters(filters) {
        BrowserStorage.set(Enums.BrowserStorage.EventFilters.Key, JSON.stringify(filters));
    }

    function getEventFilterDefaults() {
        return JSON.parse(BrowserStorage.get(Enums.BrowserStorage.EventFilters.Key));
    }

    // <> JSX
    return (
        <div className="settings-pane">
            
            {/* Startup Page */}
            <div className="settings-pane__section">
                <div className="settings-pane__section__title">Startup Page</div>
                <div className="settings-pane__section__body">
                    <div className="settings-pane__section__body__item">
                        <div className="settings-pane__section__body__item__label">Select your landing page</div>
                        <div className="settings-pane__section__body__item__control">
                            <BasicSelector
                                options={Presenter.startupOptions()}
                                value={startup}
                                setter={onPageSelected} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Event Filters */}
            <div className="settings-pane__section">
                <div className="settings-pane__section__title">Event Filters</div>
                <div className="settings-pane__section__body">
                    <div className="settings-pane__section__body__item">
                        <div className="settings-pane__section__body__item__label">Select your default event filters</div>
                        <div className="settings-pane__section__body__item__control">
                            <EventsFilters defaults={getEventFilterDefaults()} onChangeFn={saveFilters} hideDatePicker={true} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SettingsDefaults;