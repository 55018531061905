import React, { useState, useEffect } from 'react';
import './TablePaginated.sass';
import '../Table.sass';
import { Link } from 'react-router-dom';
import Controls from './TablePaginated.Controls';
import SimpleSortPicker from '../../_controls/SimpleSortPicker/SimpleSortPicker';
import IconButton from '../../_controls/IconButton/IconButton';
import { faChevronRight, faInfo, faTrash } from '@fortawesome/free-solid-svg-icons';
import Configs from '../../../config.js';

/**
 * 
 * @param {function} getPage function that gets the data page to be displayed
 * @param {[]} items the displayed data page items
 * @param {number} matchesCount the number of total search matches
 * @param {TableColumns} columnDefinitions column definitions
 * @param {function} deleteRow function for deleting a row (passes row "id" as param if present)
 * @param {function} detailsAction function for details for a row (passes row "id" as param if present)
 * @param {function} detailsActionText text for the details action button
 * @param {boolean} isLoading indicate if data loading is occurring or not
 */
export default function TablePaginated({
    getPage, 
    items,
    matchesCount,
    columnDefinitions,
    deleteRow = null,
    detailsAction = null,
    detailsActionText = 'Details',
    isLoading = false,
    refreshTriggers = []
}) {

    // <> States
    const [searchQuery, setSearchQuery] = useState({key: '', text: ''});
    const [sortQuery, setSortQuery] = useState({key: '', value: null});
    const [pages, setPages] = useState(0);
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    // <> Effects
    useEffect(() => {
        setPages(parseInt(Math.ceil(matchesCount / pageSize)));
    }, [matchesCount])

    useEffect(() => {

        // Search delay
        const delayDebounceFn = setTimeout(() => {
            getPage(searchQuery.key, searchQuery.text, sortQuery.key, sortQuery.value, pageNum, pageSize);
        }, Configs.misc.searchDelay);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery])

    useEffect(() => {
        getPage(searchQuery.key, searchQuery.text, sortQuery.key, sortQuery.value, pageNum, pageSize);
    }, [sortQuery, pageNum, pageSize, ...refreshTriggers])
    
    // <> JSX
    return (
        <div className="table table-full-paginator">

            {/* Table */}
            <div className="table-full-paginator__table-wrapper table__table-wrapper">
                <table className="items-table">

                    <thead>
                        <tr>
                            {/* Loop through column display names */}
                            {columnDefinitions.columns.map((col, index) => (
                                <th key={index}>
                                    <div className="items-table__th-label">
                                        <span className="items-table__th-label__title">{col.displayName}</span>
                                        {col.sortKey &&
                                            <div className="items-table__th-label__sort-picker">
                                                <SimpleSortPicker 
                                                    status={sortQuery.key == col.sortKey ? sortQuery.value : null}
                                                    setStatus={status => setSortQuery({ key: col.sortKey, value: status })} />
                                            </div>
                                        }
                                    </div>
                                    <div className="items-table__filters">
                                        {col.searchKey && 
                                            <input 
                                                className={`items-table__filters__input-txt items-table__filters__input-txt--${col.searchSize} input-txt`} 
                                                placeholder={col.searchPlaceholder}
                                                value={searchQuery.key == col.searchKey ? searchQuery.text : ''} 
                                                onChange={ev => setSearchQuery({ key: ev.target.value ? col.searchKey : '', text: ev.target.value })} />
                                        }
                                        
                                    </div>
                                </th>
                            ))}

                            {/* Actions */}
                            {(detailsAction || deleteRow) && (
                                <th className="actions-th">Actions</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {/* Loop through items */}
                        { items && items.map((item, index) => 
                            (<tr key={index}>

                                {/* Loop through column keys */}
                                {columnDefinitions.columns.map((col, index) => (
                                    <td 
                                        key={index} 
                                        className={`${col.breakable ? 'white-space-wrap' : ''} ${col.isCentered ? 'center-content' : ''}`}
                                        style={col.customStyles}
                                    >
                                        {col.isLink && (
                                            <Link to={item[col.key].link}>{item[col.key].text}</Link>
                                        )}
                                        {!col.isLink && (
                                            item[col.key]
                                        )}
                                    </td>
                                ))}

                                {/* Add Details Btn if Enabled */}
                                {(detailsAction || deleteRow) && (
                                    <td className="table-action-btns">
                                        {detailsAction && <IconButton
                                            text={detailsActionText}
                                            icon={faChevronRight}
                                            btnStyle={'success'}
                                            size={'md'}
                                            hideTextOnMobile={true}
                                            iconOnRight={true}
                                            onClickFn={() => detailsAction(item.id)} />}

                                        {deleteRow && <IconButton
                                            text={'Delete'}
                                            icon={faTrash}
                                            btnStyle={'danger'}
                                            size={'xsm'}
                                            hideTextOnMobile={true}
                                            onClickFn={() => deleteRow(item.id)} />}
                                    </td>
                                )}

                            </tr>))
                        }

                        {/* Empty Table */}
                        {(!isLoading && items && items.length == 0) && (
                            <tr>
                                <td className="no-data" colSpan={detailsAction || deleteRow ? columnDefinitions.columns.length + 1 : columnDefinitions.columns.length}>No data</td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>

            <div className="table-full-paginator__results-count">{matchesCount} result(s) found</div>

            {/* Table Controls */}
            <Controls 
                pages={pages}
                pageNum={pageNum}
                setPageNum={setPageNum}
                pageSize={pageSize}
                setPageSize={setPageSize}
                isLoading={isLoading} />
        </div>
    )
}