import React, { useContext, useEffect, useState } from 'react';
import './DayEventsCompact.sass';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faClock, faExclamationCircle, faFemale, faHandHoldingMedical, faHospital, faMale, faMars, faProcedures, faProjectDiagram, faStethoscope, faUserInjured, faVenus } from '@fortawesome/free-solid-svg-icons';
import Configs from '../../config.js';
import { useHistory } from 'react-router';
import Loader from '../Loader/Loader';
import IconButton from '../_controls/IconButton/IconButton';
import { MiscContext } from '../../context/misc.context';
import Enums from '../../enums';
import { UserContext } from '../../context/user.context';


const DayEventsCompact = ({ dayEvents, isLoading, refresh }) => {

    // <> Context
    const { setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { user } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> Actions
    function goToEventDetails(eventId) {
        history.push('/event/details/general', { eventId });
    }

    // <> Helpers
    function openCodeEventPopup(eventId) {
        setOpenPopupState({
            eventId,
            onSuccess: refresh
        })
        setOpenPopup(Enums.PopupTags.CodeEvent);
    }

    // <> JSX
    return (
        <div className="day-events-compact">

            {/* Loader */}
            <Loader isLoading={isLoading} />

            <div className="day-events-compact__events">
                {dayEvents && dayEvents.map((e, i) => (
                    <div key={i} className="day-events-compact__event">

                        {/* Time */}
                        <div className="day-events-compact__event__time">
                            <div className="day-events-compact__event__time__overlap"></div>
                            <div className="day-events-compact__event__time__clock">
                                <div className="day-events-compact__event__time__clock__top">{moment(e.dateFrom).format('h:mm')}</div>
                                <div className="day-events-compact__event__time__clock__bottom">{moment(e.dateFrom).format('A')}</div>
                            </div>

                        </div>

                        {/* Patient */}
                        <div className="day-events-compact__event__patient">
                            <div className="day-events-compact__event__patient__name">{e.patientName}</div>
                            <div className="day-events-compact__event__patient__info">
                                <div className="day-events-compact__event__patient__info__item">
                                    <FontAwesomeIcon icon={e.patientSex == 'male' ? faMars : faVenus} />
                                    <span>{moment().diff(moment(e.patientDoB), 'years')}y</span>
                                </div>
                                <div className="day-events-compact__event__patient__info__item">
                                    <FontAwesomeIcon icon={faHospital} />
                                    <span>{e.facilityName}</span>
                                </div>
                                <div className="day-events-compact__event__patient__info__item">
                                    <FontAwesomeIcon icon={faStethoscope} />
                                    <span>{e.physicianName}</span>
                                </div>
                                <div className="day-events-compact__event__patient__info__item">
                                    <FontAwesomeIcon icon={faHandHoldingMedical} />
                                    <span>{e.procedureCodes ?? 'None'}</span>
                                </div>
                            </div>
                        </div>

                        {/* Actions */}
                        <div className="day-events-compact__event__actions">
                            {user?.roles?.includes(Configs.userRoles.PHYSICIAN) && e.status == Enums.EventStatuses.Confirmed && <IconButton
                                text={'Event Status'}
                                icon={faProjectDiagram}
                                btnStyle={'success'}
                                onClickFn={() => openCodeEventPopup(e.id)} />}
                            <IconButton
                                text={'Details'}
                                icon={faChevronRight}
                                btnStyle={'success'}
                                iconOnRight={true}
                                onClickFn={() => goToEventDetails(e.id)} />
                        </div>

                    </div>
                ))}
            </div>
            
        </div>
    );
};

export default DayEventsCompact;