import { faCheckCircle, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './StaticAlert.sass';

const StaticAlert = ({ level, message }) => {
    return (
        <div className={`static-alert static-alert--${level}`}>
            <div className="static-alert__icon">
                {level == 'info'      && <FontAwesomeIcon icon={faInfoCircle} />}
                {level == 'success'   && <FontAwesomeIcon icon={faCheckCircle} />}
                {level == 'warning'   && <FontAwesomeIcon icon={faExclamationCircle} />}
                {level.startsWith('danger')    && <FontAwesomeIcon icon={faExclamationCircle} />}
            </div>
            <div className="static-alert__message">{message}</div>
        </div>
    );
};

export default StaticAlert;