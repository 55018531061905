import React, { useContext, useEffect, useState } from 'react';
import { MiscContext } from '../../../context/misc.context';
import './RescheduleEventPopup.sass';
import Enums from '../../../enums';
import Configs from '../../../config.js';
import ManageUserService from '../../../services/manageUser.service';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import { useHistory } from 'react-router';
import Loader from '../../Loader/Loader';
import { faCheckCircle, faExclamationCircle, faHospital, faHourglassEnd, faHourglassStart, faStethoscope, faUserInjured } from '@fortawesome/free-solid-svg-icons';
import * as Presenter from './RescheduleEventPopup.presenter';
import EventService from '../../../services/event.service';
import moment from 'moment';
import { UserContext } from '../../../context/user.context';
import HttpErrors from '../../../errors/HttpErrors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectorSearchHybrid from '../../_controls/SelectorSearchHybrid/SelectorSearchHybrid';

const RescheduleEventPopup = () => {

    // <> Context
    const { openPopup, openPopupState, setOpenPopup, addAlert } = useContext(MiscContext);
    const { user } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [event, setEvent] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);
    const [intersectingEvents, setIntersectingEvents] = useState(null);
    const [errorMsgs, setErrorMsgs] = useState([]);
    const [physicians, setPhysicians] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [durationHours, setDurationHours] = useState(1);
    const [durationMinutes, setDurationMinutes] = useState(0);
    const [isEmergency, setIsEmergency] = useState(false);

    const [selectedPhysicianId, setSelectedPhysicianId] = useState(0);

    // <> Effects
    useEffect(() => {

        if(openPopup == Enums.PopupTags.RescheduleEvent && openPopupState?.eventId) {
            getEvent();
            getData();
        }
        
    }, [openPopup, openPopupState]);

    useEffect(() => {

        if(event) {
            setStartDate(moment(event.dateFrom).format(Configs.formats.dateInputDateTime));
            setDurationHours(moment(event.dateTo).diff(moment(event.dateFrom), 'hours'));
            setDurationMinutes(moment(event.dateTo).diff(moment(event.dateFrom), 'minutes') % 60);
            setIsEmergency(!!event.isEmergency);
        }

    }, [event]);

    useEffect(() => {

        if(event && physicians) {
            setSelectedPhysicianId(event.physicianId);
        }

    }, [event, physicians]);

    // <> Actions
    async function getData() {
        
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Physicians
            const physiciansResponse = await ManageUserService.getPhysiciansWithDetails();
            const physiciansRes = physiciansResponse.data.data.physicians;

            // -> Set Options
            setPhysicians(physiciansRes);
            
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function getEvent() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data From API
            const eventResponse = await EventService.getWithDetails(openPopupState.eventId);
            const eventRes = eventResponse.data.data.event;
    
            // -> Set Event
            setEvent(eventRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function onSubmit() {

        try {

            setIsLoading(true);
            setErrorMsgs([]);
            

            // -> Validate From
            const [isValid, errors] = validateForm();

            if(!isValid) {
                setErrorMsgs(errors);
            }
            else {

                // -> Format Request Body
                const rescheduleEventRequestBody = packageEventData();

                // -> Send to API
                await EventService.reschedule(rescheduleEventRequestBody);

                // -> Success
                addAlert({
                    level: 'success',
                    message: 'Successfully rescheduled event',
                    ttl: Configs.misc.alertTTL.Mid
                })

                resetForm();
                closePopup();
                if(openPopupState?.onSuccess) openPopupState.onSuccess();
            }

        }
        catch(e) {
            setFailure(e.message);
            GlobalHttpErrorHandler(e, history, addAlert);

            if(e instanceof HttpErrors.HttpResponseError && e.status == Enums.HttpCodes.Invalid) {
                setIntersectingEvents(e.originalError.response.data.data.intersectingEvents);
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function validateForm() {

        const errors = [];

        // -> Validation
        if(!selectedPhysicianId || selectedPhysicianId == 0)    errors.push('please select a physician');
        if(!startDate)                                          errors.push('please select a start date');
        if(!durationHours && parseInt(durationHours) != 0)      errors.push('please select duration hours');
        else if(parseInt(durationHours) < 0)                    errors.push('hours cannot be negative');
        if(!durationMinutes && parseInt(durationMinutes) != 0)  errors.push('please select duration minutes');
        else if(parseInt(durationMinutes) < 0)                  errors.push('minutes cannot be negative');
        if(durationHours == 0 && durationMinutes == 0)          errors.push('duration cannot be zero');

        return [errors.length === 0, errors];
    }

    function packageEventData() {

        return {
            eventId: openPopupState.eventId,
            dateFrom: moment(startDate).format(Configs.formats.apiDateTime),
            dateTo: moment(startDate).add(durationHours, 'hours').add(durationMinutes, 'minutes').format(Configs.formats.apiDateTime),
            physicianId: selectedPhysicianId,
            physicianName: physicians.find(p => p.id == selectedPhysicianId).name,
            physicianPhone: physicians.find(p => p.id == selectedPhysicianId).phone,
            physicianFax: physicians.find(p => p.id == selectedPhysicianId).fax,
            isEmergency: isEmergency
        }
    }

    function resetForm(keepPatient = false) {

        setErrorMsgs([]);
        setPhysicians([]);
        setStartDate(null);
        setDurationHours(1);
        setDurationMinutes(0);
        setIsEmergency(false);
        setSelectedPhysicianId(0);
    }

    function closePopup() {
        setOpenPopup('');
    }


    // <> JSX
    return (
        <div 
            className="reschedule-event-popup" 
            style={{ display: openPopup == Enums.PopupTags.RescheduleEvent ? 'block' : 'none' }}
        >
            <div className="reschedule-event-popup__container">
                <div className="reschedule-event-popup__container__overlay" onClick={closePopup}></div>

                <div className="reschedule-event-popup__container__content">
                    <div className="reschedule-event-popup__container__content__title">Reschedule Event</div>

                    {/* Physician */}
                    {physicians && physicians.length > 0 && (
                        <div className="reschedule-event-popup__container__content__field">
                            <div className="reschedule-event-popup__container__content__field__label" style={{ alignSelf: 'flex-start' }}>Physician</div>
                            <div className="reschedule-event-popup__container__content__field__input">
                                <SelectorSearchHybrid
                                    options={Presenter.formatPhysicians(physicians)}
                                    value={selectedPhysicianId}
                                    setter={setSelectedPhysicianId}
                                    icon={faStethoscope} />
                            </div>
                        </div>
                    )}

                    {/* Start Date Time */}
                    <div className="reschedule-event-popup__container__content__field">
                        <div className="reschedule-event-popup__container__content__field__label">Start Date-Time</div>
                        <div className="reschedule-event-popup__container__content__field__input">
                            <input 
                                className="reschedule-event-popup__container__content__field__input--date" 
                                type="datetime-local" 
                                min={moment().subtract(150, 'years').format(Configs.formats.dateInputDateTime)}
                                max={moment().add(10, 'years').format(Configs.formats.dateInputDateTime)}
                                value={startDate} 
                                onChange={ev => setStartDate(ev.target.value)} />
                        </div>
                    </div>

                    {/* Duration */}
                    <div className="reschedule-event-popup__container__content__field">
                        <div className="reschedule-event-popup__container__content__field__label">Duration</div>
                        <div className="reschedule-event-popup__container__content__field__input--group">
                            <input className="reschedule-event-popup__container__content__field__input--small" type="number" min="0" max="30" value={durationHours} onChange={ev => setDurationHours(ev.target.value)} />
                            <span>h</span>
                            <input className="reschedule-event-popup__container__content__field__input--small" type="number" min="0" max="59" value={durationMinutes} onChange={ev => setDurationMinutes(ev.target.value)} />
                            <span>m</span>
                        </div>
                    </div>

                    {/* Is Emergency */}
                    <div className="reschedule-event-popup__container__content__field">
                        <div className="reschedule-event-popup__container__content__field__label">RTMU</div>
                        <div className="reschedule-event-popup__container__content__field__input reschedule-event-popup__container__content__field__input--checkbox">
                            <input type="checkbox" checked={isEmergency} onChange={ev => setIsEmergency(ev.target.checked)} />
                        </div>
                    </div>

                    {/* Errors */}
                    {errorMsgs && errorMsgs.length > 0 && (
                        <div className="reschedule-event-popup__container__content__errors">
                            <p>You are missing some steps:</p>
                            <ul>
                                {errorMsgs.map((msg, index) => <li key={index}>{msg}</li>)}
                            </ul>
                        </div>
                    )}

                    {/* Submit */}
                    <button className="reschedule-event-popup__container__content__submit-btn btn btn-primary btn-lg" onClick={onSubmit}>Reschedule</button>
                    
                    {/* Success Pane */}
                    {openSuccess && (
                        <div className="reschedule-event-popup__container__content__success-pane">
                            <FontAwesomeIcon icon={faCheckCircle} className="reschedule-event-popup__container__content__success-pane__icon" />
                            <div className="reschedule-event-popup__container__content__success-pane__message">Successfully rescheduled event</div>
                        </div>
                    )}

                    {/* Failure Pane */}
                    {failure && (
                        <div className="reschedule-event-popup__container__content__failure-pane">
                            {/* <FontAwesomeIcon icon={faExclamationCircle} className="reschedule-event-popup__container__content__failure-pane__icon" /> */}
                            <div className="reschedule-event-popup__container__content__failure-pane__message">Failed to reschedule event</div>
                            <div className="reschedule-event-popup__container__content__failure-pane__message2">{failure}{intersectingEvents ? '. It conflicts with the following events:' : ''}</div>
                            {intersectingEvents && (<>
                                <div className="reschedule-event-popup__container__content__failure-pane__intersections">
                                    {intersectingEvents.map((e, index) => (
                                        <div key={index}>
                                            <div><FontAwesomeIcon icon={faHospital} /> {e.facilityName}</div>
                                            <div><FontAwesomeIcon icon={faStethoscope} /> {e.physicianName}</div>
                                            <div><FontAwesomeIcon icon={faUserInjured} /> {e.patientName}</div>
                                            <div><FontAwesomeIcon icon={faHourglassStart} /> {moment(e.dateFrom).format(Configs.formats.dateTime)}</div>
                                            <div><FontAwesomeIcon icon={faHourglassEnd} /> {moment(e.dateTo).format(Configs.formats.dateTime)}</div>
                                        </div>
                                    ))}
                                </div>
                            </>)}
                            <button className="reschedule-event-popup__container__content__failure-pane__close-btn btn btn-lg btn-danger" onClick={() => setFailure(null)}>Back</button>
                        </div>
                    )}
                </div>
            </div>

            {/* Loader */}
            <Loader isLoading={isLoading} />
        </div>
    );
};

export default RescheduleEventPopup;