import React from 'react';
import './YearSelector.sass';

const currYear = new Date().getUTCFullYear();
const years = [currYear-2, currYear-1, currYear, currYear+1, currYear+2];

const YearSelector = ({year, setYear}) => {


    return (
        <div className="year-selector">
            <div className="year-selector__label">Year</div>
            <select className="selector year-selector__control" value={year} onChange={ev => setYear(parseInt(ev.target.value))}>
                {years.map((y, index) => (
                    <option key={index} value={y}>{y}</option>
                ))}
            </select>
        </div>
    );
};

export default YearSelector;