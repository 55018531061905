import Tag from "../../../../components/Tag/Tag";
import Enums from "../../../../enums";


function createStatusTag(status) {

    const tagExtraStyles = { textAlign: 'center' };
    switch(status) {
        case Enums.EventStatuses.Pending:
            return <Tag text={'Event Created'} tagStyle={'gray'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.BoardingSlipGenerated:
            return <Tag text={'BS Generated'} tagStyle={'yellow'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.WaitingForConfirmation:
            return <Tag text={'Awaiting Confirmation'} tagStyle={'orange'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.Confirmed:
            return <Tag text={'Confirmed'} tagStyle={'green'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.Completed:
            return <Tag text={'Completed'} tagStyle={'purple'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.Cancelled:
            return <Tag text={'Cancelled'} tagStyle={'red'} size={'xsm'} extraStyles={tagExtraStyles} />;
        default:
            break;
    }
}

function patientSexOptions() {
    return [
        {
            value: 'male',
            label: 'male'
        },
        {
            value: 'female',
            label: 'female'
        }
    ]
}

function carrierOptions(carriers) {
    return carriers.map(c => { return { value: c.id, label: c.name }});
}
function eventTypeOptions(options) {
    return options.map(o => { return { value: o.id, label: o.name }});
}
function priorityOptions(options) {
    return options.map(o => { return { value: o.id, label: o.name }});
}
function rtmuOptions() {
    return [
        {
            value: 0,
            label: 'No'
        },
        {
            value: 1,
            label: 'Yes'
        },
    ]
}


/**
 * Exports
 */
export {
    createStatusTag,
    patientSexOptions,
    carrierOptions,
    eventTypeOptions,
    priorityOptions,
    rtmuOptions
}