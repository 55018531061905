import { faChevronLeft, faChevronRight, faCogs, faPaperPlane, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import Profile from '../Profile/Profile';
import './Settings.sass';
import SettingsDefaults from './SettingsDefaults/SettingsDefaults';
import SettingsGeneral from './SettingsGeneral/SettingsGeneral';


const Settings = () => {

    // <> History
    const history = useHistory();
    
    // <> States
    const [pathname, setPathname] = useState(history.location.pathname);
    const [isNavOpen, setIsNavOpen] = useState(true);

    // <> Effects
    useEffect(() => {

        const unregister = history.listen((location, action) => {
            setPathname(location.pathname);
        });

        return unregister;
    }, []);

    // <> JSX
    return (
        <div className="settings-page">
            
            {/* Nav */}
            <div className={`settings-page__nav ${!isNavOpen ? 'settings-page__nav--closed' : ''}`}>

                <button className="settings-page__nav__close-btn" onClick={() => setIsNavOpen(prev => !prev)}>
                    {isNavOpen && <FontAwesomeIcon icon={faChevronLeft} />}
                    {!isNavOpen && <FontAwesomeIcon icon={faChevronRight} />}
                </button>

                <Link className={`settings-page__nav__item${pathname.endsWith('/profile') ? ' settings-page__nav__item--selected' : ''}`} to={`/settings/profile`}>
                    <FontAwesomeIcon className="settings-page__nav__item__icon" icon={faUser} />
                    <span className="settings-page__nav__item__label">Profile</span>
                </Link>
                <Link className={`settings-page__nav__item${pathname.endsWith('/notifications') ? ' settings-page__nav__item--selected' : ''}`} to={`/settings/notifications`}>
                    <FontAwesomeIcon className="settings-page__nav__item__icon" icon={faPaperPlane} />
                    <span className="settings-page__nav__item__label">Notifications</span>
                </Link>
                <Link className={`settings-page__nav__item${pathname.endsWith('/defaults') ? ' settings-page__nav__item--selected' : ''}`} to={`/settings/defaults`}>
                    <FontAwesomeIcon className="settings-page__nav__item__icon" icon={faCogs} />
                    <span className="settings-page__nav__item__label">Defaults</span>
                </Link>
            </div>

            {/* Content */}
            <div className="settings-page__content">
                <Switch>
                    <Route path="/settings/profile" exact component={Profile} />
                    <Route path="/settings/notifications" exact component={SettingsGeneral} />
                    <Route path="/settings/defaults" exact component={SettingsDefaults} />
                </Switch>
            </div>

        </div>
    );
};

export default Settings;