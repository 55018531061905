import React, { useContext, useEffect, useState } from 'react';
import { MiscContext } from '../../context/misc.context';
import BasicSelector from '../_controls/BasicSelector/BasicSelector';
import './AddEventPopup.sass';
import DropdownSearch from '../_controls/DropdownSearch/DropdownSearch';
import MasterDataService from '../../services/masterData.service';
import Enums from '../../enums';
import Configs from '../../config.js';
import ManageUserService from '../../services/manageUser.service';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import { useHistory } from 'react-router';
import Loader from '../Loader/Loader';
import DropdownExternalSearch from '../_controls/DropdownExternalSearch/DropdownExternalSearch';
import { faCalendarDay, faCheckCircle, faExclamationCircle, faHospital, faHourglassEnd, faHourglassStart, faStethoscope, faUser, faUserInjured } from '@fortawesome/free-solid-svg-icons';
import PatientService from '../../services/patient.service';
import * as Presenter from './AddEventPopup.presenter';
import EventService from '../../services/event.service';
import moment from 'moment';
import { UserContext } from '../../context/user.context';
import HttpErrors from '../../errors/HttpErrors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectorSearchHybrid from '../_controls/SelectorSearchHybrid/SelectorSearchHybrid';

const AddEventPopup = () => {

    // <> Context
    const { isAddEventPopupOpen, toggleAddEventPopup, addAlert, addEventPopupState } = useContext(MiscContext);
    const { user } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> States
    // Facility, Physician, Start Date-Time, Duration, Event Type, Priority, Is Emergency
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);
    const [intersectingEvents, setIntersectingEvents] = useState(null);
    const [isPatientsLoading, setIsPatientsLoading] = useState(false);
    const [errorMsgs, setErrorMsgs] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [physicians, setPhysicians] = useState([]);
    const [patients, setPatients] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [durationHours, setDurationHours] = useState(1);
    const [durationMinutes, setDurationMinutes] = useState(0);
    const [isEmergency, setIsEmergency] = useState(false);

    const [selectedFacilityId, setSelectedFacilityId] = useState(0);
    const [selectedPhysicianId, setSelectedPhysicianId] = useState(0);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedEventTypeId, setSelectedEventTypeId] = useState(0);
    const [selectedPriorityId, setSelectedPriorityId] = useState(0);

    // <> Effects
    useEffect(() => {
        setSelectedPatient(prev => addEventPopupState.patient);
    }, [addEventPopupState])

    useEffect(() => {

        if(isAddEventPopupOpen) {
            resetForm(true);
            getData();
        }
        else {
            setOpenSuccess(false);
            setFailure(null);
            setIntersectingEvents(null);
        }
        
    }, [isAddEventPopupOpen]);

    // <> Actions
    async function getData() {
        
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data
            const masterDataResponse = await MasterDataService.getTables([
                Enums.MasterData.Facility,
                Enums.MasterData.EventType,
                Enums.MasterData.Priority
            ]);

            // -> Extract Master Data
            const facilitiesRes = masterDataResponse.data.data[Enums.MasterData.Facility];
            const eventTypesRes = masterDataResponse.data.data[Enums.MasterData.EventType];
            const prioritiesRes = masterDataResponse.data.data[Enums.MasterData.Priority];

            // -> Get Physicians
            const physiciansResponse = await ManageUserService.getPhysiciansWithDetails();
            const physiciansRes = physiciansResponse.data.data.physicians;

            // -> Set Options
            setFacilities(facilitiesRes);
            setPhysicians(physiciansRes);
            setEventTypes(eventTypesRes);
            setPriorities(prioritiesRes);

            // -> Set favorite event type
            if(user?.favoriteEventTypeId) setSelectedEventTypeId(user.favoriteEventTypeId);

            // -> Set default priority
            const defaultPriority = prioritiesRes?.find(p => !!p.isDefault);
            if(defaultPriority) setSelectedPriorityId(defaultPriority.id);
            
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function searchPatients(query) {
        
        try {

            // -> Set Loader ON
            setIsPatientsLoading(true);

            // -> Search Patients
            const foundPatientsResponse = await PatientService.search('name', query, null, null, 0, 5);
            const foundPatientsRes = foundPatientsResponse.data.data.patients;

            // -> Set Patients
            setPatients(foundPatientsRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsPatientsLoading(false);
        }
    }

    async function onSubmit() {

        try {

            setIsLoading(true);
            setErrorMsgs([]);
            

            // -> Validate From
            const [isValid, errors] = validateForm();

            if(!isValid) {
                setErrorMsgs(errors);
            }
            else {

                // -> Format Request Body
                const addEventRequestBody = packageEventData();

                // -> Send to API
                const newEventResponse = await EventService.add(addEventRequestBody);

                // -> Open Success Pane
                resetForm();
                goToEventDetails(newEventResponse.data.data.event.id);
            }

        }
        catch(e) {
            setFailure(e.message);
            GlobalHttpErrorHandler(e, history, addAlert);

            if(e instanceof HttpErrors.HttpResponseError && e.status == Enums.HttpCodes.Invalid) {
                setIntersectingEvents(e.originalError.response.data.data.intersectingEvents);
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function validateForm() {

        const errors = [];

        // -> Validation
        if(!selectedFacilityId || selectedFacilityId == 0)      errors.push('please select a facility');
        if(!selectedPatient)                                    errors.push('please select a patient');
        if(!selectedPhysicianId || selectedPhysicianId == 0)    errors.push('please select a physician');
        if(!startDate)                                          errors.push('please select a start date');
        if(!durationHours && parseInt(durationHours) != 0)      errors.push('please select duration hours');
        else if(parseInt(durationHours) < 0)                    errors.push('hours cannot be negative');
        if(!durationMinutes && parseInt(durationMinutes) != 0)  errors.push('please select duration minutes');
        else if(parseInt(durationMinutes) < 0)                  errors.push('minutes cannot be negative');
        if(durationHours == 0 && durationMinutes == 0)          errors.push('duration cannot be zero');
        if(!selectedEventTypeId || selectedEventTypeId == 0)    errors.push('please select a department');
        if(!selectedPriorityId || selectedPriorityId == 0)      errors.push('please select a priority');

        return [errors.length === 0, errors];
    }

    function packageEventData() {

        return {
            dateFrom: moment(startDate).format(Configs.formats.apiDateTime),
            dateTo: moment(startDate).add(durationHours, 'hours').add(durationMinutes, 'minutes').format(Configs.formats.apiDateTime),
            eventTypeId: selectedEventTypeId,
            eventTypeCode: eventTypes.find(e => e.id == selectedEventTypeId).code,
            eventTypeName: eventTypes.find(e => e.id == selectedEventTypeId).name,
            priorityId: selectedPriorityId,
            priority: priorities.find(p => p.id == selectedPriorityId).priority,
            priorityText: priorities.find(p => p.id == selectedPriorityId).name,
            facilityId: selectedFacilityId,
            facilityCode: facilities.find(f => f.id == selectedFacilityId).code,
            facilityName: facilities.find(f => f.id == selectedFacilityId).name,
            facilityAddress: facilities.find(f => f.id == selectedFacilityId).address,
            physicianId: selectedPhysicianId,
            physicianName: physicians.find(p => p.id == selectedPhysicianId).name,
            physicianPhone: physicians.find(p => p.id == selectedPhysicianId).phone,
            physicianFax: physicians.find(p => p.id == selectedPhysicianId).fax,
            coordinatorId: user.userId,
            coordinatorName: user.name,
            patientId: selectedPatient.id,
            patientName: selectedPatient.name,
            isEmergency: isEmergency
        }
    }

    function resetForm(keepPatient = false) {

        setErrorMsgs([]);
        setFacilities([]);
        setPhysicians([]);
        setPatients([]);
        setEventTypes([]);
        setPriorities([]);
        setStartDate(null);
        setDurationHours(1);
        setDurationMinutes(0);
        setIsEmergency(false);
        setSelectedFacilityId(0);
        setSelectedPhysicianId(0);
        if(!keepPatient) setSelectedPatient(null);
        setSelectedEventTypeId(0);
        setSelectedPriorityId(0);
    }

    function goToEventDetails(eventId = null) {
        history.push('/event/details/procedures', { eventId: eventId });
        toggleAddEventPopup();
    }


    // <> JSX
    return (
        <div 
            className="add-event-popup" 
            style={{ display: isAddEventPopupOpen ? 'block' : 'none' }}
        >
            <div className="add-event-popup__container">
                <div className="add-event-popup__container__overlay" onClick={toggleAddEventPopup}></div>

                <div className="add-event-popup__container__content">
                    <div className="add-event-popup__container__content__title">Add Event</div>

                    {/* Facility */}
                    <div className="add-event-popup__container__content__field">
                        <div className="add-event-popup__container__content__field__label">Facility</div>
                        <div className="add-event-popup__container__content__field__input">
                            <BasicSelector name="facility" options={Presenter.formatFacilities(facilities)} value={selectedFacilityId} setter={setSelectedFacilityId} />
                        </div>
                    </div>

                    {/* Event Type */}
                    {eventTypes && (
                        <div className="add-event-popup__container__content__field">
                            <div className="add-event-popup__container__content__field__label">Department</div>
                            <div className="add-event-popup__container__content__field__input">
                                <BasicSelector name="physician" options={Presenter.formatEventTypes(eventTypes)} value={selectedEventTypeId} setter={setSelectedEventTypeId} />
                            </div>
                        </div>
                    )}

                    {/* Patient */}
                    <div className="add-event-popup__container__content__field">
                        <div className="add-event-popup__container__content__field__label">Patient</div>
                        <div className="add-event-popup__container__content__field__input">
                            <DropdownExternalSearch 
                                search={searchPatients}
                                matches={patients}
                                reset={() => setPatients([])}
                                value={selectedPatient}
                                setter={setSelectedPatient}
                                displayKey={'name'}
                                icon={faUser}
                                isLoading={isPatientsLoading} />
                        </div>
                    </div>

                    {/* Physician */}
                    {physicians && physicians.length > 0 && (
                        <div className="add-event-popup__container__content__field">
                            <div className="add-event-popup__container__content__field__label" style={{ alignSelf: 'flex-start' }}>Physician</div>
                            <div className="add-event-popup__container__content__field__input">
                                <SelectorSearchHybrid
                                    options={Presenter.formatPhysicians(physicians)}
                                    value={selectedPhysicianId}
                                    setter={setSelectedPhysicianId}
                                    icon={faStethoscope} />
                            </div>
                        </div>
                    )}

                    {/* Start Date Time */}
                    <div className="add-event-popup__container__content__field">
                        <div className="add-event-popup__container__content__field__label">Start Date-Time</div>
                        <div className="add-event-popup__container__content__field__input">
                            <input 
                                className="add-event-popup__container__content__field__input--date" 
                                type="datetime-local"
                                min={moment().subtract(150, 'years').format(Configs.formats.dateInputDateTime)}
                                max={moment().add(10, 'years').format(Configs.formats.dateInputDateTime)}
                                value={startDate} 
                                onChange={ev => setStartDate(ev.target.value)} />
                        </div>
                    </div>

                    {/* Duration */}
                    <div className="add-event-popup__container__content__field">
                        <div className="add-event-popup__container__content__field__label">Duration</div>
                        <div className="add-event-popup__container__content__field__input--group">
                            <input className="add-event-popup__container__content__field__input--small" type="number" min="0" max="30" value={durationHours} onChange={ev => setDurationHours(ev.target.value)} />
                            <span>h</span>
                            <input className="add-event-popup__container__content__field__input--small" type="number" min="0" max="59" value={durationMinutes} onChange={ev => setDurationMinutes(ev.target.value)} />
                            <span>m</span>
                        </div>
                    </div>

                    {/* Priority */}
                    {priorities && (
                        <div className="add-event-popup__container__content__field">
                            <div className="add-event-popup__container__content__field__label">Priority</div>
                            <div className="add-event-popup__container__content__field__input">
                                <BasicSelector name="physician" options={Presenter.formatPriorities(priorities)} value={selectedPriorityId} setter={setSelectedPriorityId} />
                            </div>
                        </div>
                    )}

                    {/* Is Emergency */}
                    <div className="add-event-popup__container__content__field">
                        <div className="add-event-popup__container__content__field__label">RTMU</div>
                        <div className="add-event-popup__container__content__field__input add-event-popup__container__content__field__input--checkbox">
                            <input type="checkbox" value={isEmergency} onChange={ev => setIsEmergency(ev.target.value)} />
                        </div>
                    </div>

                    {/* Errors */}
                    {errorMsgs && errorMsgs.length > 0 && (
                        <div className="add-event-popup__container__content__errors">
                            <p>You are missing some steps:</p>
                            <ul>
                                {errorMsgs.map((msg, index) => <li key={index}>{msg}</li>)}
                            </ul>
                        </div>
                    )}

                    {/* Submit */}
                    <button className="add-event-popup__container__content__submit-btn btn btn-primary btn-lg" onClick={onSubmit}>Add</button>
                    

                    {/* Success Pane */}
                    {openSuccess && (
                        <div className="add-event-popup__container__content__success-pane">
                            <FontAwesomeIcon icon={faCheckCircle} className="add-event-popup__container__content__success-pane__icon" />
                            <div className="add-event-popup__container__content__success-pane__message">Successfully added event</div>
                        </div>
                    )}

                    {/* Failure Pane */}
                    {failure && (
                        <div className="add-event-popup__container__content__failure-pane">
                            <FontAwesomeIcon icon={faExclamationCircle} className="add-event-popup__container__content__failure-pane__icon" />
                            <div className="add-event-popup__container__content__failure-pane__message">Failed to add event</div>
                            <div className="add-event-popup__container__content__failure-pane__message2">{failure}{intersectingEvents ? '. It conflicts with the following events:' : ''}</div>
                            {intersectingEvents && (<>
                                <div className="add-event-popup__container__content__failure-pane__intersections">
                                    {intersectingEvents.map((e, index) => (
                                        <div key={index}>
                                            <div><FontAwesomeIcon icon={faHospital} /> {e.facilityName}</div>
                                            <div><FontAwesomeIcon icon={faStethoscope} /> {e.physicianName}</div>
                                            <div><FontAwesomeIcon icon={faUserInjured} /> {e.patientName}</div>
                                            <div><FontAwesomeIcon icon={faHourglassStart} /> {moment(e.dateFrom).format(Configs.formats.dateTime)}</div>
                                            <div><FontAwesomeIcon icon={faHourglassEnd} /> {moment(e.dateTo).format(Configs.formats.dateTime)}</div>
                                        </div>
                                    ))}
                                </div>
                            </>)}
                            <button className="add-event-popup__container__content__failure-pane__close-btn btn btn-lg btn-danger" onClick={() => setFailure(null)}>Close</button>
                        </div>
                    )}
                </div>
            </div>

            {/* Loader */}
            <Loader isLoading={isLoading} />
        </div>
    );
};

export default AddEventPopup;