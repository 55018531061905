import React, { useRef, useState } from 'react';
import StringFormatter from '../../../utils/string.formats';
import CustomValidators from '../../../utils/validator';
import './SSNInputSimple.sass';

const SSNInputSimple = ({ extra, defaultValue = '', addClassName = '' }) => {

    // <> States
    const [ssn, setSsn] = useState(defaultValue);

    // <> Helpers
    function ssnOnChange(val) {
        if(!CustomValidators.isNumeric(val.replaceAll('-', ''))) return;
        setSsn(StringFormatter.formatSSN(val));
    }

    // <> JSX
    return (
        <input
            className={`ssn-input-simple ${addClassName}`}
            placeholder="###-##-####"
            value={ssn}
            {...extra}
            onChange={ev => ssnOnChange(ev.target.value)} />
    );
};

export default SSNInputSimple;