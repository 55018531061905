import React, { useContext, useEffect, useState } from 'react';
import './EventScheduling.sass';
import '../EventForms.sass';
import moment from 'moment';
import Configs from '../../../../config';
import { useHistory, useLocation } from 'react-router';
import Enums from '../../../../enums';
import EventService from '../../../../services/event.service';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../../../context/misc.context';
import StringFormatter from '../../../../utils/string.formats';
import Loader from '../../../../components/Loader/Loader';
import * as Presenter from './EventScheduling.presenter';
import EditableField from '../../../../components/_controls/EditableField/EditableField';
import { UserContext } from '../../../../context/user.context';
import AsyncPromptPopup from '../../../../components/_popups/AsyncPromptPopup/AsyncPromptPopup';

const EventScheduling = ({ isLoading, eventDetails, facilities, priorities, eventTypes, carriers, refresh }) => {

    // <> Context
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> States
    const [isUpdating, setIsUpdating] = useState(false);
    const [isAuthNumberRequired, setIsAuthNumberRequired] = useState(false);
    const [isAuthNumberEditable, setIsAuthNumberEditable] = useState(false);

    // <> History
    const history = useHistory();

    // <> States
    useEffect(() => {
        if(eventDetails) setIsAuthNumberRequired(eventDetails.insuranceAuthorizationStatus == Enums.InsuranceAuthorizationStatuses.Approved);
        if(eventDetails) setIsAuthNumberEditable(eventDetails.insuranceAuthorizationStatus != Enums.InsuranceAuthorizationStatuses.NotRequired);
    }, [eventDetails])

    // <> Actions
    async function updateAuthStatus(status) {

        try {

            if(eventDetails.insuranceAuthorizationStatus == status) return;

            let authNumber = '';
            if(status == Enums.InsuranceAuthorizationStatuses.Approved && !eventDetails.insuranceAuthorizationNumber) {
                authNumber = await AsyncPromptPopup({ message: 'Authorization Number' });
                if(!authNumber) return;
            }

            // -> Turn Loader ON
            setIsUpdating(true);

            // Update Params
            const params = {
                insuranceAuthorizationStatus: status,
                insuranceAuthorizationStatusUpdaterId: user.userId,
                insuranceAuthorizationStatusUpdatedOn: moment().format(Configs.formats.apiDateTime)
            }
            if(status == Enums.InsuranceAuthorizationStatuses.NotRequired) params.insuranceAuthorizationNumber = null;
            else if(authNumber) params.insuranceAuthorizationNumber = authNumber;

            // -> Update
            await EventService.update(eventDetails.id, params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateEvent(updateParams) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            // -> Save
            await EventService.update(eventDetails.id, updateParams);

            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateEventType(eventTypeId) {

        try {

            const selectedEvent = eventTypes.find(e => e.id == eventTypeId);

            // -> Turn Loader ON
            setIsUpdating(true);

            const params = {
                eventTypeId,
                eventTypeCode: selectedEvent.code,
                eventTypeName: selectedEvent.name
            }

            // -> Save
            await EventService.update(eventDetails.id, params);

            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updatePriority(priorityId) {

        try {

            const selectedPriority = priorities.find(e => e.id == priorityId);

            // -> Turn Loader ON
            setIsUpdating(true);

            const params = {
                priorityId,
                priority: selectedPriority.priority,
                priorityText: selectedPriority.name
            }

            // -> Save
            await EventService.update(eventDetails.id, params);

            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    function updateCarrier(carrierId) {

        const params = {};
        const selectedCarrier = carriers.find(c => c.id == carrierId);
        params.carrierId = carrierId;
        params.carrierCode = selectedCarrier.code;
        params.carrierName = selectedCarrier.name;

        updateEvent(params);
    }

    // <> Helpers
    function openReschedulePopup() {
        setOpenPopupState({
            eventId: eventDetails.id,
            onSuccess: refresh
        })
        setOpenPopup(Enums.PopupTags.RescheduleEvent);
    }

    // <> JSX
    return (
        <div className="event-scheduling">

            {/* Loader */}
            <Loader isLoading={isUpdating} />

            {eventDetails && eventTypes && carriers && <>

                <div>

                    <div className="form-sections">
                        <div className="form-sections__left">

                            <div className="event-scheduling__title">General</div>

                            {/* Status */}
                            <div className="form-field">
                                <div className="form-field__label">Status</div>
                                {Presenter.createStatusTag(eventDetails.status)}
                            </div>

                            {/* Facility */}
                            <div className="form-field">
                                <div className="form-field__label">Facility</div>
                                <div className="form-field__value">{eventDetails.facilityName}</div>
                            </div>

                            {/* Department */}
                            <div className="form-field">
                                <div className="form-field__label">Department</div>
                                <EditableField 
                                    isEditable={!isOnlyPhysician()}
                                    type="select"
                                    options={Presenter.eventTypeOptions(eventTypes)}
                                    rules={{ required: 'department required' }}
                                    value={eventDetails.eventTypeId} 
                                    shownValue={eventDetails.eventTypeName}
                                    save={newVal => updateEventType(newVal)} />
                            </div>

                            {/* Physician */}
                            <div className={`form-field ${!isOnlyPhysician() ? 'clickable' : ''}`} onClick={() => { if(!isOnlyPhysician()) openReschedulePopup() }}>
                                <div className="form-field__label">Physician</div>
                                <div className="form-field__value">{eventDetails.physicianName}</div>
                            </div>

                            {/* Coordinator */}
                            <div className="form-field">
                                <div className="form-field__label">Coordinator</div>
                                <div className="form-field__value">{eventDetails.coordinatorName}</div>
                            </div>

                            {/* Start */}
                            <div className={`form-field ${!isOnlyPhysician() ? 'clickable' : ''}`} onClick={() => { if(!isOnlyPhysician()) openReschedulePopup() }}>
                                <div className="form-field__label">Start</div>
                                <div className="form-field__value">{moment(eventDetails.dateFrom).format(Configs.formats.dateTime)}</div>
                            </div>

                            {/* End */}
                            <div className={`form-field ${!isOnlyPhysician() ? 'clickable' : ''}`} onClick={() => { if(!isOnlyPhysician()) openReschedulePopup() }}>
                                <div className="form-field__label">End</div>
                                <div className="form-field__value">{moment(eventDetails.dateTo).format(Configs.formats.dateTime)}</div>
                            </div>

                            {/* Duration */}
                            <div className={`form-field ${!isOnlyPhysician() ? 'clickable' : ''}`} onClick={() => { if(!isOnlyPhysician()) openReschedulePopup() }}>
                                <div className="form-field__label">Duration</div>
                                <div className="form-field__value">{StringFormatter.timeDifference(eventDetails.dateFrom, eventDetails.dateTo)}</div>
                            </div>

                            {/* Priority */}
                            <div className="form-field">
                                <div className="form-field__label">Priority</div>
                                <EditableField 
                                    isEditable={!isOnlyPhysician()}
                                    type="select"
                                    options={Presenter.priorityOptions(priorities)}
                                    rules={{ required: 'priority required' }}
                                    value={eventDetails.priorityId} 
                                    shownValue={eventDetails.priorityText}
                                    save={newVal => updatePriority(newVal)} />
                            </div>

                            {/* Is Emergency */}
                            <div className="form-field">
                                <div className="form-field__label">RTMU</div>
                                <EditableField 
                                    isEditable={!isOnlyPhysician()}
                                    type="select"
                                    options={Presenter.rtmuOptions()}
                                    value={eventDetails.isEmergency} 
                                    shownValue={eventDetails.isEmergency ? 'Yes' : 'No'}
                                    save={newVal => updateEvent({ isEmergency: newVal == 1 ? true : false })} />
                            </div>
                        </div>


                        <div className="form-sections__right">

                            <div className="event-scheduling__title">Patient Details</div>

                            <div className="form-sections__flex">

                                {/* Patient First Name */}
                                <div className="form-field">
                                    <div className="form-field__label">First Name</div>
                                    <EditableField 
                                        isEditable={!isOnlyPhysician()}
                                        value={eventDetails.patientFirstName} 
                                        save={newVal => updateEvent({ patientFirstName: newVal })} />
                                </div>

                                {/* Patient Middle Name */}
                                <div className="form-field">
                                    <div className="form-field__label">Middle Name</div>
                                    <EditableField 
                                        isEditable={!isOnlyPhysician()}
                                        value={eventDetails.patientMiddleName} 
                                        save={newVal => updateEvent({ patientMiddleName: newVal })} />
                                </div>

                                {/* Patient Last Name */}
                                <div className="form-field">
                                    <div className="form-field__label">Last Name</div>
                                    <EditableField 
                                        isEditable={!isOnlyPhysician()}
                                        value={eventDetails.patientLastName} 
                                        save={newVal => updateEvent({ patientLastName: newVal })} />
                                </div>
                                
                            </div>

                            

                            {/* Patient Address */}
                            <div className="form-field">
                                <div className="form-field__label">Patient Address</div>
                                <EditableField 
                                    isEditable={!isOnlyPhysician()}
                                    rules={{ required: 'patient address required', minLength: { value: 5, message: 'patient address must be 5 characters or more' } }}
                                    value={eventDetails.patientAddress} 
                                    save={newVal => updateEvent({ patientAddress: newVal })} />
                            </div>

                            {/* Patient Phone */}
                            <div className="form-field">
                                <div className="form-field__label">Patient Phone Number</div>
                                <EditableField 
                                    isEditable={!isOnlyPhysician()}
                                    type="phone"
                                    rules={{minLength: { value: 10, message: 'Enter correct phone number' }, maxLength: { value: 10, message: 'Enter correct phone number' }}}
                                    value={eventDetails.patientPreferredPhone} 
                                    shownValue={StringFormatter.formatPhone(eventDetails.patientPreferredPhone)}
                                    save={newVal => updateEvent({ patientPreferredPhone: newVal })} />
                            </div>

                            {/* Patient DoB */}
                            <div className="form-field">
                                <div className="form-field__label">Patient DoB</div>
                                <EditableField 
                                    isEditable={!isOnlyPhysician()}
                                    type="date"
                                    rules={{
                                        minDate: { value: moment().subtract(150, 'years').format(Configs.formats.dateInputDate), message: 'Chosen DoB is too old' }, 
                                        maxDate: { value: moment().format(Configs.formats.dateInputDate), message: 'DoB must be in the past' }
                                    }}
                                    value={moment(eventDetails.patientDoB).format(Configs.formats.isoDate)} 
                                    shownValue={moment(eventDetails.patientDoB).format(Configs.formats.date)}
                                    save={newVal => updateEvent({ patientDoB: moment(newVal).format(Configs.formats.apiDateTime) })} />
                            </div>

                            {/* Patient Sex */}
                            <div className="form-field">
                                <div className="form-field__label">Patient Sex</div>
                                <EditableField 
                                    isEditable={!isOnlyPhysician()}
                                    type="select"
                                    options={Presenter.patientSexOptions()}
                                    rules={{ require: 'patient sex required' }}
                                    value={eventDetails.patientGender} 
                                    save={newVal => updateEvent({ patientGender: newVal })} />
                            </div>


                            <div className="event-scheduling__title">Insurance</div>

                            <div className="form-sections__flex">

                                {/* Carrier Name */}
                                {eventDetails && carriers && <div className="form-field">
                                    <div className="form-field__label">Carrier</div>
                                    <EditableField 
                                    isEditable={!isOnlyPhysician()}
                                        type="select"
                                        options={Presenter.carrierOptions(carriers)}
                                        rules={{ require: 'carrier required' }}
                                        value={eventDetails.carrierId} 
                                        shownValue={eventDetails.carrierName}
                                        save={newVal => updateCarrier(newVal)} />
                                </div>}

                                {/* Carrier Authorization URL */}
                                {eventDetails?.authorizationUrl && <div className="form-field">
                                    <div className="form-field__label">Authorization Url</div>
                                    <div className="form-field__value">
                                        <a className="link" href={eventDetails.authorizationUrl} target="_blank" rel="noreferrer">Visit</a>
                                    </div>
                                </div>}

                            </div>

                            <div className="form-sections__flex">

                                {/* Subscriber ID */}
                                <div className="form-field">
                                    <div className="form-field__label">Subscriber ID</div>
                                    <EditableField 
                                        isEditable={!isOnlyPhysician()}
                                        rules={{ required: 'subscriber id required' }}
                                        value={eventDetails.subscriberId} 
                                        save={newVal => updateEvent({ subscriberId: newVal })} />
                                </div>

                                {/* Carrier Authorization Phone */}
                                {eventDetails?.authorizationPhone && <div className="form-field">
                                    <div className="form-field__label">Authorization Phone Number</div>
                                    <div className="form-field__value">{StringFormatter.formatPhone(eventDetails.authorizationPhone)}</div>
                                </div>}

                            </div>

                            <div className="form-sections__flex">

                                {/* Group Name */}
                                <div className="form-field">
                                    <div className="form-field__label">Group Name</div>
                                    <EditableField 
                                        isEditable={!isOnlyPhysician()}
                                        rules={{ required: 'group name required' }}
                                        value={eventDetails.groupName} 
                                        save={newVal => updateEvent({ groupName: newVal })} />
                                </div>

                                {/* Group Number */}
                                <div className="form-field">
                                    <div className="form-field__label">Group Number</div>
                                    <EditableField 
                                        isEditable={!isOnlyPhysician()}
                                        rules={{ required: 'group number required' }}
                                        value={eventDetails.groupNumber} 
                                        save={newVal => updateEvent({ groupNumber: newVal })} />
                                </div>

                            </div>

                            {/* Insurance Authorization Status */}
                            <div className="form-field">
                                <div className="form-field__label">Authorization</div>
                                <div className={`event-scheduling__auth-status ${isOnlyPhysician() ? 'event-scheduling__auth-status--no-actions' : ''}`}>
                                    <div className={`event-scheduling__auth-status__not-required ${eventDetails.insuranceAuthorizationStatus == Enums.InsuranceAuthorizationStatuses.NotRequired ? 'event-scheduling__auth-status__not-required--active' : ''}`} onClick={() => updateAuthStatus(Enums.InsuranceAuthorizationStatuses.NotRequired)}>NOT REQUIRED</div>
                                    <div className={`event-scheduling__auth-status__tbd ${eventDetails.insuranceAuthorizationStatus == Enums.InsuranceAuthorizationStatuses.TBD ? 'event-scheduling__auth-status__tbd--active' : ''}`} onClick={() => updateAuthStatus(Enums.InsuranceAuthorizationStatuses.TBD)}>TBO</div>
                                    <div className={`event-scheduling__auth-status__requested ${eventDetails.insuranceAuthorizationStatus == Enums.InsuranceAuthorizationStatuses.Requested ? 'event-scheduling__auth-status__requested--active' : ''}`} onClick={() => updateAuthStatus(Enums.InsuranceAuthorizationStatuses.Requested)}>REQUESTED</div>
                                    <div className={`event-scheduling__auth-status__approved ${eventDetails.insuranceAuthorizationStatus == Enums.InsuranceAuthorizationStatuses.Approved ? 'event-scheduling__auth-status__approved--active' : ''}`} onClick={() => updateAuthStatus(Enums.InsuranceAuthorizationStatuses.Approved)}>APPROVED</div>
                                    <div className={`event-scheduling__auth-status__rejected ${eventDetails.insuranceAuthorizationStatus == Enums.InsuranceAuthorizationStatuses.Rejected ? 'event-scheduling__auth-status__rejected--active' : ''}`} onClick={() => updateAuthStatus(Enums.InsuranceAuthorizationStatuses.Rejected)}>REJECTED</div>
                                </div>
                            </div>

                            {/* Insurance Authorization Number */}
                            <div className="form-field">
                                <div className="form-field__label">Authorization Number</div>
                                <EditableField 
                                    isEditable={!isOnlyPhysician() && isAuthNumberEditable}
                                    rules={{ required: isAuthNumberRequired ? 'insurance authorization number required' : null }}
                                    value={eventDetails.insuranceAuthorizationNumber} 
                                    save={newVal => updateEvent({ insuranceAuthorizationNumber: newVal })} />
                            </div>

                            {/* Insurance Authorization Comment */}
                            <div className="form-field">
                                <div className="form-field__label">Authorization Comments</div>
                                <EditableField 
                                    type={'textarea'}
                                    isEditable={!isOnlyPhysician() && isAuthNumberEditable}
                                    rules={{ minLength: { value: 10, message: 'comment must be at least 10 characters long' } }}
                                    value={eventDetails.insuranceAuthorizationComment} 
                                    save={newVal => updateEvent({ insuranceAuthorizationComment: newVal })} />
                            </div>

                        </div>
                    </div>

                    
                </div>
                
            </>}
            
        </div>
    );
};

export default EventScheduling;