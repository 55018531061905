import axios from 'axios';
import Configs from '../config';


function addOne({ eventId, userId, note }) {
    return axios.post(`${Configs.apiUrl}/events/notes/addOne`, { eventId, userId, note });
}

function get(eventId) {
    return axios.post(`${Configs.apiUrl}/events/notes/get`, { eventId });
}

function deleteOne(eventNoteId) {
    return axios.post(`${Configs.apiUrl}/events/notes/deleteOne`, { eventNoteId });
}


/**
 * Exports
 */
const EventNoteService = {
    addOne,
    get,
    deleteOne
}
export default EventNoteService;