import React, { useContext, useEffect, useState } from 'react';
import './PatientAllergies.sass';
import '../PatientDetailsContainer.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import EditableField from '../../../../components/_controls/EditableField/EditableField';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import { MiscContext } from '../../../../context/misc.context';
import Enums from '../../../../enums';
import moment from 'moment';
import EditableTable from '../../../../components/_tables/EditableTable/EditableTable';
import * as Presenter from './PatientAllergies.presenter';
import { useHistory, useLocation } from 'react-router';
import PatientAllergyService from '../../../../services/patient.allergy.service';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import Loader from '../../../../components/Loader/Loader';
import PatientDataService from '../../../../services/patientData.service';

const PatientAllergies = () => {

    // <> Context
    const { setOpenPopup, setOpenPopupState, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [allergies, setAllergies] = useState([]);

    // <> Location & State Variables Passed
    const location = useLocation();
    const { patientId, patientName } = location.state;

    // <> Actions
    

    // <> Effects
    useEffect(() => {
        getData();
    }, []);

    // <> Actions
    function onAddClicked() {
        setOpenPopupState({
            patientId,
            patientName,
            onSuccess: getData
        });
        setOpenPopup(Enums.PopupTags.AddPatientAllergy);
    }

    async function getData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Patient Allergies From API
            const allergiesResponse = await PatientDataService.getTable(Enums.PatientData.Allergies);
            const allergiesRes = allergiesResponse.data.data.patientData;
    
            // -> Set Allergies
            setAllergies(allergiesRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function updateAllergyField(allergyId, fieldName, newValue) {
        console.log(`updated ${fieldName} of allergy #${allergyId} to ${newValue}`);

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Update Col in API
            await PatientAllergyService.updateCol(allergyId, fieldName, newValue);

            // -> Refetch Data
            getData();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setIsLoading(false);
        }
    }

    async function deleteAllergy(allergyId) {
        
        try {

            // -> Confirm
            if(!window.confirm('Are you sure you want to delete this allergy?')) return;

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Update Col in API
            await PatientAllergyService.deleteRow(allergyId);

            // -> Refetch Data
            getData();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setIsLoading(false);
        }
    }

    // <> JSX
    return (
        <div className="patient-data">

            <div className="patient-data__top">
                {/* Patient Name */}
                <div className="patient-data__top__title">Patient Allergies for <span className="primary-color">{patientName}</span></div>

                <div className="patient-data__top__actions">
                    {/* Add Allergy Button */}
                    <div className="patient-data__top__actions__action">
                        <IconButton 
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={onAddClicked} />
                    </div>
                </div>
            </div>

            <div className="patient-data__container">

                <EditableTable
                    isLoading={isLoading}
                    items={Presenter.presentData(allergies)}
                    updateField={updateAllergyField}
                    deleteRow={deleteAllergy}
                    columnDefinitions={Presenter.tableColumnDefinitions()} />

            </div>
        </div>
    );
};

export default PatientAllergies;