import React, { useContext, useEffect, useState } from 'react';
import '../MasterDataContainer.sass';
import IconButton from '../../../components/_controls/IconButton/IconButton';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import EditableTable from '../../../components/_tables/EditableTable/EditableTable';
import * as Presenter from './BoardingLinks.presenter';
import { useHistory } from 'react-router';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MasterDataService from '../../../services/masterData.service';
import Loader from '../../../components/Loader/Loader';

const BoardingLinks = () => {

    // <> Context
    const { setMasterDataOpenedPopup, setMasterDataOpenedPopupState, addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [boardingLinks, setBoardingLinks] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);

    // <> Effects
    useEffect(() => {
        getData();
    }, []);

    // <> Actions
    function onAddClicked() {
        setMasterDataOpenedPopup(Enums.AddMasterDataPopupTags.BoardingLink);
        setMasterDataOpenedPopupState({ onSuccessFn: getData });
    }

    async function getData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data From API
            const masterDataResponse = await MasterDataService.getTables([
                Enums.MasterData.BoardingLink,
                Enums.MasterData.Facility,
                Enums.MasterData.EventType,
            ]);
            const boardingLinksRes = masterDataResponse.data.data[Enums.MasterData.BoardingLink];
            const facilitiesRes = masterDataResponse.data.data[Enums.MasterData.Facility];
            const eventTypesRes = masterDataResponse.data.data[Enums.MasterData.EventType];
    
            // -> Format the data
            const presentableData = Presenter.presentData(boardingLinksRes, facilitiesRes, eventTypesRes);
    
            // -> Set States
            setBoardingLinks(presentableData);
            setFacilities(facilitiesRes);
            setEventTypes(eventTypesRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function updateField(rowId, fieldName, newValue) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Update Col in API
            await MasterDataService.updateCol(Enums.MasterData.BoardingLink, rowId, fieldName, newValue);

            // -> Refetch Data
            getData();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setIsLoading(false);
        }
    }

    async function deleteRow(rowId) {

        // -> Confirm
        setOpenPopupState({
            level: 'danger',
            message: 'Are you sure you want to delete this boarding link?',
            onOk: async () => {
                
                try {
        
                    // -> Turn Loader ON
                    setIsLoading(true);
        
                    // -> Update Col in API
                    await MasterDataService.deleteRow(Enums.MasterData.BoardingLink, rowId);
        
                    // -> Refetch Data
                    getData();
                }
                catch(e) {
                    GlobalHttpErrorHandler(e, history, addAlert);
                    setIsLoading(false);
                }
                
            }
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
        
    }

    // <> JSX
    return (
        <div className="master-data">

            {/* Loader */}
            <Loader isLoading={isLoading} />

            <div className="master-data__top">
                {/* Patient Name */}
                <div className="master-data__top__title">Boarding Links</div>

                <div className="master-data__top__actions">
                    {/* Add Allergy Button */}
                    <div className="master-data__top__actions__action">
                        <IconButton 
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={onAddClicked} />
                    </div>
                </div>
            </div>

            <div className="master-data__container">

                {facilities && eventTypes && <EditableTable
                    isLoading={isLoading}
                    items={boardingLinks}
                    updateField={updateField}
                    deleteRow={deleteRow}
                    columnDefinitions={Presenter.tableColumnDefinitions(facilities, eventTypes)} />}

            </div>
        </div>
    );
};

export default BoardingLinks;