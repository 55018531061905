import React, { useContext, useEffect, useState } from 'react';
import TablePaginated from '../../components/_tables/TablePaginated/TablePaginated';
import './ManageUsers.sass';
import * as Presenter from './ManageUsers.presenter';
import { UserContext } from '../../context/user.context';
import { MiscContext } from '../../context/misc.context';
import { useHistory } from 'react-router';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import ManageUserService from '../../services/manageUser.service';
import IconButton from '../../components/_controls/IconButton/IconButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Enums from '../../enums';


const ManageUsers = () => {

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState({key: '', text: ''});
    const [sortQuery, setSortQuery] = useState({key: '', value: null});
    const [users, setUsers] = useState([]);
    const [pages, setPages] = useState(0);
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchResultsCount, setSearchResultsCount] = useState(0);

    // <> Contexts
    const { toggleAddUserPopup, addAlert, setAddUserSuccessFn } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> Actions
    async function getPage(searchKey, searchQuery, sortKey, sortIsAsc, pageNum, pageSize) {

        try {

            // -> Loader ON
            setIsLoading(true);
    
            // -> Fetch Search Results
            const foundPatientsRes = ManageUserService.search(searchKey, searchQuery, sortKey, sortIsAsc, pageNum * parseInt(pageSize), parseInt(pageSize));
            const foundPatients = (await foundPatientsRes).data.data.users;
            const foundPatientsCount = (await foundPatientsRes).data.data.usersCount;
    
            // -> Map Results To Desired Display Values
            const usersPresentable = Presenter.presentData(foundPatients);
    
            // -> Set Data
            setUsers(usersPresentable);
    
            // -> Set Total Results Count
            setSearchResultsCount(foundPatientsCount);
            
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {

            // -> Loader OFF
            setIsLoading(false);
        }
    }

    function onAddClicked() {
        toggleAddUserPopup();
        setAddUserSuccessFn(newUserId => userDetails(newUserId));
    }

    function userDetails(userId) {
        history.push(`/master-data/manage-users/${userId}`);
    }

    // <> JSX
    return (
        <div className="manage-users-page">

            {/* Add User Button */}
            <div className="manage-users-page__add-btn">
                <div className="master-data__top__title">Manage Users</div>
                <IconButton 
                    text={'Add'}
                    icon={faPlus}
                    onClickFn={onAddClicked} />
            </div>

            {/* Container */}
            <div className="manage-users-page__container">

                {/* Search */}
                <div className="manage-users-page__container__search">
                    <div className="search__top">
                        <div className="search__top__left">
                        </div>
                        <div className="search__top__right">
                            <div className="search__results-count">{searchResultsCount} RESULTS</div>
                        </div>
                    </div>
                </div>

                {/* Results */}
                <div className="manage-users-page__container__results">
                    <TablePaginated 
                        getPage={getPage}
                        items={users}
                        matchesCount={searchResultsCount}
                        columnDefinitions={Presenter.tableColumnDefinitions()}
                        detailsAction={userDetails}
                        detailsActionText={'Manage'}
                        isLoading={isLoading} />
                </div>

            </div>
        </div>
    )
};

export default ManageUsers;