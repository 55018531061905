import React, { useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import UserService from '../../services/user.service';
import BrowserStorage from '../../utils/browser.storage';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faHeart, faLock, faEnvelope, faArrowRight, faMobile } from '@fortawesome/free-solid-svg-icons'
import './Register.sass';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../context/misc.context';
import HttpErrors from '../../errors/HttpErrors';
import Enums from '../../enums';

export default function Register() {

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    // <> Context
    const { user, setUser } = useContext(UserContext);
    const { addAlert } = useContext(MiscContext);

    // <> Form, History
    const { handleSubmit, register, formState: { errors } } = useForm();
    const history = useHistory();
    
    // <> Form Submit Handler
    const onSubmit = async values => {

        // -> Activate Loader
        setIsLoading(true);
        setError('');

        try {

            // -> Register
            const response = await UserService.register(values.email, values.password, values.phone);

            // -> Extract Tokens
            const accessToken = response.data.data.accessToken;
            const refreshToken = response.data.data.refreshToken;

            // -> Store Tokens in Browser
            BrowserStorage.set(Enums.BrowserStorage.AccessToken.Key, accessToken);
            BrowserStorage.set(Enums.BrowserStorage.RefreshToken.Key, refreshToken);
    
            // -> Get logged in User Data
            const userData = (await UserService.getUserData()).data.data;

            // -> Set User Data to Context
            setUser({
                loggedIn: true,
                userId: userData.user.id,
                roles: userData.userRoles,
                ...userData.user
            });

            // -> Navigate to Homepage
            console.log('logged in')
            history.push('/');
            
        }
        catch(e) {

            if(e instanceof HttpErrors.HttpResponseError) {
                setError(e.originalError.response.data.message);
            }
            else {
                GlobalHttpErrorHandler(e, history, addAlert);
                setError(e.response.data.message);
            }
        }
        finally {

            // -> Deactivate Loader
            setIsLoading(false);
        }
    }

    // <> JSX
    return (
        <div className="register-page">
            <div className="register-page__container">
                {/* <div className="logo">
                    <FontAwesomeIcon className="logo__image" icon={faHeart} />
                    <span className="logo__text">GALEN</span>
                </div> */}

                <span className="register-page__container__title">Register</span>
                <span className="register-page__container__subtitle">Sign up to Galen</span>
                <form onSubmit={handleSubmit(onSubmit)} className="register-form">

                    {/* Email */}
                    <div className="register-page__container__form__field">
                        <FontAwesomeIcon className="register-page__container__form__field__icon" icon={faEnvelope} />
                        <input
                            className="register-page__container__form__field__input"
                            placeholder="Enter email"
                            {...register('email', {
                                required: "email required",
                                minLength: 3,
                                type: 'email'
                                // pattern: {
                                //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                //     message: "invalid email address"
                                // }
                            })}
                        />
                    </div>
                    {errors.email && errors.email.message && <span className="error-msg">{errors.email.message}</span>}
                    {errors.email && errors.email.type == "minLength" && <span className="error-msg">email must be valid</span>}

                    {/* Password */}
                    <div className="register-page__container__form__field">
                        <FontAwesomeIcon className="register-page__container__form__field__icon" icon={faLock} />
                        <input
                            className="register-page__container__form__field__input"
                            placeholder="Enter password"
                            {...register('password', {
                                required: "password required",
                                minLength: 3,
                                type: "password" 
                                //validate: value => value !== "admin" || "Nice try!"
                            })}
                        />
                    </div>
                    {errors.password && errors.password.message && <span className="error-msg">{errors.password.message}</span>}
                    {errors.password && errors.password.type == "minLength" && <span className="error-msg">password must be at least 3 characters</span>}

                    {/* Phone */}
                    <div className="register-page__container__form__field">
                        <FontAwesomeIcon className="register-page__container__form__field__icon" icon={faMobile} />
                        <input
                            className="register-page__container__form__field__input"
                            placeholder="Enter mobile number"
                            {...register('phone', {
                                required: "mobile number required required",
                                minLength: 10,
                                maxlength: 10,
                                type: "number" 
                                //validate: value => value !== "admin" || "Nice try!"
                            })}
                        />
                    </div>
                    {errors.phone && errors.phone.message && <span className="error-msg">{errors.phone.message}</span>}
                    {errors.phone && errors.phone.type == "minLength" && <span className="error-msg">must be valid phone number</span>}

                    {error && <span className="error-msg">{error}</span>}
                    <button 
                        type="submit" 
                        disabled={isLoading} 
                        className="register-page__container__form__submit-btn">
                            {isLoading && <FontAwesomeIcon icon={faSpinner} className="spin" />}
                            <span>Register</span> 
                            <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </form>

                {/* Go To Login */}
                <div className="register-page__container__nav">
                    <Link to="/login">already have an account? Go to login</Link>
                </div>
            </div>
        </div>
    )
}
