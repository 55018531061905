import { faCheckCircle, faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Enums from '../../enums';
import './ClearanceStatusIcon.sass';

const ClearanceStatusIcon = ({ status }) => {

    // <> JSX
    return (
        <div className="clearance-status-icon">

            {/* Not Required */}
            {status == Enums.ClearanceStatuses.NotRequired && (
                <div className="clearance-status-icon__not-required">
                    <FontAwesomeIcon icon={faCircle} />
                </div>
            )}

            {/* Pending */}
            {status == Enums.ClearanceStatuses.Pending && (
                <div className="clearance-status-icon__pending">
                    <FontAwesomeIcon icon={faCircle} />
                </div>
            )}

            {/* Requested */}
            {status == Enums.ClearanceStatuses.Requested && (
                <div className="clearance-status-icon__requested">
                    <FontAwesomeIcon icon={faCircle} />
                </div>
            )}

            {/* Accepted */}
            {status == Enums.ClearanceStatuses.Accepted && (
                <div className="clearance-status-icon__accepted">
                    <FontAwesomeIcon icon={faCheckCircle} />
                </div>
            )}

            {/* Rejected */}
            {status == Enums.ClearanceStatuses.Rejected && (
                <div className="clearance-status-icon__rejected">
                    <FontAwesomeIcon icon={faTimesCircle} />
                </div>
            )}

        </div>
    );
};

export default ClearanceStatusIcon;