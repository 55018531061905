import React, { useContext, useEffect, useState } from 'react';
import './PatientInsurance.sass';
import { faCheck, faPencilAlt, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import { MiscContext } from '../../../../context/misc.context';
import Enums from '../../../../enums';
import { useHistory, useLocation } from 'react-router';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import PatientService from '../../../../services/patient.service';
import Loader from '../../../../components/Loader/Loader';
import { useForm } from 'react-hook-form';
import Configs from '../../../../config';
import moment from 'moment';
import MasterDataService from '../../../../services/masterData.service';
import * as Presenter from './PatientInsurance.presenter';

const PatientInsurance = () => {

    // <> Context
    const { addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> Form
    const { register, handleSubmit, formState: { errors } } = useForm();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [inEditMode, setInEditMode] = useState(false);
    const [patient, setPatient] = useState(null);
    const [carriers, setCarriers] = useState([]);

    // <> Location & State Variables Passed
    const location = useLocation();
    const { patientId, patientName } = location.state;

    // <> Effects
    useEffect(() => {
        getPatient();
        getData();
    }, []);

    // <> Actions
    async function getPatient() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Patient
            const patientResponse = await PatientService.findById(patientId);
            const patientsRes = patientResponse.data.data.patient;

            // -> Set State
            setPatient(patientsRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function getData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Carriers
            const carriersResponse = await MasterDataService.getTable(Enums.MasterData.Carrier);
            const carriersRes = carriersResponse.data.data.masterData;

            // -> Set State
            setCarriers(carriersRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function updateData(data) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // Update Params
            const selectedCarrier = carriers.find(c => c.id == data.carrierId);

            const params = {};
            params.carrierId = data.carrierId;
            params.carrierCode = selectedCarrier.code;
            params.carrierName = selectedCarrier.name;
            params.subscriberId = data.subscriberId;
            params.groupName = data.groupName;
            params.groupNumber = data.groupNumber;


            // -> Update Patient
            await PatientService.updateOne(patientId, params);

            // -> Refresh
            setInEditMode(false);
            getPatient();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> JSX
    return (
        <div className="patient-insurance">

            {/* Top */}
            <div className="patient-insurance__top">

                {/* Patient Name */}
                <div className="patient-insurance__top__title">Patient insurance for <span className="primary-color">{patientName}</span></div>

            </div>

            {/* Patient Insurance */}
            <div className="patient-insurance__container">

                {patient && carriers && <form onSubmit={handleSubmit(updateData)} className="form-sections">

                    <div className="form-sections__left">

                        <div className="form-sections__title">Insurance</div>

                        {/* Carrier Name */}
                        <div className="form-field">
                            <div className="form-field__label">Carrier</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.carrierName}</div>
                            <select 
                                hidden={!inEditMode}
                                defaultValue={patient.carrierId}
                                className="form-field__input"
                                {...register('carrierId', { required: 'carrier required' })}
                            >
                                {carriers.map((carrier, index) => (
                                    <option key={index} value={carrier.id}>{carrier.name}</option>
                                ))}
                            </select>
                            <div hidden={!inEditMode} className="form-field__error">{errors?.carrierId?.message}</div>
                        </div>

                        {/* Subscriber ID */}
                        <div className="form-field">
                            <div className="form-field__label">Subscriber ID</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.subscriberId}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.subscriberId}
                                className="form-field__input"
                                {...register('subscriberId', { required: 'subscriber id required' })} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.subscriberId?.message}</div>
                        </div>

                        {/* Group Name */}
                        <div className="form-field">
                            <div className="form-field__label">Group Name</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.groupName}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.groupName}
                                className="form-field__input"
                                {...register('groupName', { required: 'group name required' })} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.groupName?.message}</div>
                        </div>

                        {/* Group Number */}
                        <div className="form-field">
                            <div className="form-field__label">Group Number</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.groupNumber}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.groupNumber}
                                className="form-field__input"
                                {...register('groupNumber', { required: 'group number required' })} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.groupNumber?.message}</div>
                        </div>


                    </div>

                </form>}

            </div>

            {/* Actions */}
            <div className="patient-insurance__actions">
                <div className="patient-insurance__actions__left">
                    {!inEditMode && <IconButton
                        text={'Edit'}
                        icon={faPencilAlt}
                        btnStyle={'danger'}
                        onClickFn={() => setInEditMode(true)} />}
                    {inEditMode && <IconButton
                        text={'Cancel'}
                        icon={faTimes}
                        btnStyle={'faded'}
                        onClickFn={() => setInEditMode(false)} />}
                    {inEditMode && <IconButton
                        text={'Save'}
                        icon={faCheck}
                        onClickFn={handleSubmit(updateData)} />}
                </div>

                <div className="patient-insurance__actions__right">
                    
                </div>
            </div>

            {/* Loader */}
            <Loader isLoading={isLoading} />

        </div>
    );
};

export default PatientInsurance;