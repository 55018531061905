import React from 'react';
import './Statistics.sass';

const Statistics = () => {
    return (
        <div className="statistics-page">
            <div>
                <div>Coming Soon</div>
                <div>Page under construction</div>
            </div>
        </div>
    );
};

export default Statistics;