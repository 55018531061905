import React, { useContext } from 'react'
import { UserContext } from '../../context/user.context';
import './Header.sass';
import UserSlip from '../_bits/UserSlip/UserSlip';
import { MiscContext } from '../../context/misc.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import LOGO from '../../assets/logo.png';

export default function Header() {
    
    // <> Contexts
    const { user } = useContext(UserContext);
    const { toggleSideMenu, pageTitle } = useContext(MiscContext);
    
    // <> JSX
    return (
        <div className="header">

            {/* Burger */}
            <div className="header__burger" onClick={toggleSideMenu}>
                <FontAwesomeIcon icon={faBars} className="header__burger__icon" />
            </div>

            {/* Date */}
            <div className="header__title">{pageTitle}</div>

            {/* Logo */}
            {/* <div className="header__logo">
                <img className="header__logo__image" src={LOGO} alt="logo" />
                <span className="header__logo__text">GALEN</span>
            </div> */}

            {/* User Slip */}
            <div className="header__user-slip">
                <UserSlip username={user?.name ?? 'N/A'} roles={user?.roles ?? []} />
            </div>

        </div>
    )
}
