import Configs from '../../../config.js';
import { TableColumn, TableColumns } from '../../../components/_tables/TableColumns';
import moment from 'moment';
import StringFormatter from '../../../utils/string.formats.js';
import Tag from '../../../components/Tag/Tag';


// Map Data to Desired Display Values
function presentData(data, updateField) {

    return data.map(d => {
        d.createdOn = moment(d.createdOn).format(Configs.formats.dateTime);
        if(d.authorizationPhone) d.authorizationPhoneFormatted = StringFormatter.formatPhone(d.authorizationPhone);

        // Set if entry is exempt or not
        const labelExtraStyles = { display: 'block', width: '100%', textAlign: 'center' };
        d.isExemptSwitch = (<>
            {!!d.isExempt && 
                <div className="clickable" onClick={() => setEntryAsUnExempt(updateField, d.id)}>
                    <Tag tagStyle={'green'} size={'xsm'} text={'Yes'} extraStyles={labelExtraStyles} />
                </div>
            }
            {!d.isExempt && 
                <div className="clickable" onClick={() => setEntryAsExempt(updateField, d.id)}>
                    <Tag tagStyle={'gray'} size={'xsm'} text={'No'} extraStyles={labelExtraStyles} />
                </div>
            }
        </>)

        return d;
    });
}

function setEntryAsExempt(updateField, rowId) {
    updateField(rowId, 'isExempt', true);
}
function setEntryAsUnExempt(updateField, rowId) {
    updateField(rowId, 'isExempt', false);
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const codeCol = new TableColumn()
        .setKey('code')
        .setDisplayName('Code')
        .setEditKey('code')

    const nameCol = new TableColumn()
        .setKey('name')
        .setDisplayName('Name')
        .setEditKey('name')

    const authorizationUrlCol = new TableColumn()
        .setKey('authorizationUrl')
        .setDisplayName('Authorization URL')
        .setEditKey('authorizationUrl')

    const authorizationPhoneCol = new TableColumn()
        .setType('phone')
        .setKey('authorizationPhone')
        .setShownValueKey('authorizationPhoneFormatted')
        .setEditKey('authorizationPhone')
        .setEditRules({ minLength: { value: 10, message: 'must be valid format' }, maxLength: { value: 10, message: 'must be valid format' } })
        .setDisplayName('Authorization Phone Number')

    const isExemptCol = new TableColumn()
        .setKey('isExemptSwitch')
        .setDisplayName('Is Exempt')

    const tableCols = new TableColumns([
        codeCol,
        nameCol,
        authorizationUrlCol,
        authorizationPhoneCol,
        isExemptCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}