import Configs from '../../../config.js';
import { TableColumn, TableColumns } from '../../../components/_tables/TableColumns';
import moment from 'moment';
import ColorPicker from '../../../components/_controls/ColorPicker/ColorPicker.js';
import EventTypeColorPicker from './EventTypeColorPicker.js';


// Map Data to Desired Display Values
function presentData(data, refresh) {

    return data.map(d => {
        d.createdOn = moment(d.createdOn).format(Configs.formats.dateTime);

        d.colorSelector = (
            <div style={{ position: 'absolute', zIndex: 20 }}>
                <EventTypeColorPicker eventType={d} excludedColors={data.map(el => el.color)} refresh={refresh} />
            </div>
        )

        return d;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const codeCol = new TableColumn()
        .setKey('code')
        .setDisplayName('Code')
        .setEditKey('code')

    const nameCol = new TableColumn()
        .setKey('name')
        .setDisplayName('Name')
        .setEditKey('name')

    const shortnameCol = new TableColumn()
        .setKey('shortname')
        .setDisplayName('Short Name')
        .setEditKey('shortname')

    const colorCol = new TableColumn()
        .setKey('colorSelector')
        .setDisplayName('Color')

    const tableCols = new TableColumns([
        codeCol,
        nameCol,
        shortnameCol,
        colorCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}