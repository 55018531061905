import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Configs from '../../config';
import { MiscContext } from '../../context/misc.context';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import PatientService from '../../services/patient.service';
import './PatientsSearch.sass';
import * as Presenter from './PatientsSearch.presenter';
import Enums from '../../enums';
import IconButton from '../../components/_controls/IconButton/IconButton';
import { faChevronRight, faEnvelope, faHashtag, faIdCard, faMapMarkerAlt, faPhone, faPlus, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../context/user.context';

const PatientsSearch = () => {

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [patients, setPatients] = useState([]);
    const [query, setQuery] = useState('');
    const [hoveredAt, setHoveredAt] = useState(-1);

    // <> Contexts
    const { setPatientDataOpenedPopup, setPatientDataOpenedPopupState, toggleAddEventPopup, setAddEventPopupState, addAlert } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> Effects
    useEffect(() => {

        setHoveredAt(-1);

        if(!query) {
            setPatients([]);
        }
        else {

            const timeoutRef = setTimeout(getPage, Configs.misc.searchDelay);
            return () => clearTimeout(timeoutRef);
        }
    }, [query])

    // <> Actions
    async function getPage() {

        try {

            // -> Loader ON
            setIsLoading(true);
            setNoResults(false);
    
            // -> Fetch Search Results
            const foundPatientsRes = await PatientService.searchAdvanced(query, 0, Configs.misc.patientsAdvancedSearchResultsLimit);
            const foundPatients = foundPatientsRes.data.data.patients;
            if(!foundPatients || foundPatients.length == 0) setNoResults(true);
    
            // -> Map Results To Desired Display Values
            const patientsPresentable = Presenter.presentData(foundPatients);
    
            // -> Set Data
            setPatients(patientsPresentable);
            
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {

            // -> Loader OFF
            setIsLoading(false);
        }
    }

    // <> Actions
    function onKeyPressed(ev) {

        // If Arrow Down, move matched item selection down
        if(ev.key == 'ArrowDown' && hoveredAt < patients.length - 1) setHoveredAt(prev => prev + 1);

        // If Arrow Down, move matched item selection up
        if(ev.key == 'ArrowUp' && hoveredAt > 0) setHoveredAt(prev => prev - 1);

        // If Enter, open patient details
        if(ev.key == 'Enter' && hoveredAt > -1) {
            ev.preventDefault();
            const selectedPatient = patients[hoveredAt];
            if(selectedPatient) {
                if(ev.ctrlKey) openAddPatientEventPopup(selectedPatient.id);
                else goToPatientDetails(selectedPatient.id);
            }
            
        }
    }

    function onAddPatientClicked() {
        setPatientDataOpenedPopup(Enums.AddPatientDetailsPopupTags.Patient);
        // setPatientDataOpenedPopupState({ onSuccessFn: getPage });
    }

    function goToPatientDetails(patientId) {
        const patientName = patients.find(p => p.id == patientId)?.name;
        history.push('/patients/demographics', { patientId, patientName });
    }
    
    function openAddPatientEventPopup(patientId) {
        const patient = patients.find(p => p.id == patientId);
        toggleAddEventPopup();
        setAddEventPopupState({ patient });
    }

    // <> JSX
    return (
        <div className="patients-search">

            {/* Page Title */}
            {/* <div className="page-title">
                <span className="title-text">Patients</span>
                <span className="title-subtext">Search for patients registered in the system.</span>
            </div> */}

            {/* Add Patient Button */}
            {!isOnlyPhysician() && <div className="patients-search__add-btn">
                <IconButton 
                    text={'Add New Patient'}
                    icon={faPlus}
                    onClickFn={onAddPatientClicked} />
            </div>}

            {/* Container */}
            <div className="patients-search__container">

                {/* Search */}
                <div className="patients-search__container__search">
                    <div className="patients-search__search-input">
                        <div className="patients-search__search-input__icon">
                            {!isLoading && <FontAwesomeIcon icon={faSearch} />}
                            {isLoading && <div className="spinner"><FontAwesomeIcon icon={faSpinner} /></div>}
                        </div>
                        <input 
                            className="patients-search__search-input__control"
                            placeholder="Search Patients"
                            onKeyDown={onKeyPressed}
                            onChange={ev => setQuery(ev.target.value)} />
                    </div>

                </div>

                {/* Results */}
                <div className="patients-search__container__results">
                    {patients && patients.map((patient, index) => (
                        <div key={index} className={`patients-search__container__results__item ${hoveredAt == index ? 'patients-search__container__results__item--selected' : ''}`}>

                            {/* Content */}
                            <div className="patients-search__container__results__item__content">
                                {/* Name */}
                                <div className="patients-search__container__results__item__content__name">{patient.name}</div>

                                {/* Info */}
                                <div className="patients-search__container__results__item__content__info">
                                    <div className="patients-search__container__results__item__content__info__item">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <span>{patient.email}</span>
                                    </div>
                                    <div className="patients-search__container__results__item__content__info__item">
                                        <FontAwesomeIcon icon={faPhone} />
                                        <span>{patient.phone}</span>
                                    </div>
                                    <div className="patients-search__container__results__item__content__info__item">
                                        <FontAwesomeIcon icon={faIdCard} />
                                        <span>{patient.ssn}</span>
                                    </div>
                                    <div className="patients-search__container__results__item__content__info__item">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        <span>{patient.fullAddress}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Actions */}
                            <div className="patients-search__container__results__item__actions">
                                {!isOnlyPhysician() && <IconButton
                                    text="Add Event"
                                    icon={faPlus}
                                    onClickFn={() => openAddPatientEventPopup(patient.id)} />}
                                <IconButton
                                    text="Details"
                                    icon={faChevronRight}
                                    btnStyle={'success'}
                                    iconOnRight={true}
                                    onClickFn={() => goToPatientDetails(patient.id)} />
                            </div>
                        </div>
                    ))}
                </div>

                {/* No Data Found */}
                {!isLoading && query.length > 0 && patients.length == 0 && noResults && <div className="patients-search__container__not-found">No patients found</div>}

            </div>
        </div>
    );
};

export default PatientsSearch;