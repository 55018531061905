import { faCheck, faCheckCircle, faCheckSquare, faExclamation, faExclamationCircle, faInfoCircle, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Configs from '../../../config';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import EventService from '../../../services/event.service';
import Loader from '../../Loader/Loader';
import IconButton from '../../_controls/IconButton/IconButton';
import Toggle from '../../_controls/Toggle/Toggle';
import '../Popup.sass';
import './SendPatientCommunicationPopup.sass';


const SendPatientCommunicationPopup = () => {

    // <> Context
    const { addAlert, openPopup, openPopupState, setOpenPopup } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);
    const [sendPatientComEmail, setSendPatientComEmail] = useState(false);
    const [sendPatientComSms, setSendPatientComSms] = useState(false);
    const [selectedPatientComIds, setSelectedPatientComIds] = useState([]);

    // <> Effects
    useEffect(() => {

        if(openPopup == Enums.PopupTags.SendPatientCommunications && openPopupState?.eventId) {
            getEvent(openPopupState.eventId);
        }

    }, [openPopup, openPopupState]);

    // <> Actions
    async function getEvent(eventId) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Fetch Promises
            const eventResponse = await EventService.getWithDetails(eventId);
            const eventRes = eventResponse.data.data.event;

            // -> Set State
            setEventDetails(eventRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function sendComs() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as Confirmed in API
            await EventService.sendPatientCommunications({ 
                eventId: eventDetails.id, 
                sendPatientComEmail, 
                sendPatientComSms, 
                patientComIds: selectedPatientComIds 
            });

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Patient communications sent'
            })

            closePopup();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        setSendPatientComEmail(false);
        setSendPatientComSms(false);
        setSelectedPatientComIds([]);
        setEventDetails(null);
        setIsLoading(false);
        setOpenPopup('');
    }

    function onComChecked(comId) {
        if(!selectedPatientComIds.includes(comId)) setSelectedPatientComIds(prev => [...prev, comId]);
        else setSelectedPatientComIds(prev => prev.filter(c => c != comId));
    }

    function selectAllComs() {
        setSelectedPatientComIds(eventDetails.patientCommunications.map(c => c.id));
    }

    function selectNoComs() {
        setSelectedPatientComIds([]);
    }

    // <> JSX
    return (
        <div className={`send-patient-communication-popup popup ${openPopup == Enums.PopupTags.SendPatientCommunications ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container send-patient-communication-popup__container">

                {/* Loader */}
                <Loader isLoading={isLoading} />

                {/* Icon */}
                <div className={`send-patient-communication-popup__container__icon send-patient-communication-popup__container__icon--info`}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </div>

                {/* Body */}
                {eventDetails && <div className="send-patient-communication-popup__container__body">

                    {/* Top */}
                    <div className="send-patient-communication-popup__container__body__top">
                        <div className="send-patient-communication-popup__container__body__top__title">Send Patient Communications</div>
                    </div>

                    {/* Content */}
                    <div className="send-patient-communication-popup__container__body__content">

                        {/* Send Patient Communications Email */}
                        <div className="send-patient-communication-popup__container__body__content__toggle">
                            <Toggle checked={sendPatientComEmail} setChecked={setSendPatientComEmail} />
                            <span>Send patient communications email</span>
                        </div>

                        {/* Send Patient Communications Sms */}
                        <div className="send-patient-communication-popup__container__body__content__toggle">
                            <Toggle checked={sendPatientComSms} setChecked={setSendPatientComSms} />
                            <span>Send patient communications sms</span>
                        </div>

                        {/* Coms Table Selection Assistants */}
                        <div className="send-patient-communication-popup__container__body__content__selection-buttons">
                            <IconButton
                                text={'Select All'}
                                icon={faCheckSquare}
                                btnStyle={'info'}
                                size={'xsm'}
                                isDisabled={!sendPatientComEmail && !sendPatientComSms}
                                onClickFn={selectAllComs} />
                            <IconButton
                                text={'Select None'}
                                icon={faSquare}
                                btnStyle={'info'}
                                size={'xsm'}
                                isDisabled={!sendPatientComEmail && !sendPatientComSms}
                                onClickFn={selectNoComs} />
                        </div>

                        {/* Patient Com Selection */}
                        {eventDetails?.patientCommunications?.length > 0 && (
                            <table className={`send-patient-communication-popup__container__body__content__coms-table`}>
                                <thead>
                                    <th></th>
                                    <th>Title</th>
                                    <th>Description</th>
                                </thead>
                                <tbody>
                                {eventDetails.patientCommunications.map((c, i) => (
                                    <tr index={i}>
                                        <td center-col="true">
                                            <input 
                                                type="checkbox" 
                                                disabled={!sendPatientComEmail && !sendPatientComSms}
                                                checked={selectedPatientComIds.includes(c.id)}
                                                onChange={ev => onComChecked(c.id)} />
                                        </td>
                                        <td>{c.title}</td>
                                        <td>{c.description}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}
                        
                    </div>

                    {/* Actions */}
                    <div className="send-patient-communication-popup__container__body__actions">
                        <IconButton
                            text={'Cancel'}
                            icon={faTimes}
                            btnStyle={'faded'}
                            onClickFn={closePopup} />
                        <IconButton
                            text={'Confirm & Schedule'}
                            icon={faCheck}
                            btnStyle={'success'}
                            onClickFn={sendComs} />
                    </div>

                </div>}


            </div>
        </div>
    );
};

export default SendPatientCommunicationPopup;