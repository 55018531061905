/**
 * @note unused, was requested to be removed but kept the files in case it was required again
 */


import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import TablePaginated from '../../components/_tables/TablePaginated/TablePaginated';
import './Physicians.sass';
import Configs from '../../config.js';
import { MiscContext } from '../../context/misc.context';
import * as Presenter from './Physicians.presenter';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import ManageUserService from '../../services/manageUser.service';


export default function Physicians() {

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [physicians, setPhysicians] = useState([]);
    const [searchResultsCount, setSearchResultsCount] = useState(0);

    // <> Contexts
    const { addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> Actions
    async function getPage(searchKey, searchQuery, sortKey, sortIsAsc, pageNum, pageSize) {

        try {

            // -> Loader ON
            setIsLoading(true);
    
            // -> Fetch Search Results
            const foundPhysiciansResponse = await ManageUserService.searchWithRole(searchKey, searchQuery, sortKey, sortIsAsc, pageNum * pageSize, parseInt(pageSize), Configs.userRoles.PHYSICIAN);
            const foundPhysiciansRes = foundPhysiciansResponse.data.data.users;
            const foundPhysiciansCountRes = foundPhysiciansResponse.data.data.usersCount;

            // -> Map Results To Desired Display Values
            const physiciansPresentable = Presenter.presentData(foundPhysiciansRes);
    
            // -> Set Data
            setPhysicians(physiciansPresentable);
    
            // -> Set Total Results Count
            setSearchResultsCount(foundPhysiciansCountRes);
            
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {

            // -> Loader OFF
            setIsLoading(false);
        }
    }

    function goToPhysicianDetails(physicianId) {
        const physicianEmail = physicians.find(p => p.id == physicianId)?.email;
        history.push('/physicians/details', { physicianId, physicianEmail });
    }

    // <> JSX
    return (
        <div className="physicians-page">

            {/* Page Title */}
            <div className="page-title">
                <span className="title-text">Physicians</span>
                <span className="title-subtext">View all the registered physicians.</span>
            </div>

            {/* Container */}
            <div className="physicians-page__container">

                {/* Search */}
                <div className="patients-page__container__search">
                    <div className="search__top">
                        <div className="search__top__left">
                        </div>
                        <div className="search__top__right">
                            <div className="search__results-count">{searchResultsCount} RESULTS</div>
                        </div>
                    </div>

                </div>

                {/* Results */}
                <div className="physicians-page__container__results">
                    <TablePaginated 
                        getPage={getPage}
                        items={physicians}
                        matchesCount={searchResultsCount}
                        columnDefinitions={Presenter.tableColumnDefinitions()}
                        detailsAction={goToPhysicianDetails}
                        isLoading={isLoading} />
                </div>

            </div>
        </div>
    )
}
