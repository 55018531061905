import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ColorPicker from '../../../components/_controls/ColorPicker/ColorPicker';
import Configs from '../../../config';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import MasterDataService from '../../../services/masterData.service';

const EventTypeColorPicker = ({ eventType, excludedColors, refresh }) => {

    // <> Context
    const { addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [color, setColor] = useState(eventType.color || '#000000');

    // <> Effects
    useEffect(() => {
        if(color != eventType.color) {
            updateColor();
        }
    }, [color])

    // <> Actions
    async function updateColor() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Update Col in API
            await MasterDataService.updateCol(Enums.MasterData.EventType, eventType.id, 'color', color);

            // -> Refetch Data
            refresh();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function getOptions() {
        return Configs.departmentColors.filter(c => !excludedColors.includes(c));
    }

    // <> JSX
    return (
        <div className="event-type-color-picker">
            <ColorPicker value={color} setter={setColor} options={getOptions()} isLoading={isLoading} />
        </div>
    );
};

export default EventTypeColorPicker;