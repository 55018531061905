import React, { useEffect } from 'react';
import './BasicSelector.sass';



/**
 * a basic dropdown selector
 * @param {{ name: string, options: [{ value, label }], value: number, setter: function, enableDefault: boolean? }} params 
 */
const BasicSelector = ({ name, options, value, setter = null, enableDefault = true, defaultOptionText = '-- Select --', defaultValue = null }) => {

    // <> JSX
    return (
        <select 
            className="basic-selector" 
            name={name} 
            value={value} 
            onChange={ev => { if(setter) setter(ev.target.value) }}
            defaultValue={defaultValue}
        >
            {enableDefault && <option value={0}>{defaultOptionText}</option>}
            {options && options.map((opt, index) => (
                <option key={index} value={opt.value}>{opt.label}</option>
            ))}
        </select>
    );
};

export default BasicSelector;