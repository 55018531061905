/** 
 * @description a utility for regex string pattern matching
 */


 const regex = {
    // Email
    email: /^([\w.-]+)@([\w-]+)((\.(\w)+)+)$/,
    // USA Phone Numbers
    usaPhoneNumber: /^[0-9]{10}$/,
    // Should have 1 lowercase letter, 1 uppercase letter, 1 number, and be 8 to 24 characters long
    password: /((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))^.{8,24}$/,
    // Alphanumeric string that may include _ and – having a length of 3 to 16 characters –
    username: /^[a-zA-Z0-9_-]{3,16}$/,
    // Alphabetical string for names having a length of 2 to 16 characters –
    name: /^[a-zA-Z'-\s]{2,16}$/,
    // Alphanumeric string for nicknames having a length of 2 to 16 characters –
    nickname: /^[a-zA-Z0-9'-\s]{2,16}$/,
    // url (protocol optional)
    url: /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
    // url (protocol required)
    urlAndProtocolRequired: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/,
    // ipv4
    ipv4: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
    // ipv6
    ipv6: /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/,
    // ipv4 or ipv6
    ip: /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
    // Social Security Number
    ssn: /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/,
    // Passport
    passport: /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/,
    // USA Zip Code
    usaZipCode: /^\d{5}([ -]\d{4})?$/,
    // Visa Credit Card
    visaCreditCard: /^4[0-9]{12}(?:[0-9]{3})?$/,
    // MasterCard Credit Card
    mastercardCreditCard: /^5[0-9]{15}$/,
    // American Express Credit Card
    americanExpressCreditCard: /^3[47][0-9]{13}$/,
    // 3-digit numeric number
    cvv: /^[0-9]{3}$/,
    // 4-digit OTP code
    otpCode: /^[0-9]{4}$/,
    // numeric
    numeric: /^[0-9]*$/
    
}


/**
 * Exports
 */
const CustomValidators =  {
    regex,

    isEmail: str => !!str.match(regex.email),
    isUsaPhoneNumber: str => !!str.match(regex.usaPhoneNumber),
    isPassword: str => !!str.match(regex.password),
    isUsername: str => !!str.match(regex.username),
    isName: str => !!str.match(regex.name),
    isNickname: str => !!str.match(regex.nickname),
    isUrl: str => !!str.match(regex.url),
    isUrlAndProtocolRequired: str => !!str.match(regex.urlAndProtocolRequired),
    isIpv4: str => !!str.match(regex.ipv4),
    isIpv6: str => !!str.match(regex.ipv6),
    isIp: str => !!str.match(regex.ip),
    isSsn: str => !!str.match(regex.ssn),
    isPassport: str => !!str.match(regex.passport),
    isUsaZipCode: str => !!str.match(regex.usaZipCode),
    isVisaCreditCard: str => !!str.match(regex.visaCreditCard),
    isMastercardCreditCard: str => !!str.match(regex.mastercardCreditCard),
    isAmericanExpressCreditCard: str => !!str.match(regex.americanExpressCreditCard),
    isCreditCardNumber: str => !!str.match(regex.visaCreditCard) || !!str.match(regex.mastercardCreditCard) || !!str.match(regex.americanExpressCreditCard),
    isCvv: str => !!str.match(regex.cvv),
    isOtpCode: str => !!str.toString().match(regex.otpCode),
    isNumeric: str => !!str.toString().match(regex.numeric),

}
export default CustomValidators;