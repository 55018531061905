import React, { useContext, useEffect, useState } from 'react';
import './EventPatientCommunications.sass';
import '../EventForms.sass';
import { useHistory, useLocation } from 'react-router';
import Enums from '../../../../enums';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../../../context/misc.context';
import Loader from '../../../../components/Loader/Loader';
import EventService from '../../../../services/event.service';
import Tag from '../../../../components/Tag/Tag';
import Configs from '../../../../config';
import moment from 'moment';
import Label from '../../../../components/Label/Label';
import StaticAlert from '../../../../components/StaticAlert/StaticAlert';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import { faCheck, faFileMedical, faFilePdf, faPaperPlane, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import EventClearanceService from '../../../../services/event.clearance.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditableField from '../../../../components/_controls/EditableField/EditableField';
import * as Presenter from './EventPatientCommunications.presenter';
import { UserContext } from '../../../../context/user.context';
import EventPatientCommunicationService from '../../../../services/event.patientCommunication.service';

const EventPatientCommunications = ({ isLoading, eventDetails, refresh }) => {

    // <> Context
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> States
    const [isUpdating, setIsUpdating] = useState(false);

    // <> History
    const history = useHistory();

    // <> Actions
    async function updateEventPatientCommunication(eventPatientCommunicationId, data) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            // -> Update
            await EventPatientCommunicationService.updateOne(eventPatientCommunicationId, data);

            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function deleteEventPatientCommunication(eventPatientCommunicationId) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            // -> Delete
            await EventPatientCommunicationService.deleteOne(eventPatientCommunicationId);

            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }


    // <> Helpers
    function openAddCommunicationPopup() {
        setOpenPopupState({
            eventId: eventDetails.id,
            assigneeId: eventDetails.coordinatorId,
            assigneeName: eventDetails.coordinatorName,
            dueDate: moment(eventDetails.dateFrom).subtract(3, 'days'),
            onSuccess: refresh
        });
        setOpenPopup(Enums.PopupTags.AddEventPatientCommunication);
    }

    function openSendCommunicationPopup() {
        setOpenPopupState({
            eventId: eventDetails.id,
            onSuccess: refresh
        });
        setOpenPopup(Enums.PopupTags.SendPatientCommunications);
    }

    function deleteEventPatientCommunicationHandler(eventPatientCommunication) {

        setOpenPopupState({
            title: 'Delete Event Patient Communication',
            message: `Are you sure you want to delete "${eventPatientCommunication.title}" from this event?`,
            level: 'danger',
            onOk: () => deleteEventPatientCommunication(eventPatientCommunication.id)
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    // <> JSX
    return (
        <div className="event-patient-communications">

            <Loader isLoading={isUpdating} />

            {eventDetails && <>

                {eventDetails &&
                    <div>

                        <div className="form-sections">
                            <div className="form-sections__left">

                                
                                <div className="event-patient-communications__title">Patient Communications</div>

                                <div className="event-patient-communications__groups">

                                    {/* Communications */}
                                    {eventDetails?.patientCommunications?.length > 0 && eventDetails.patientCommunications.map((com, i) => (

                                        <div key={i} className="event-patient-communications__groups__group">

                                            {/* Top */}
                                            <div className="event-patient-communications__groups__group__top">

                                                {/* Clearance */}
                                                <EditableField
                                                    isEditable={!isOnlyPhysician()}
                                                    value={com.title}
                                                    save={val => updateEventPatientCommunication(com.id, { title: val })} />

                                                {/* Actions */}
                                                {!isOnlyPhysician() && <div className="event-patient-communications__groups__group__top__actions event-patient-communications__groups__group__top__actions--right">
                                                    <IconButton
                                                        text={'Delete Communication'}
                                                        icon={faTrash}
                                                        btnStyle={'danger'}
                                                        size={'sm'}
                                                        onClickFn={() => deleteEventPatientCommunicationHandler(com)} />
                                                </div>}

                                            </div>

                                            {/* Items */}
                                            <div className={`event-patient-communications__items`}>

                                                <div className="event-patient-communications__items__item">

                                                    {/* Description */}
                                                    {com.description && (
                                                        <div className="event-patient-communications__items__item__description">
                                                            <EditableField 
                                                                isEditable={!isOnlyPhysician()}
                                                                value={com.description}
                                                                rules={{ minLength: { value: 10, message: 'description must be at least 10 characters long' } }}
                                                                save={newVal => updateEventPatientCommunication(com.id, { description: newVal })} />
                                                        </div>
                                                    )}

                                                    {/* Tags */}
                                                    <div className="event-patient-communications__items__item__labels">
                                                        <Label label={'Has File'} message={com.file ? 'Yes' : 'No'} size={'xsm'} />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                    
                                </div>

                                {eventDetails?.patientCommunications?.length == 0 && (
                                    <div className="event-patient-communications__groups__empty-message">No patient communications added</div>
                                )}

                                {!isOnlyPhysician() && (
                                    <div className="event-patient-communications__actions">
                                        {/* Add Patient Communication Btn */}
                                        <IconButton
                                            text={'Add Patient Communication'}
                                            icon={faPlus}
                                            onClickFn={openAddCommunicationPopup} />
                                        {/* Send Patient Communication Btn */}
                                        {eventDetails?.patientCommunications?.length > 0 && <IconButton
                                            text={'Send Patient Communication'}
                                            icon={faPaperPlane}
                                            onClickFn={openSendCommunicationPopup} />}

                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                }
                
            </>}
            
        </div>
    );
};

export default EventPatientCommunications;