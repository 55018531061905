import { faCheckCircle, faCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Configs from '../../config';
import { MiscContext } from '../../context/misc.context';
import Enums from '../../enums';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import EventService from '../../services/event.service';
import './ClearanceStatusPicker.sass';
import { UserContext } from '../../context/user.context';
import AsyncPromptPopup from '../_popups/AsyncPromptPopup/AsyncPromptPopup';

const ClearanceStatusPicker = ({ eventDetails, clearanceType }) => {

    // <> Context
    const { addAlert } = useContext(MiscContext);
    const { user } = useContext(UserContext);
    
    // <> History
    const history = useHistory();

    // <> States
    const [inEditMode, setInEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(eventDetails[clearanceType]);

    // <> Effects
    useEffect(() => {
        if(eventDetails && clearanceType) {
            setSelectedStatus(eventDetails[clearanceType]);
        }
    }, [eventDetails, clearanceType])

    // <> Actions
    async function setStatus(status) {

        try {

            if(status == selectedStatus) {
                setInEditMode(false);
                return;
            };

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Save Params
            const params = {};

            if(status == Enums.ClearanceStatuses.NotRequired) {
                params[getStatusPhysicianColumnName()] = null;
            }
            else {
                params[getStatusAssigneeIdColumnName()] = eventDetails.coordinatorId;
                params[getStatusDueDateColumnName()] = moment(eventDetails.dateFrom).subtract(3, 'days').format(Configs.formats.apiDateTime);
            }

            if(selectedStatus == Enums.ClearanceStatuses.NotRequired) {
                const physician = await AsyncPromptPopup({ message: 'Clearance Physician Name' });
                if(!physician) return;
                params[getStatusPhysicianColumnName()] = physician;
            }

            if(status == Enums.ClearanceStatuses.Rejected) {
                const rejectionReason = await AsyncPromptPopup({ message: 'Rejection Reason' });
                if(!rejectionReason) return;
                params[getStatusRejectionReasonColumnName()] = rejectionReason;
            }
            else {
                params[getStatusRejectionReasonColumnName()] = null;
            }

            params[clearanceType] = status;
            params[getStatusUpdaterColumnName()] = user.userId;
            params[getStatusUpdatedOnColumnName()] = moment().format(Configs.formats.apiDateTime);

            // -> Save
            await EventService.update(eventDetails.id, params);

            // -> End
            setInEditMode(false);
            setSelectedStatus(status);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function getStatusUpdaterColumnName() {
        switch(clearanceType) {
            case 'medicalClearanceStatus': return 'medicalClearanceUpdaterId';
            case 'cardiacClearanceStatus': return 'cardiacClearanceUpdaterId';
            case 'otherClearanceStatus': return 'otherClearanceUpdaterId';
            default: return null;
        }
    }

    function getStatusUpdatedOnColumnName() {
        switch(clearanceType) {
            case 'medicalClearanceStatus': return 'medicalClearanceStatusUpdatedOn';
            case 'cardiacClearanceStatus': return 'cardiacClearanceStatusUpdatedOn';
            case 'otherClearanceStatus': return 'otherClearanceStatusUpdatedOn';
            default: return null;
        }
    }

    function getStatusPhysicianColumnName() {
        switch(clearanceType) {
            case 'medicalClearanceStatus': return 'medicalClearancePhysician';
            case 'cardiacClearanceStatus': return 'cardiacClearancePhysician';
            case 'otherClearanceStatus': return 'otherClearancePhysician';
            default: return null;
        }
    }

    function getStatusRejectionReasonColumnName() {
        switch(clearanceType) {
            case 'medicalClearanceStatus': return 'medicalClearanceRejectionReason';
            case 'cardiacClearanceStatus': return 'cardiacClearanceRejectionReason';
            case 'otherClearanceStatus': return 'otherClearanceRejectionReason';
            default: return null;
        }
    }

    function getStatusAssigneeIdColumnName() {
        switch(clearanceType) {
            case 'medicalClearanceStatus': return 'medicalClearanceAssigneeId';
            case 'cardiacClearanceStatus': return 'cardiacClearanceAssigneeId';
            case 'otherClearanceStatus': return 'otherClearanceAssigneeId';
            default: return null;
        }
    }

    function getStatusDueDateColumnName() {
        switch(clearanceType) {
            case 'medicalClearanceStatus': return 'medicalClearanceDueDate';
            case 'cardiacClearanceStatus': return 'cardiacClearanceDueDate';
            case 'otherClearanceStatus': return 'otherClearanceDueDate';
            default: return null;
        }
    }

    // <> JSX
    return (
        <div className={`clearance-status-picker clearance-status-picker${inEditMode ? '--active' : ''}`}>

            {/* Overlay */}
            <div className={`clearance-status-picker__overlay`} onClick={() => setInEditMode(prev => !prev)}></div>

            {/* Loader */}
            <div className="clearance-status-picker__loader" hidden={!isLoading}>
                <FontAwesomeIcon icon={faSpinner} />
            </div>

            {/* Status */}
            <div className={`clearance-status-picker__status`} onClick={() => setInEditMode(prev => !prev)} hidden={isLoading}>
                {selectedStatus == Enums.ClearanceStatuses.NotRequired && (
                    <div className="clearance-status-picker__status__not-required">
                        <FontAwesomeIcon icon={faCircle} />
                    </div>
                )}
                {selectedStatus == Enums.ClearanceStatuses.Pending && (
                    <div className="clearance-status-picker__status__pending">
                        <FontAwesomeIcon icon={faCircle} />
                    </div>
                )}
                {selectedStatus == Enums.ClearanceStatuses.Requested && (
                    <div className="clearance-status-picker__status__requested">
                        <FontAwesomeIcon icon={faCircle} />
                    </div>
                )}
                {selectedStatus == Enums.ClearanceStatuses.Accepted && (
                    <div className="clearance-status-picker__status__accepted">
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                )}
                {selectedStatus == Enums.ClearanceStatuses.Rejected && (
                    <div className="clearance-status-picker__status__rejected">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                )}
            </div>

            <div className={`clearance-status-picker__pickers clearance-status-picker__pickers${inEditMode ? '--show' : '--hide'}`}>

                {/* Not Required */}
                {selectedStatus != Enums.ClearanceStatuses.NotRequired && <div 
                    className={`clearance-status-picker__not-required clearance-status-picker__not-required${inEditMode ? '--show' : '--hide'}`} 
                    title="set as not required"
                    onClick={() => setStatus(Enums.ClearanceStatuses.NotRequired)}
                >
                    <FontAwesomeIcon icon={faCircle} />
                </div>}

                {/* Pending */}
                {selectedStatus != Enums.ClearanceStatuses.Pending && <div 
                    className={`clearance-status-picker__pending clearance-status-picker__pending${inEditMode ? '--show' : '--hide'}`} 
                    title="set as pending"
                    onClick={() => setStatus(Enums.ClearanceStatuses.Pending)}
                >
                    <FontAwesomeIcon icon={faCircle} />
                </div>}

                {/* Requested */}
                {selectedStatus != Enums.ClearanceStatuses.Requested && <div 
                    className={`clearance-status-picker__requested clearance-status-picker__requested${inEditMode ? '--show' : '--hide'}`} 
                    title="set as requested"
                    onClick={() => setStatus(Enums.ClearanceStatuses.Requested)}
                >
                    <FontAwesomeIcon icon={faCircle} />
                </div>}

                {/* Accepted */}
                {selectedStatus != Enums.ClearanceStatuses.Accepted && <div 
                    className={`clearance-status-picker__accepted clearance-status-picker__accepted${inEditMode ? '--show' : '--hide'}`} 
                    title="set as accepted"
                    onClick={() => setStatus(Enums.ClearanceStatuses.Accepted)}
                >
                    <FontAwesomeIcon icon={faCheckCircle} />
                </div>}

                {/* Rejected */}
                {selectedStatus != Enums.ClearanceStatuses.Rejected && <div 
                    className={`clearance-status-picker__rejected clearance-status-picker__rejected${inEditMode ? '--show' : '--hide'}`} 
                    title="set as rejected"
                    onClick={() => setStatus(Enums.ClearanceStatuses.Rejected)}
                >
                    <FontAwesomeIcon icon={faTimesCircle} />
                </div>}

            </div>
        </div>
    );
};

export default ClearanceStatusPicker;