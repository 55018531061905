import Configs from '../../config.js';
import { TableColumn, TableColumns } from '../../components/_tables/TableColumns';
import moment from 'moment';
import StringFormatter from '../../utils/string.formats.js';



function presentData(patients) {

    return patients.map(patient => {
        patient.dateofbirth = moment(patient.dateofbirth).format(Configs.formats.date);
        patient.phone = StringFormatter.formatPhone(patient.phone);
        patient.fullAddress = `${patient.address1}${patient.address2 ? `, ${patient.address1}` : ''}, ${patient.state}, ${patient.city}, ${patient.zipcode}`;

        return patient;
    });
}


/**
 * Exports
 */
export {
    presentData
}