import { faCheckCircle, faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Configs from '../../../config';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import EventChecklistService from '../../../services/event.checklist.service';
import IconButton from '../../_controls/IconButton/IconButton';
import '../Popup.sass';

const AddChecklistItem = () => {

    // <> Context
    const { addAlert, openPopup, openPopupState, setOpenPopup, setOpenPopupState } = useContext(MiscContext);

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [failure, setFailure] = useState(null);

    // <> History
    const history = useHistory();
    
    // <> Form
    const { handleSubmit, register, reset, formState: { errors } } = useForm();

    // <> Actions
    async function onSubmit(data) {
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // Params
            const params = { 
                eventId: openPopupState.eventId, 
                groupName: data.groupName, 
                description: data.description, 
                dueDate: moment(data.dueDate).format(Configs.formats.apiDateTime), 
                assigneeId: data.assigneeId
            }

            // -> Add
            await EventChecklistService.addOne(params);
            
            // -> Callback
            setSuccess('Checklist item added successfully');
            if(openPopupState.onSuccess) openPopupState.onSuccess();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        setOpenPopup('');
        setOpenPopupState({});
        setSuccess('');
        setFailure('');
        reset();
    }

    function dismissFailure() {
        setFailure('');
    }

    // <> JSX
    return (
        <div className={`popup ${openPopup == Enums.PopupTags.AddChecklistItem ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container">

                {/* Title */}
                <div className="popup__container__title">Add Checklist Item</div>

                {openPopupState && <form onSubmit={handleSubmit(onSubmit)} className="popup__container__form">

                    {/* Group Name */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Group Name</div>
                        <input 
                            className="popup__container__form__input__control" 
                            defaultValue={openPopupState.groupName ? openPopupState.groupName : ''}
                            {...register('groupName', { required: 'group name required' })} />
                        <div className="popup__container__form__input__error">{errors?.groupName?.message}</div>
                    </div>

                    {/* Assignee */}
                    {openPopupState.users && (
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Assign To</div>
                        <select 
                            className="popup__container__form__input__control" 
                            defaultValue={openPopupState.eventCoordinatorId}
                            {...register('assigneeId', { required: 'assignee required' })}
                        >
                            {openPopupState.users.map((u, index) => (
                                <option key={index} value={u.id}>{u.name}</option>
                            ))}    
                        </select>
                        <div className="popup__container__form__input__error">{errors?.assigneeId?.message}</div>
                    </div>
                    )}

                    {/* Due Date */}
                    {openPopupState.eventDateFrom && <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Due Date <span className="small-note">(by default 3 days before the event)</span></div>
                        <input 
                            className="popup__container__form__input__control" 
                            type="date"
                            defaultValue={moment(openPopupState.eventDateFrom).subtract(3, 'days').format(Configs.formats.dateInputDate)}
                            {...register('dueDate', { required: 'due date required' })} />
                        <div className="popup__container__form__input__error">{errors?.dueDate?.message}</div>
                    </div>}
                    
                    {/* Description */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Description</div>
                        <input 
                            className="popup__container__form__input__control" 
                            {...register('description', { required: 'description required' })} />
                        <div className="popup__container__form__input__error">{errors?.description?.message}</div>
                    </div>

                    {/* Submit Btn */}
                    <div className="popup__container__form__submit-btn">
                        <IconButton
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={() => handleSubmit(onSubmit)}
                            isLoading={isLoading} />
                    </div>

                </form>}

                {/* Success Pane */}
                {success && (
                    <div className="popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="popup__container__success-pane__icon" />
                        <div className="popup__container__success-pane__message">{success}</div>
                        <button className="popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="popup__container__failure-pane__icon" />
                        <div className="popup__container__failure-pane__message">{failure}</div>
                        <button className="popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={dismissFailure}>Back</button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default AddChecklistItem;