import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MonthSchedule from '../_calendars/MonthSchedule/MonthSchedule';
import './MonthEvents.sass';
import Configs from '../../config.js';
import DayEvents from '../DayEvents/DayEvents';
import EventService from '../../services/event.service';
import * as Presenter from './MonthEvents.presenter';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import { useHistory } from 'react-router';
import { MiscContext } from '../../context/misc.context';
import { UserContext } from '../../context/user.context';
import Enums from '../../enums';
import Loader from '../Loader/Loader';
import IconButton from '../_controls/IconButton/IconButton';
import { faCalendarAlt, faCalendarWeek, faList, faStream } from '@fortawesome/free-solid-svg-icons';
import BrowserStorage from '../../utils/browser.storage';
import DayEventsCompact from '../DayEventsCompact/DayEventsCompact';
import EventFilters from '../EventFilters/EventFilters';
import WeekCalendar from '../_calendars/WeekCalendar/WeekCalendar';

const MonthEvents = () => {

    // <> Contexts
    const { addAlert } = useContext(MiscContext);
    const { isOnlyPhysician, user } = useContext(UserContext);

    // <> States
    const [isWeekMode, setIsWeekMode] = useState(BrowserStorage.get(Enums.BrowserStorage.CalendarIsWeekMode.Key) == Enums.BrowserStorage.CalendarIsWeekMode.Values.True);
    const [isCompactMode, setIsCompactMode] = useState(BrowserStorage.get(Enums.BrowserStorage.DayEventsIsCompactMode.Key) == Enums.BrowserStorage.DayEventsIsCompactMode.Values.True);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDay, setSelectedDay] = useState(moment());
    const [dayEvents, setDayEvents] = useState([]);
    const [filters, setFilters] = useState({});
    
    const listRef = useRef();

    // <> History
    const history = useHistory();

    // <> Effects
    useEffect(() => {
        if(user?.loggedIn) getDayEvents();
    }, [user, selectedDay, filters]);

    useEffect(() => {
        scrollToEventsList();
    }, [selectedDay])

    useEffect(() => {
        BrowserStorage.set(Enums.BrowserStorage.CalendarIsWeekMode.Key, isWeekMode ? Enums.BrowserStorage.CalendarIsWeekMode.Values.True : Enums.BrowserStorage.CalendarIsWeekMode.Values.False);
    }, [isWeekMode]);

    useEffect(() => {
        BrowserStorage.set(Enums.BrowserStorage.DayEventsIsCompactMode.Key, isCompactMode ? Enums.BrowserStorage.DayEventsIsCompactMode.Values.True : Enums.BrowserStorage.DayEventsIsCompactMode.Values.False);
    }, [isCompactMode]);

    // <> Actions
    async function getDayEvents() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Fetch Data from API
            const eventsResponse = await EventService.search({
                skip: 0, 
                limit: 1000, 
                physicianId: filters?.physicianId, 
                facilityId: filters?.facilityId,
                coordinatorId: filters?.coordinatorId,
                fromDate: moment(selectedDay).utc().hour(0).minute(0).second(0),
                toDate: moment(selectedDay).utc().add(1, 'day').hour(0).minute(0).second(0),
                status: isOnlyPhysician() ? Enums.PhysicianEventStatusVisibility : filters?.status,
                isEmergency: filters?.isEmergency,
                eventTypeId: filters?.eventTypeId
            })

            const eventsRes = eventsResponse.data.data.events;

            // -> Present Data
            const eventsPresentable = Presenter.presentDayEvents(eventsRes);

            // -> Set States
            setDayEvents(eventsPresentable);

        }
        catch (e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            // -> Turn Loader OFF
            setIsLoading(false);
        }
        
    }

    // <> Helpers
    function scrollToEventsList() {
        if(listRef && listRef.current) listRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    function getEventFilterDefaults() {
        return JSON.parse(BrowserStorage.get(Enums.BrowserStorage.EventFilters.Key));
    }

    // <> JSX
    return (
        <div className="month-events">

            {/* Mode Switch */}
            <div className="month-events__mode-switch">
                <IconButton
                    icon={isWeekMode ? faCalendarAlt : faCalendarWeek}
                    btnStyle={'faded'}
                    size={'xsm'}
                    onClickFn={() => setIsWeekMode(prev => !prev)} />
            </div>


            {/* Calendar Schedule */}
            <div className="month-events__calendar-container">

                {/* Filters */}
                <EventFilters defaults={getEventFilterDefaults()} onChangeFn={setFilters} hideDatePicker={true} />

                {!isWeekMode && <MonthSchedule 
                    selectedDay={selectedDay} 
                    setSelectedDay={setSelectedDay} 
                    filters={filters} />}

                {isWeekMode && <WeekCalendar 
                    selectedDay={selectedDay} 
                    setSelectedDay={setSelectedDay} />}
            </div>

            {/* Selected Day's Events */}
            <div className="month-events__list-container" ref={listRef}>

                {/* Mode Switch */}
                <div className="month-events__list-container__mode-switch">
                    <IconButton
                        icon={isCompactMode ? faList : faStream}
                        btnStyle={'faded'}
                        size={'xsm'}
                        onClickFn={() => setIsCompactMode(prev => !prev)} />
                </div>

                {/* Title */}
                <span className="month-events__list-container__title">
                    Events for <span className="month-events__list-container__title__date">{selectedDay.format(Configs.formats.friendlyDate)}</span>
                </span>

                {/* Selected Day's Events */}
                {dayEvents && !isCompactMode && <DayEvents dayEvents={dayEvents} isLoading={isLoading} refresh={getDayEvents} />}
                {dayEvents && isCompactMode && <DayEventsCompact dayEvents={dayEvents} isLoading={isLoading} refresh={getDayEvents} />}

                {/* Empty List Message */}
                {(!dayEvents || dayEvents.length == 0) && (
                    <span className="month-events__list-container__empty-text">No events found for selected day</span>
                )}
            </div>
        </div>
    );
};

export default MonthEvents;