import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Loader.sass';

const Loader = ({ isLoading }) => {
    return (
        <div className={`loader${isLoading ? ' loader--active' : ''}`}>
            <FontAwesomeIcon icon={faSpinner} className="loader__icon" />
        </div>
    );
};

export default Loader;