import React, { useContext, useEffect, useRef, useState } from 'react';
import './EventBloodThinners.sass';
import '../EventForms.sass';
import DropdownSearch from '../../../../components/_controls/DropdownSearch/DropdownSearch';
import DropdownExternalSearch from '../../../../components/_controls/DropdownExternalSearch/DropdownExternalSearch';
import { faCheck, faDiagnoses, faFileMedical, faPaperPlane, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../../../context/misc.context';
import { useHistory } from 'react-router';
import DiagnosisService from '../../../../services/history.service copy';
import EventService from '../../../../services/event.service';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import Loader from '../../../../components/Loader/Loader';
import EventBloodThinnerService from '../../../../services/event.bloodThinner.service';
import * as Presenter from './EventBloodThinners.presenter';
import EditableField from '../../../../components/_controls/EditableField/EditableField';
import Enums from '../../../../enums';
import { UserContext } from '../../../../context/user.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EventBloodThinners = ({ eventDetails, refresh, bloodThinners }) => {

    // <> Context
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { isOnlyPhysician } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isUpdating, setIsUpdating] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [sendSms, setSendSms] = useState(true);

    // <> Actions
    async function addBloodThinner(selectedBloodThinnerId, manualDiagnosis) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            const params = {
                eventId: eventDetails.id
            }

            if(manualDiagnosis) {
                params.bloodThinnerDescription = manualDiagnosis;
            }
            else {
                const selectedBloodThinner = bloodThinners.find(bt => bt.id == selectedBloodThinnerId);
                params.bloodThinnerId = selectedBloodThinner.id;
                params.bloodThinnerCode = selectedBloodThinner.code;
                params.bloodThinnerName = selectedBloodThinner.name;
                params.bloodThinnerDescription = selectedBloodThinner.description;
            }

            // -> Add
            await EventBloodThinnerService.addOne(params);
            
            // -> Refresh Event
            refresh();
            reset();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateBloodThinner(id, updateCols) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            const params = {
                id
            }
            if(updateCols.bloodThinnerCode) params.bloodThinnerCode = updateCols.bloodThinnerCode;
            if(updateCols.bloodThinnerDescription) params.bloodThinnerDescription = updateCols.bloodThinnerDescription;
            if(updateCols.isActive !== undefined) params.isActive = updateCols.isActive;
            else params.isActive = eventDetails.bloodThinners.find(b => b.id == id).isActive;

            // -> Add
            await EventBloodThinnerService.updateOne(params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function deleteBloodThinner(id) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            // -> Delete
            await EventBloodThinnerService.deleteOne(id);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    function deleteAllBloodThinners() {
        // -> Confirm
        setOpenPopupState({
            level: 'danger',
            message: `Are you sure you want to unselect all of the event's blood thinners?`,
            onOk: async () => {

                try {
        
                    // -> Turn Loader ON
                    setIsUpdating(true);
        
                    // -> Delete
                    await EventBloodThinnerService.deleteAllFromEvent(eventDetails.id);
                    
                    // -> Refresh Event
                    refresh();
                }
                catch(e) {
                    GlobalHttpErrorHandler(e, history, addAlert);
                }
                finally {
                    setIsUpdating(false);
                }
            }
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    // <> Helpers
    function reset() {
        // searchComponentRef.current.resetComponent();
    }

    function onBloodThinnerToggleSelected(bloodThinnerId) {
        const selectedEventBloodThinner = eventDetails.bloodThinners.find(bt => bt.bloodThinnerId == bloodThinnerId);
        if(selectedEventBloodThinner) deleteBloodThinner(selectedEventBloodThinner.id);
        else addBloodThinner(bloodThinnerId, null);
    }

    function onManualBloodThinnerToggleSelected(manualBloodThinner) {
        updateBloodThinner(manualBloodThinner.id, {
            isActive: manualBloodThinner.isActive ? false : true
        })
    }

    function onDeleteManualBloodThinner(manualBloodThinner) {
        deleteBloodThinner(manualBloodThinner.id);
    }

    function onBloodThinnerNoneSelected() {
        deleteAllBloodThinners();
    }

    function openAddEventBloodThinnerPopup() {

        setOpenPopupState({
            eventId: eventDetails.id,
            onSuccess: refresh
        })
        setOpenPopup(Enums.PopupTags.AddEventBloodThinner);
    }

    function hasActiveBloodThinners() {
        return (eventDetails?.bloodThinners?.filter(ebt => ebt.isActive).length > 0);
    }


    // <> JSX
    return (
        <div className="event-blood-thinners">

            <Loader isLoading={isUpdating} />

            {eventDetails && bloodThinners &&  <>

                <div className="event-insurance__groups__group">

                    {/* Top */}
                    <div className="event-insurance__groups__group__top">

                        {/* Title */}
                        <div className="event-insurance__groups__group__top__title">Blood Thinners</div>

                        {!isOnlyPhysician() && <div className="event-insurance__groups__group__top__actions event-insurance__groups__group__top__actions--right">
                            <IconButton
                                text={'Add'}
                                icon={faPlus}
                                btnStyle={'primary'}
                                size={'sm'}
                                onClickFn={openAddEventBloodThinnerPopup} />
                        </div>}

                    </div>

                    {/* Items */}
                    <div className={`event-insurance__items`}>

                        {/* Other Clearance */}
                        <div className="event-insurance__items__item event-insurance__items__item--primary">

                            {/* Selected Blood Thinners */}
                            <div className={`event-insurance__items__item__status ${isOnlyPhysician() ? 'event-insurance__items__item__status--no-actions' : ''}`}>

                                {/* None */}
                                <div className={`event-insurance__items__item__status__item ${eventDetails?.bloodThinners?.length == 0 ? 'event-insurance__items__item__status__item--active' : ''}`} onClick={onBloodThinnerNoneSelected}>None</div>

                                {/* Master Files Blood Thinners */}
                                {bloodThinners.map((bt, index) => (
                                    <div key={index} className={`event-insurance__items__item__status__item ${eventDetails?.bloodThinners?.some(ebt => ebt.bloodThinnerId == bt.id && ebt.isActive) ? 'event-insurance__items__item__status__item--active' : ''}`} onClick={() => onBloodThinnerToggleSelected(bt.id)}>{bt.name}</div>
                                ))}

                                {/* Separator */}
                                {eventDetails?.bloodThinners?.filter(ebt => !ebt.bloodThinnerId)?.length > 0 && <div className="event-insurance__items__item__status__separator"></div>}

                                {/* Manual Blood Thinners */}
                                {eventDetails?.bloodThinners?.length > 0 && eventDetails.bloodThinners.filter(ebt => !ebt.bloodThinnerId).map((ebt, index) => (
                                    <div className="event-insurance__items__item__status__item__wrapper">
                                        <div key={index} className={`event-insurance__items__item__status__item ${ebt.isActive ? 'event-insurance__items__item__status__item--active' : ''}`} onClick={() => onManualBloodThinnerToggleSelected(ebt)}>
                                            {ebt.bloodThinnerName}
                                        </div>
                                        <div className="event-insurance__items__item__status__item__wrapper__delete" onClick={() => onDeleteManualBloodThinner(ebt)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>

                    </div>
                </div>

                {/* Selected Blood Thinners */}
                <div className="event-blood-thinners__list">
                    {eventDetails?.bloodThinners?.filter(ebt => ebt.isActive).map((ebt, index) => (
                        <div key={index} className="event-blood-thinners__list__item">
                            <div className="event-blood-thinners__list__item__title">
                                {ebt.bloodThinnerName}
                            </div>
                            <div className="event-blood-thinners__list__item__description">
                                <EditableField
                                    value={ebt.bloodThinnerDescription}
                                    rules={{ required: 'description required' }}
                                    isEditable={!isOnlyPhysician()}
                                    save={newVal => updateBloodThinner(ebt.id, { bloodThinnerDescription: newVal })} />
                            </div>
                        </div>
                    ))}
                </div>

                {/* Send To Patient */}
                {hasActiveBloodThinners() && <div className="event-blood-thinners__send">
                    <div className="event-blood-thinners__send__checkboxes">
                        <label>
                            <input type="checkbox" checked={sendEmail} onChange={ev => setSendEmail(ev.target.checked)} />
                            Send patient email
                        </label>
                        <label>
                            <input type="checkbox" checked={sendSms} onChange={ev => setSendSms(ev.target.checked)} />
                            Send patient sms
                        </label>
                    </div>
                    <div className="event-blood-thinners__send__button">
                        <IconButton
                            text={'send'}
                            icon={faPaperPlane}
                            btnStyle={'primary'}
                            size={'md'}
                            extraStyles={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            isDisabled={!sendEmail && !sendSms}
                            onClickFn={() => {}} />
                    </div>
                </div>}

            </>}
        </div>
    );
};

export default EventBloodThinners;