import React from 'react';
import './MonthSelector.sass';

const MonthSelector = ({month, setMonth}) => {


    return (
        <div className="month-selector">
            <div className="month-selector__label">Month</div>
            <select className="selector month-selector__control" value={month} onChange={ev => setMonth(parseInt(ev.target.value))}>
                <option value="0">January</option>
                <option value="1">February</option>
                <option value="2">March</option>
                <option value="3">April</option>
                <option value="4">May</option>
                <option value="5">June</option>
                <option value="6">July</option>
                <option value="7">August</option>
                <option value="8">September</option>
                <option value="9">October</option>
                <option value="10">November</option>
                <option value="11">December</option>
            </select>
        </div>
    );
};

export default MonthSelector;