import axios from 'axios';
import Configs from '../config.js';


function get(skip, limit) {
    return axios.post(`${Configs.apiUrl}/history/get`, { skip, limit });
}

function search({skip, limit, fromDate, toDate, userId, eventId, patientId}) {
    return axios.post(`${Configs.apiUrl}/history/search`, { skip, limit, fromDate, toDate, userId, eventId, patientId });
}


/**
 * Exports
 */
const HistoryService = {
    get,
    search
}
export default HistoryService;