import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './WeekCalendar.sass';

const WeekCalendar = ({ selectedDay, setSelectedDay }) => {

    // <> States
    const [days, setDays] = useState([]);

    // <> Effects
    useEffect(() => {
        
        const daysRes = [
            { date: moment().subtract(2, 'days') },
            { date: moment().subtract(1, 'day') },
            { date: moment(), isToday: true },
            { date: moment().add(1, 'day') },
            { date: moment().add(2, 'days') },
            { date: moment().add(3, 'days') },
            { date: moment().add(4, 'days') },
        ]

        setDays(daysRes);

    }, []);

    // <> Helpers
    const isSameDay = (date1, date2) => {
        return (moment(date1).year() == moment(date2).year()
            && moment(date1).dayOfYear() == moment(date2).dayOfYear());
    }

    // <> JSX
    return (
        <div className="week-calendar">

            {/* Calendar */}
            <div className="week-calendar__calendar">
                {days && days.map((day, index) => (
                    <div 
                        key={index}
                        className={`calendar__day ${isSameDay(day.date, selectedDay) ? 'calendar__day--selected' : '' } ${day.isToday ? 'calendar__day--today' : ''}`}
                        onClick={() => setSelectedDay(day.date)}
                    >
                        <div className="calendar__day__name">{day.date.format('ddd')}</div>
                        <div className="calendar__day__number">{day.date.date()}</div>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default WeekCalendar;