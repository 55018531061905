/**
 * @description utility functions for generated various formatted strings
 */


import moment from 'moment';


/**
 * get the difference between two date times in "Xh Xm" format
 * @param {Date|string|moment.MomentInput} from starting date time
 * @param {Date|string|moment.MomentInput} to end date time
 * @returns {string} formatted time difference string
 */
function timeDifference(from, to) {

    const durationInMinutes = moment(to).diff(moment(from), 'minutes');
    const durationHours = durationInMinutes > 59 ? Math.floor(durationInMinutes / 60) : 0;
    const durationMinutes = durationInMinutes <= 59 ? durationInMinutes : durationInMinutes % 60;
    const durationStr = `${durationHours ? `${durationHours}h ` : ''}${durationMinutes}m`;

    return durationStr;
}


/**
 * format a number into USA phone (###) ###-####
 * @param {string} str phone number as a numeric string
 * @returns {string} formatted phone number
 */
function formatPhone(str) {

    // If empty, return empty
    if(!str || typeof str !== 'string' || str.length === 0) return ``;

    // If str < 3: (###)
    if(str.length <= 3) return `(${str})`;

    // If str is between 3 & 6 numbers: (###) ###
    if(str.length <= 6) return `(${str.substr(0, 3)}) ${str.substr(3)}`;

    // If str is more than 6 numbers: (###) ###-####
    return `(${str.substr(0, 3)}) ${str.substr(3,3)}-${str.substr(6,4)}`;
}


/**
 * format a string to be SSN format
 * @param {string} str 
 * @returns {string} formatted SSN
 */
function formatSSN(str) {

    let numeric = str.replaceAll('-', '');

    if(numeric.length <= 3) return numeric;
    if(numeric.length <= 5) return `${numeric.substr(0, 3)}-${numeric.substr(3)}`;
    if(numeric.length < 9) return `${numeric.substr(0, 3)}-${numeric.substr(3, 2)}-${numeric.substr(5)}`;
    return `${numeric.substr(0, 3)}-${numeric.substr(3, 2)}-${numeric.substr(5, 4)}`;
}


/**
 * Exports
 */
const StringFormatter = {
    timeDifference,
    formatPhone,
    formatSSN
}
export default StringFormatter;