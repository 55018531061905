import React, { useEffect, useState } from 'react';
import StringFormatter from '../../../utils/string.formats';
import CustomValidators from '../../../utils/validator';
import './PhoneNumberInput.sass';

const PhoneNumberInput = ({ extra, defaultValue = '', addClassName = '', onChangeFn = null, onKeyDownFn = null }) => {

    // <> States
    const [phone, setPhone] = useState(defaultValue);
    const [formattedPhone, setFormattedPhone] = useState('');

    // <> Effects
    useEffect(() => {
        setFormattedPhone(StringFormatter.formatPhone(phone));
        if(onChangeFn) onChangeFn(phone);
    }, [phone]);

    // <> Actions
    function setPhoneValue(val) {
        if(val.length > 10) val = val.substr(0, 10);
        if(!CustomValidators.isNumeric(val)) return;
        setPhone(val);
    }

    // <> JSX
    return (
        <div className={`phone-number-input ${addClassName}`}>
            <input 
                className="phone-number-input__control"
                autocomplete="off"
                min="10"
                max="10"
                title="Enter numeric phone number of length 10"
                value={phone}
                {...extra}
                onKeyDown={onKeyDownFn}
                onChange={ev => setPhoneValue(ev.target.value)}
                />

            <div className="phone-number-input__formatted">
                {formattedPhone}
            </div>
        </div>
    );
};

export default PhoneNumberInput;