import { faCalendar, faCheck, faCheckDouble, faEllipsisH, faFax, faFileAlt, faFilePdf, faHistory, faPencilAlt, faProjectDiagram, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MiscContext } from '../../../context/misc.context';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import EventService from '../../../services/event.service';
import MasterDataService from '../../../services/masterData.service';
import EventScheduling from './EventScheduling/EventScheduling';
import './ManageEvent.sass';
import Enums from '../../../enums';
import IconButton from '../../../components/_controls/IconButton/IconButton';
import EventProcedures from './EventProcedures/EventProcedures';
import Loader from '../../../components/Loader/Loader';
import EventInsurance from './EventInsurance/EventInsurance';
import EventNotes from './EventNotes/EventNotes';
import moment from 'moment';
import Configs from '../../../config';
import { PDFViewer } from '@react-pdf/renderer';
import EventDiagnosis from './EventDiagnosis/EventDiagnosis';
import { UserContext } from '../../../context/user.context';
import StaticAlert from '../../../components/StaticAlert/StaticAlert';
import * as Presenter from './ManageEvent.presenter';
import EventPatientCommunications from './EventPatientCommunications/EventPatientCommunications';
import EventBloodThinners from './EventBloodThinners/EventBloodThinners';
import { id } from 'date-fns/locale';
import GeneratePdf, { getBlobObjectUrl, generateClearance } from '../../../pdfGenerators/pdfGenerators';


const ManageEvent = () => {

    // <> History
    const history = useHistory();

    // <> Context
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> Location
    const location = useLocation();
    const { eventId, editMode = false, scrollToSection = null } = location.state;
    
    // <> States
    const [pathname, setPathname] = useState(history.location.pathname);
    const [isLoading, setIsLoading] = useState(false);
    const [inEditMode, setInEditMode] = useState(editMode);
    const [showPDF, setShowPDF] = useState(false);
    const [isRunningAction, setIsRunningAction] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [showStatusSelector, setShowStatusSelector] = useState(false);
    // Master Data
    const [facilities, setFacilities] = useState(null);
    const [eventTypes, setEventTypes] = useState(null);
    const [priorities, setPriorities] = useState(null);
    const [bloodThinners, setBloodThinners] = useState(null);
    const [procedures, setProcedures] = useState(null);
    const [carriers, setCarriers] = useState(null);
    const [boardingLinks, setBoardingLinks] = useState(null);

    // <> Refs
    const generalSectionRef = useRef(null);
    const proceduresSectionRef = useRef(null);
    const clearancesSectionRef = useRef(null);
    // const checklistSectionRef = useRef(null);
    const notesSectionRef = useRef(null);
    const patientCommunicationsSectionRef = useRef(null);

    // <> Effects
    useEffect(() => {

        // -> Set Pathname
        const unregister = history.listen((location, action) => {
            setPathname(location.pathname);
        });

        // -> Fetch Event & Master Data
        const initPromises = [getEvent(), getMasterData()];
        Promise.all(initPromises).then(() => {

            // -> Scroll To Section if requested
            if(scrollToSection) {
                switch(scrollToSection) {
                    case Enums.EventDetailsPageSections.General:
                        scrollTo(generalSectionRef);
                        break;
                    case Enums.EventDetailsPageSections.Procedures:
                        scrollTo(proceduresSectionRef);
                        break;
                    case Enums.EventDetailsPageSections.Clearances:
                        scrollTo(clearancesSectionRef);
                        break;
                    case Enums.EventDetailsPageSections.PatientCommunications:
                        scrollTo(patientCommunicationsSectionRef);
                        break;
                    case Enums.EventDetailsPageSections.Notes:
                        scrollTo(notesSectionRef);
                        break;
                    default:
                        break;
                }
            }
        })

        return unregister;
    }, []);

    useEffect(() => {

        if(eventDetails) {
            const steps = [];
            if(eventDetails.facilityName && eventDetails.physicianName && eventDetails.coordinatorName && eventDetails.dateFrom && eventDetails.dateTo && eventDetails.priority != null && eventDetails.eventTypeName && eventDetails.patientName && eventDetails.patientAddress && eventDetails.patientPreferredPhone && eventDetails.patientDoB) steps.push('general');
            if(eventDetails.procedures?.length > 0) steps.push('procedures');
            steps.push('insurance');
            if(eventDetails?.patientCommunications?.length > 0) steps.push('patient-communications');
            if(eventDetails?.notes?.length > 0) steps.push('notes');
    
            setCompletedSteps(steps);
        }

    }, [eventDetails]);

    // <> Actions
    async function getEvent() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Fetch Promises
            const eventResponse = await EventService.getWithDetails(eventId);
            const eventRes = eventResponse.data.data.event;

            // -> Set State
            setEventDetails(eventRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function getMasterData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data
            const masterDataResponse = await MasterDataService.getTables([
                Enums.MasterData.Facility,
                Enums.MasterData.EventType,
                Enums.MasterData.Priority,
                Enums.MasterData.BloodThinner,
                Enums.MasterData.Procedures,
                Enums.MasterData.Carrier,
                Enums.MasterData.BoardingLink,
            ]);

            // -> Extract Master Data
            let facilitiesRes = masterDataResponse.data.data[Enums.MasterData.Facility];
            const eventTypesRes = masterDataResponse.data.data[Enums.MasterData.EventType];
            const prioritiesRes = masterDataResponse.data.data[Enums.MasterData.Priority];
            const bloodThinnersRes = masterDataResponse.data.data[Enums.MasterData.BloodThinner];
            const proceduresRes = masterDataResponse.data.data[Enums.MasterData.Procedures];
            const carriersRes = masterDataResponse.data.data[Enums.MasterData.Carrier];
            const boardingLinksRes = masterDataResponse.data.data[Enums.MasterData.BoardingLink];

            // -> Set State
            setFacilities(facilitiesRes);
            setEventTypes(eventTypesRes);
            setPriorities(prioritiesRes);
            setBloodThinners(bloodThinnersRes);
            setProcedures(proceduresRes);
            setCarriers(carriersRes);
            setBoardingLinks(boardingLinksRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function setAsBSG() {

        try {

            if(!['general', 'procedures', 'insurance'].every(s => completedSteps.includes(s))) {
                addAlert({ level: 'info', ttl: Configs.misc.alertTTL.Mid, message: 'Please complete the steps before generating a boarding slip' })
                return;
            }

            // -> Turn Loader ON
            setIsRunningAction(true);

            // -> Set as BSG in API
            await EventService.setAsBSG(eventId);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully set as Boarding Slip Generated'
            })

            
            // -> Open Generated Boarding Slip PDF
            generatePdf();

            // -> Refresh
            await getEvent();

            // End
            setShowStatusSelector(false);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsRunningAction(false);
        }
    }

    async function setAsWFC() {

        try {

            // -> Turn Loader ON
            setIsRunningAction(true);

            // -> Set as WFC in API
            await EventService.setAsWFC(eventId);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully set as Waiting for Confirmation'
            })

            // -> Refresh
            getEvent();
            setShowStatusSelector(false);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsRunningAction(false);
        }
    }

    async function setAsCompleted() {

        try {

            // -> Turn Loader ON
            setIsRunningAction(true);

            // -> Set as Completed in API
            await EventService.setAsCompleted(eventId);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully set as completed'
            })

            // -> Refresh
            getEvent();
            setShowStatusSelector(false);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsRunningAction(false);
        }
    }

    async function deleteEvent() {

        try {

            // -> Turn Loader ON
            setIsRunningAction(true);

            // -> Set as Completed in API
            await EventService.deleteEvent(eventId);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully deleted'
            })

            // -> Navigate back
            history.push('/events');
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsRunningAction(false);
        }
    }

    async function cancelEvent(reason) {

        try {

            // -> Turn Loader ON
            setIsRunningAction(true);

            // -> Set as Completed in API
            await EventService.cancelEvent(eventId, reason);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully cancelled'
            })

            // -> Refresh
            getEvent();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsRunningAction(false);
        }
    }

    /**
     * @deprecated
     */
    async function updateEvent(data) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Save Params
            const params = {};

            params.patientAddress = data.patientAddress || null;
            params.patientPreferredPhone = data.patientPreferredPhone || null;
            params.patientDoB = data.patientDoB;
            params.patientGender = data.patientGender;

            const selectedCarrier = carriers.find(c => c.id == data.carrierId);
            params.carrierCode = selectedCarrier.code;
            params.carrierName = selectedCarrier.name;
            params.subscriberId = data.subscriberId;
            params.groupName = data.groupName;
            params.groupNumber = data.groupNumber;

            if(!data.bloodThinnerId || data.bloodThinnerId == 0) {
                params.bloodThinnerId = null;
                params.bloodThinnerCode = null;
                params.bloodThinnerDescription = null;
            }
            else {
                const selectedBloodThinner = bloodThinners.find(b => b.id == data.bloodThinnerId);
                params.bloodThinnerId = parseInt(selectedBloodThinner.id);
                params.bloodThinnerCode = selectedBloodThinner.code;
                params.bloodThinnerName = selectedBloodThinner.name;
                params.bloodThinnerDescription = selectedBloodThinner.description;
            }

            if(!data.diagnosisId || data.diagnosisId == 0) {
                params.diagnosisId = null;
                params.diagnosisCode = null;
                params.diagnosisDescription = null;
            }
            else {
                params.diagnosisId = data.diagnosisId;
                params.diagnosisCode = data.diagnosisCode;
                params.diagnosisDescription = data.diagnosisDescription;
            }

            if(eventDetails.cardiacClearanceStatus != Enums.ClearanceStatuses.NotRequired) params.cardiacClearancePhysician = data.cardiacClearancePhysician;
            else params.cardiacClearancePhysician = null;
            if(eventDetails.medicalClearanceStatus != Enums.ClearanceStatuses.NotRequired) params.medicalClearancePhysician = data.medicalClearancePhysician;
            else params.medicalClearancePhysician = null;
            if(eventDetails.otherClearanceStatus != Enums.ClearanceStatuses.NotRequired) params.otherClearancePhysician = data.otherClearancePhysician;
            else params.otherClearancePhysician = null;

            params.insuranceAuthorizationNumber = data.insuranceAuthorizationNumber;

            // -> Save
            await EventService.update(eventDetails.id, params);

            // -> Refresh Event
            getEvent();
            setInEditMode(false);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function scrollTo(sectionRef) {
        sectionRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    function goToEventAuditTrail() {
        history.push('/history', { eventId });
    }

    async function setAsBSGHandler() {

        if(!['general', 'procedures', 'insurance'].every(s => completedSteps.includes(s))) {
            addAlert({ level: 'info', ttl: Configs.misc.alertTTL.Mid, message: 'Please complete the steps before generating a boarding slip' })
            return;
        }

        setOpenPopupState({
            title: 'Generate Boarding Slip',
            message: `Are you sure you want to generate the boarding slip and set this event's status as boarding slip generated?`,
            level: 'info',
            onOk: setAsBSG
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    async function setAsWFCHandler() {

        setOpenPopupState({
            title: 'Set as Waiting for Confirmation',
            message: 'Are you sure you want to set this event as waiting for confirmation?',
            level: 'info',
            onOk: setAsWFC
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    async function setAsConfirmedHandler() {

        setOpenPopupState({
            eventId,
            onSuccess: getEvent
        })
        setOpenPopup(Enums.PopupTags.EventConfirmWithReschedule);
    }

    async function setAsCompletedHandler() {

        setOpenPopupState({
            title: 'Set as Complete',
            message: 'Are you sure you want to set this event as completed?',
            level: 'info',
            onOk: setAsCompleted
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    function deleteEventHandler() {

        setOpenPopupState({
            title: 'Delete Event',
            message: 'Are you sure you want to delete this event?',
            level: 'danger',
            onOk: deleteEvent
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    function cancelEventHandler() {

        setOpenPopupState({
            title: 'Cancel Event',
            message: 'Provide a cancellation reason',
            level: 'danger',
            onOk: reason => cancelEvent(reason)
        })
        setOpenPopup(Enums.PopupTags.Prompt);
    }

    function openCodeEventPopup() {
        setOpenPopupState({
            eventId,
            onSuccess: getEvent
        })
        setOpenPopup(Enums.PopupTags.CodeEvent);
    }

    function openReschedulePopup() {
        setOpenPopupState({
            eventId,
            onSuccess: getEvent
        })
        setOpenPopup(Enums.PopupTags.RescheduleEvent);
    }

    async function generatePdf() {
        try {

            // -> Get PDF linked to this event type and facility
            const pdfLink = boardingLinks.find(b => b.facilityId == eventDetails.facilityId && b.eventTypeId == eventDetails.eventTypeId);
            if(!pdfLink) {
                addAlert({
                    level: 'info',
                    message: 'No PDF has been linked to this facility and department.',
                    ttl: Configs.misc.alertTTL.Long
                })
                return;
            }

            // -> Open Generated Boarding Slip PDF
            const pdfUnitArray = await GeneratePdf(eventDetails, pdfLink.linkId);
            const pdfDataUrl = getBlobObjectUrl(pdfUnitArray);
            window.open(pdfDataUrl);
        }
        catch(e) {
            console.error(e);
            addAlert({
                level: 'warning',
                message: 'Something went wrong with generating the PDF'
            })
        }
    }


    // <> JSX
    return (
        <div className="manage-event-page">

            {/* Cancellation Reason */}
            {eventDetails && eventDetails.status == Enums.EventStatuses.Cancelled && eventDetails.cancelReason && (
                <div className="manage-event-page__cancel-reason">
                    <StaticAlert level={'warning'} message={`Event cancellation reason: "${eventDetails.cancelReason}"`} />
                </div>
            )}

            {/* Top */}
            <div className="manage-event-page__top">

                {/* Title */}
                <div className="manage-event-page__top__left">

                </div>

            </div>
            
            {/* Nav */}
            <div className="manage-event-page__nav">

                {/* General */}
                <div className={`manage-event-page__nav__item${completedSteps.includes('general') ? ' manage-event-page__nav__item--done' : ''}${pathname.endsWith('/general') ? ' manage-event-page__nav__item--selected' : ''}`} onClick={() => scrollTo(generalSectionRef)}>
                    <div className="manage-event-page__nav__item__label">1. Details & Insurances</div>
                    <div className={`manage-event-page__nav__item__number`}><FontAwesomeIcon icon={completedSteps.includes('general') ? faCheck : faEllipsisH} /></div>
                </div>

                {/* Procedures */}
                <div className={`manage-event-page__nav__item${completedSteps.includes('procedures') ? ' manage-event-page__nav__item--done' : ''}${pathname.endsWith('/procedures') ? ' manage-event-page__nav__item--selected' : ''}`} onClick={() => scrollTo(proceduresSectionRef)}>
                    <div className="manage-event-page__nav__item__label">2. Procedures & Diagnosis</div>
                    <div className={`manage-event-page__nav__item__number`}><FontAwesomeIcon icon={completedSteps.includes('procedures') ? faCheck : faEllipsisH} /></div>
                </div>

                {/* Insurance & Diagnosis */}
                <div className={`manage-event-page__nav__item${completedSteps.includes('insurance') ? ' manage-event-page__nav__item--done' : ''}${pathname.endsWith('/insurance') ? ' manage-event-page__nav__item--selected' : ''}`} onClick={() => scrollTo(clearancesSectionRef)}>
                    <div className="manage-event-page__nav__item__label">3. Clearances & Instructions</div>
                    <div className={`manage-event-page__nav__item__number`}><FontAwesomeIcon icon={completedSteps.includes('insurance') ? faCheck : faEllipsisH} /></div>
                </div>

                {/* Checklist */}
                {/* <div className={`manage-event-page__nav__item${completedSteps.includes('checklist') ? ' manage-event-page__nav__item--done' : ''}${pathname.endsWith('/checklist') ? ' manage-event-page__nav__item--selected' : ''}`} onClick={() => scrollTo(checklistSectionRef)}>
                    <div className="manage-event-page__nav__item__label">4. Checklist</div>
                    <div className={`manage-event-page__nav__item__number`}><FontAwesomeIcon icon={completedSteps.includes('checklist') ? faCheck : faEllipsisH} /></div>
                </div> */}

                {/* Patient Communications */}
                <div className={`manage-event-page__nav__item${completedSteps.includes('patient-communications') ? ' manage-event-page__nav__item--done' : ''}${pathname.endsWith('/patient-communications') ? ' manage-event-page__nav__item--selected' : ''}`} onClick={() => scrollTo(patientCommunicationsSectionRef)}>
                    <div className="manage-event-page__nav__item__label">4. Patient Communications</div>
                    <div className={`manage-event-page__nav__item__number`}><FontAwesomeIcon icon={completedSteps.includes('patient-communications') ? faCheck : faEllipsisH} /></div>
                </div>

                {/* Notes */}
                <div className={`manage-event-page__nav__item${completedSteps.includes('notes') ? ' manage-event-page__nav__item--done' : ''}${pathname.endsWith('/notes') ? ' manage-event-page__nav__item--selected' : ''}`} onClick={() => scrollTo(notesSectionRef)}>
                    <div className="manage-event-page__nav__item__label">5. Internal Notes</div>
                    <div className={`manage-event-page__nav__item__number`}><FontAwesomeIcon icon={completedSteps.includes('notes') ? faCheck : faEllipsisH} /></div>
                </div>

            </div>

            {/* Content */}
            <div className="manage-event-page__content">
                <div className="manage-event-page__content__pane" ref={generalSectionRef}>
                    <EventScheduling eventDetails={eventDetails} facilities={facilities} priorities={priorities} carriers={carriers} eventTypes={eventTypes} refresh={getEvent} />
                </div>
                <div className="manage-event-page__content__pane" ref={proceduresSectionRef}>
                    <EventProcedures eventDetails={eventDetails} procedures={procedures} refresh={getEvent} />
                </div>
                <div className="manage-event-page__content__pane">
                    <EventDiagnosis eventDetails={eventDetails} refresh={getEvent} />
                </div>
                {/* <div className="manage-event-page__content__pane">
                    <EventBloodThinners eventDetails={eventDetails} refresh={getEvent} bloodThinners={bloodThinners} />
                </div> */}
                <div className="" ref={clearancesSectionRef}>
                    <EventInsurance eventDetails={eventDetails} refresh={getEvent} bloodThinners={bloodThinners} />
                </div>
                <div className="manage-event-page__content__pane" ref={patientCommunicationsSectionRef}>
                    <EventPatientCommunications eventDetails={eventDetails} refresh={getEvent} />
                </div>
                <div className="manage-event-page__content__pane" ref={notesSectionRef}>
                    <EventNotes eventDetails={eventDetails} refresh={getEvent} />
                </div>
                <Loader isLoading={isLoading} />
            </div>

            {/* Actions */}
            <div className="manage-event-page__actions">

                <div className="manage-event-page__actions__left">

                    

                    {!inEditMode && user?.roles?.includes(Configs.userRoles.ADMIN) && <IconButton
                        text={'Event Audit Trail'}
                        icon={faHistory}
                        btnStyle={'info'}
                        onClickFn={goToEventAuditTrail} />}

                </div>

                <div className="manage-event-page__actions__right">

                    {!isOnlyPhysician() && eventDetails && <IconButton
                        text={'Reschedule Event'}
                        icon={faCalendar}
                        btnStyle={'danger'}
                        onClickFn={openReschedulePopup} />}

                    {!isOnlyPhysician() && <div className="manage-event-page__actions__status-selector">

                        {showStatusSelector && (
                            <div className="manage-event-page__actions__status-selector__overlay" onClick={() => setShowStatusSelector(false)}></div>
                        )}

                        <IconButton
                            text={'Change Status'}
                            icon={faPencilAlt}
                            btnStyle={'danger'}
                            onClickFn={() => setShowStatusSelector(prev => !prev)} />

                        <div className={`manage-event-page__actions__status-selector__btn-group ${showStatusSelector ? 'manage-event-page__actions__status-selector__btn-group--show' : 'manage-event-page__actions__status-selector__btn-group--hide'}`}>
                            
                            <div className="manage-event-page__actions__status-selector__close-btn" onClick={() => setShowStatusSelector(false)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>

                            {eventDetails && <h3>Current event status is {Presenter.createStatusTag(eventDetails.status)}</h3>}
                            <div className="hr"></div>
                            
                            {eventDetails && eventDetails.status != Enums.EventStatuses.BoardingSlipGenerated && <IconButton
                                text={'Set as Boarding Slip Generated'}
                                icon={faFileAlt}
                                btnStyle={'warning'}
                                size={'sm'}
                                onClickFn={setAsBSGHandler}
                                isLoading={isRunningAction} />}
                            {eventDetails && eventDetails.status != Enums.EventStatuses.WaitingForConfirmation && <IconButton
                                text={'Set as Awaiting Confirmation'}
                                icon={faFax}
                                btnStyle={'warning'}
                                size={'sm'}
                                onClickFn={setAsWFCHandler}
                                isLoading={isRunningAction} />}
                            {eventDetails && eventDetails.status != Enums.EventStatuses.Confirmed && <IconButton
                                text={'Set as Confirmed'}
                                icon={faCheck}
                                btnStyle={'success'}
                                size={'sm'}
                                onClickFn={setAsConfirmedHandler}
                                isLoading={isRunningAction} />}
                            {eventDetails && eventDetails.status != Enums.EventStatuses.Completed && <IconButton
                                text={'Set as Completed'}
                                icon={faCheckDouble}
                                btnStyle={'purple'}
                                size={'sm'}
                                onClickFn={setAsCompletedHandler}
                                isLoading={isRunningAction} />}
                            {eventDetails?.status != Enums.EventStatuses.Pending && eventDetails?.status != Enums.EventStatuses.Cancelled && eventDetails?.status != Enums.EventStatuses.Completed && (
                                <IconButton
                                    text={'Cancel Event'}
                                    icon={faTimes}
                                    btnStyle={'danger'}
                                    size={'sm'}
                                    onClickFn={cancelEventHandler}
                                    isLoading={isRunningAction} />
                            )}
                        </div>
                    </div>}

                    {!inEditMode && !isOnlyPhysician() && eventDetails?.status == Enums.EventStatuses.Pending && (
                        <IconButton
                            text={'Delete Event'}
                            icon={faTrash}
                            btnStyle={'danger'}
                            onClickFn={deleteEventHandler}
                            isLoading={isRunningAction} />
                    )}
                    {!inEditMode && !isOnlyPhysician() && eventDetails && [Enums.EventStatuses.BoardingSlipGenerated, Enums.EventStatuses.WaitingForConfirmation, Enums.EventStatuses.Confirmed, Enums.EventStatuses.Rejected, Enums.EventStatuses.Completed].includes(eventDetails.status) && (
                        <IconButton
                            text={'Generate Boarding Slip'}
                            icon={faFilePdf}
                            onClickFn={generatePdf} />
                    )}
                    {!inEditMode && user?.roles.includes(Configs.userRoles.PHYSICIAN) && eventDetails?.status == Enums.EventStatuses.Confirmed && (
                        <IconButton
                            text={'Event Status'}
                            icon={faProjectDiagram}
                            btnStyle={'success'}
                            onClickFn={openCodeEventPopup} />
                    )}
                    
                </div>
                
            </div>

            {/* PDF Viewer */}
            {/* {eventDetails && showPDF && (
                <div className="manage-event-page__pdf-popup" onClick={() => setShowPDF(false)}>
                    <div className="manage-event-page__pdf-popup__container">
                        <PDFViewer>
                            <MaccombOaklandENDOBoardingSlip eventDetails={eventDetails} />
                        </PDFViewer>
                    </div>
                </div>
            )} */}

        </div>
    );
};

export default ManageEvent;