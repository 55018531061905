import { faBalanceScale, faBalanceScaleLeft, faCheckSquare, faChevronLeft, faChevronRight, faDiagnoses, faEnvelopeOpenText, faExclamationTriangle, faFile, faFileContract, faFileMedical, faFlagCheckered, faGavel, faHandHoldingMedical, faHandPaper, faHeartbeat, faHospital, faLayerGroup, faLink, faMarsStroke, faMask, faMedkit, faMicrochip, faPlus, faSkullCrossbones, faSmoking, faSyringe, faTint, faUser, faUsers, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ManageUser from '../ManageUsers/ManageUser/ManageUser';
import ManageUsers from '../ManageUsers/ManageUsers';
import BloodThinners from './BloodThinners/BloodThinners';
import BoardingLinks from './BoardingLinks/BoardingLinks';
import Carriers from './Carriers/Carriers';
import Clearances from './Clearances/Clearances';
import Diagnosis from './Diagnosis/Diagnosis';
import EventRulings from './EventRulings/EventRulings';
import EventTypes from './EventTypes/EventTypes';
import Facilities from './Facility/Facilities';
import './MasterData.sass';
import PatientCommunication from './PatientCommunication/PatientCommunication';
import Priorities from './Priorities/Priorities';
import Procedures from './Procedures/Procedures';

const MasterData = () => {

    // <> History
    const history = useHistory();
    
    // <> States
    const [pathname, setPathname] = useState(history.location.pathname);
    const [isNavOpen, setIsNavOpen] = useState(true);

    // <> Effects
    useEffect(() => {

        const unregister = history.listen((location, action) => {
            setPathname(location.pathname);
        });

        return unregister;
    }, []);


    // <> JSX
    return (
        <div className="master-data-page">

            {/* Nav */}
            <div className={`master-data-page__nav ${!isNavOpen ? 'master-data-page__nav--closed' : ''}`}>

                <button className="master-data-page__nav__close-btn" onClick={() => setIsNavOpen(prev => !prev)}>
                    {isNavOpen && <FontAwesomeIcon icon={faChevronLeft} />}
                    {!isNavOpen && <FontAwesomeIcon icon={faChevronRight} />}
                </button>

                <Link className={`master-data-page__nav__item${pathname.endsWith('/facilities') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/facilities`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faHospital} />
                    <span className="master-data-page__nav__item__text">Facilities</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.endsWith('/event-types') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/event-types`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faLayerGroup} />
                    <span className="master-data-page__nav__item__text">Departments</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.endsWith('/priorities') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/priorities`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faBalanceScaleLeft} />
                    <span className="master-data-page__nav__item__text">Priorities</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.endsWith('/procedures') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/procedures`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faHandHoldingMedical} />
                    <span className="master-data-page__nav__item__text">Procedures</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.endsWith('/diagnosis') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/diagnosis`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faDiagnoses} />
                    <span className="master-data-page__nav__item__text">Diagnosis</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.endsWith('/blood-thinners') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/blood-thinners`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faTint} />
                    <span className="master-data-page__nav__item__text">Blood Thinners</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.endsWith('/carriers') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/carriers`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faFileMedical} />
                    <span className="master-data-page__nav__item__text">Carriers</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.endsWith('/event-rulings') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/event-rulings`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faGavel} />
                    <span className="master-data-page__nav__item__text">Carrier Auth Rules</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.includes('/clearances') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/clearances`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faCheckSquare} />
                    <span className="master-data-page__nav__item__text">Clearances</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.includes('/patient-communications') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/patient-communications`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faEnvelopeOpenText} />
                    <span className="master-data-page__nav__item__text">Patient Communications</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.includes('/boarding-links') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/boarding-links`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faLink} />
                    <span className="master-data-page__nav__item__text">Boarding Links</span>
                </Link>
                <Link className={`master-data-page__nav__item${pathname.includes('/manage-users') ? ' master-data-page__nav__item--selected' : ''}`} to={`/master-data/manage-users`}>
                    <FontAwesomeIcon className="master-data-page__nav__item__icon" icon={faUsers} />
                    <span className="master-data-page__nav__item__text">Manage Users</span>
                </Link>
            </div>

            {/* Content */}
            <div className="master-data-page__content">
                <Switch>
                    <Route path="/master-data/facilities" exact component={Facilities} />
                    <Route path="/master-data/event-types" exact component={EventTypes} />
                    <Route path="/master-data/procedures" exact component={Procedures} />
                    <Route path="/master-data/diagnosis" exact component={Diagnosis} />
                    <Route path="/master-data/blood-thinners" exact component={BloodThinners} />
                    <Route path="/master-data/priorities" exact component={Priorities} />
                    <Route path="/master-data/carriers" exact component={Carriers} />
                    <Route path="/master-data/event-rulings" exact component={EventRulings} />
                    <Route path="/master-data/clearances" exact component={Clearances} />
                    <Route path="/master-data/boarding-links" exact component={BoardingLinks} />
                    <Route path="/master-data/patient-communications" exact component={PatientCommunication} />
                    <Route path="/master-data/manage-users" exact component={ManageUsers} />
                    <Route path="/master-data/manage-users/:id" component={ManageUser} />
                </Switch>
            </div>
        </div>
    );
};

export default MasterData;