import React, { useContext, useEffect, useState } from 'react';
import './EventInsurance.sass';
import '../EventForms.sass';
import { useHistory, useLocation } from 'react-router';
import Enums from '../../../../enums';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../../../context/misc.context';
import Loader from '../../../../components/Loader/Loader';
import EventService from '../../../../services/event.service';
import Tag from '../../../../components/Tag/Tag';
import Configs from '../../../../config';
import moment from 'moment';
import Label from '../../../../components/Label/Label';
import StaticAlert from '../../../../components/StaticAlert/StaticAlert';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import { faCheck, faFileMedical, faFilePdf, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import EventClearanceService from '../../../../services/event.clearance.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditableField from '../../../../components/_controls/EditableField/EditableField';
import * as Presenter from './EventInsurance.presenter';
import { UserContext } from '../../../../context/user.context';
import AsyncPromptPopup from '../../../../components/_popups/AsyncPromptPopup/AsyncPromptPopup';
import GeneratePdf, { getBlobObjectUrl, generateClearance } from '../../../../pdfGenerators/pdfGenerators';
import EventBloodThinners from '../EventBloodThinners/EventBloodThinners';

const EventInsurance = ({ isLoading, eventDetails, refresh, bloodThinners }) => {

    // <> Context
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> States
    const [mcRequired, setMcRequired] = useState(false);
    const [ccRequired, setCcRequired] = useState(false);
    const [ocRequired, setOcRequired] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    // <> History
    const history = useHistory();

    // <> Effects
    useEffect(() => {
        if(eventDetails) {
            setMcRequired(eventDetails.medicalClearanceStatus != Enums.ClearanceStatuses.NotRequired);
            setCcRequired(eventDetails.cardiacClearanceStatus != Enums.ClearanceStatuses.NotRequired);
            setOcRequired(eventDetails.otherClearanceStatus != Enums.ClearanceStatuses.NotRequired);
        }
    }, [eventDetails])

    // <> Actions
    async function updateMedicalClearanceStatus(status) {

        try {

            let params = {};

            // If same status, exit
            if(eventDetails.medicalClearanceStatus == status) return;

            // Update status
            params.medicalClearanceStatus = status;
            params.medicalClearanceStatusUpdatedOn = moment().format(Configs.formats.apiDateTime);
            params.medicalClearanceUpdaterId = user.userId;

            // If changing status from NotRequired to another status, ask for the physician
            if(eventDetails.medicalClearanceStatus == Enums.ClearanceStatuses.NotRequired) {
                const physician = await AsyncPromptPopup({ message: 'Clearance Physician Name' });
                if(!physician) return;
                params.medicalClearancePhysician = physician;
            }

            // If changing status to Rejected, ask for the reason
            if(status == Enums.ClearanceStatuses.Rejected) {
                const rejectionReason = await AsyncPromptPopup({ message: 'Rejection Reason' });
                if(!rejectionReason) return;
                params.medicalClearanceRejectionReason = rejectionReason;
            }
            // Else null the rejection reason
            else {
                params.medicalClearanceRejectionReason = null;
            }

            // -> Turn Loader ON
            setIsUpdating(true);

            // -> Update
            // If changing status to NotRequired, null the physician
            if(status == Enums.ClearanceStatuses.NotRequired) params.medicalClearancePhysician = null;
            // Else if changing status to other than NotRequired, specify the assignee and due date
            else {
                params.medicalClearanceAssigneeId = eventDetails.coordinatorId;
                params.medicalClearanceDueDate = moment(eventDetails.dateFrom).subtract(3, 'days').format(Configs.formats.apiDateTime);
            }
            
            // -> Update
            await EventService.update(eventDetails.id, params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateCardiacClearanceStatus(status) {

        try {

            let params = {};

            // If same status, exit
            if(eventDetails.cardiacClearanceStatus == status) return;

            // Update status
            params.cardiacClearanceStatus = status;
            params.cardiacClearanceStatusUpdatedOn = moment().format(Configs.formats.apiDateTime);
            params.cardiacClearanceUpdaterId = user.userId;

            // If changing status from NotRequired to another status, ask for the physician
            if(eventDetails.cardiacClearanceStatus == Enums.ClearanceStatuses.NotRequired) {
                const physician = await AsyncPromptPopup({ message: 'Clearance Physician Name' });
                if(!physician) return;
                params.cardiacClearancePhysician = physician;
            }

            // If changing status to Rejected, ask for the reason
            if(status == Enums.ClearanceStatuses.Rejected) {
                const rejectionReason = await AsyncPromptPopup({ message: 'Rejection Reason' });
                if(!rejectionReason) return;
                params.cardiacClearanceRejectionReason = rejectionReason;
            }
            // Else null the rejection reason
            else {
                params.cardiacClearanceRejectionReason = null;
            }

            // -> Turn Loader ON
            setIsUpdating(true);

            // -> Update
            // If changing status to NotRequired, null the physician
            if(status == Enums.ClearanceStatuses.NotRequired) params.cardiacClearancePhysician = null;
            // Else if changing status to other than NotRequired, specify the assignee and due date
            else {
                params.cardiacClearanceAssigneeId = eventDetails.coordinatorId;
                params.cardiacClearanceDueDate = moment(eventDetails.dateFrom).subtract(3, 'days').format(Configs.formats.apiDateTime);
            }
            
            // -> Update
            await EventService.update(eventDetails.id, params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateOtherClearanceStatus(status) {

        try {

            let params = {};

            // If same status, exit
            if(eventDetails.otherClearanceStatus == status) return;

            // Update status
            params.otherClearanceStatus = status;
            params.otherClearanceStatusUpdatedOn = moment().format(Configs.formats.apiDateTime);
            params.otherClearanceUpdaterId = user.userId;

            // If changing status from NotRequired to another status, ask for the physician
            if(eventDetails.otherClearanceStatus == Enums.ClearanceStatuses.NotRequired) {
                const physician = await AsyncPromptPopup({ message: 'Clearance Physician Name' });
                if(!physician) return;
                params.otherClearancePhysician = physician;
            }

            // If changing status to Rejected, ask for the reason
            if(status == Enums.ClearanceStatuses.Rejected) {
                const rejectionReason = await AsyncPromptPopup({ message: 'Rejection Reason' });
                if(!rejectionReason) return;
                params.otherClearanceRejectionReason = rejectionReason;
            }
            // Else null the rejection reason
            else {
                params.otherClearanceRejectionReason = null;
            }

            // -> Turn Loader ON
            setIsUpdating(true);

            // -> Update
            // If changing status to NotRequired, null the physician
            if(status == Enums.ClearanceStatuses.NotRequired) params.otherClearancePhysician = null;
            // Else if changing status to other than NotRequired, specify the assignee and due date
            else {
                params.otherClearanceAssigneeId = eventDetails.coordinatorId;
                params.otherClearanceDueDate = moment(eventDetails.dateFrom).subtract(3, 'days').format(Configs.formats.apiDateTime);
            }
            
            // -> Update
            await EventService.update(eventDetails.id, params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateExtraClearanceStatus(eventClearanceId, status) {

        try {

            let params = { id: eventClearanceId, eventId: eventDetails.id };
            const eventClearance = eventDetails.clearances.find(c => c.id == eventClearanceId);

            // If same status, exit
            if(eventClearance.status == status) return;

            // Update status
            params.status = status;
            params.statusUpdatedOn = moment().format(Configs.formats.apiDateTime);
            params.statusUpdaterId = user.userId;

            // If changing status to Rejected, ask for the reason
            if(status == Enums.ClearanceStatuses.Rejected) {
                const rejectionReason = await AsyncPromptPopup({ message: 'Rejection Reason' });
                if(!rejectionReason) return;
                params.rejectionReason = rejectionReason;
            }
            // Else null the rejection reason
            else {
                params.rejectionReason = null;
            }

            // -> Turn Loader ON
            setIsUpdating(true);
            
            // -> Update
            await EventClearanceService.updateOne(params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateExtraClearancePhysician(eventClearanceId, eventClearancePhysician) {

        try {

            let params = { id: eventClearanceId, eventId: eventDetails.id };
            const eventClearance = eventDetails.clearances.find(c => c.id == eventClearanceId);

            // If same, exit
            if(eventClearance.physicianName == eventClearancePhysician) return;

            // Update param physician name
            params.physicianName = eventClearancePhysician;

            // -> Turn Loader ON
            setIsUpdating(true);
            
            // -> Update
            await EventClearanceService.updateOne(params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateExtraClearanceDescription(eventClearanceId, eventClearanceDescription) {

        try {

            let params = { id: eventClearanceId, eventId: eventDetails.id };
            const eventClearance = eventDetails.clearances.find(c => c.id == eventClearanceId);

            // If same, exit
            if(eventClearance.clearanceDescription == eventClearanceDescription) return;

            // Update param physician name
            params.clearanceDescription = eventClearanceDescription;

            // -> Turn Loader ON
            setIsUpdating(true);
            
            // -> Update
            await EventClearanceService.updateOne(params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function updateEvent(updateParams) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            // -> Save
            await EventService.update(eventDetails.id, updateParams);

            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }

    async function deleteEventClearance(clearanceId) {

        try {

            // -> Turn Loader ON
            setIsUpdating(true);

            // -> Delete
            await EventClearanceService.deleteOne(clearanceId);

            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsUpdating(false);
        }
    }


    // <> Helpers
    function openAddClearancePopup() {
        setOpenPopupState({
            eventId: eventDetails.id,
            assigneeId: eventDetails.coordinatorId,
            assigneeName: eventDetails.coordinatorName,
            dueDate: moment(eventDetails.dateFrom).subtract(3, 'days'),
            onSuccess: refresh
        });
        setOpenPopup(Enums.PopupTags.AddEventClearance);
    }

    function deleteExtraClearanceHandler(clearance) {

        setOpenPopupState({
            title: 'Delete Event Clearance',
            message: `Are you sure you want to delete "${clearance.clearanceName}" from this event?`,
            level: 'danger',
            onOk: () => deleteEventClearance(clearance.id)
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    async function generateClearancePdf(physician, note) {

        console.log('IN GCP')

        try {

            // -> Open Generated Clearance Slip PDF
            const pdfUnitArray = await generateClearance({ 
                physician, 
                patient: `${eventDetails.patientFirstName} ${eventDetails.patientMiddleName} ${eventDetails.patientLastName}`,
                facility: eventDetails.facilityName, 
                note 
            });
            const pdfDataUrl = getBlobObjectUrl(pdfUnitArray);
            window.open(pdfDataUrl);
        }
        catch(e) {
            console.error(e);
            addAlert({
                level: 'warning',
                message: 'Something went wrong with generating the PDF'
            })
        }
    }

    // <> JSX
    return (
        <div className="event-insurance">

            <Loader isLoading={isUpdating} />

            {eventDetails && 
            <>
                <div className="manage-event-page__content__pane">

                    <div className="form-sections">
                        <div className="form-sections__left">
                            
                            <div className="event-insurance__title">Clearances & Checklist</div>

                            <div className="event-insurance__groups">

                                {/* Medical Clearance */}
                                <div className="event-insurance__groups__group">

                                    {/* Top */}
                                    <div className="event-insurance__groups__group__top">

                                        {/* Clearance */}
                                        <div className="event-insurance__groups__group__top__title">Medical Clearance</div>

                                        {/* Physician */}
                                        {eventDetails.medicalClearancePhysician && (
                                            <div className="event-insurance__groups__group__top__physician">
                                                <EditableField 
                                                    isEditable={!isOnlyPhysician()}
                                                    rules={{ required: mcRequired ? 'medical clearance physician required' : null }}
                                                    value={eventDetails.medicalClearancePhysician} 
                                                    shownValue={<Label label={'Requested from'} message={eventDetails.medicalClearancePhysician} labelStyle={'primary'} size={'xsm'} />}
                                                    save={newVal => updateEvent({ medicalClearancePhysician: newVal })} />
                                            </div>
                                        )}

                                        <div className="event-insurance__groups__group__top__actions">
                                            {!isOnlyPhysician() && eventDetails.medicalClearanceStatus != Enums.ClearanceStatuses.NotRequired && <IconButton
                                                text={'Generate Clearance'}
                                                icon={faFilePdf}
                                                btnStyle={'success'}
                                                size={'sm'}
                                                onClickFn={() => generateClearancePdf(eventDetails.medicalClearancePhysician, eventDetails.medicalClearanceNote)} />}
                                        </div>
                                    
                                    </div>

                                    {/* Items */}
                                    <div className={`event-insurance__items`}>

                                        {/* Medical Clearance */}
                                        <div className="event-insurance__items__item event-insurance__items__item--primary">

                                            {/* Status */}
                                            <div className={`event-insurance__items__item__status ${isOnlyPhysician() ? 'event-insurance__items__item__status--no-actions' : ''}`}>
                                                <div className={`event-insurance__items__item__status__not-required ${eventDetails.medicalClearanceStatus == Enums.ClearanceStatuses.NotRequired ? 'event-insurance__items__item__status__not-required--active' : ''}`} onClick={() => updateMedicalClearanceStatus(Enums.ClearanceStatuses.NotRequired)}>NOT REQUIRED</div>
                                                <div className={`event-insurance__items__item__status__pending ${eventDetails.medicalClearanceStatus == Enums.ClearanceStatuses.Pending ? 'event-insurance__items__item__status__pending--active' : ''}`} onClick={() => updateMedicalClearanceStatus(Enums.ClearanceStatuses.Pending)}>TBO</div>
                                                <div className={`event-insurance__items__item__status__requested ${eventDetails.medicalClearanceStatus == Enums.ClearanceStatuses.Requested ? 'event-insurance__items__item__status__requested--active' : ''}`} onClick={() => updateMedicalClearanceStatus(Enums.ClearanceStatuses.Requested)}>REQUESTED</div>
                                                <div className={`event-insurance__items__item__status__accepted ${eventDetails.medicalClearanceStatus == Enums.ClearanceStatuses.Accepted ? 'event-insurance__items__item__status__accepted--active' : ''}`} onClick={() => updateMedicalClearanceStatus(Enums.ClearanceStatuses.Accepted)}>APPROVED</div>
                                                <div className={`event-insurance__items__item__status__rejected ${eventDetails.medicalClearanceStatus == Enums.ClearanceStatuses.Rejected ? 'event-insurance__items__item__status__rejected--active' : ''}`} onClick={() => updateMedicalClearanceStatus(Enums.ClearanceStatuses.Rejected)}>REJECTED</div>
                                            </div>

                                            {/* Note */}
                                            <div className="event-insurance__items__item__note">
                                                <span className="event-insurance__items__item__note__label">Note: </span>
                                                <EditableField 
                                                    isEditable={!isOnlyPhysician()}
                                                    rules={{ minLength: { value: 10, message: 'note must be at least 10 characters long' } }}
                                                    value={eventDetails.medicalClearanceNote}
                                                    save={newVal => updateEvent({ medicalClearanceNote: newVal })} />
                                            </div>

                                            {/* Tags */}
                                            <div className="event-insurance__items__item__labels">
                                                {eventDetails.medicalClearanceStatus != Enums.ClearanceStatuses.NotRequired && (
                                                    <>
                                                        <Label label={'Assigned To'} message={eventDetails.medicalClearanceAssigneeName} size={'xsm'} />
                                                        <Label label={'Due Date'} message={moment(eventDetails.medicalClearanceDueDate).format(Configs.formats.date)} size={'xsm'} labelStyle={moment(eventDetails.medicalClearanceDueDate).isBefore(moment()) ? 'danger' : 'primary'} />
                                                    </>
                                                )}
                                            </div>

                                        </div>

                                        {/* Clearance Status Timestamp */}
                                        {eventDetails.medicalClearanceStatus == Enums.ClearanceStatuses.Requested && (
                                            <div className="event-insurance__items__status-timestamp">
                                                <Label label={'Requested by'} message={eventDetails.medicalClearanceStatusUpdaterName} size={'xsm'} labelStyle={'info'} />
                                                <Label label={'Requested on'} message={moment(eventDetails.medicalClearanceStatusUpdatedOn).format(Configs.formats.dateTime)} size={'xsm'} labelStyle={'info'} />
                                            </div>
                                        )}

                                        {eventDetails.medicalClearanceStatus == Enums.ClearanceStatuses.Rejected && eventDetails.medicalClearanceRejectionReason && (
                                            <StaticAlert level={'danger-light'} message={`Medical Clearance Rejection Reason: "${eventDetails.medicalClearanceRejectionReason}"`} />
                                        )}

                                    </div>
                                </div>

                                {/* Cardiac Clearance */}
                                <div className="event-insurance__groups__group">

                                    {/* Top */}
                                    <div className="event-insurance__groups__group__top">

                                        {/* Clearance */}
                                        <div className="event-insurance__groups__group__top__title">Cardiac Clearance</div>

                                        {/* Physician */}
                                        {eventDetails.cardiacClearancePhysician && (
                                            <div className="event-insurance__groups__group__top__physician">
                                                <EditableField 
                                                    isEditable={!isOnlyPhysician()}
                                                    rules={{ required: ccRequired ? 'cardiac clearance physician required' : null }}
                                                    value={eventDetails.cardiacClearancePhysician} 
                                                    shownValue={<Label label={'Requested from'} message={eventDetails.cardiacClearancePhysician} labelStyle={'primary'} size={'xsm'} />}
                                                    save={newVal => updateEvent({ cardiacClearancePhysician: newVal })} />
                                            </div>    
                                        )}

                                        <div className="event-insurance__groups__group__top__actions">
                                        {!isOnlyPhysician() && eventDetails.cardiacClearanceStatus != Enums.ClearanceStatuses.NotRequired && <IconButton
                                                text={'Generate Clearance'}
                                                icon={faFilePdf}
                                                btnStyle={'success'}
                                                size={'sm'}
                                                onClickFn={() => generateClearancePdf(eventDetails.cardiacClearancePhysician, eventDetails.cardiacClearanceNote)} />}
                                        </div>

                                    </div>

                                    {/* Items */}
                                    <div className={`event-insurance__items`}>

                                        {/* Cardiac Clearance */}
                                        <div className="event-insurance__items__item event-insurance__items__item--primary">

                                            {/* Status */}
                                            <div className={`event-insurance__items__item__status ${isOnlyPhysician() ? 'event-insurance__items__item__status--no-actions' : ''}`}>
                                                <div className={`event-insurance__items__item__status__not-required ${eventDetails.cardiacClearanceStatus == Enums.ClearanceStatuses.NotRequired ? 'event-insurance__items__item__status__not-required--active' : ''}`} onClick={() => updateCardiacClearanceStatus(Enums.ClearanceStatuses.NotRequired)}>NOT REQUIRED</div>
                                                <div className={`event-insurance__items__item__status__pending ${eventDetails.cardiacClearanceStatus == Enums.ClearanceStatuses.Pending ? 'event-insurance__items__item__status__pending--active' : ''}`} onClick={() => updateCardiacClearanceStatus(Enums.ClearanceStatuses.Pending)}>TBO</div>
                                                <div className={`event-insurance__items__item__status__requested ${eventDetails.cardiacClearanceStatus == Enums.ClearanceStatuses.Requested ? 'event-insurance__items__item__status__requested--active' : ''}`} onClick={() => updateCardiacClearanceStatus(Enums.ClearanceStatuses.Requested)}>REQUESTED</div>
                                                <div className={`event-insurance__items__item__status__accepted ${eventDetails.cardiacClearanceStatus == Enums.ClearanceStatuses.Accepted ? 'event-insurance__items__item__status__accepted--active' : ''}`} onClick={() => updateCardiacClearanceStatus(Enums.ClearanceStatuses.Accepted)}>APPROVED</div>
                                                <div className={`event-insurance__items__item__status__rejected ${eventDetails.cardiacClearanceStatus == Enums.ClearanceStatuses.Rejected ? 'event-insurance__items__item__status__rejected--active' : ''}`} onClick={() => updateCardiacClearanceStatus(Enums.ClearanceStatuses.Rejected)}>REJECTED</div>
                                            </div>

                                            {/* Note */}
                                            <div className="event-insurance__items__item__note">
                                                <span className="event-insurance__items__item__note__label">Note: </span>
                                                <EditableField 
                                                    isEditable={!isOnlyPhysician()}
                                                    rules={{ minLength: { value: 10, message: 'note must be at least 10 characters long' } }}
                                                    value={eventDetails.cardiacClearanceNote}
                                                    save={newVal => updateEvent({ cardiacClearanceNote: newVal })} />
                                            </div>

                                            {/* Tags */}
                                            <div className="event-insurance__items__item__labels">
                                                {eventDetails.cardiacClearanceStatus != Enums.ClearanceStatuses.NotRequired && (
                                                    <>
                                                        <Label label={'Assigned To'} message={eventDetails.cardiacClearanceAssigneeName} size={'xsm'} />
                                                        <Label label={'Due Date'} message={moment(eventDetails.cardiacClearanceDueDate).format(Configs.formats.date)} size={'xsm'} labelStyle={moment(eventDetails.cardiacClearanceDueDate).isBefore(moment()) ? 'danger' : 'primary'} />
                                                    </>
                                                )}
                                            </div>

                                        </div>

                                        {/* Clearance Status Timestamp */}
                                        {eventDetails.cardiacClearanceStatus == Enums.ClearanceStatuses.Requested && (
                                            <div className="event-insurance__items__status-timestamp">
                                                <Label label={'Requested by'} message={eventDetails.cardiacClearanceStatusUpdaterName} size={'xsm'} labelStyle={'info'} />
                                                <Label label={'Requested on'} message={moment(eventDetails.cardiacClearanceStatusUpdatedOn).format(Configs.formats.dateTime)} size={'xsm'} labelStyle={'info'} />
                                            </div>
                                        )}

                                        {eventDetails.cardiacClearanceStatus == Enums.ClearanceStatuses.Rejected && eventDetails.cardiacClearanceRejectionReason && (
                                            <StaticAlert level={'danger-light'} message={`Cardiac Clearance Rejection Reason: "${eventDetails.cardiacClearanceRejectionReason}"`} />
                                        )}

                                    </div>
                                </div>

                                {/* Other Clearance */}
                                <div className="event-insurance__groups__group">

                                    {/* Top */}
                                    <div className="event-insurance__groups__group__top">

                                        {/* Clearance */}
                                        <div className="event-insurance__groups__group__top__title">Pulmonary Clearance</div>

                                        {/* Physician */}
                                        {eventDetails.otherClearancePhysician && (
                                            <div className="event-insurance__groups__group__top__physician">
                                                <EditableField 
                                                    isEditable={!isOnlyPhysician()}
                                                    rules={{ required: ocRequired ? 'pulmonary clearance physician required' : null }}
                                                    value={eventDetails.otherClearancePhysician} 
                                                    shownValue={<Label label={'Requested from'} message={eventDetails.otherClearancePhysician} labelStyle={'primary'} size={'xsm'} />}
                                                    save={newVal => updateEvent({ otherClearancePhysician: newVal })} />
                                            </div>
                                        )}

                                        {/* Actions */}
                                        <div className="event-insurance__groups__group__top__actions">
                                            {!isOnlyPhysician() && eventDetails.otherClearanceStatus != Enums.ClearanceStatuses.NotRequired && <IconButton
                                                text={'Generate Clearance'}
                                                icon={faFilePdf}
                                                btnStyle={'success'}
                                                size={'sm'}
                                                onClickFn={() => generateClearancePdf(eventDetails.otherClearancePhysician, eventDetails.otherClearanceNote)} />}
                                        </div>

                                    </div>

                                    {/* Items */}
                                    <div className={`event-insurance__items`}>

                                        {/* Other Clearance */}
                                        <div className="event-insurance__items__item event-insurance__items__item--primary">

                                            {/* Status */}
                                            <div className={`event-insurance__items__item__status ${isOnlyPhysician() ? 'event-insurance__items__item__status--no-actions' : ''}`}>
                                                <div className={`event-insurance__items__item__status__not-required ${eventDetails.otherClearanceStatus == Enums.ClearanceStatuses.NotRequired ? 'event-insurance__items__item__status__not-required--active' : ''}`} onClick={() => updateOtherClearanceStatus(Enums.ClearanceStatuses.NotRequired)}>NOT REQUIRED</div>
                                                <div className={`event-insurance__items__item__status__pending ${eventDetails.otherClearanceStatus == Enums.ClearanceStatuses.Pending ? 'event-insurance__items__item__status__pending--active' : ''}`} onClick={() => updateOtherClearanceStatus(Enums.ClearanceStatuses.Pending)}>TBO</div>
                                                <div className={`event-insurance__items__item__status__requested ${eventDetails.otherClearanceStatus == Enums.ClearanceStatuses.Requested ? 'event-insurance__items__item__status__requested--active' : ''}`} onClick={() => updateOtherClearanceStatus(Enums.ClearanceStatuses.Requested)}>REQUESTED</div>
                                                <div className={`event-insurance__items__item__status__accepted ${eventDetails.otherClearanceStatus == Enums.ClearanceStatuses.Accepted ? 'event-insurance__items__item__status__accepted--active' : ''}`} onClick={() => updateOtherClearanceStatus(Enums.ClearanceStatuses.Accepted)}>APPROVED</div>
                                                <div className={`event-insurance__items__item__status__rejected ${eventDetails.otherClearanceStatus == Enums.ClearanceStatuses.Rejected ? 'event-insurance__items__item__status__rejected--active' : ''}`} onClick={() => updateOtherClearanceStatus(Enums.ClearanceStatuses.Rejected)}>REJECTED</div>
                                            </div>

                                            {/* Note */}
                                            <div className="event-insurance__items__item__note">
                                                <span className="event-insurance__items__item__note__label">Note: </span>
                                                <EditableField 
                                                    isEditable={!isOnlyPhysician()}
                                                    rules={{ minLength: { value: 10, message: 'note must be at least 10 characters long' } }}
                                                    value={eventDetails.otherClearanceNote}
                                                    save={newVal => updateEvent({ otherClearanceNote: newVal })} />
                                            </div>

                                            {/* Tags */}
                                            <div className="event-insurance__items__item__labels">
                                                {eventDetails.otherClearanceStatus != Enums.ClearanceStatuses.NotRequired && (
                                                    <>
                                                        <Label label={'Assigned To'} message={eventDetails.otherClearanceAssigneeName} size={'xsm'} />
                                                        <Label label={'Due Date'} message={moment(eventDetails.otherClearanceDueDate).format(Configs.formats.date)} size={'xsm'} labelStyle={moment(eventDetails.otherClearanceDueDate).isBefore(moment()) ? 'danger' : 'primary'} />
                                                    </>
                                                )}
                                            </div>

                                        </div>

                                        {/* Clearance Status Timestamp */}
                                        {eventDetails.otherClearanceStatus == Enums.ClearanceStatuses.Requested && (
                                            <div className="event-insurance__items__status-timestamp">
                                                <Label label={'Requested by'} message={eventDetails.otherClearanceStatusUpdaterName} size={'xsm'} labelStyle={'info'} />
                                                <Label label={'Requested on'} message={moment(eventDetails.otherClearanceStatusUpdatedOn).format(Configs.formats.dateTime)} size={'xsm'} labelStyle={'info'} />
                                            </div>
                                        )}

                                        {eventDetails.otherClearanceStatus == Enums.ClearanceStatuses.Rejected && eventDetails.otherClearanceRejectionReason && (
                                            <StaticAlert level={'danger-light'} message={`Pulmonary Clearance Rejection Reason: "${eventDetails.otherClearanceRejectionReason}"`} />
                                        )}

                                    </div>
                                </div>

                                {/* Blood Thinners */}
                                <EventBloodThinners eventDetails={eventDetails} refresh={refresh} bloodThinners={bloodThinners} />
                                
                            </div>

                        </div>
                    </div>
                    
                </div>

                <div className="manage-event-page__content__pane">

                    <div className="event-insurance__title">Other Clearances</div>

                    {/* Extra Clearances */}
                    {eventDetails?.clearances?.length > 0 && eventDetails.clearances.map((clearance, i) => (

                        <div className="event-insurance__groups__group">

                            {/* Top */}
                            <div className="event-insurance__groups__group__top">

                                {/* Clearance */}
                                <div className="event-insurance__groups__group__top__title">{clearance.clearanceName}</div>

                                {/* Physician */}
                                <div className="event-insurance__groups__group__top__physician">
                                    <EditableField 
                                        isEditable={!isOnlyPhysician()}
                                        value={clearance.physicianName} 
                                        shownValue={<Label label={'Requested from'} message={clearance.physicianName || '-'} labelStyle={'primary'} size={'xsm'} />}
                                        save={newVal => updateExtraClearancePhysician(clearance.id, newVal)} />
                                </div>

                                {!isOnlyPhysician() && <div className="event-insurance__groups__group__top__actions event-insurance__groups__group__top__actions--right">
                                    <IconButton
                                        text={'Delete Clearance'}
                                        icon={faTrash}
                                        btnStyle={'danger'}
                                        size={'sm'}
                                        onClickFn={() => deleteExtraClearanceHandler(clearance)} />
                                </div>}

                            </div>

                            {/* Items */}
                            <div className={`event-insurance__items`}>

                                {/* Other Clearance */}
                                <div className="event-insurance__items__item event-insurance__items__item--secondary">

                                    {/* Status */}
                                    <div className={`event-insurance__items__item__status ${isOnlyPhysician() ? 'event-insurance__items__item__status--no-actions' : ''}`}>
                                        {/* <div className={`event-insurance__items__item__status__not-required ${eventDetails.otherClearanceStatus == Enums.ClearanceStatuses.NotRequired ? 'event-insurance__items__item__status__not-required--active' : ''}`} onClick={() => updateOtherClearanceStatus(Enums.ClearanceStatuses.NotRequired)}>Not Required</div> */}
                                        <div className={`event-insurance__items__item__status__pending ${clearance.status == Enums.ClearanceStatuses.Pending ? 'event-insurance__items__item__status__pending--active' : ''}`} onClick={() => updateExtraClearanceStatus(clearance.id, Enums.ClearanceStatuses.Pending)}>TBO</div>
                                        <div className={`event-insurance__items__item__status__requested ${clearance.status == Enums.ClearanceStatuses.Requested ? 'event-insurance__items__item__status__requested--active' : ''}`} onClick={() => updateExtraClearanceStatus(clearance.id, Enums.ClearanceStatuses.Requested)}>REQUESTED</div>
                                        <div className={`event-insurance__items__item__status__accepted ${clearance.status == Enums.ClearanceStatuses.Accepted ? 'event-insurance__items__item__status__accepted--active' : ''}`} onClick={() => updateExtraClearanceStatus(clearance.id, Enums.ClearanceStatuses.Accepted)}>APPROVED</div>
                                        <div className={`event-insurance__items__item__status__rejected ${clearance.status == Enums.ClearanceStatuses.Rejected ? 'event-insurance__items__item__status__rejected--active' : ''}`} onClick={() => updateExtraClearanceStatus(clearance.id, Enums.ClearanceStatuses.Rejected)}>REJECTED</div>
                                    </div>

                                    {/* Description */}
                                    {clearance.clearanceDescription && (
                                        <div className="event-insurance__items__item__description">
                                            <EditableField 
                                                isEditable={!isOnlyPhysician()}
                                                rules={{ required: `description required` }}
                                                value={clearance.clearanceDescription}
                                                save={newVal => updateExtraClearanceDescription(clearance.id, newVal)} />
                                        </div>
                                    )}

                                    {/* Tags */}
                                    <div className="event-insurance__items__item__labels">
                                        {clearance.status != Enums.ClearanceStatuses.NotRequired && (
                                            <>
                                                <Label label={'Assigned To'} message={clearance.assigneeName} size={'xsm'} />
                                                <Label label={'Due Date'} message={moment(clearance.dueDate).format(Configs.formats.date)} size={'xsm'} labelStyle={moment(clearance.dueDate).isBefore(moment()) ? 'danger' : 'primary'} />
                                            </>
                                        )}
                                    </div>

                                </div>

                                {/* Clearance Status Timestamp */}
                                {clearance.status == Enums.ClearanceStatuses.Requested && (
                                    <div className="event-insurance__items__status-timestamp">
                                        <Label label={'Requested by'} message={clearance.statusUpdaterName} size={'xsm'} labelStyle={'info'} />
                                        <Label label={'Requested on'} message={moment(clearance.statusUpdatedOn).format(Configs.formats.dateTime)} size={'xsm'} labelStyle={'info'} />
                                    </div>
                                )}

                                {clearance.status == Enums.ClearanceStatuses.Rejected && clearance.rejectionReason && (
                                    <StaticAlert level={'danger-light'} message={`${clearance.clearanceName} Rejection Reason: "${clearance.rejectionReason}"`} />
                                )}

                            </div>
                        </div>
                    ))}

                    {/* Add Clearance Btn */}
                    {!isOnlyPhysician() && <IconButton
                        text={'Add Clearance & Checklist'}
                        icon={faPlus}
                        onClickFn={openAddClearancePopup} />}
                </div>
                
            </>}
            
        </div>
    );
};

export default EventInsurance;