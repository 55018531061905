import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import './EditableField.sass';
import moment from 'moment';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';


const EditableField = ({ type, options = [], value, shownValue = null, save, rules = null, isEditable = true }) => {

    // <> States
    const [newVal, setNewVal] = useState(value);
    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [error, setError] = useState(null);

    // <> Actions
    function onSaveClick() {

        setError(null);

        // -> Validate
        if(rules) {
            const [isValid, errorMsg] = validate();
            if(!isValid) {
                setError(errorMsg);
                return;
            }
        }

        // -> Callback
        save(newVal);

        // -> Turn Edit Mode OFF
        setIsBeingEdited(false);
    }

    function cancelEdit() {
        setNewVal(value);
        setIsBeingEdited(false);
        setError(null);
    }

    // <> Helpers
    function validate() {

        if(rules?.required && !newVal)                                                                             return [false, rules.required];
        if(rules?.required || newVal) {
            if(rules?.minLength?.value && newVal.length < rules.minLength.value)                return [false, rules.minLength.message];
            if(rules?.maxLength?.value && newVal.length > rules.maxLength.value)                return [false, rules.maxLength.message];
            if(rules?.minDate?.value && moment(newVal).isBefore(moment(rules.minDate.value)))   return [false, rules.minDate.message];
            if(rules?.maxDate?.value && moment(newVal).isAfter(moment(rules.maxDate.value)))    return [false, rules.maxDate.message];
            if(rules?.min?.value && newVal < rules.min.value)                                   return [false, rules.min.message];
            if(rules?.max?.value && newVal > rules.max.value)                                   return [false, rules.max.message];
            if(rules?.pattern?.value && !newVal.match(newVal))                                  return [false, rules.pattern.message];
        }

        return [true, null];
    }

    function onKeyPressed(ev) {

        // If Enter, save
        if(ev.key === 'Enter') {
            ev.preventDefault();
            onSaveClick();
        }
    }
    
    // <> JSX
    return (
        <div className="editable-field">

            {/* Label When Not Edit Mode */}
            {!isBeingEdited && <div className={`editable-field__text ${isEditable ? 'editable-field__text--clickable' : ''}`} onClick={() => {setIsBeingEdited(isEditable)}}>{shownValue || value || '-'}</div>}

            {/* Input When Edit Mode */}
            {isBeingEdited && (
                <>
                    <div className="editable-field__edit-mode">

                        {/* Cancel */}
                        <div className="editable-field__edit-mode__cancel" onClick={cancelEdit}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>

                        {/* Regular Input */}
                        {(!type || type === 'text') && (<input 
                            autoFocus
                            className="editable-field__edit-mode__input" 
                            type={type} 
                            value={newVal} 
                            onKeyDown={onKeyPressed}
                            onChange={ev => setNewVal(ev.target.value)} />
                        )}

                        {/* Text Area Input */}
                        {(type === 'textarea') && (
                            <textarea 
                                autoFocus
                                className="editable-field__edit-mode__input" 
                                onKeyDown={onKeyPressed}
                                onChange={ev => setNewVal(ev.target.value)}
                            >{value}</textarea>
                        )}

                        {/* Select Input */}
                        {type === 'select' && (
                            <select onChange={ev => setNewVal(ev.target.value)}>
                                {options && options.map(op => (
                                    <option value={op.value} selected={value == op.value ? 'selected' : ''}>{op.label}</option>
                                ))}
                            </select>
                        )}

                        {/* Date Input */}
                        {type === 'date' && (<input 
                            autoFocus
                            className="editable-field__edit-mode__input" 
                            type={type} 
                            value={newVal} 
                            min={rules?.minDate?.value}
                            max={rules?.maxDate?.value}
                            onChange={ev => setNewVal(ev.target.value)} />
                        )}

                        {/* Phone Input */}
                        {type === 'phone' && (
                            <PhoneNumberInput defaultValue={value} onChangeFn={val => setNewVal(val)} onKeyDownFn={onKeyPressed} />
                        )}

                        {/* Confirm */}
                        <div className="editable-field__edit-mode__save" onClick={onSaveClick}>
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                    </div>

                    {/* Error Message */}
                    <span className="error-msg">{error}</span>
                </>
            )}

        </div>
    )
}

export default EditableField;