import React, { useContext, useState } from 'react';
import './EventCard.sass';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faClock, faExclamationCircle, faHospital, faPlus, faProjectDiagram, faSpinner, faStethoscope, faUserInjured } from '@fortawesome/free-solid-svg-icons';
import Configs from '../../../config.js';
import { useHistory } from 'react-router';
import ClearanceStatusIcon from '../../ClearanceStatusIcon/ClearanceStatusIcon';
import * as Presenter from './EventCard.presenter';
import IconButton from '../../_controls/IconButton/IconButton';
import { UserContext } from '../../../context/user.context';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import { useForm } from 'react-hook-form';
import EventNoteService from '../../../services/event.note.service';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';


const EventCard = ({ eventDetails, refresh }) => {

    // <> Context
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { user } = useContext(UserContext);

    // <> States
    const [isAddingNote, setIsAddingNote] = useState(false);

    // <> History
    const history = useHistory();

    // <> Form
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({ mode: 'onChange' });

    // <> Actions
    async function onAddNote(data) {

        try {

            // -> Turn Loader ON
            setIsAddingNote(true);

            // -> Add Note
            await EventNoteService.addOne({ eventId: data.eventId, userId: user.userId, note: data.note });

            // -> Alert
            addAlert({
                level: 'success',
                message: 'Internal note successfully added',
                ttl: Configs.misc.alertTTL.Short
            })
            
            // -> Refresh Event
            reset();
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsAddingNote(false);
        }

    }

    // <> Helpers
    function goToEventDetails(eventId) {
        history.push('/event/details/general', { eventId });
    }

    function openCodeEventPopup(eventId) {
        setOpenPopupState({
            eventId,
            onSuccess: refresh
        })
        setOpenPopup(Enums.PopupTags.CodeEvent);
    }

    // <> JSX
    return (
        <div className={`day-event-card day-event-card--${eventDetails.status} ${eventDetails.isEmergency ? 'day-event-card--danger' : ''}`}>
            
            {/* Info */}
            <div className="day-event-card__info">

                {/* Time */}
                <div className="day-event-card__info__time">
                    {!!eventDetails.isEmergency && <FontAwesomeIcon icon={faExclamationCircle} className="day-event-card__danger-icon" />}
                    <FontAwesomeIcon icon={faClock} />
                    <span>{moment(eventDetails.dateFrom).format(Configs.formats.time)}</span>
                    <span> - </span>
                    <span>{moment(eventDetails.dateTo).format(Configs.formats.time)}</span>
                    <span className="day-event-card__info__title">{eventDetails.title}</span>
                </div>

                {/* Status */}
                <div className="day-event-card__info__status">
                    {Presenter.createStatusTag(eventDetails.status)}
                </div>

            </div>

            {/* Statuses */}
            <div className="day-event-card__statuses">
                <div className="day-event-card__statuses__item">
                    <ClearanceStatusIcon status={eventDetails.medicalClearanceStatus} />
                    <span className="day-event-card__statuses__item__text">MEDCLR</span>
                </div>
                <div className="day-event-card__statuses__item">
                    <ClearanceStatusIcon status={eventDetails.cardiacClearanceStatus} />
                    <span className="day-event-card__statuses__item__text">CRDCLR</span>
                </div>
                <div className="day-event-card__statuses__item">
                    <ClearanceStatusIcon status={eventDetails.otherClearanceStatus} />
                    <span className="day-event-card__statuses__item__text">PLMCLR</span>
                </div>
            </div>

            {/* Add Internal Note */}
            <div className="day-event-card__add-note">
                <form className="day-event-card__add-note__form" onSubmit={handleSubmit(onAddNote)}>
                    <input type="hidden" value={eventDetails.id} {...register('eventId')} />
                    <textarea placeholder="Add Internal Note" {...register('note', { required: true, minLength: 20 })}></textarea>
                    <button type="submit" disabled={!isValid || isAddingNote}>
                        {!isAddingNote && <FontAwesomeIcon icon={faPlus} />}
                        {isAddingNote && <div className="spinner"><FontAwesomeIcon icon={faSpinner} /></div>}
                    </button>
                </form>
            </div>

            {/* Physician, Patient, Facility */}
            <div className="day-event-card__participants">
                <div className="day-event-card__participants__item">
                    <FontAwesomeIcon icon={faStethoscope} />
                    <span className="day-event-card__participants__item__text">{eventDetails.physicianName}</span>
                </div>
                <div className="day-event-card__participants__item">
                    <FontAwesomeIcon icon={faUserInjured} />
                    <span className="day-event-card__participants__item__text">{eventDetails.patientName}</span>
                </div>
                <div className="day-event-card__participants__item">
                    <FontAwesomeIcon icon={faHospital} />
                    <span className="day-event-card__participants__item__text">{eventDetails.facilityName}</span>
                </div>
            </div>

            {/* Actions */}
            <div className="day-event-card__actions">
                {user?.roles?.includes(Configs.userRoles.PHYSICIAN) && eventDetails.status == Enums.EventStatuses.Confirmed && <IconButton
                    text={'Event Status'}
                    icon={faProjectDiagram}
                    btnStyle={'success'}
                    size={'sm'}
                    onClickFn={() => openCodeEventPopup(eventDetails.id)} />}
                <IconButton
                    text={'Details'}
                    icon={faChevronRight}
                    btnStyle={'success'}
                    size={'sm'}
                    iconOnRight={true}
                    onClickFn={() => goToEventDetails(eventDetails.id)} />
            </div>

        </div>
    );

}

export default EventCard;