import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MiscContext } from '../../context/misc.context';
import './AddPatientDataPopups.sass';
import Enums from '../../enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlus, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import PatientService from '../../services/patient.service';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import PatientAllergyService from '../../services/patient.allergy.service';
import { useHistory } from 'react-router';
import IconButton from '../_controls/IconButton/IconButton';
import MasterDataService from '../../services/masterData.service';
import PhoneNumberInput from '../_controls/PhoneNumberInput/PhoneNumberInput';
import moment from 'moment';
import Configs from '../../config';
import SSNInputSimple from '../_controls/SSNInputSimple/SSNInputSimple';
import AddressSearch from '../AddressSearch/AddressSearch';


/**
 * Add patient
 */
const AddPatientPopup = () => {

    // <> Context
    const { addPatientDataOpenedPopup, addPatientDataOpenedPopupState, setPatientDataOpenedPopup, addAlert, setAddEventPopupState, toggleAddEventPopup } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [newPatient, setNewPatient] = useState(null);
    const [carriers, setCarriers] = useState(null);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');

    // <> Form
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
        getData();
    }, [addPatientDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Patient Using API
            const chosenCarrier = carriers.find(c => c.id == data.carrierId);
            data.carrierCode = chosenCarrier.code;
            data.carrierName = chosenCarrier.name;
            const addPatientResponse = await PatientService.add(data);

            setNewPatient(addPatientResponse.data.data.patient);
            setOpenSuccess(true);
            reset();

            if(addPatientDataOpenedPopupState.onSuccessFn) addPatientDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    async function getData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);
    
            // -> Add Patient Using API
            const carriersResponse = await MasterDataService.getTable(Enums.MasterData.Carrier);
            const carriersRes = carriersResponse.data.data.masterData;

            // -> Set State
            setCarriers(carriersRes);
            
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        console.log('close popup');
        setPatientDataOpenedPopup('');
    }

    function goToAddEvent() {
        closePopup();
        toggleAddEventPopup();
        setAddEventPopupState({ patient: newPatient });
    }

    function onAddressChoice(choice) {

        setValue('address', choice.formattedAddress);
        setValue('address1', choice.address1);
        // setValue('address2', choice.address2);
        setValue('city', choice.city);
        setValue('state', choice.state);
        setValue('zipcode', choice.zipCode);
    }

    // <> JSX
    return (
        <div className="add-patient-data-popup" style={{ display: addPatientDataOpenedPopup == Enums.AddPatientDetailsPopupTags.Patient ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-patient-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-patient-data-popup__container">

                {/* Title */}
                <div className="add-patient-data-popup__container__title">Patient Details</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-patient-data-popup__container__form">

                    <div className="add-patient-data-popup__container__form__flex">
                        {/* First Name */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">First Name</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                {...register('firstName', { required: 'first name required' })}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.firstName?.message}</label>
                        </div>

                        {/* Middle Name */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">Middle Name</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                {...register('middleName', { required: 'middle name required' })}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.middleName?.message}</label>
                        </div>

                        {/* Last Name */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">Last Name</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                {...register('lastName', { required: 'last name required' })}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.lastName?.message}</label>
                        </div>
                    </div>

                    {/* DoB */}
                    <div className="add-patient-data-popup__container__form__field">
                        <label className="add-patient-data-popup__container__form__field__label">DoB</label>
                        <input 
                            className="add-patient-data-popup__container__form__field__input" 
                            type="date"
                            min={moment().subtract(150, 'years').format(Configs.formats.dateInputDate)}
                            max={moment().format(Configs.formats.dateInputDate)}
                            {...register('dateofbirth', { required: 'date of birth required' })}
                            />
                        <label className="add-patient-data-popup__container__form__field__error">{errors?.dateofbirth?.message}</label>
                    </div>

                    {/* Sex */}
                    <div className="add-patient-data-popup__container__form__field">
                        <label className="add-patient-data-popup__container__form__field__label">Sex</label>
                        <select className="add-patient-data-popup__container__form__field__input" {...register('sex', { required: 'sex required' })}>
                            <option value="male">male</option>
                            <option value="female">female</option>
                        </select>
                        <label className="add-patient-data-popup__container__form__field__error">{errors?.sex?.message}</label>
                    </div>

                    
                    {/* Email */}
                    <div className="add-patient-data-popup__container__form__field">
                        <label className="add-patient-data-popup__container__form__field__label">Email</label>
                        <input 
                            className="add-patient-data-popup__container__form__field__input"
                            {...register('email', { required: 'email required' })}
                            />
                        <label className="add-patient-data-popup__container__form__field__error">{errors?.email?.message}</label>
                    </div>

                    {/* Phone */}
                    <div className="add-patient-data-popup__container__form__field">
                        <label className="add-patient-data-popup__container__form__field__label">Phone Number</label>
                        <PhoneNumberInput defaultValue={''} extra={register('phone', { required: 'phone number required' })} />
                        <label className="add-patient-data-popup__container__form__field__error">{errors?.phone?.message}</label>
                    </div>

                    {/* SSN */}
                    <div className="add-patient-data-popup__container__form__field">
                        <label className="add-patient-data-popup__container__form__field__label">Social Security Number</label>
                        <SSNInputSimple extra={register('ssn', { required: 'social security number required' })} addClassName={'add-patient-data-popup__container__form__field__input'} />
                        <label className="add-patient-data-popup__container__form__field__error">{errors?.ssn?.message}</label>
                    </div>
                    
                    {/* Title */}
                    <div className="add-patient-data-popup__container__title">Patient Address</div>

                    {/* Address Lookup */}
                    <AddressSearch onChoice={onAddressChoice} />

                    <div className="add-patient-data-popup__container__form__flex">

                        {/* Address 1 */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">Address 1</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                {...register('address1', { required: 'address 1 required' })}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.address1?.message}</label>
                        </div>

                        {/* Address 2 */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">Address 2</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                placeholder="Address 2 / Apartment / Suite Number"
                                {...register('address2')}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.address2?.message}</label>
                        </div>
                    </div>

                    <div className="add-patient-data-popup__container__form__flex">
                        {/* State */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">State</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                {...register('state', { required: 'state required' })}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.state?.message}</label>
                        </div>

                        {/* City */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">City</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                {...register('city', { required: 'city required' })}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.city?.message}</label>
                        </div>

                        {/* Zip Code */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">Zip Code</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                {...register('zipcode', { required: 'zip code required' })}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.zipcode?.message}</label>
                        </div>
                    </div>

                    {/* Insurance Section */}
                    <div className="add-patient-data-popup__container__title">Insurance</div>

                    {/* Carrier */}
                    {carriers && (
                        <select 
                            className="add-patient-data-popup__container__form__field__input"
                            {...register('carrierId', { required: 'carrier required' })}
                        >
                            {carriers.map((c, index) => (
                                <option key={index} value={c.id}>{c.name}</option>
                            ))}
                        </select>
                    )}

                    {/* Subscriber ID */}
                    <div className="add-patient-data-popup__container__form__field">
                        <label className="add-patient-data-popup__container__form__field__label">Subscriber ID</label>
                        <input 
                            className="add-patient-data-popup__container__form__field__input" 
                            {...register('subscriberId', { required: 'subscriber id required' })}
                            />
                        <label className="add-patient-data-popup__container__form__field__error">{errors?.subscriberId?.message}</label>
                    </div>

                    <div className="add-patient-data-popup__container__form__flex">
                        {/* Group Name */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">Group Name</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                {...register('groupName', { required: 'group name required' })}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.groupName?.message}</label>
                        </div>
                        
                        {/* Group Number */}
                        <div className="add-patient-data-popup__container__form__field">
                            <label className="add-patient-data-popup__container__form__field__label">Group Number</label>
                            <input 
                                className="add-patient-data-popup__container__form__field__input" 
                                {...register('groupNumber', { required: 'group number required' })}
                                />
                            <label className="add-patient-data-popup__container__form__field__error">{errors?.groupNumber?.message}</label>
                        </div>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-patient-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-patient-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    
                    {/* Hidden */}
                    <input type="hidden" {...register('address')} />
                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-patient-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-patient-data-popup__container__success-pane__icon" />
                        <div className="add-patient-data-popup__container__success-pane__message">Successfully added patient</div>
                        <div className="add-patient-data-popup__container__success-pane__actions">
                            <IconButton
                                text={'Add Event'}
                                icon={faPlus}
                                btnStyle={'success'}
                                onClickFn={goToAddEvent} />
                            <IconButton
                                text={'Close'}
                                icon={faTimes}
                                btnStyle={'primary'}
                                onClickFn={closePopup} />
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
}


/**
 * Exports
 */
export {
    AddPatientPopup
}