import { faExclamationCircle, faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ManageUserService from '../../../services/manageUser.service';
import BasicSelector from '../../_controls/BasicSelector/BasicSelector';
import MonthSelector from '../../_controls/MonthSelector/MonthSelector';
import YearSelector from '../../_controls/YearSelector/YearSelector';
import './MonthSchedule.sass';
import Configs from '../../../config.js';
import Enums from '../../../enums';
import MasterDataService from '../../../services/masterData.service';
import * as Presenter from './MonthSchedule.presenter';
import EventService from '../../../services/event.service';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../../context/misc.context';
import { useHistory } from 'react-router';
import { UserContext } from '../../../context/user.context';
import Loader from '../../Loader/Loader';
import BasicMultiSelector from '../../_controls/BasicMultiSelector/BasicMultiSelector';
import EventFilters from '../../EventFilters/EventFilters';
import BrowserStorage from '../../../utils/browser.storage';


const MonthSchedule = ({
    selectedDay, 
    setSelectedDay, 
    filters
}) => {

    // <> Contexts
    const { addAlert } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState(new Date().getUTCFullYear());
    const [month, setMonth] = useState(new Date().getUTCMonth());
    const [events, setEvents] = useState([]);
    const [days, setDays] = useState([]);
    

    // <> History
    const history = useHistory();

    // <> Effects
    useEffect(() => {
        setMonthDays();
    }, [year, month]);

    useEffect(() => {
        if(user?.loggedIn) getEvents();
    }, [user, days, filters]);

    // <> Actions
    async function getEvents() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // Time range search params
            const firstDayOfMonth = moment().year(year).month(month).startOf('month').hour(0).minute(0).second(0).format(Configs.formats.apiDateTime);
            const firstDayOfMonthAfter = moment().year(year).month(month).add(1, 'month').startOf('month').hour(0).minute(0).second(0).format(Configs.formats.apiDateTime);

            // -> Fetch Data from API
            const eventsResponse = await EventService.search({
                sortCol: 'dateFrom', 
                sortIsAsc: true, 
                skip: 0, 
                limit: 1000, 
                physicianId: filters?.physicianId,
                facilityId: filters?.facilityId,
                coordinatorId: filters?.coordinatorId,
                fromDate: firstDayOfMonth,
                toDate: firstDayOfMonthAfter,
                status: isOnlyPhysician() ? Enums.PhysicianEventStatusVisibility : filters?.status,
                isEmergency: filters?.isEmergency,
                eventTypeId: filters?.eventTypeId
            })

            const eventsRes = eventsResponse.data.data.events;
            console.log(eventsRes);

            // -> Present Data
            const eventsPresentable = Presenter.presentMonthEvents(eventsRes);

            // -> Set States
            setEvents(eventsPresentable);

        }
        catch (e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            // -> Turn Loader OFF
            setIsLoading(false);
        }

    }


    // <> Helpers
    function setMonthDays() {

        // Get first day of month as date
        const firstDayOfMonth = moment(`${year}-${month+1}-01 12:00`);

        // Get number of days in the current month
        const daysInMonth = firstDayOfMonth.daysInMonth();

        // Get days of the month as date array
        let daysArr = [];
        for(let i = 1; i <= daysInMonth; i++) {
            daysArr = [...daysArr, moment(`${year}-${month+1}-${i} 12:00`)];
        }

        // Set Days
        setDays(prev => daysArr);
    }
    
    // <> JSX
    return (
        <div className="month-schedule">

            {/* Filters */}
            <div className="month-schedule__filters">

                {/* Year Selector */}
                <YearSelector year={year} setYear={setYear} />

                {/* Month Selector */}
                <MonthSelector month={month} setMonth={setMonth} />

                <div className="flex-basis-100"></div>

            </div>

            {/* Calendar */}
            <div className="month-schedule__calendar-wrapper">

                {/* Loader */}
                <Loader isLoading={isLoading} />

                <div className="month-schedule__weekdays">
                    <span>Mon</span>
                    <span>Tues</span>
                    <span>Wed</span>
                    <span>Thur</span>
                    <span>Fri</span>
                    <span>Sat</span>
                    <span>Sun</span>
                </div>

                <div className="month-schedule__calendar">
                    {/* Blank slots if first days are not first of week */}
                    {days && days[0] && [...Array(days[0].isoWeekday() - 1)].map((item, index) => (
                        <div key={index} className="month-schedule__calendar__filler-day"></div>
                    ))}

                    {/* Month Days */}
                    {days && days.map((d, index) => (
                        <div 
                            key={index}
                            className={`month-schedule__calendar__day${selectedDay.isSame(d, 'day') ? ' month-schedule__calendar__day--selected' : ''}`}
                            onClick={() => setSelectedDay(d)}
                        >
                            <span className="month-schedule__calendar__day__number">{d.format('D')}</span>
                            {events && events.filter(e => e.dateFrom.isSame(d, 'day')).map((e, index2) => (
                                <div key={index2} className={`month-schedule__calendar__day__event month-schedule__calendar__day__event--${e.status} ${e.isEmergency ? '' : ''}`}>
                                    <div className="month-schedule__calendar__day__event__time">
                                        {e.isEmergency && (
                                            <span className="month-schedule__calendar__day__event__danger">
                                                <FontAwesomeIcon icon={faExclamationCircle} />
                                            </span>
                                        )}
                                        <span className="month-schedule__calendar__day__event__time__from">{e.dateFrom.format(Configs.formats.time)}</span>
                                        <span className="month-schedule__calendar__day__event__time__separator">-</span>
                                        <span className="month-schedule__calendar__day__event__time__to">{e.dateTo.format(Configs.formats.time)}</span>
                                    </div>
                                    <span className="month-schedule__calendar__day__event__title">{e.title}</span>
                                </div>
                            ))}
                            <div className="month-schedule__calendar__day__events-count">
                                {[...Array(events.filter(e => e.dateFrom.isSame(d, 'day')).length)].map((item, index3) => (
                                    <FontAwesomeIcon key={index3} icon={faHandHoldingMedical} />
                                ))}
                            </div>
                        </div>
                    ))}

                    {/* Blank slots if last days are not last of week */}
                    {days && days[days.length-1] && [...Array(7-days[days.length-1].isoWeekday())].map((item, index) => (
                        <div key={index} className="month-schedule__calendar__filler-day"></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MonthSchedule;