/**
 * @description Table column definitions class to be used in table paginator
 */



/**
 * Pagination table column definition
 */
class TableColumn {

    constructor() {
        this.type = 'text';
        this.options = [];
        this.searchSize = 'md';
        this.searchPlaceholder = 'Search';
        this.breakable = false;
        this.isCentered = false;
        this.customStyles = {};
    }

    setKey(key) {
        this.key = key;
        return this;
    }

    setShownValueKey(shownValueKey) {
        this.shownValueKey = shownValueKey;
        return this;
    }

    setType(type) {
        this.type = type;
        return this;
    }

    setOptions(options) {
        this.options = options;
        return this;
    }

    setDisplayName(displayName) {
        this.displayName = displayName;
        return this;
    }

    setSearchKey(searchKey) {
        this.searchKey = searchKey;
        return this;
    }

    setSearchSize(searchSize) {
        this.searchSize = searchSize;
        return this;
    }

    setSearchPlaceholder(searchPlaceholder) {
        this.searchPlaceholder = searchPlaceholder;
        return this;
    }

    setSortKey(sortKey) {
        this.sortKey = sortKey;
        return this;
    }

    setEditKey(editKey) {
        this.editKey = editKey;
        return this;
    }

    setIsLink(isLink) {
        this.isLink = isLink;
        return this;
    }

    setBreakable(breakable) {
        this.breakable = breakable;
        return this;
    }

    setIsCentered(isCentered) {
        this.isCentered = isCentered;
        return this;
    }

    setCustomStyles(customStyles) {
        this.customStyles = customStyles;
        return this;
    }

    setEditRules(editRules) {
        this.editRules = editRules;
        return this;
    }
}

class TableColumns {
    constructor(columns) { 
        this.columns = columns;
    }
}


// Exports
export {
    TableColumn,
    TableColumns
};