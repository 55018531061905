import axios from 'axios';
import Configs from '../config';


function search(query) {
    return axios.post(`${Configs.apiUrl}/address-lookup/search`, { query });
}

function details(placeId) {
    return axios.post(`${Configs.apiUrl}/address-lookup/details`, { placeId });
}


/**
 * Exports
 */
const AddressLookupService = {
    search,
    details
}
export default AddressLookupService;