import React, { useContext, useEffect, useState } from 'react';
import '../MasterDataContainer.sass';
import IconButton from '../../../components/_controls/IconButton/IconButton';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import EditableTable from '../../../components/_tables/EditableTable/EditableTable';
import * as Presenter from './Priorities.presenter';
import { useHistory } from 'react-router';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MasterDataService from '../../../services/masterData.service';
import PriorityService from '../../../services/priority.service';

const Priorities = () => {

    // <> Context
    const { setMasterDataOpenedPopup, setMasterDataOpenedPopupState, addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [priorities, setPriorities] = useState([]);

    // <> Effects
    useEffect(() => {
        getData();
    }, []);

    // <> Actions
    function onAddClicked() {
        setMasterDataOpenedPopup(Enums.AddMasterDataPopupTags.Priority);
        setMasterDataOpenedPopupState({ onSuccessFn: getData });
    }

    async function getData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data From API
            const prioritiesResponse = await MasterDataService.getTable(Enums.MasterData.Priority);
            const prioritiesRes = prioritiesResponse.data.data.masterData;
    
            // -> Format the data
            const presentableData = Presenter.presentData(prioritiesRes, setAsDefault);
    
            // -> Set Allergies
            setPriorities(presentableData);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function updateField(rowId, fieldName, newValue) {
        console.log(`updated ${fieldName} of ${Enums.MasterData.Priority} #${rowId} to ${newValue}`);

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Update Col in API
            await MasterDataService.updateCol(Enums.MasterData.Priority, rowId, fieldName, newValue);

            // -> Refetch Data
            getData();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setIsLoading(false);
        }
    }

    async function deleteRow(rowId) {

        // -> Confirm
        setOpenPopupState({
            level: 'danger',
            message: 'Are you sure you want to delete this priority?',
            onOk: async () => {
                
                try {
        
                    // -> Turn Loader ON
                    setIsLoading(true);
        
                    // -> Update Col in API
                    await MasterDataService.deleteRow(Enums.MasterData.Priority, rowId);
        
                    // -> Refetch Data
                    getData();
                }
                catch(e) {
                    GlobalHttpErrorHandler(e, history, addAlert);
                    setIsLoading(false);
                }
                
            }
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
        
    }

    async function setAsDefault(rowId) {
        
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Update Col in API
            await PriorityService.setAsDefault(rowId);

            // -> Refetch Data
            getData();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setIsLoading(false);
        }
    }

    // <> JSX
    return (
        <div className="master-data">

            <div className="master-data__top">
                {/* Patient Name */}
                <div className="master-data__top__title">Priorities</div>

                <div className="master-data__top__actions">
                    {/* Add Allergy Button */}
                    <div className="master-data__top__actions__action">
                        <IconButton 
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={onAddClicked} />
                    </div>
                </div>
            </div>

            <div className="master-data__container">

                <EditableTable
                    isLoading={isLoading}
                    items={priorities}
                    updateField={updateField}
                    deleteRow={deleteRow}
                    columnDefinitions={Presenter.tableColumnDefinitions()} />

            </div>
        </div>
    );
};

export default Priorities;