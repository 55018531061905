import Configs from '../../config.js';
import { TableColumn, TableColumns } from '../../components/_tables/TableColumns';
import moment from 'moment';
import StringFormatter from '../../utils/string.formats.js';


// Map Data to Desired Display Values
function presentData(data) {

    return data.map(d => {
        d.createdOn = moment(d.createdOn).format(Configs.formats.dateTime);
        d.roles = d.user_roles;
        d.phone = StringFormatter.formatPhone(d.phone);

        return d;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')
        .setSearchKey('id')
        .setSortKey('id')

    const emailCol = new TableColumn()
        .setKey('email')
        .setDisplayName('Email')
        .setSearchKey('email')
        .setSortKey('email')

    const nameCol = new TableColumn()
        .setKey('name')
        .setDisplayName('Name')
        .setSearchKey('name')
        .setSortKey('name')

    const phoneCol = new TableColumn()
        .setKey('phone')
        .setDisplayName('Phone #')
        .setSearchKey('phone')
        
    const rolesCol = new TableColumn()
        .setKey('roles')
        .setDisplayName('Roles')

    const tableCols = new TableColumns([
        emailCol,
        nameCol,
        phoneCol,
        rolesCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}