import axios from 'axios';
import Configs from '../config.js';


function getForPatient(patientId) {
    return axios.post(`${Configs.apiUrl}/patients/allergies/get`, { patientId });
}

function add(patientId, allergy) {
    return axios.post(`${Configs.apiUrl}/patients/allergies/add`, { patientId, ...allergy });
}

function updateCol(rowId, col, val) {
    return axios.put(`${Configs.apiUrl}/patients/allergies/updateCol`, { rowId, col, val });
}

function deleteRow(rowId, col, val) {
    return axios.delete(`${Configs.apiUrl}/patients/allergies/deleteRow/${rowId}`);
}


/**
 * Exports
 */
const PatientAllergyService = {
    getForPatient,
    add,
    updateCol,
    deleteRow
}
export default PatientAllergyService;