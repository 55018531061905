import { faChevronLeft, faChevronRight, faDiagnoses, faExclamationTriangle, faFileContract, faFlagCheckered, faHeartbeat, faMedkit, faMicrochip, faPills, faPlus, faProcedures, faSkullCrossbones, faSmoking, faSyringe, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import PatientAllergies from './PatientAllergies/PatientAllergies';
import PatientAssessments from './PatientAssessments/PatientAssessments';
import PatientDemographics from './PatientDemographics/PatientDemographics';
import './PatientDetails.sass';
import PatientEvents from './PatientEvents/PatientEvents';
import PatientGoals from './PatientGoals/PatientGoals';
import PatientHealthConcerns from './PatientHealthConcerns/PatientHealthConcerns';
import PatientImmunizations from './PatientImmunizations/PatientImmunizations';
import PatientImplantedDevices from './PatientImplantedDevices/PatientImplantedDevices';
import PatientInsurance from './PatientInsurance/PatientInsurance';
import PatientMedications from './PatientMedications/PatientMedications';
import PatientSmokingStatuses from './PatientSmokingStatuses/PatientSmokingStatuses';

const PatientDetails = () => {

    // <> History, Params
    const history = useHistory();
    
    // <> States
    const [pathname, setPathname] = useState(history.location.pathname);
    const [isNavOpen, setIsNavOpen] = useState(true);

    // <> Location & State Variables Passed
    const location = useLocation();

    // <> Effects
    useEffect(() => {

        const unregister = history.listen((location, action) => {
            setPathname(location.pathname);
        });

        return unregister;
    }, []);


    // <> JSX
    return (
        <div className="patient-details-page">

            {/* Nav */}
            <div className={`patient-details-page__nav ${!isNavOpen ? 'patient-details-page__nav--closed' : ''}`}>

                <button className="master-data-page__nav__close-btn" onClick={() => setIsNavOpen(prev => !prev)}>
                    {isNavOpen && <FontAwesomeIcon icon={faChevronLeft} />}
                    {!isNavOpen && <FontAwesomeIcon icon={faChevronRight} />}
                </button>

                <Link className={`patient-details-page__nav__item${pathname.endsWith('/demographics') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/demographics`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faUser} />
                    <span className="patient-details-page__nav__item__text">Details</span>
                </Link>
                {/* <Link className={`patient-details-page__nav__item${pathname.endsWith('/insurance') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/insurance`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faFileContract} />
                    <span className="patient-details-page__nav__item__text">Insurance</span>
                </Link>
                <Link className={`patient-details-page__nav__item${pathname.endsWith('/allergies') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/allergies`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faSkullCrossbones} />
                    <span className="patient-details-page__nav__item__text">Allergies</span>
                </Link>
                <Link className={`patient-details-page__nav__item${pathname.endsWith('/smoking-statuses') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/smoking-statuses`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faSmoking} />
                    <span className="patient-details-page__nav__item__text">Smoking Status</span>
                </Link>
                <Link className={`patient-details-page__nav__item${pathname.endsWith('/health-concerns') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/health-concerns`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faExclamationTriangle} />
                    <span className="patient-details-page__nav__item__text">Health Concerns</span>
                </Link>
                <Link className={`patient-details-page__nav__item${pathname.endsWith('/immunizations') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/immunizations`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faSyringe} />
                    <span className="patient-details-page__nav__item__text">Immunizations</span>
                </Link>
                <Link className={`patient-details-page__nav__item${pathname.endsWith('/goals') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/goals`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faFlagCheckered} />
                    <span className="patient-details-page__nav__item__text">Goals</span>
                </Link>
                <Link className={`patient-details-page__nav__item${pathname.endsWith('/assessments') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/assessments`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faDiagnoses} />
                    <span className="patient-details-page__nav__item__text">Assessments</span>
                </Link>
                <Link className={`patient-details-page__nav__item${pathname.endsWith('/implanted-devices') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/implanted-devices`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faMicrochip} />
                    <span className="patient-details-page__nav__item__text">Implanted Devices</span>
                </Link>
                <Link className={`patient-details-page__nav__item${pathname.endsWith('/medications') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/medications`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faPills} />
                    <span className="patient-details-page__nav__item__text">Medications</span>
                </Link> */}
                <Link className={`patient-details-page__nav__item${pathname.endsWith('/events') ? ' patient-details-page__nav__item--selected' : ''}`} to={{ pathname: `/patients/events`, state: location.state }}>
                    <FontAwesomeIcon className="patient-details-page__nav__item__icon" icon={faProcedures} />
                    <span className="patient-details-page__nav__item__text">Associated Events</span>
                </Link>
            </div>

            {/* Content */}
            <div className="patient-details-page__content">
                <Switch>
                    <Route path="/patients/demographics" exact component={PatientDemographics} />
                    <Route path="/patients/insurance" exact component={PatientInsurance} />
                    <Route path="/patients/allergies" exact component={PatientAllergies} />
                    <Route path="/patients/smoking-statuses" exact component={PatientSmokingStatuses} />
                    <Route path="/patients/health-concerns" exact component={PatientHealthConcerns} />
                    <Route path="/patients/immunizations" exact component={PatientImmunizations} />
                    <Route path="/patients/goals" exact component={PatientGoals} />
                    <Route path="/patients/assessments" exact component={PatientAssessments} />
                    <Route path="/patients/implanted-devices" exact component={PatientImplantedDevices} />
                    <Route path="/patients/medications" exact component={PatientMedications} />
                    <Route path="/patients/events" exact component={PatientEvents} />
                </Switch>
            </div>
        </div>
    );
};

export default PatientDetails;