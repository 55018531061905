import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../context/user.context';
import './Sprite.sass';

const Sprite = () => {

    // <> Context
    const { user } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> States
    const [pathname, setPathname] = useState(history.location.pathname);
    const [show, setShow] = useState(true);

    // <> Effects
    useEffect(() => {

        const unregister = history.listen((location, action) => {
            setPathname(location.pathname);
        });

        return unregister;
    }, [])

    useEffect(() => {
        setShow(shouldShow());
    }, [user, pathname])

    // <> Helpers
    function shouldShow() {
        return (
            !(user?.loggedIn)
            && !(pathname.startsWith('/login') || pathname.startsWith('/register'))
        )
    }

    // <> JSX
    return (
        <div className={`sprite ${show ? 'sprite--show' : 'sprite--hide'}`}>
            <span className="sprite__logo">GALEN</span>
        </div>
    );
};

export default Sprite;