import Tag from "../../../components/Tag/Tag";
import Enums from "../../../enums";


function createStatusTag(status) {

    const tagExtraStyles = { display: 'block', textAlign: 'center' };
    switch(status) {
        case Enums.EventStatuses.Pending:
            return <Tag text={'Event Created'} tagStyle={'gray'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.BoardingSlipGenerated:
            return <Tag text={'BS Generated'} tagStyle={'yellow'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.WaitingForConfirmation:
            return <Tag text={'Awaiting Confirmation'} tagStyle={'orange'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.Confirmed:
            return <Tag text={'Confirmed'} tagStyle={'green'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.Completed:
            return <Tag text={'Completed'} tagStyle={'purple'} size={'xsm'} extraStyles={tagExtraStyles} />;
        case Enums.EventStatuses.Cancelled:
            return <Tag text={'Cancelled'} tagStyle={'red'} size={'xsm'} extraStyles={tagExtraStyles} />;
        default:
            break;
    }
}


/**
 * Exports
 */
export {
    createStatusTag
}