import React, { useState, useEffect } from 'react';
import './DropdownSearch.sass';
import { similarity } from '../../../utils/stringSimilarity';
import Configs from '../../../config.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch, faStethoscope } from '@fortawesome/free-solid-svg-icons';

const DropdownSearch = ({ options, value, setter, icon = null, hideSelection = false, onManualInputFn = null }) => {

    // <> States
    const [query, setQuery] = useState('');
    const [matches, setMatches] = useState([]);
    const [hoveredAt, setHoveredAt] = useState(-1);
    const [manualValue, setManualValue] = useState(null);

    // <> Effects
    useEffect(() => {

        setHoveredAt(-1);

        if(query == '') {
            setMatches([]);
            return;
        }

        // -> Get options that match the search query [by being a substring or by having close similarity]
        const foundMatches = options.filter(item => 
            similarity(item.label, query) > Configs.misc.physiciansSearchMatchSensitivity
            || (query.length >= Configs.misc.dropdownSearchQueryMinLengthTrigger && (item.label.toLowerCase().includes(query.toLowerCase()))));

        // -> Set found matches to the dropdown
        setMatches(foundMatches);
    }, [query]);

    // <> Actions
    function onItemClicked(itemValue) {
        setter(itemValue);
        setQuery('');
        setHoveredAt(-1);
        setManualValue(null);
    }

    function onKeyPressed(ev) {

        // If Arrow Down, move matched item selection down
        if(ev.key == 'ArrowDown' && hoveredAt < matches.length - 1) setHoveredAt(prev => prev + 1);

        // If Arrow Down, move matched item selection up
        if(ev.key == 'ArrowUp' && hoveredAt > 0) setHoveredAt(prev => prev - 1);

        // If Enter, select a match
        if(ev.key == 'Enter') {
            ev.preventDefault();

            if(onManualInputFn && hoveredAt <= -1) {
                manualInput();
            }
            else if(matches.length > 0) {
                if(hoveredAt > -1) {
                    onItemClicked(matches[hoveredAt].value);
                }
                else {
                    onItemClicked(matches[0].value);
                }
            }
        }
    }

    function manualInput() {
        onManualInputFn(query);
        setQuery('');
        setHoveredAt(-1);
        setManualValue(query);
    }

    // <> JSX
    return (
        <div className={`dropdown-search ${hideSelection ? 'dropdown-search--hide-selection' : ''}`}>
            <div className="dropdown-search__input">
                <input 
                    className="dropdown-search__input__input" 
                    type="text" value={query} 
                    onChange={ev => setQuery(ev.target.value)}
                    onKeyDown={onKeyPressed}
                    placeholder="Search" />
                <FontAwesomeIcon className="dropdown-search__input__icon" icon={faSearch} />

                {/* Button for manual input in case enabled */}
                {onManualInputFn && (
                    <div className={`dropdown-search__input__manual-input-btn ${!query ? 'dropdown-search__input__manual-input-btn--disabled' : ''}`} onClick={manualInput}>
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                )}
            </div>
            <div className="dropdown-search__dropdown">
                {query && query.length >= Configs.misc.dropdownSearchQueryMinLengthTrigger && matches.length == 0 && <div className="dropdown-search__dropdown__item">No matches found</div>}
                {matches && matches.map((match, index) => (
                    <div key={index} className={`dropdown-search__dropdown__item ${hoveredAt == index ? 'dropdown-search__dropdown__item--highlight' : ''}`} onClick={() => onItemClicked(match.value)}>
                        {match.label}
                    </div>
                ))}
            </div>
            <div hidden={hideSelection} className="dropdown-search__chosen">
                <FontAwesomeIcon className="dropdown-search__chosen__icon" icon={icon || faStethoscope} /> {manualValue || (options.find(o => o.value == value)?.label ?? '')}
            </div>
        </div>
    );
};

export default DropdownSearch;