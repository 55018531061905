import { faArrowLeft, faArrowRight, faCaretDown, faCaretRight, faCheck, faCheckDouble, faChevronDown, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Configs from '../../../config';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import EventService from '../../../services/event.service';
import MasterDataService from '../../../services/masterData.service';
import Loader from '../../Loader/Loader';
import Tag from '../../Tag/Tag';
import DropdownSearch from '../../_controls/DropdownSearch/DropdownSearch';
import IconButton from '../../_controls/IconButton/IconButton';
import '../Popup.sass';
import './CodeEvent.sass';

const CodeEvent = () => {

    // <> Context
    const { openPopup, openPopupState, setOpenPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [isCodingPane, setIsCodingPane] = useState(false);
    const [isCancelPane, setIsCancelPane] = useState(false);
    const [event, setEvent] = useState(null);
    const [eventProcedures, setEventProcedures] = useState([]);
    const [cancelReason, setCancelReason] = useState('');
    const [procedures, setProcedures] = useState([]);
    const [selectedProcedures, setSelectedProcedures] = useState([]);
    const [selectedProcedureId, setSelectedProcedureId] = useState(null);
    const [availableProcedures, setAvailableProcedures] = useState([]);

    // <> Effects
    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if(openPopup == Enums.PopupTags.CodeEvent && openPopupState?.eventId) {
            getEvent();
        }
    }, [openPopup, openPopupState])

    useEffect(() => {
        if(event && event.procedures) {
            setEventProcedures(event.procedures.map(p => { return { id: p.procedureId, code: p.procedureCode, name: p.procedureName } }));
        }
    }, [event])

    useEffect(() => {
        // Show available procedures to add that are not already added
        if(eventProcedures && procedures) {
            setAvailableProcedures(procedures.filter(p => !selectedProcedures.some(sp => sp.id == p.id) && !eventProcedures.some(sp => sp.id == p.id)));
        }
    }, [eventProcedures, selectedProcedures, procedures])

    useEffect(() => {
        // If selected procedure is not in selected list, add it
        if(selectedProcedureId && !selectedProcedures.some(p => p.id == selectedProcedureId)) {
            const selectedProcedure = procedures.find(p => p.id == selectedProcedureId);
            setSelectedProcedures(prev => [...prev, selectedProcedure]);
        }
    }, [selectedProcedureId])

    // <> Actions
    async function getData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data From API
            const proceduresResponse = await MasterDataService.getTable(Enums.MasterData.Procedures);
            const proceduresRes = proceduresResponse.data.data.masterData;
    
            // -> Set Procedures
            setProcedures(proceduresRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function getEvent() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data From API
            const eventResponse = await EventService.getWithDetails(openPopupState.eventId);
            const eventRes = eventResponse.data.data.event;
    
            // -> Set Event
            setEvent(eventRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function cancelEvent() {

        try {

            // -> User Confirmation
            if(!window.confirm('Are you sure you want to cancel this event?')) return;

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as Completed in API
            await EventService.cancelEvent(openPopupState.eventId, cancelReason);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully cancelled'
            })

            // -> Close
            if(openPopupState?.onSuccess) openPopupState.onSuccess();
            closePopup();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }
    async function completeEvent() {

        try {

            // -> User Confirmation
            if(!window.confirm('Are you sure you want to complete this event?')) return;

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as Completed in API
            await EventService.setAsCompleted(openPopupState.eventId);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully completed'
            })

            // -> Close
            if(openPopupState?.onSuccess) openPopupState.onSuccess();
            closePopup();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function codeEvent() {

        try {

            // -> User Confirmation
            if(!window.confirm('Are you sure you want to complete this event with the selected procedures?')) return;

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as Completed in API
            await EventService.codeEvent(openPopupState.eventId, selectedProcedures);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully completed'
            })

            // -> Close
            if(openPopupState?.onSuccess) openPopupState.onSuccess();
            closePopup();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        reset();
        setOpenPopup('');
    }

    function reset() {
        setAvailableProcedures([]);
        setSelectedProcedures([]);
        setSelectedProcedureId(null);
        setIsCodingPane(false);
        setIsCancelPane(false);
        setCancelReason('');
    }

    function selectCurrentProcedure(procedure) {
        if(!selectedProcedures.some(p => p.id == procedure.id)) {
            setSelectedProcedures(prev => [...prev, procedure]);
        }
    }

    // <> JSX
    return (
        <div className={`popup ${openPopup == Enums.PopupTags.CodeEvent ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container">

                {/* Loader */}
                <Loader isLoading={isLoading} />

                {/* Close Btn */}
                <div className="popup__container__close-btn" onClick={closePopup}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>

                {/* Top */}
                <div className="popup__container__title">Event Status</div>

                {/* Body */}
                <div className="popup__container__body">

                    <div className="popup__container__body__section-title">Complete Event</div>
                    {/* Complete Event */}
                    <IconButton
                        text={'Set Event As Completed'}
                        icon={faCheckDouble}
                        btnStyle={'purple'}
                        onClickFn={completeEvent} />

                    <div className="popup__container__body__section-title">Event Coding</div>
                    {/* Event Coding */}
                    <IconButton
                        text={'Event Coding'}
                        icon={isCodingPane ? faCaretDown : faCaretRight}
                        btnStyle={'warning'}
                        iconOnRight={true}
                        onClickFn={() => setIsCodingPane(prev => !prev)} />

                    {/* Coding Pane */}
                    {isCodingPane && (
                        <div className="popup__container__body__subsection code-event__coding">
                            <div className="popup__container__body__section-title">Current Event Procedures (click to add)</div>
                            {/* Current Procedures */}
                            <div className="code-event__tag-list">
                                {eventProcedures && eventProcedures.map(p => (
                                    <div className={`code-event__tag-selectable ${selectedProcedures.some(sp => sp.id == p.id) ? 'code-event__tag-selectable--disabled' : ''}`} onClick={() => selectCurrentProcedure(p)}>
                                        <Tag text={p.code || p.name} tagStyle={'primary'} size={'sm'} />
                                    </div>
                                ))}
                            </div>

                            <div className="popup__container__body__section-title">Add Event Procedures (type code)</div>
                            {/* Add Procedure Input */}
                            <DropdownSearch
                                options={availableProcedures.map(p => { return { value: p.id, label: p.code } })}
                                value={selectedProcedureId}
                                setter={setSelectedProcedureId}
                                hideSelection={true} />

                            <div className="popup__container__body__section-title popup__container__body__section-title--mg-top">Final Event Procedures</div>
                            {/* Selected Procedures */}
                            <div className="code-event__tag-list">
                                {selectedProcedures && selectedProcedures.length > 0 && selectedProcedures.map(p => (
                                    <Tag text={p.code || p.name} tagStyle={'green'} size={'sm'} />
                                ))}
                                {selectedProcedures && selectedProcedures.length == 0 && (
                                    <div className="code-event__tag-list__empty-message">No procedures selected, please select at least one</div>
                                )}
                            </div>

                            <IconButton
                                text={'Set Event As Completed'}
                                icon={faCheckDouble}
                                btnStyle={'success'}
                                isDisabled={selectedProcedures.length == 0}
                                onClickFn={codeEvent} />
                        </div>
                    )}

                    <div className="popup__container__body__section-title">Cancel Event</div>
                    <IconButton
                        text={'Cancel Event'}
                        icon={isCancelPane ? faCaretDown : faCaretRight}
                        btnStyle={'danger'}
                        iconOnRight={true}
                        onClickFn={() => setIsCancelPane(prev => !prev)} />

                    {isCancelPane && (
                        <div className="popup__container__body__subsection code-event__cancel">
                            {/* Cancellation Reason */}
                            <textarea
                                className=""
                                rows={3}
                                placeholder="Cancellation reason"
                                value={cancelReason}
                                onChange={ev => setCancelReason(ev.target.value)}
                            ></textarea>
                            <IconButton
                                text={'Update Event'}
                                icon={faTimes}
                                btnStyle={'danger'}
                                isDisabled={cancelReason.length < 3}
                                onClickFn={cancelEvent} />
                        </div>
                    )}
                    
                </div>

            </div>

        </div>
    );
};

export default CodeEvent;