import React from 'react';
import './Footer.sass';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__text">GALEN - Surgical Scheduling System (C) {new Date().getFullYear()}</div>
        </div>
    );
};

export default Footer;