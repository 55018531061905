import axios from 'axios';
import Configs from '../config';


function add(event) {
    return axios.post(`${Configs.apiUrl}/events/add`, event);
}

function reschedule(event) {
    return axios.post(`${Configs.apiUrl}/events/reschedule`, event);
}

function findById(eventId) {
    return axios.post(`${Configs.apiUrl}/events/findById`, { eventId });
}

function getWithDetails(eventId) {
    return axios.post(`${Configs.apiUrl}/events/getWithDetails`, { eventId });
}

function search({searchCol = null, searchQuery = null, sortCol = null, sortIsAsc = null, skip, limit, physicianId = null, coordinatorId = null, patientId = null, facilityId = null, fromDate = null, toDate = null, status = null, isEmergency = null, eventTypeId = null}) {
    return axios.post(`${Configs.apiUrl}/events/search`, { searchCol, searchQuery, sortCol, sortIsAsc, skip, limit, physicianId, coordinatorId, patientId, facilityId, fromDate, toDate, status, isEmergency, eventTypeId });
}

function update(eventId, params) {
    return axios.put(`${Configs.apiUrl}/events/update`, { eventId, params });
}

function setAsBSG(eventId) {
    return axios.put(`${Configs.apiUrl}/events/setAsBSG`, { eventId });
}

function setAsWFC(eventId) {
    return axios.put(`${Configs.apiUrl}/events/setAsWFC`, { eventId });
}

function setAsConfirmed({ eventId, sendPatientComEmail, sendPatientComSms, patientComIds }) {
    return axios.put(`${Configs.apiUrl}/events/setAsConfirmed`, { eventId, sendPatientComEmail, sendPatientComSms, patientComIds });
}

function setAsConfirmedAndReschedule({ eventId, dateFrom, dateTo, sendPatientComEmail, sendPatientComSms, patientComIds }) {
    return axios.put(`${Configs.apiUrl}/events/setAsConfirmedAndReschedule`, { eventId, dateFrom, dateTo, sendPatientComEmail, sendPatientComSms, patientComIds });
}

function setAsRejected(eventId) {
    return axios.put(`${Configs.apiUrl}/events/setAsRejected`, { eventId });
}

function setAsCompleted(eventId) {
    return axios.put(`${Configs.apiUrl}/events/setAsCompleted`, { eventId });
}

function deleteEvent(eventId) {
    return axios.post(`${Configs.apiUrl}/events/deleteEvent`, { eventId });
}

function cancelEvent(eventId, cancelReason) {
    return axios.post(`${Configs.apiUrl}/events/cancelEvent`, { eventId, cancelReason });
}

function codeEvent(eventId, selectedProcedures) {
    return axios.post(`${Configs.apiUrl}/events/codeEvent`, { eventId, selectedProcedures });
}

function sendPatientCommunications({ eventId, sendPatientComEmail, sendPatientComSms, patientComIds }) {
    return axios.put(`${Configs.apiUrl}/events/sendPatientCommunications`, { eventId, sendPatientComEmail, sendPatientComSms, patientComIds });
}


/**
 * Exports
 */
const EventService = {
    add,
    reschedule,
    findById,
    getWithDetails,
    search,
    update,
    setAsBSG,
    setAsWFC,
    setAsConfirmed,
    setAsConfirmedAndReschedule,
    setAsRejected,
    setAsCompleted,
    deleteEvent,
    cancelEvent,
    codeEvent,
    sendPatientCommunications
}
export default EventService;