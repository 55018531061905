import axios from 'axios';
import Configs from '../config';


function setAsDefault(rowId) {
    return axios.post(`${Configs.apiUrl}/priorities/setAsDefault`, { rowId });
}


/**
 * Exports
 */
const PriorityService = {
    setAsDefault
}
export default PriorityService;