import React, { useContext, useEffect, useState } from 'react';
import '../PatientDetailsContainer.sass';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import { MiscContext } from '../../../../context/misc.context';
import Enums from '../../../../enums';
import EditableTable from '../../../../components/_tables/EditableTable/EditableTable';
import * as Presenter from './PatientAssessments.presenter';
import { useHistory, useLocation } from 'react-router';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import PatientDataService from '../../../../services/patientData.service';

const PatientAssessments = () => {

    // <> Context
    const { setOpenPopup, setOpenPopupState, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [patientData, setPatientData] = useState([]);

    // <> Location & State Variables Passed
    const location = useLocation();
    const { patientId, patientName } = location.state;

    // <> Actions
    

    // <> Effects
    useEffect(() => {
        getData();
    }, []);

    // <> Actions
    function onAddClicked() {
        setOpenPopupState({
            patientId,
            patientName,
            onSuccess: getData
        });
        setOpenPopup(Enums.PopupTags.AddPatientAssessment);
    }

    async function getData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Patient Data From API
            const patientDataResponse = await PatientDataService.getTable(Enums.PatientData.Assessments);
            const patientDataRes = patientDataResponse.data.data.patientData;
    
            // -> Set State
            setPatientData(patientDataRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function updateCol(rowId, col, val) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Update Col in API
            await PatientDataService.updateCol(Enums.PatientData.Assessments, rowId, col, val);

            // -> Refetch Data
            getData();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setIsLoading(false);
        }
    }

    async function deleteRow(rowId) {
        
        try {

            // -> Confirm
            if(!window.confirm('Are you sure you want to delete this entry?')) return;

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Update Col in API
            await PatientDataService.deleteRow(Enums.PatientData.Assessments, rowId);

            // -> Refetch Data
            getData();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setIsLoading(false);
        }
    }

    // <> JSX
    return (
        <div className="patient-data">

            <div className="patient-data__top">
                {/* Patient Name */}
                <div className="patient-data__top__title">Patient assessments for <span className="primary-color">{patientName}</span></div>

                <div className="patient-data__top__actions">
                    {/* Add Assessment Button */}
                    <div className="patient-data__top__actions__action">
                        <IconButton 
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={onAddClicked} />
                    </div>
                </div>
            </div>

            <div className="patient-data__container">

                <EditableTable
                    isLoading={isLoading}
                    items={Presenter.presentData(patientData)}
                    updateField={updateCol}
                    deleteRow={deleteRow}
                    columnDefinitions={Presenter.tableColumnDefinitions()} />

            </div>
        </div>
    );
};

export default PatientAssessments;