import React, { useContext, useEffect, useState } from 'react';
import './PatientDemographics.sass';
import { faCheck, faHistory, faPencilAlt, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import { MiscContext } from '../../../../context/misc.context';
import Enums from '../../../../enums';
import { useHistory, useLocation } from 'react-router';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import PatientService from '../../../../services/patient.service';
import Loader from '../../../../components/Loader/Loader';
import { useForm } from 'react-hook-form';
import Configs from '../../../../config';
import moment from 'moment';
import MasterDataService from '../../../../services/masterData.service';
import { UserContext } from '../../../../context/user.context';
import StringFormatter from '../../../../utils/string.formats';
import PhoneNumberInput from '../../../../components/_controls/PhoneNumberInput/PhoneNumberInput';
import SSNInputSimple from '../../../../components/_controls/SSNInputSimple/SSNInputSimple';
import AddressSearch from '../../../../components/AddressSearch/AddressSearch';

const PatientDemographics = () => {

    // <> Context
    const { addAlert, toggleAddEventPopup, setAddEventPopupState } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> Form
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [inEditMode, setInEditMode] = useState(false);
    const [patient, setPatient] = useState(null);
    const [carriers, setCarriers] = useState([]);

    // <> Location & State Variables Passed
    const location = useLocation();
    const { patientId, patientName } = location.state;

    // <> Effects
    useEffect(() => {
        getPatient();
        getData();
    }, []);

    // <> Actions
    async function getPatient() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Patient
            const patientResponse = await PatientService.findById(patientId);
            const patientsRes = patientResponse.data.data.patient;

            // -> Set State
            setPatient(patientsRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function getData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Carriers
            const carriersResponse = await MasterDataService.getTable(Enums.MasterData.Carrier);
            const carriersRes = carriersResponse.data.data.masterData;

            // -> Set State
            setCarriers(carriersRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function updateData(data) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // Update Params
            const selectedCarrier = carriers.find(c => c.id == data.carrierId);

            const params = { ...data };
            params.carrierCode = selectedCarrier.code;
            params.carrierName = selectedCarrier.name;
            
            // -> Update Patient
            await PatientService.updateOne(patientId, params);

            // -> Refresh
            setInEditMode(false);
            getPatient();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function deletePatient() {

        try {

            // -> Confirm
            if(!window.confirm('Are you sure you want to delete this patient?')) return;

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Delete Patient
            await PatientService.deleteOne(patientId);

            // -> Navigate Back to Patients Page
            history.push('/patients');
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function openAddEventPopup() {
        setAddEventPopupState({ patient });
        toggleAddEventPopup();
    }

    function goToPatientAuditTrail() {
        history.push('/history', { patientId, patientName });
    }

    function onAddressChoice(choice) {

        setValue('address1', choice.address1);
        // setValue('address2', choice.address2);
        setValue('city', choice.city);
        setValue('state', choice.state);
        setValue('zipcode', choice.zipCode);
    }

    // <> JSX
    return (
        <div className="patient-demographics">

            {/* Top */}
            <div className="patient-demographics__top">

                {/* Patient Name */}
                <div className="patient-demographics__top__title">Patient details for <span className="primary-color">{patientName}</span></div>

            </div>

            {/* Patient Demographics */}
            <div className="patient-demographics__container">

                {patient && <form onSubmit={handleSubmit(updateData)} className="form-sections">

                    <div className="form-sections__left">

                        <div className="form-sections__title">Details</div>

                        {/* First Name */}
                        <div className="form-field">
                            <div className="form-field__label">First Name</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.firstName}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.firstName}
                                className="form-field__input"
                                {...register('firstName', { required: 'first name required' })} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.firstName?.message}</div>
                        </div>

                        {/* Middle Name */}
                        <div className="form-field">
                            <div className="form-field__label">Middle Name</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.middleName}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.middleName}
                                className="form-field__input"
                                {...register('middleName', { required: 'middle name required' })} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.middleName?.message}</div>
                        </div>

                        {/* Last Name */}
                        <div className="form-field">
                            <div className="form-field__label">Last Name</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.lastName}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.lastName}
                                className="form-field__input"
                                {...register('lastName', { required: 'last name required' })} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.lastName?.message}</div>
                        </div>

                        {/* Patient DoB */}
                        <div className="form-field">
                            <div className="form-field__label">DoB</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.dateofbirth ? moment(patient.dateofbirth).format(Configs.formats.date) : 'N/A'}</div>
                            <input 
                                hidden={!inEditMode}
                                type="date"
                                defaultValue={moment(patient.dateofbirth).format(Configs.formats.isoDate)}
                                className="form-field__input"
                                {...register('dateofbirth')} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.dateofbirth?.message}</div>
                        </div>

                        {/* Patient Phone */}
                        <div className="form-field">
                            <div className="form-field__label">Phone Number</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.phone ? StringFormatter.formatPhone(patient.phone) : 'N/A'}</div>
                            {inEditMode && <PhoneNumberInput defaultValue={patient.phone} addClassName={'form-field__input'} extra={register('phone')} />}
                            <div hidden={!inEditMode} className="form-field__error">{errors?.phone?.message}</div>
                        </div>

                        {/* Patient Email */}
                        <div className="form-field">
                            <div className="form-field__label">Email</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.email ?? 'N/A'}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.email}
                                className="form-field__input"
                                {...register('email')} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.email?.message}</div>
                        </div>

                        {/* Patient Sex */}
                        <div className="form-field">
                            <div className="form-field__label">Sex</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.sex ?? 'N/A'}</div>
                            <select hidden={!inEditMode} className="form-field__input" defaultValue={patient.sex} {...register('sex')}>
                                <option value="male">male</option>
                                <option value="female">female</option>
                            </select>
                            <div hidden={!inEditMode} className="form-field__error">{errors?.sex?.message}</div>
                        </div>

                        {/* Patient SSN */}
                        <div className="form-field">
                            <div className="form-field__label">SSN</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.ssn ?? 'N/A'}</div>
                            {inEditMode && <SSNInputSimple extra={register('ssn')} defaultValue={patient.ssn} addClassName={'form-field__input'} />}
                            <div hidden={!inEditMode} className="form-field__error">{errors?.ssn?.message}</div>
                        </div>

                        {/* Created On */}
                        <div className="form-field">
                            <div className="form-field__label">Date Added</div>
                            <div className="form-field__value">{moment(patient.createdOn).format(Configs.formats.dateTime)}</div>
                        </div>


                    </div>


                    <div className="form-sections__right">

                        <div className="form-sections__title">Address</div>

                        {/* Address Lookup */}
                        {inEditMode && (
                            <div className="form-field">
                                <div className="form-field__label">Address Lookup</div>
                                <AddressSearch onChoice={onAddressChoice} />
                            </div>
                        )}

                        {/* Address 1 */}
                        <div className="form-field">
                            <div className="form-field__label">Address 1</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.address1 ?? 'N/A'}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.address1}
                                className="form-field__input"
                                {...register('address1')} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.address1?.message}</div>
                        </div>

                        {/* Address 2 */}
                        <div className="form-field">
                            <div className="form-field__label">Address 2 / Apartment / Suite Number</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.address2 ?? 'N/A'}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.address2}
                                className="form-field__input"
                                {...register('address2')} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.address2?.message}</div>
                        </div>

                        {/* City */}
                        <div className="form-field">
                            <div className="form-field__label">City</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.city ?? 'N/A'}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.city}
                                className="form-field__input"
                                {...register('city')} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.city?.message}</div>
                        </div>

                        {/* State */}
                        <div className="form-field">
                            <div className="form-field__label">State</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.state ?? 'N/A'}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.state}
                                className="form-field__input"
                                {...register('state')} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.state?.message}</div>
                        </div>

                        {/* Zip Code */}
                        <div className="form-field">
                            <div className="form-field__label">Zip Code</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.zipcode ?? 'N/A'}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.zipcode}
                                className="form-field__input"
                                {...register('zipcode')} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.zipcode?.message}</div>
                        </div>

                        <div className="form-sections__title">Insurance</div>

                        {/* Carrier Name */}
                        {patient && <div className="form-field">
                            <div className="form-field__label">Carrier</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.carrierName}</div>
                            <select 
                                hidden={!inEditMode}
                                defaultValue={patient.carrierId}
                                className="form-field__input"
                                {...register('carrierId', { required: 'carrier required' })}
                            >
                                {carriers.map((carrier, index) => (
                                    <option key={index} value={carrier.id}>{carrier.name}</option>
                                ))}
                            </select>
                            <div hidden={!inEditMode} className="form-field__error">{errors?.carrierId?.message}</div>
                        </div>}

                        {/* Subscriber ID */}
                        <div className="form-field">
                            <div className="form-field__label">Subscriber ID</div>
                            <div hidden={inEditMode} className="form-field__value">{patient.subscriberId}</div>
                            <input 
                                hidden={!inEditMode}
                                defaultValue={patient.subscriberId}
                                className="form-field__input"
                                {...register('subscriberId', { required: 'subscriber id required' })} />
                            <div hidden={!inEditMode} className="form-field__error">{errors?.subscriberId?.message}</div>
                        </div>

                        <div className="form-sections__flex">

                            {/* Group Name */}
                            <div className="form-field">
                                <div className="form-field__label">Group Name</div>
                                <div hidden={inEditMode} className="form-field__value">{patient.groupName}</div>
                                <input 
                                    hidden={!inEditMode}
                                    defaultValue={patient.groupName}
                                    className="form-field__input"
                                    {...register('groupName', { required: 'group name required' })} />
                                <div hidden={!inEditMode} className="form-field__error">{errors?.groupName?.message}</div>
                            </div>

                            {/* Group Number */}
                            <div className="form-field">
                                <div className="form-field__label">Group Number</div>
                                <div hidden={inEditMode} className="form-field__value">{patient.groupNumber}</div>
                                <input 
                                    hidden={!inEditMode}
                                    defaultValue={patient.groupNumber}
                                    className="form-field__input"
                                    {...register('groupNumber', { required: 'group number required' })} />
                                <div hidden={!inEditMode} className="form-field__error">{errors?.groupNumber?.message}</div>
                            </div>

                        </div>

                    </div>

                </form>}

            </div>

            {/* Actions */}
            {!isOnlyPhysician() && <div className="patient-demographics__actions">
                <div className="patient-demographics__actions__left">
                    <IconButton
                        text={'Patient Audit Trail'}
                        icon={faHistory}
                        btnStyle={'info'}
                        onClickFn={goToPatientAuditTrail} />
                    {!inEditMode  && <IconButton
                        text={'Edit'}
                        icon={faPencilAlt}
                        btnStyle={'danger'}
                        onClickFn={() => setInEditMode(true)} />}
                    {inEditMode && <IconButton
                        text={'Cancel'}
                        icon={faTimes}
                        btnStyle={'faded'}
                        onClickFn={() => setInEditMode(false)} />}
                    {inEditMode && <IconButton
                        text={'Save'}
                        icon={faCheck}
                        onClickFn={handleSubmit(updateData)} />}
                </div>

                <div className="patient-demographics__actions__right">
                    <IconButton
                        text={'Delete Patient'}
                        icon={faTrash}
                        btnStyle={'danger'}
                        onClickFn={deletePatient}
                        isLoading={isLoading} />
                    <IconButton
                        text={'Add Event'}
                        icon={faPlus}
                        btnStyle={'primary'}
                        onClickFn={openAddEventPopup} />
                </div>
            </div>}

            {/* Loader */}
            <Loader isLoading={isLoading} />

        </div>
    );
};

export default PatientDemographics;