import React from 'react';
import './NotFound.sass';

export default function Homepage() {

    return (
        <div className="not-found-page">
            <div className="not-found-page__container">
                <h1>Page Not Found</h1>
            </div>
        </div>
    )
}
