import axios from 'axios';
import Configs from '../config';


function addOne(data) {
    return axios.post(`${Configs.apiUrl}/events/diagnosis/addOne`, data);
}

function updateOne(data) {
    return axios.post(`${Configs.apiUrl}/events/diagnosis/updateOne`, data);
}

function get(eventId) {
    return axios.post(`${Configs.apiUrl}/events/diagnosis/get`, { eventId });
}

function deleteOne(eventDiagnosisId) {
    return axios.post(`${Configs.apiUrl}/events/diagnosis/deleteOne`, { eventDiagnosisId });
}


/**
 * Exports
 */
const EventDiagnosisService = {
    addOne,
    updateOne,
    get,
    deleteOne
}
export default EventDiagnosisService;