import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MiscContext } from '../../context/misc.context';
import { UserContext } from '../../context/user.context';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import './EventFilters.sass';
import * as Presenter from './EventFilters.presenter';
import Enums from '../../enums';
import Configs from '../../config.js';
import MasterDataService from '../../services/masterData.service';
import moment from 'moment';
import ManageUserService from '../../services/manageUser.service';
import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import IconButton from '../_controls/IconButton/IconButton';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import BasicMultiSelector from '../_controls/BasicMultiSelector/BasicMultiSelector';


const EventFilters = ({ defaults = null, onChangeFn = null, hideDatePicker = false }) => {

    // <> Contexts
    const { addAlert } = useContext(MiscContext);
    const { user, isOnlyPhysician } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [dateRanges, setDateRanges] = useState([{startDate: moment().toDate(), endDate: moment().toDate(), key: 'selection'}]);
    const [facilities, setFacilities] = useState(null);
    const [eventTypes, setEventTypes] = useState(null);
    const [physicians, setPhysicians] = useState(null);
    const [coordinators, setCoordinators] = useState(null);
    const [filters, setFilters] = useState(defaults);

    // <> Effects
    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if(filters && onChangeFn) onChangeFn(filters);
    }, [filters, onChangeFn])

    // <> Actions
    async function getData() {
        
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data
            const masterDataResponse = await MasterDataService.getTables([
                Enums.MasterData.Facility,
                Enums.MasterData.EventType,
            ]);

            // -> Extract Master Data
            const facilitiesRes = masterDataResponse.data.data[Enums.MasterData.Facility];
            const eventTypesRes = masterDataResponse.data.data[Enums.MasterData.EventType];

            // -> Get Physicians
            const physiciansResponse = await ManageUserService.getUsersWithRole(Configs.userRoles.PHYSICIAN);
            const physiciansRes = physiciansResponse.data.data.users;

            // -> Get Coordinators
            const coordinatorsResponse = await ManageUserService.getUsersWithRole(Configs.userRoles.COORDINATOR);
            const coordinatorsRes = coordinatorsResponse.data.data.users;

            // -> Get Admins
            const adminsResponse = await ManageUserService.getUsersWithRole(Configs.userRoles.ADMIN);
            const adminsRes = adminsResponse.data.data.users;

            // -> Set Options
            setFacilities(facilitiesRes);
            setEventTypes(eventTypesRes);
            setPhysicians(physiciansRes);
            setCoordinators([...coordinatorsRes, ...adminsRes]);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    
    // <> Helpers
    function dateRangeHandler(range) {

        let start = moment(range.selection.startDate);
        let end = moment(range.selection.endDate);
        if(start.isSame(end)) end = end.add(1, 'day');

        // Set States
        adjustFilters({ fromDate: start });
        adjustFilters({ toDate: end });
        setDateRanges([range.selection]);
    }

    function adjustFilters(newValues) {
        setFilters(prev => { return { ...prev, ...newValues } })
    }


    // <> JSX
    return (
        <div className="event-filters">

            {/* Filters */}
            {facilities && eventTypes && coordinators && physicians && <div className="event-filters__filters">

                {/* Date Range */}
                {!hideDatePicker && <div className="event-filters__filters__filter event-filters__filters__date-range-filter">
                    <div className="event-filters__filters__filter__label">Date Range</div>
                    <IconButton
                        text={isDatePickerOpen ? 'Close' : 'Open'}
                        icon={faCalendar}
                        size={'sm'}
                        onClickFn={() => setIsDatePickerOpen(prev => !prev)} />
                    {isDatePickerOpen && (
                        <div className="event-filters__filters__date-range-filter__wrapper">
                            <div className={`event-filters__filters__date-range-filter__wrapper__overlay event-filters__filters__date-range-filter__wrapper__overlay${isDatePickerOpen ? '--show' : '--hide'}`} onClick={() => setIsDatePickerOpen(false)}></div>
                            <div className="event-filters__filters__date-range-filter__wrapper__inner">
                                <div className="event-filters__filters__date-range-filter__wrapper__inner__holder">
                                    {window.screen.width > Configs.misc.mobileBreakpoint && <DateRangePicker
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        direction="horizontal"
                                        ranges={dateRanges}
                                        onChange={dateRangeHandler} />}
                                    {window.screen.width <= Configs.misc.mobileBreakpoint && <DateRange
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        ranges={dateRanges}
                                        onChange={dateRangeHandler} />}
                                </div>
                            </div>
                        </div>
                    )}
                </div>}

                {/* Physicians Filter */}
                <div className="event-filters__filters__filter">
                    <div className="event-filters__filters__filter__label">Physician</div>
                    <div className="event-filters__filters__filter__control">
                        <BasicMultiSelector
                            defaults={defaults?.physicianId}
                            options={Presenter.formatPhysiciansForSelector(physicians, user?.roles?.includes(Configs.userRoles.PHYSICIAN) ? user.userId : null)}
                            setter={val => adjustFilters({ physicianId: val })}
                            defaultOptionText={'-- All --'} />
                    </div>
                </div>

                {/* Facility Filter */}
                <div className="event-filters__filters__filter">
                    <div className="event-filters__filters__filter__label">Facility</div>
                    <div className="event-filters__filters__filter__control">
                        <BasicMultiSelector
                            defaults={defaults?.facilityId}
                            options={Presenter.formatFacilitiesForSelector(facilities)}
                            setter={val => adjustFilters({ facilityId: val })}
                            defaultOptionText={'-- All --'} />
                    </div>
                </div>

                {/* Coordinators Filter */}
                <div className="event-filters__filters__filter">
                    <div className="event-filters__filters__filter__label">Coordinator</div>
                    <div className="event-filters__filters__filter__control">
                        <BasicMultiSelector
                            defaults={defaults?.coordinatorId}
                            options={Presenter.formatCoordinatorsForSelector(coordinators)}
                            setter={val => adjustFilters({ coordinatorId: val })}
                            defaultOptionText={'-- All --'} />
                    </div>
                </div>

                {/* Status Filter */}
                <div hidden={isOnlyPhysician()} className="event-filters__filters__filter">
                    <div className="event-filters__filters__filter__label">Status</div>
                    <div className="event-filters__filters__filter__control">
                        <BasicMultiSelector
                            defaults={defaults?.status}
                            options={Presenter.statusesForSelector()}
                            setter={val => adjustFilters({ status: val })}
                            defaultOptionText={'-- All --'} />
                    </div>
                </div>

                {/* RTMU */}
                <div className="event-filters__filters__filter">
                    <div className="event-filters__filters__filter__label">RTMU</div>
                    <div className="event-filters__filters__filter__control">
                        <BasicMultiSelector
                            defaults={defaults?.isEmergency}
                            options={Presenter.rtmuForSelector()}
                            setter={val => adjustFilters({ isEmergency: val })}
                            defaultOptionText={'-- All --'} />
                    </div>
                </div>

                {/* Event Type Filter */}
                <div className="event-filters__filters__filter">
                    <div className="event-filters__filters__filter__label">Type</div>
                    <div className="event-filters__filters__filter__control">
                        <BasicMultiSelector
                            defaults={defaults?.eventTypeId}
                            options={Presenter.formatEventTypesForSelector(eventTypes)}
                            setter={val => adjustFilters({ eventTypeId: val })}
                            defaultOptionText={'-- All --'} />
                    </div>
                </div>


            </div>}

        </div>
    );
};

export default EventFilters;