import axios from 'axios';
import Configs from '../config';


function getTable(table) {
    return axios.post(`${Configs.apiUrl}/patients/data/get`, { table });
}

function getTables(tables) {
    return axios.post(`${Configs.apiUrl}/patients/data/getMany`, { tables });
}

function add(table, data) {
    return axios.post(`${Configs.apiUrl}/patients/data/add`, { table, data });
}

function updateCol(table, rowId, col, val) {
    return axios.put(`${Configs.apiUrl}/patients/data/updateCol`, { table, rowId, col, val });
}

function deleteRow(table, rowId) {
    return axios.delete(`${Configs.apiUrl}/patients/data/deleteRow/${table}/${rowId}`);
}


/**
 * Exports
 */
const PatientDataService = {
    getTable,
    getTables,
    add,
    updateCol,
    deleteRow
}
export default PatientDataService;