import React, { useContext } from 'react';
import EventsTable from '../../components/EventsTable/EventsTable';
import './Events.sass';
import Configs from '../../config.js';
import { UserContext } from '../../context/user.context';


const Events = () => {

    // <> Context
    const { user } = useContext(UserContext);


    // <> JSX
    return (
        <div className="events-page">

            {/* Page Title */}
            {/* <div className="events-page__top">
                <span className="events-page__top__title">Events</span>
                <span className="events-page__top__subtitle">View events.</span>
            </div> */}

            {/* Content */}
            <div className="events-page__content">

                {/* Events */}
                <EventsTable />

            </div>

        </div>
    );
};

export default Events;