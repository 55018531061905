import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './DropdownExternalSearch.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Configs from '../../../config.js';

/**
 * a dropdown search component, search functionality must be defined outside and passed into this component
 * @param {function} search function to call for searching for items, passes first param as the search query 
 * @param {[]} matches array of matches found after calling the search function 
 * @param {string} displayKey the key of the items used for display (eg 'name' if searched items should be displayed as their 'name' property) 
 * @param {function} setter the setter function that sets the selected value 
 * @param {*} value the selected value 
 * @param {function} reset a function to reset the matches array
 * @param {IconDefinition} icon the icon to display next to the selected item
 * @param {boolean} isLoading indicate whether the search function is currently running and fetching results or not
 */
const DropdownExternalSearch = forwardRef(({ search, matches, displayKey, setter, value, reset, icon, isLoading, isDisabled = false, onManualInputFn = null, hideSelection = false }, ref) => {

    // <> States
    const [query, setQuery] = useState('');
    const [hoveredAt, setHoveredAt] = useState(-1);
    const [manualValue, setManualValue] = useState(null);

    // <> Imperative Handler
    useImperativeHandle(ref, () => ({

        resetComponent() {
            setQuery('');
            setManualValue('');
            setHoveredAt(-1);
        }

    }))

    // <> Effects
    useEffect(() => {

        // Search Delay
        const delayDebounceFn = setTimeout(() => {
            if(query) search(query);
            else reset();
        }, Configs.misc.searchDelay)

        return () => clearTimeout(delayDebounceFn);
        
    }, [query]);

    // <> Actions
    function onItemClicked(itemValue) {
        setter(itemValue);
        setQuery('');
        reset();
        setHoveredAt(-1);
        setManualValue(null);
    }

    function onKeyPressed(ev) {

        // If Arrow Down, move matched item selection down
        if(ev.key == 'ArrowDown' && hoveredAt < matches.length - 1) setHoveredAt(prev => prev + 1);

        // If Arrow Down, move matched item selection up
        if(ev.key == 'ArrowUp' && hoveredAt > 0) setHoveredAt(prev => prev - 1);

        // If Enter, select a match
        if(ev.key == 'Enter') {
            ev.preventDefault();

            if(onManualInputFn && hoveredAt <= -1) {
                manualInput();
            }
            else if(matches.length > 0) {
                if(hoveredAt > -1) {
                    onItemClicked(matches[hoveredAt]);
                }
                else {
                    onItemClicked(matches[0]);
                }
            }
        }

    }

    function manualInput() {
        onManualInputFn(query);
        setQuery('');
        setHoveredAt(-1);
        setManualValue(query);
    }

    // <> JSX
    return (
        <div className={`dropdown-external-search ${hideSelection ? 'dropdown-external-search--hide-selection' : ''}`}>
            <div className="dropdown-external-search__input">
                <input 
                    className="dropdown-external-search__input__input" 
                    type="text" 
                    value={query} 
                    onChange={ev => setQuery(ev.target.value)} 
                    onKeyDown={onKeyPressed}
                    disabled={isDisabled}
                    placeholder="Search" />
                <FontAwesomeIcon className={`dropdown-external-search__input__icon${isLoading ? ' dropdown-external-search__input__icon--loading' : ''}`} icon={isLoading ? faSpinner : faSearch} />
                
                {/* Button for manual input in case enabled */}
                {onManualInputFn && (
                    <div className={`dropdown-external-search__input__manual-input-btn ${!query ? 'dropdown-external-search__input__manual-input-btn--disabled' : ''}`} onClick={manualInput}>
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                )}
            </div>
            <div className="dropdown-external-search__dropdown">
                {matches && matches.map((match, index) => (
                    <div key={index} className={`dropdown-external-search__dropdown__item ${hoveredAt == index ? 'dropdown-external-search__dropdown__item--highlight' : ''}`} onClick={() => onItemClicked(match)}>
                        {match[displayKey]}
                    </div>
                ))}
            </div>
            {!hideSelection && <div className={`dropdown-external-search__chosen ${isDisabled ? 'dropdown-external-search__chosen--disabled' : ''}`}>
                <FontAwesomeIcon className="dropdown-external-search__chosen__icon" icon={icon ?? faCheck} /> {manualValue || (value && value[displayKey] ? value[displayKey] : '')}
            </div>}
        </div>
    );
});

export default DropdownExternalSearch;