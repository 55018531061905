import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import './SideMenu.sass';
import logo from '../../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faThLarge, faCalendar, faUserMd, faProcedures, faUser, faDatabase, faSlidersH, faIdBadge, faChartArea, faUsers, faHistory, faPlus, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { MiscContext } from '../../context/misc.context';
import Configs from '../../config.js';
import IconButton from '../_controls/IconButton/IconButton';
import Enums from '../../enums';
import LOGO from '../../assets/logo.png';

export default function SideMenu() {
    
    // <> Contexts, History
    const { user } = useContext(UserContext);
    const { isMobileSideMenuOpen, closeSideMenu, toggleAddEventPopup, setPatientDataOpenedPopup, setPageTitle } = useContext(MiscContext);
    const history = useHistory();

    // <> States
    const [pathname, setPathname] = useState(history.location.pathname);
    const [isNavOpen, setIsNavOpen] = useState(true);

    // <> Effects
    useEffect(() => {

        const unregister = history.listen((location, action) => {
            setPathname(location.pathname);
        });

        return unregister;
    }, []);

    useEffect(() => {
        if(pathname == '/') setPageTitle('Overview');
        if(pathname.startsWith('/calendar')) setPageTitle('Calendar');
        if(pathname.startsWith('/event')) setPageTitle('Events');
        if(pathname.startsWith('/patient')) setPageTitle('Patients');
        if(pathname.startsWith('/statistics')) setPageTitle('Statistics');
        if(pathname.startsWith('/master-data')) setPageTitle('Master Files');
        if(pathname.startsWith('/history')) setPageTitle('Audit Trail');
        if(pathname.startsWith('/settings')) setPageTitle('Profile & Settings');
        
    }, [pathname])

    useEffect(() => {
        if(isNavOpen) {
            document.querySelector('#app-container').classList.remove('side-menu-collapsed');
        }
        else {
            document.querySelector('#app-container').classList.add('side-menu-collapsed');
        }
    }, [isNavOpen])

    // <> Actions
    function navItemClicked() {
        closeSideMenu();
    }

    // <> Helpers
    function openAddEventPopup() {
        toggleAddEventPopup();
    }

    function openAddPatientPopup() {
        setPatientDataOpenedPopup(Enums.AddPatientDetailsPopupTags.Patient);
    }
    
    // <> JSX
    return (
        <>
            <div className="side-menu-closer" onClick={closeSideMenu} style={isMobileSideMenuOpen ? {display: 'block'} : {display: 'none'}} />
            <div className={`side-menu ${!isNavOpen ? 'side-menu--collapsed' : ''}`} style={isMobileSideMenuOpen ? { transform: 'translateX(0)' } : {}}>

                {/* Collapse Btn */}
                <button className="side-menu__close-btn" onClick={() => setIsNavOpen(prev => !prev)}>
                    {isNavOpen && <FontAwesomeIcon icon={faChevronLeft} />}
                    {!isNavOpen && <FontAwesomeIcon icon={faChevronRight} />}
                </button>

                {/* Identity */}
                <div className="logo">
                    <img className="logo__image" src={LOGO} alt="logo" />
                    <span className="logo__text">GALEN</span>
                    <span className="logo__subtext">Surgical Scheduling System</span>
                </div>            

                <div className="nav">

                    {/* Management */}
                    <span className="nav__section-title">MANAGEMENT</span>
                    <Link onClick={navItemClicked} className={`nav__item${(pathname == '/' || pathname.startsWith('/event')) ? ' nav__item--selected' : ''}`} to="/">
                        <FontAwesomeIcon className="nav__item__icon" icon={faProcedures} />
                        <span className="nav__item__text">Events</span>
                        <div className="nav__item__action">
                            <IconButton
                                text={'New'}
                                icon={faPlus}
                                size={'sm'}
                                onClickFn={openAddEventPopup} />
                        </div>
                    </Link>
                    <Link onClick={navItemClicked} className={`nav__item${pathname.startsWith('/calendar') ? ' nav__item--selected' : ''}`} to="/calendar">
                        <FontAwesomeIcon className="nav__item__icon" icon={faCalendar} />
                        <span className="nav__item__text">Calendar</span>
                    </Link>
                    {/* <Link onClick={navItemClicked} className={`nav__item${pathname.startsWith('/physicians') ? ' nav__item--selected' : ''}`} to="/physicians">
                        <FontAwesomeIcon className="nav__item__icon" icon={faUserMd} />
                        <span className="nav__item__text">Physicians</span>
                    </Link> */}
                    <Link onClick={navItemClicked} className={`nav__item${pathname.startsWith('/patients') ? ' nav__item--selected' : ''}`} to="/patients">
                        <FontAwesomeIcon className="nav__item__icon" icon={faUser} />
                        <span className="nav__item__text">Patients</span>
                        <div className="nav__item__action">
                            <IconButton
                                text={'Add'}
                                icon={faPlus}
                                size={'sm'}
                                onClickFn={openAddPatientPopup} />
                        </div>
                    </Link>
                    <Link onClick={navItemClicked} className={`nav__item${pathname.startsWith('/statistics') ? ' nav__item--selected' : ''}`} to="/statistics">
                        <FontAwesomeIcon className="nav__item__icon" icon={faChartArea} />
                        <span className="nav__item__text">Analytics</span>
                    </Link>

                    {/* Account */}
                    {/* <span className="nav__section-title">ACCOUNT</span>
                    <Link onClick={navItemClicked} className={`nav__item${pathname.startsWith('/profile') ? ' nav__item--selected' : ''}`} to="/profile">
                        <FontAwesomeIcon className="nav__item__icon" icon={faIdBadge} />
                        <span className="nav__item__text">Profile</span>
                    </Link>
                    <Link onClick={navItemClicked} className={`nav__item${pathname.startsWith('/settings') ? ' nav__item--selected' : ''}`} to="/settings/general">
                        <FontAwesomeIcon className="nav__item__icon" icon={faSlidersH} />
                        <span className="nav__item__text">Settings</span>
                    </Link> */}

                    {/* Admin */}
                    {user && user?.roles?.includes(Configs.userRoles.ADMIN) && <>
                        <span className="nav__section-title">ADMIN</span>
                        <Link onClick={navItemClicked} className={`nav__item${pathname.startsWith('/master-data') ? ' nav__item--selected' : ''}`} to="/master-data/facilities">
                            <FontAwesomeIcon className="nav__item__icon" icon={faDatabase} />
                            <span className="nav__item__text">Master Files</span>
                        </Link>
                        {/* <Link onClick={navItemClicked} className={`nav__item${pathname.startsWith('/manage-users') ? ' nav__item--selected' : ''}`} to="/manage-users">
                            <FontAwesomeIcon className="nav__item__icon" icon={faUsers} />
                            <span className="nav__item__text">Manage Users</span>
                        </Link> */}
                        <Link onClick={navItemClicked} className={`nav__item${pathname.startsWith('/history') ? ' nav__item--selected' : ''}`} to="/history">
                            <FontAwesomeIcon className="nav__item__icon" icon={faHistory} />
                            <span className="nav__item__text">Audit Trail</span>
                        </Link>
                    </>}

                </div>
                
            </div>
        </>
    )
}
