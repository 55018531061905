import axios from 'axios';
import Configs from '../config';


function addOne(procedure) {
    return axios.post(`${Configs.apiUrl}/events/procedures/addOne`, procedure);
}

function updateOne(params) {
    return axios.post(`${Configs.apiUrl}/events/procedures/updateOne`, params);
}

function get(eventId) {
    return axios.post(`${Configs.apiUrl}/events/procedures/get`, { eventId });
}

function deleteOne(eventProcedureId) {
    return axios.post(`${Configs.apiUrl}/events/procedures/deleteOne`, { eventProcedureId });
}


/**
 * Exports
 */
const EventProcedureService = {
    addOne,
    updateOne,
    get,
    deleteOne
}
export default EventProcedureService;