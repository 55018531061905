import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import UserService from '../../services/user.service';
import BrowserStorage from '../../utils/browser.storage';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faLock, faEnvelope, faArrowRight, faUser } from '@fortawesome/free-solid-svg-icons'
import './Login.sass';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../context/misc.context';
import HttpErrors from '../../errors/HttpErrors';
import Enums from '../../enums';
import PULSE from '../../assets/pulse2.png';
import BG from '../../assets/login-bg.jpg';

export default function Login() {

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [savedEmail, setSavedEmail] = useState(BrowserStorage.get(Enums.BrowserStorage.LoginEmail.Key) || null);
    const [savedPass, setSavedPass] = useState(BrowserStorage.get(Enums.BrowserStorage.LoginPass.Key) || null);
    const [saved, setSaved] = useState(!!BrowserStorage.get(Enums.BrowserStorage.LoginEmail.Key));

    // <> Context
    const { setUser } = useContext(UserContext);
    const { addAlert } = useContext(MiscContext);

    // <> Form, History
    const { handleSubmit, register, formState: { errors } } = useForm();
    const history = useHistory();
    
    // <> Form Submit Handler
    const onSubmit = async values => {

        // -> Activate Loader
        setIsLoading(true);
        setError('');

        try {

            // -> Remember Me
            if(values.remember) {
                BrowserStorage.set(Enums.BrowserStorage.LoginEmail.Key, values.email);
                BrowserStorage.set(Enums.BrowserStorage.LoginPass.Key, values.password);
            }
            else {
                BrowserStorage.clear(Enums.BrowserStorage.LoginEmail.Key);
                BrowserStorage.clear(Enums.BrowserStorage.LoginPass.Key);
            }

            // -> Login
            const response = await UserService.login(values.email, values.password);

            // -> Extract Tokens
            const accessToken = response.data.data.accessToken;
            const refreshToken = response.data.data.refreshToken;

            // -> Store Tokens in Browser
            BrowserStorage.set(Enums.BrowserStorage.AccessToken.Key, accessToken);
            BrowserStorage.set(Enums.BrowserStorage.RefreshToken.Key, refreshToken);
    
            // -> Get logged in User Data
            const userData = (await UserService.getUserData()).data.data;

            // -> Set User Data to Context
            setUser({
                loggedIn: true,
                userId: userData.user.id,
                roles: userData.userRoles,
                ...userData.user
            });

            // -> Navigate to Startup Page or Homepage
            const startupPageLink = BrowserStorage.get(Enums.BrowserStorage.StartupPage.Key);
            if(startupPageLink) {
                history.push(startupPageLink);
            }
            else {
                history.push('/');
            }
            
        }
        catch(e) {

            if(e instanceof HttpErrors.HttpResponseError) {
                // setError(e.originalError.response.data.message);
                setError(e.message || 'Incorrect email or password');
            }
            else {
                GlobalHttpErrorHandler(e, history, addAlert);
                setError(e.response.data.message);
            }
        }
        finally {

            // -> Deactivate Loader
            setIsLoading(false);
        }
    }

    // <> JSX
    return (<>
        <div className="login-page" style={{backgroundImage: `url(${BG})`}}>
            <div className="login-page__bg-grad"></div>
            <div className="login-page__container">
                
                {/* Left */}
                <div className="login-page__container__left">
                    <div className="login-page__container__left__row">
                        <div className="login-page__container__left__title">GALEN</div>
                        {/* <img src={PULSE} className="login-page__container__left__pulse" alt="pulse" /> */}
                    </div>
                    <div className="login-page__container__left__dash"></div>
                    <div className="login-page__container__left__subtitle">Surgical Scheduling System</div>
                    <div className="login-page__container__left__dots">
                        {[...Array(9 * 3)].map((el, i) => <div key={i}></div>)}
                    </div>
                </div>

                {/* Right */}
                <div className="login-page__container__right">
                    <span className="login-page__container__right__title"></span>
                    <form onSubmit={handleSubmit(onSubmit)} className="login-form">

                        {/* Email */}
                        <div className="login-page__container__right__form__field">
                            <FontAwesomeIcon className="login-page__container__right__form__field__icon" icon={faUser} />
                            <input
                                className="login-page__container__right__form__field__input"
                                placeholder="Email"
                                defaultValue={savedEmail}
                                {...register('email', {
                                    required: "email required",
                                    minLength: 3,
                                    type: 'email'
                                })}
                            />
                        </div>
                        {errors.email && errors.email.message && <span className="error-msg">{errors.email.message}</span>}
                        {errors.email && errors.email.type == "minLength" && <span className="error-msg">email must be valid</span>}

                        {/* Password */}
                        <div className="login-page__container__right__form__field">
                            <FontAwesomeIcon className="login-page__container__right__form__field__icon" icon={faLock} />
                            <input
                                className="login-page__container__right__form__field__input"
                                type="password"
                                placeholder="Password"
                                autocomplete="off"
                                defaultValue={savedPass}
                                {...register('password', {
                                    required: "password required",
                                    minLength: 3,
                                    type: "password" 
                                })}
                            />
                        </div>
                        {errors.password && errors.password.message && <span className="error-msg">{errors.password.message}</span>}
                        {errors.password && errors.password.type == "minLength" && <span className="error-msg">password must be at least 3 characters</span>}

                        <div className="login-page__container__right__form__misc">
                            <div className="login-page__container__right__form__misc__remember">
                                <label><input type="checkbox" defaultChecked={saved} {...register('remember')} /> Remember me</label>
                            </div>
                            <div className="login-page__container__right__form__misc__forgot">
                                Forgot password?
                            </div>
                        </div>

                        {error && <span className="error-msg">{error}</span>}
                        <button 
                            type="submit" 
                            disabled={isLoading} 
                            className="login-page__container__right__form__submit-btn">
                                {isLoading && <FontAwesomeIcon icon={faSpinner} className="spin" />}
                                <span>Sign In</span> 
                        </button>
                    </form>
                </div>

            </div>
        </div>
    </>)
}
