import moment from 'moment';


function presentDayEvents(events) {
    return events.map(event => { 

        event.dateFrom = moment(event.dateFrom);
        event.dateTo = moment(event.dateTo);

        return event;
    });
}

/**
 * Exports
 */
export {
    presentDayEvents
}