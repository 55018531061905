/**
 * @description generates PDF documents
 */


import moment from 'moment';
import { ColorTypes, degrees, PDFDocument, rgb, StandardFonts, font } from 'pdf-lib';
import MacombOaklandENDO from '../assets/pdf/BLANK ENDO Boarding Form (Macomb and Oakland).pdf';
import MacombOaklandOR from '../assets/pdf/BLANK OR Surgical Boarding Form (Macomb and Oakland).pdf';
import AscensionMorris from '../assets/pdf/ENDO or Surgical ( Ascension Moross ) Boarding Form .pdf';
import StJohn from '../assets/pdf/SJ-Main Blank Fillable Boarding Slip.pdf';
import Clearance from '../assets/pdf/Clearance Form.pdf';
import Enums from '../enums';
import StringFormatter from '../utils/string.formats';


async function GeneratePdf(eventDetails, type) {

    switch(type) {
        case Enums.PdfTypes.MacombOaklandENDO.id:
            return generateMacombOaklandENDO(eventDetails);
        case Enums.PdfTypes.MacombOaklandOR.id:
            return generateMacombOaklandOR(eventDetails);
        case Enums.PdfTypes.StJohn.id:
            return generateStJohn(eventDetails);
        case Enums.PdfTypes.AscensionMorris.id:
            return generateAscensionMorris(eventDetails);
        default:
            break;
    }
}


async function generateStJohn(eventDetails) {

    // -> Load
    const existingPdfBytes = await fetch(StJohn).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // -> Get Form
    const form = pdfDoc.getForm();

    // -> Fill Fields
    form.getTextField('Primary Surgeons').setText(eventDetails.physicianName);
    form.getTextField('Surgeon Phone').setText(eventDetails.physicianPhone);
    form.getTextField('Surgeon Fax').setText(eventDetails.physicianFax);
    form.getTextField('Scheduled by').setText(eventDetails.coordinatorName);
    form.getTextField('Birth Date').setText(moment(eventDetails.patientDoB).format('MM/DD/YYYY'));
    form.getTextField('Surgeon Time Requested').setText(StringFormatter.timeDifference(eventDetails.dateFrom, eventDetails.dateTo));
    form.getTextField('Surgery Date').setText(moment(eventDetails.dateFrom).format('MM/DD/YYYY'));
    form.getTextField('Surgery Time Requested').setText(moment(eventDetails.dateFrom).format('hh:mm a'));
    form.getTextField('Social Security Number').setText('XXX-XX-' + eventDetails.patientDetails?.ssn?.substr(-4));
    if(moment(eventDetails.dateFrom).isoWeekday() == 1) form.getCheckBox('M').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 2) form.getCheckBox('T').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 3) form.getCheckBox('W').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 4) form.getCheckBox('Th').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 5) form.getCheckBox('F').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 6) form.getCheckBox('S').check();
    form.getTextField('Patient Name').setText(`${eventDetails.patientLastName} / ${eventDetails.patientFirstName}`);
    if(eventDetails.patientGender == 'male') form.getRadioGroup('Gender').select('M_2');
    else form.getRadioGroup('Gender').select('F_2');
    if(eventDetails.medicalClearanceStatus != Enums.ClearanceStatuses.NotRequired) {
        form.getRadioGroup('Medical Clearance Needed').select('Yes');
        form.getTextField('Physician').setText(eventDetails.medicalClearancePhysician);
    }
    else form.getRadioGroup('Medical Clearance Needed').select('No');
    form.getTextField('Address  City State  Zip').setText(`${eventDetails.patientDetails.address2}/${eventDetails.patientDetails.city}/${eventDetails.patientDetails.state}/${eventDetails.patientDetails.zipcode}`);
    form.getTextField('Primary Secondary Insurance with Contract').setText(eventDetails.patientDetails.carrierName);
    if(eventDetails.diagnosis[0]) form.getTextField('Diagnosis No Abbreviations 1').setText(eventDetails.diagnosis[0].diagnosisDescription);
    if(eventDetails.diagnosis[1]) form.getTextField('Diagnosis No Abbreviations 2').setText(eventDetails.diagnosis[1].diagnosisDescription);
    if(eventDetails.diagnosis[0] || eventDetails.diagnosis[1]) {
        const icd9 = [];
        if(eventDetails.diagnosis[0]) icd9.push(eventDetails.diagnosis[0].diagnosisCode);
        if(eventDetails.diagnosis[1]) icd9.push(eventDetails.diagnosis[1].diagnosisCode);
        form.getTextField('ICD 9').setText(`(${icd9.join(' & ')})`);
    }
    if(eventDetails.procedures[0]) form.getTextField('Procedures No Abbreviations be specific as this will be used to generate the wording on the Surgical Consent Form 1').setText(eventDetails.procedures[0].procedureName);
    if(eventDetails.procedures[1]) form.getTextField('Procedures No Abbreviations be specific as this will be used to generate the wording on the Surgical Consent Form 2').setText(eventDetails.procedures[1].procedureName);
    if(eventDetails.procedures[2]) form.getTextField('Procedures No Abbreviations be specific as this will be used to generate the wording on the Surgical Consent Form 3').setText(eventDetails.procedures[2].procedureName);
    if(eventDetails.procedures[3]) form.getTextField('Procedures No Abbreviations be specific as this will be used to generate the wording on the Surgical Consent Form 4').setText(eventDetails.procedures[3].procedureName);
    if(eventDetails.procedures[4]) form.getTextField('Procedures No Abbreviations be specific as this will be used to generate the wording on the Surgical Consent Form 5').setText(eventDetails.procedures[4].procedureName);

    // -> Save
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
}

async function generateAscensionMorris(eventDetails) {

    // -> Load
    const existingPdfBytes = await fetch(AscensionMorris).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // -> Get Form
    const form = pdfDoc.getForm();

    // -> Fill Fields
    form.getTextField('Primary Surgeons').setText(eventDetails.physicianName);
    form.getTextField('Surgeon Phone').setText(eventDetails.physicianPhone);
    form.getTextField('Surgeon Fax').setText(eventDetails.physicianFax);
    form.getTextField('Scheduled by').setText(eventDetails.coordinatorName);
    form.getTextField('Birth Date').setText(moment(eventDetails.patientDoB).format('MM/DD/YYYY'));
    form.getTextField('Procedure Duration').setText(StringFormatter.timeDifference(eventDetails.dateFrom, eventDetails.dateTo));
    form.getTextField('Surgery Date').setText(moment(eventDetails.dateFrom).format('MM/DD/YYYY'));
    form.getTextField('Surgery Time Requested').setText(moment(eventDetails.dateFrom).format('hh:mm a'));
    form.getTextField('Social Security Number').setText('XXX-XX-' + eventDetails.patientDetails?.ssn?.substr(-4));
    if(moment(eventDetails.dateFrom).isoWeekday() == 1) form.getCheckBox('M').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 2) form.getCheckBox('T').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 3) form.getCheckBox('W').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 4) form.getCheckBox('Th').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 5) form.getCheckBox('F').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 6) form.getCheckBox('S').check();
    form.getTextField('Patient Name').setText(`${eventDetails.patientLastName} / ${eventDetails.patientFirstName}`);
    if(eventDetails.patientGender == 'male') form.getRadioGroup('Gender').select('M_2');
    else form.getRadioGroup('Gender').select('F_2');
    if(eventDetails.medicalClearanceStatus != Enums.ClearanceStatuses.NotRequired) {
        form.getRadioGroup('Medical Clearance Needed').select('Yes');
        form.getTextField('Physician').setText(eventDetails.medicalClearancePhysician);
    }
    else form.getRadioGroup('Medical Clearance Needed').select('No');
    form.getTextField('Address  City State  Zip').setText(`${eventDetails.patientDetails.address2}/${eventDetails.patientDetails.city}/${eventDetails.patientDetails.state}/${eventDetails.patientDetails.zipcode}`);
    form.getTextField('Primary Secondary Insurance with Contract').setText(eventDetails.patientDetails.carrierName);
    if(eventDetails.diagnosis[0]) form.getTextField('Diagnosis No Abbreviations 1').setText(eventDetails.diagnosis[0].diagnosisDescription);
    if(eventDetails.diagnosis[1]) form.getTextField('Diagnosis No Abbreviations 2').setText(eventDetails.diagnosis[1].diagnosisDescription);
    if(eventDetails.diagnosis[0] || eventDetails.diagnosis[1]) {
        const icd9 = [];
        if(eventDetails.diagnosis[0]) icd9.push(eventDetails.diagnosis[0].diagnosisCode);
        if(eventDetails.diagnosis[1]) icd9.push(eventDetails.diagnosis[1].diagnosisCode);
        form.getTextField('ICD 9').setText(`(${icd9.join(' & ')})`);
    }
    if(eventDetails.procedures[0]) form.getTextField('Procedures No Abbreviations be specific as this will be used to generate the wording on the Surgical Consent Form 1').setText(eventDetails.procedures[0].procedureName);
    if(eventDetails.procedures[1]) form.getTextField('Procedures No Abbreviations be specific as this will be used to generate the wording on the Surgical Consent Form 2').setText(eventDetails.procedures[1].procedureName);
    
    // -> Save
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
}


async function generateMacombOaklandENDO(eventDetails) {

    // -> Load
    const existingPdfBytes = await fetch(MacombOaklandENDO).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // -> Get Form
    const form = pdfDoc.getForm();

    // -> Fill Fields
    form.getTextField('Primary EndoscopistSurgeons').setText(eventDetails.physicianName);
    form.getTextField('Submitted by').setText(eventDetails.coordinatorName);
    form.getTextField('Birth Date').setText(moment(eventDetails.patientDoB).format('MM'));
    form.getTextField('undefined_2').setText(moment(eventDetails.patientDoB).format('DD'));
    form.getTextField('undefined_3').setText(moment(eventDetails.patientDoB).format('YYYY'));
    form.getTextField('Surgery Time Requested').setText(StringFormatter.timeDifference(eventDetails.dateFrom, eventDetails.dateTo));
    form.getTextField('Surgery Date').setText(moment(eventDetails.dateFrom).format('MM'));
    form.getTextField('undefined_4').setText(moment(eventDetails.dateFrom).format('DD'));
    form.getTextField('undefined_5').setText(moment(eventDetails.dateFrom).format('YYYY'));
    form.getTextField('Last 4 of SS').setText(eventDetails.patientDetails?.ssn?.substr(-4));
    form.getTextField('Time Needed minutes').setText(moment(eventDetails.dateTo).diff(moment(eventDetails.dateFrom), 'minutes').toString());
    if(moment(eventDetails.dateFrom).isoWeekday() == 1) form.getCheckBox('6').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 2) form.getCheckBox('7').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 3) form.getCheckBox('8').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 4) form.getCheckBox('9').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 5) form.getCheckBox('10').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 6) form.getCheckBox('11').check();
    form.getTextField('Name').setText(`${eventDetails.patientLastName}, ${eventDetails.patientFirstName}, ${eventDetails.patientMiddleName}`);
    if(eventDetails.patientGender == 'male') form.getCheckBox('12').check();
    else form.getCheckBox('13').check();
    form.getTextField('Street Address').setText(eventDetails.patientDetails.address2);
    form.getTextField('City').setText(eventDetails.patientDetails.city);
    form.getTextField('State').setText(eventDetails.patientDetails.state);
    form.getTextField('Zip').setText(eventDetails.patientDetails.zipcode);
    form.getTextField('Email').setText(eventDetails.patientDetails.email);
    form.getTextField('Primary').setText(eventDetails.patientDetails.carrierName);
    form.getTextField('Group').setText(eventDetails.patientDetails.groupName);
    if(eventDetails.diagnosis[0]) form.getTextField('Admitting Diagnosis 1').setText(eventDetails.diagnosis[0].diagnosisDescription);
    if(eventDetails.diagnosis[0]) form.getTextField('ICD10').setText(eventDetails.diagnosis[0].diagnosisCode);
    if(eventDetails.diagnosis[1]) form.getTextField('Admitting Diagnosis 2').setText(eventDetails.diagnosis[1].diagnosisDescription);
    if(eventDetails.diagnosis[1]) form.getTextField('ICD10_2').setText(eventDetails.diagnosis[1].diagnosisCode);
    if(eventDetails.medicalClearanceStatus != Enums.ClearanceStatuses.NotRequired) {
        form.getCheckBox('48').check();
        form.getTextField('No  Physician').setText(eventDetails.medicalClearancePhysician);
    }
    else form.getCheckBox('49').check();

    // -> Save
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
}


async function generateMacombOaklandOR(eventDetails) {

    // -> Load
    const existingPdfBytes = await fetch(MacombOaklandOR).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // -> Get Form
    const form = pdfDoc.getForm();

    // -> Fill Fields
    form.getTextField('Primary Surgeons').setText(eventDetails.physicianName);
    form.getTextField('Scheduler').setText(eventDetails.coordinatorName);
    form.getTextField('Birth Date').setText(moment(eventDetails.patientDoB).format('MM'));
    form.getTextField('undefined_3').setText(moment(eventDetails.patientDoB).format('DD'));
    form.getTextField('undefined_4').setText(moment(eventDetails.patientDoB).format('YYYY'));
    form.getTextField('Surgery Time Requested 1').setText(StringFormatter.timeDifference(eventDetails.dateFrom, eventDetails.dateTo));
    form.getTextField('Surgery Date').setText(moment(eventDetails.dateFrom).format('MM'));
    form.getTextField('undefined').setText(moment(eventDetails.dateFrom).format('DD'));
    form.getTextField('undefined_2').setText(moment(eventDetails.dateFrom).format('YYYY'));
    form.getTextField('SS').setText(eventDetails.patientDetails?.ssn);
    form.getTextField('Time Needed min').setText(moment(eventDetails.dateTo).diff(moment(eventDetails.dateFrom), 'minutes').toString());
    if(moment(eventDetails.dateFrom).isoWeekday() == 1) form.getCheckBox('Check Box3').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 2) form.getCheckBox('Check Box4').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 3) form.getCheckBox('Check Box5').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 4) form.getCheckBox('Check Box6').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 5) form.getCheckBox('Check Box7').check();
    if(moment(eventDetails.dateFrom).isoWeekday() == 6) form.getCheckBox('Check Box8').check();
    form.getTextField('Surgery Time Requested 2').setText(`${eventDetails.patientLastName}, ${eventDetails.patientFirstName}, ${eventDetails.patientMiddleName}`);
    if(eventDetails.patientGender == 'male') form.getCheckBox('Check Box9').check();
    else form.getCheckBox('Check Box10').check();
    form.getTextField('Street Address').setText(eventDetails.patientDetails.address2);
    form.getTextField('City').setText(eventDetails.patientDetails.city);
    form.getTextField('State').setText(eventDetails.patientDetails.state);
    form.getTextField('Zip').setText(eventDetails.patientDetails.zipcode);
    form.getTextField('Primary  Secondary Insurance').setText(eventDetails.patientDetails.carrierName);
    if(eventDetails.diagnosis[0]) form.getTextField('Admitting Diagnosis').setText(eventDetails.diagnosis[0].diagnosisDescription);
    if(eventDetails.procedures[0]) form.getTextField('Procedures NO ABBREVIATIONS   Check here if any of the procedures requested is SELFPAY or COSMETIC 1').setText(eventDetails.procedures[0].procedureName);
    if(eventDetails.procedures[1]) form.getTextField('Procedures NO ABBREVIATIONS   Check here if any of the procedures requested is SELFPAY or COSMETIC 2').setText(eventDetails.procedures[1].procedureName);
    if(eventDetails.procedures[2]) form.getTextField('Procedures NO ABBREVIATIONS   Check here if any of the procedures requested is SELFPAY or COSMETIC 3').setText(eventDetails.procedures[2].procedureName);

    // -> Save
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
}


async function generateClearance({ physician, patient, dateFrom, facility, note }) {

    // -> Load
    const existingPdfBytes = await fetch(Clearance).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();

    // Physician Name
    firstPage.drawText(physician, {
        x: 170,
        y: height / 2 + 287,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0)
    })

    // Patient Name
    firstPage.drawText(patient, {
        x: 372,
        y: height - 208,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0)
    })

    // Facility Name Cover
    const rectHeight = 90;
    const rectWidth = 500;
    firstPage.drawRectangle({
        x: 0,
        y: height - rectHeight,
        width: rectWidth,
        height: rectHeight,
        color: rgb(1, 1, 1)
    })

    // Facility Name
    firstPage.drawText(facility, {
        x: 45,
        y: height - 55,
        size: 20,
        font: helveticaFont,
        color: rgb(0, 0, 0)
    })

    
    // Note
    if(note) {
        const rect2Width = 580;
        const rect2YOffset = 510;

        const lines = [];
        let line = '';
        const chars = note.split(' ');
        for(let i = 0; i < chars.length; i++) {
            line += chars[i] + ' ';
            if(lines.length == 0) {
                console.log(line)
                if(helveticaFont.widthOfTextAtSize(line, 10) > (rect2Width - 300)) {
                    lines.push(line);
                    line = '';
                }
            }
            else {

                if(helveticaFont.widthOfTextAtSize(line, 10) > (rect2Width - 100)) {
                    lines.push(line);
                    line = '';
                }
            }
        }
        if(line) lines.push(line);
        console.log(lines);
        const firstLine = lines[0];
        const formattedNote = lines.splice(1).join('\n');
        firstPage.drawText(firstLine, {
            x: 200,
            y: height - 15 - rect2YOffset,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            lineHeight: 12
        })
        firstPage.drawText(formattedNote, {
            x: 45,
            y: height - 29 - rect2YOffset,
            size: 10,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            lineHeight: 14,
            
        })
    }

    // -> Save
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
}


function getBlobObjectUrl(bytes) {

    const blob = new Blob([bytes], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
}


/**
 * Exports
 */
export default GeneratePdf;
export {
    getBlobObjectUrl,
    generateClearance
}
