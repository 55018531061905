import axios from 'axios';
import Configs from '../config';


function addOne({ eventId, clearanceId, clearanceName, clearanceDescription, physicianName, assigneeId, dueDate, status }) {
    return axios.post(`${Configs.apiUrl}/events/clearances/addOne`, { eventId, clearanceId, clearanceName, clearanceDescription, physicianName, assigneeId, dueDate, status });
}

function updateOne({ id, eventId, status, rejectionReason, physicianName, clearanceDescription, statusUpdatedOn, statusUpdaterId }) {
    return axios.put(`${Configs.apiUrl}/events/clearances/updateOne`, { id, eventId, status, rejectionReason, physicianName, clearanceDescription, statusUpdatedOn, statusUpdaterId });
}

function get(eventId) {
    return axios.post(`${Configs.apiUrl}/events/clearances/get`, { eventId });
}

function deleteOne(id) {
    return axios.post(`${Configs.apiUrl}/events/clearances/deleteOne`, { id });
}


/**
 * Exports
 */
const EventClearanceService = {
    addOne,
    updateOne,
    get,
    deleteOne
}
export default EventClearanceService;