import React from 'react';
import './Label.sass';

const Label = ({ label, message, labelStyle = 'primary', size = 'md' }) => {
    return (
        <div className={`styled-label styled-label--${labelStyle} styled-label--${size}`}>
            <div className="styled-label__label">{label}</div>
            <div className="styled-label__content">{message}</div>
        </div>
    );
};

export default Label;