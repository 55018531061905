const Enums = {
    BrowserStorage: {
        PrefersDarkTheme: {
            Key: 'prefers-dark-theme',
            Values: {
                True: 'yes',
                False: 'no'
            }
        },
        AccessToken: {
            Key: 'access-token'
        },
        RefreshToken: {
            Key: 'refresh-token'
        },
        StartupPage: {
            Key: 'startup-page'
        },
        EventFilters: {
            Key: 'event-filters'
        },
        SelectorSearchHybridMode: {
            Key: 'selector-search-hybrid-mode'
        },
        LoginEmail: {
            Key: 'login-email'
        },
        LoginPass: {
            Key: 'login-pass'
        },
        CalendarIsWeekMode: {
            Key: 'calendar-is-week-mode',
            Values: {
                True: 'yes',
                False: 'no'
            }
        },
        DayEventsIsCompactMode: {
            Key: 'day-events-is-compact-mode',
            Values: {
                True: 'yes',
                False: 'no'
            }
        }
    },
    Themes: {
        Dark: ''
    },
    HttpCodes: {
        BadRequest: 400,
        Unauthorized: 401,
        Forbidden: 403,
        Invalid: 420,
        InvalidRefreshToken: 477,
        InternalServerError: 500
    },
    AddPatientDetailsPopupTags: {
        Patient: 'patient',
        Demographic: 'demographic',
        Allergy: 'allergy'
    },
    AddMasterDataPopupTags: {
        Facility: 'facility',
        FacilityRoom: 'facility_room',
        EventType: 'event_type',
        BloodThinner: 'blood_thinner',
        Diagnosis: 'diagnosis',
        Priority: 'priority',
        Procedures: 'procedures',
        Carrier: 'carrier',
        Clearance: 'clearance',
        PatientCommunication: 'patient_communication',
        BoardingLink: 'boarding_link'
    },
    MasterData: {
        Facility: 'facility',
        FacilityRoom: 'facility_room',
        EventType: 'event_type',
        BloodThinner: 'blood_thinner',
        Diagnosis: 'diagnosis',
        Priority: 'priority',
        Procedures: 'procedures',
        Carrier: 'carrier',
        Clearance: 'clearance',
        PatientCommunication: 'patient_communication',
        BoardingLink: 'boarding_link'
    },
    PatientData: {
        Allergies: 'patient_allergy',
        SmokingStatuses: 'patient_smoking_status',
        Medications: 'patient_medication',
        ImplantedDevices: 'patient_implanted_device',
        Immunizations: 'patient_immunization',
        Goals: 'patient_goal',
        Assessments: 'patient_assessment',
        HealthConcerns: 'patient_health_concern',
    },
    HistoryEvents: {
        AddMasterData: 'AddMasterData',
        DeleteMasterData: 'DeleteMasterData',
        AddEvent: 'AddEvent',
        AddEventClearance: 'AddEventClearance',
        UpdateEvent: 'UpdateEvent',
        CancelEvent: 'CancelEvent',
        UpdateEventClearance: 'UpdateEventClearance',
        UpdateEventInsuranceAuthorization: 'UpdateEventInsuranceAuthorization',
        DeleteEvent: 'DeleteEvent',
        DeleteEventClearance: 'DeleteEventClearance',
        CodeEvent: 'CodeEvent',
        RescheduleEvent: 'RescheduleEvent',
        AddPatient: 'AddPatient',
        UpdatePatient: 'UpdatePatient',
        DeletePatient: 'DeletePatient',
        UpdateEventExtraClearance: 'UpdateEventExtraClearance'
    },
    EventStatuses: {
        Pending: 'pending',
        Draft: 'draft',
        BoardingSlipGenerated: 'boarding_slip_generated',
        WaitingForConfirmation: 'waiting_for_confirmation',
        Confirmed: 'confirmed',
        Rejected: 'rejected',
        Completed: 'completed',
        Cancelled: 'cancelled',
    },
    ClearanceStatuses: {
        NotRequired: 'not_required',
        Pending: 'pending',
        Requested: 'requested',
        Accepted: 'accepted',
        Rejected: 'rejected'
    },
    PopupTags: {
        AddChecklistItem: 'AddChecklistItem',
        Confirmation: 'Confirmation',
        Prompt: 'Prompt',
        AddPatientAllergy: 'AddPatientAllergy',
        AddPatientSmokingStatus: 'AddPatientSmokingStatus',
        AddPatientMedication: 'AddPatientMedication',
        AddPatientImplantedDevice: 'AddPatientImplantedDevice',
        AddPatientImmunization: 'AddPatientImmunization',
        AddPatientGoal: 'AddPatientGoal',
        AddPatientAssessment: 'AddPatientAssessment',
        AddPatientHealthConcern: 'AddPatientHealthConcern',
        CodeEvent: 'CodeEvent',
        RescheduleEvent: 'RescheduleEvent',
        AddEventClearance: 'AddEventClearance',
        EventConfirmWithReschedule: 'EventConfirmWithReschedule',
        AddEventPatientCommunication: 'AddEventPatientCommunication',
        ChangePatientCommunicationFile: 'ChangePatientCommunicationFile',
        SendPatientCommunications: 'SendPatientCommunications',
        AddEventBloodThinner: 'AddEventBloodThinner',
    },
    ChecklistItemStatuses: {
        TBD: 'tbd',
        Done: 'done',
        NA: 'na'
    },
    InsuranceAuthorizationStatuses: {
        NotRequired: 'not-required',
        TBD: 'tbd',
        Approved: 'approved',
        Requested: 'requested',
        Rejected: 'rejected'
    },
    SelectorSearchModes: {
        Select: 'selector',
        Search: 'search'
    },
    EventDetailsPageSections: {
        General: 'general',
        Procedures: 'procedures',
        Clearances: 'clearances',
        PatientCommunications: 'patient-communications',
        Notes: 'notes'
    },
    PhysicianEventStatusVisibility : ['confirmed', 'completed', 'cancelled'],
    PdfTypes: {
        MacombOaklandENDO: {
            id: 'MacombOaklandENDO',
            label: 'Macomb Oakland ENDO'
        },
        MacombOaklandOR: {
            id: 'MacombOaklandOR',
            label: 'Macomb Oakland OR'
        },
        AscensionMorris: {
            id: 'AscensionMorris',
            label: 'Ascension Morris'
        },
        StJohn: {
            id: 'StJohn',
            label: 'St John'
        }
    }
}

export default Enums;