import React, { useState } from 'react';
import './Toggle.sass';

const Toggle = ({ checked = false, setChecked }) => {

    // <> Helpers
    function clickHandler() {
        setChecked(!checked);
    }

    // <> JSX
    return (
        <div className={`toggle ${checked ? 'toggle--on' : 'toggle--off'}`} onClick={clickHandler}>
            <div className="toggle__bar"></div>
            <div className="toggle__circle"></div>
        </div>
    );
};

export default Toggle;