import React, { useContext, useEffect } from 'react';
import { MiscContext } from '../../context/misc.context';
import './Alerts.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faTimes, faInfoCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const Alerts = () => {

    // <> Context
    const { alerts, removeAlert } = useContext(MiscContext);

    // <> Effects
    useEffect(() => {

        // -> Filter new alerts
        const newAlerts = alerts.filter(a => !a.handled);

        // -> Loop through new alert
        newAlerts.forEach(newAlert => {

            // -> If this alert has a TTL, set a timeout for its dismissal
            if(newAlert.ttl) {

                setTimeout(() => {
                    dismiss(newAlert.id);
                }, newAlert.ttl)
            }

            // -> Flag this alert as handled
            newAlert.handled = true;
        })
    }, [alerts])

    // <> Actions
    function dismiss(alertId) {
        removeAlert(alertId);
    }

    // <> JSX
    return (
        <div className="alerts">

            <div className="alerts__list">

                {alerts && alerts.map(alert => (
                    <div className={`alerts__list__alert alerts__list__alert--${alert.level}`}>
                        <div className="alerts__list__alert__icon">
                            {alert.level == 'info'      && <FontAwesomeIcon icon={faInfoCircle} />}
                            {alert.level == 'success'   && <FontAwesomeIcon icon={faCheckCircle} />}
                            {alert.level == 'warning'   && <FontAwesomeIcon icon={faExclamationCircle} />}
                            {alert.level == 'danger'    && <FontAwesomeIcon icon={faExclamationCircle} />}
                        </div>
                        <div className="alerts__list__alert__message">{alert.message}</div>
                        <div className="alerts__list__alert__close" onClick={() => dismiss(alert.id)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                ))}

            </div>

        </div>
    );
};

export default Alerts;