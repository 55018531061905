import Configs from '../../../config.js';
import { TableColumn, TableColumns } from '../../../components/_tables/TableColumns';
import moment from 'moment';


// Map Data to Desired Display Values
function presentFacilities(data) {

    return data.map(d => {
        d.createdOn = moment(d.createdOn).format(Configs.formats.dateTime);

        return d;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const codeCol = new TableColumn()
        .setKey('code')
        .setDisplayName('Code')
        .setEditKey('code')

    const nameCol = new TableColumn()
        .setKey('name')
        .setDisplayName('Name')
        .setEditKey('name')

    const addressCol = new TableColumn()
        .setKey('address')
        .setDisplayName('Address')
        .setEditKey('address')

    const schedulingOfficePhoneCol = new TableColumn()
        .setKey('schedulingOfficePhone')
        .setDisplayName('Scheduling Office Phone Number')
        .setEditKey('schedulingOfficePhone')

    const faxCol = new TableColumn()
        .setKey('fax')
        .setDisplayName('Fax')
        .setEditKey('fax')

    const tableCols = new TableColumns([
        codeCol,
        nameCol,
        addressCol,
        schedulingOfficePhoneCol,
        faxCol
    ])

    return tableCols;

}


/**
 * Exports
 */
export {
    presentFacilities,
    tableColumnDefinitions
}