import React, { useContext, useEffect, useState } from 'react';
import '../MasterDataContainer.sass';
import IconButton from '../../../components/_controls/IconButton/IconButton';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import EditableTable from '../../../components/_tables/EditableTable/EditableTable';
import { useHistory } from 'react-router';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MasterDataService from '../../../services/masterData.service';

const EventRulings = () => {

    // <> Context
    const { setMasterDataOpenedPopup, setMasterDataOpenedPopupState, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);

    // <> JSX
    return (
        <div className="master-data">

            <div className="master-data__top">
                {/* Patient Name */}
                <div className="master-data__top__title">Carrier Auth Rules</div>

                {/* <div className="master-data__top__actions">
                    <div className="master-data__top__actions__action">
                        <IconButton 
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={onAddClicked} />
                    </div>
                </div> */}
            </div>

            <div className="master-data__container">

                {/* <EditableTable
                    isLoading={isLoading}
                    items={Carriers}
                    updateField={updateField}
                    deleteRow={deleteRow}
                    columnDefinitions={Presenter.tableColumnDefinitions()} /> */}

            </div>
        </div>
    );
};

export default EventRulings;