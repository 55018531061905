import { faCheese, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './IconButton.sass';

const IconButton = ({ reference = null, text, icon = faCheese, btnStyle = 'primary', size = 'md', onClickFn = () => {}, isLoading = false, hideTextOnMobile = false, isDisabled = false, iconOnRight = false, extraStyles = null }) => {

    // <> JSX
    return (
        <button ref={reference} autoFocus={false} className={`icon-button icon-button-${size} icon-button-${btnStyle} ${isLoading ? 'icon-button--loading' : ''} ${iconOnRight ? 'icon-button--icon-right' : ''}`} onClick={onClickFn} disabled={isDisabled} style={extraStyles}>
            <span hidden={!iconOnRight} className={`icon-button__text ${hideTextOnMobile ? 'icon-button__text--hide-for-mobile' : ''}`}>{text}</span>
            <FontAwesomeIcon icon={!isLoading ? icon : faSpinner} />
            <span hidden={iconOnRight} className={`icon-button__text ${hideTextOnMobile ? 'icon-button__text--hide-for-mobile' : ''}`}>{text}</span>
        </button>
    );
};

export default IconButton;