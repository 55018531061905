import Configs from '../../config.js';
import Enums from '../../enums';
import moment from 'moment';


function presentData(histories) {

    return histories.map(history => {

        history.createdOn = moment(history.createdOn).format(Configs.formats.dateTime);
        history.title = getTitleOfEvent(history.actionType);
        history.level = getLevelOfEvent(history.actionType);

        return history;
    })
}

function getTitleOfEvent(eventType) {

    const types = Enums.HistoryEvents;
    switch(eventType) {
        case types.AddMasterData: 
            return 'Add Master Data Entry';
        case types.DeleteMasterData: 
            return 'Delete Master Data Entry';
        case types.AddEvent: 
            return 'Add Event';
        case types.AddEventClearance: 
            return 'Add Event Clearance';
        case types.UpdateEvent: 
            return 'Update Event';
        case types.UpdateEventClearance: 
            return 'Update Event Clearance';
        case types.UpdateEventExtraClearance: 
            return 'Update Event Other Clearance';
        case types.UpdateEventInsuranceAuthorization: 
            return 'Update Event Insurance Authorization';
        case types.DeleteEvent: 
            return 'Delete Event';
        case types.DeleteEventClearance: 
            return 'Delete Event Clearance';
        case types.CodeEvent: 
            return 'Code Event';
        case types.RescheduleEvent: 
            return 'Reschedule Event';
        case types.AddPatient: 
            return 'Add Patient';
        case types.UpdatePatient: 
            return 'Update Patient';
        case types.DeletePatient: 
            return 'Delete Patient';
        default:
            return eventType;
    }
}

function getLevelOfEvent(eventType) {

    const types = Enums.HistoryEvents;
    switch(eventType) {
        case types.AddMasterData: 
        case types.AddPatient: 
        case types.AddEvent: 
        case types.AddEventClearance: 
            return 'success';
        case types.DeleteMasterData: 
        case types.DeleteEvent: 
        case types.DeleteEventClearance: 
        case types.DeletePatient: 
            return 'danger';
        case types.UpdateEvent: 
        case types.RescheduleEvent: 
        case types.CodeEvent: 
        case types.UpdateEventClearance: 
        case types.UpdateEventExtraClearance: 
        case types.UpdateEventInsuranceAuthorization: 
        case types.UpdatePatient: 
            return 'info';
        default:
            return 'info';
    }
}

function usersForSelector(usersArg) {
    return usersArg.map(u => { return { value: u.id, label: u.name } });
}


/**
 * Exports
 */
export {
    presentData,
    usersForSelector
}