import './AsyncPromptPopup.sass';


function AsyncPromptPopup({ message, cancelBtnText = 'Cancel', okBtnText = 'Ok' }) {

    // Get document root
    const root = document.querySelector('#root');

    // Create Popup DOM
    const Main = document.createElement('div');
    Main.classList.add('async-prompt-popup');

    const Overlay = document.createElement('div');
    Overlay.classList.add('async-prompt-popup__overlay');

    const Container = document.createElement('div');
    Container.classList.add('async-prompt-popup__container');

    const Message = document.createElement('div');
    Message.classList.add('async-prompt-popup__container__message');
    Message.innerHTML = message;

    const Input = document.createElement('input');
    Input.classList.add('async-prompt-popup__container__input');

    const Actions = document.createElement('div');
    Actions.classList.add('async-prompt-popup__container__actions');

    const CancelBtn = document.createElement('button');
    CancelBtn.classList.add('async-prompt-popup__btn', 'async-prompt-popup__btn-faded');
    CancelBtn.innerHTML = cancelBtnText;

    const OkBtn = document.createElement('button');
    OkBtn.classList.add('async-prompt-popup__btn', 'async-prompt-popup__btn-success');
    OkBtn.innerHTML = okBtnText;

    // Assemble Popup DOM
    Actions.append(CancelBtn, OkBtn);
    Container.append(Message, Input, Actions);
    Main.append(Overlay, Container);

    // Attach Popup To Root
    root.append(Main);

    // Return Promise
    return new Promise((resolve, reject) => {

        // Ok Btn listener
        OkBtn.addEventListener('click', onOk);

        // Cancel Btn listener
        CancelBtn.addEventListener('click', onCancel);

        // Overlay Listener
        Overlay.addEventListener('click', onCancel);

        // On Enter Clicked
        Input.addEventListener('keypress', function(ev) {
            if(ev.key === 'Enter') onOk();
        })

        // <> Helpers
        function onOk() {
            const inputValue = Input.value;
            if(inputValue) {
                resolve(inputValue);
                root.removeChild(Main);
            }
            else {
                reject(null);
                root.removeChild(Main);
            }
        }

        function onCancel() {
            resolve(null);
            root.removeChild(Main);
        }
    })


}


/**
 * Export
 */
export default AsyncPromptPopup;