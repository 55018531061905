import { faCompass, faMapMarker, faSearchLocation, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Configs from '../../config';
import { MiscContext } from '../../context/misc.context';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import AddressLookupService from '../../services/address-lookup.service';
import './AddressSearch.sass';

const AddressSearch = ({ onChoice }) => {

    // <> Context
    const { addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingPlace, setIsFetchingPlace] = useState(false);
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    // <> Effects
    useEffect(() => {

        if(!query) {
            setSuggestions([]);
            return;
        }

        const timeoutDebounce = setTimeout(() => {
            searchAddresses();
        }, Configs.misc.searchDelay)

        return () => clearTimeout(timeoutDebounce);
    }, [query])

    // <> Actions
    async function searchAddresses() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Search using API
            const searchResponse = await AddressLookupService.search(query);
            const searchRes = searchResponse.data.data.candidates;
    
            // -> Set State
            setSuggestions(searchRes);
            
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function pickAddress(chosenSuggestion) {

        try {

            // -> Turn Loader ON
            setIsFetchingPlace(true);
            setSuggestions([]);

            // -> Search using API
            const detailsResponse = await AddressLookupService.details(chosenSuggestion.place_id);
            const detailsRes = detailsResponse.data.data.details;
    
            // -> Set State
            console.log(detailsRes);
            onChoice(detailsRes);
            reset();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsFetchingPlace(false);
        }
        
    }

    // <> Helpers
    function reset() {
        setSuggestions([]);
        setIsLoading(false);
        setQuery('');
    }

    // <> JSX
    return (
        <div className="address-search">

            {/* Input */}
            <div className="address-search__input">

                {/* Search Icon */}
                {!isLoading && !isFetchingPlace && <div className="address-search__input__icon">
                    <FontAwesomeIcon icon={faMapMarker} />
                </div>}

                {/* Loader Icon */}
                {isLoading && <div className="address-search__input__icon spinner">
                    <FontAwesomeIcon icon={faSpinner} />
                </div>}

                {/* Loader Icon */}
                {isFetchingPlace && <div className="address-search__input__icon spinner">
                    <FontAwesomeIcon icon={faCompass} />
                </div>}

                {/* Input Control */}
                <input 
                    className="address-search__input__control"
                    placeholder="Search addresses"
                    disabled={isFetchingPlace}
                    value={query}
                    onChange={ev => setQuery(ev.target.value)} />

            </div>

            {/* Suggestions */}
            <div className="address-search__suggestions">
                {suggestions && suggestions.map((suggestion, index) => (
                    <div
                        key={index}
                        className="address-search__suggestions__item"
                        onClick={() => pickAddress(suggestion)}
                    >
                        {suggestion.formatted_address}
                    </div>
                ))}
            </div>

        </div>
    );
};

export default AddressSearch;