import { faCheckCircle, faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Configs from '../../../config';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import EventChecklistService from '../../../services/event.checklist.service';
import EventClearanceService from '../../../services/event.clearance.service';
import MasterDataService from '../../../services/masterData.service';
import DropdownSearch from '../../_controls/DropdownSearch/DropdownSearch';
import IconButton from '../../_controls/IconButton/IconButton';
import '../Popup.sass';

const AddEventClearance = () => {

    // <> Context
    const { addAlert, openPopup, openPopupState, setOpenPopup, setOpenPopupState } = useContext(MiscContext);

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [clearances, setClearances] = useState(null);
    const [clearanceName, setClearanceName] = useState('');
    const [selectedClearanceId, setSelectedClearanceId] = useState(null);
    const [success, setSuccess] = useState(null);
    const [failure, setFailure] = useState(null);

    // <> History
    const history = useHistory();
    
    // <> Form
    const { handleSubmit, register, reset, setValue, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        getClearances();
    }, [])

    useEffect(() => {
        if(clearances && clearances.length) {
            onClearancePicked(clearances[0].id);
        }
    }, [clearances])

    // <> Actions
    async function getClearances() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data From API
            const clearancesResponse = await MasterDataService.getTable(Enums.MasterData.Clearance);
            const clearancesRes = clearancesResponse.data.data.masterData;
    
            // -> Set States
            setClearances(clearancesRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function onSubmit(data) {
        
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // Params
            const params = { 
                eventId: openPopupState.eventId, 
                
                clearanceDescription: data.clearanceDescription,
                physicianName: data.physicianName,
                assigneeId: openPopupState.assigneeId,
                dueDate: moment(openPopupState.dueDate).format(Configs.formats.apiDateTime)
            }

            if(clearanceName) {
                params.clearanceId = null;
                params.clearanceName = clearanceName;
            }
            else {
                const selectedClearance = clearances.find(c => c.id == data.clearanceId);
                params.clearanceId = selectedClearance.id;
                params.clearanceName = selectedClearance.name;
            }


            // -> Add
            await EventClearanceService.addOne(params);
            
            // -> Callback
            setSuccess('Event clearance added successfully');
            if(openPopupState.onSuccess) openPopupState.onSuccess();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        setOpenPopup('');
        setOpenPopupState({});
        setSuccess('');
        setFailure('');
        setClearanceName('');
        reset();
    }

    function dismissFailure() {
        setFailure('');
    }

    function onClearancePicked(clearanceId) {

        const selectedClearance = clearances.find(c => c.id == clearanceId);

        setValue('clearanceId', selectedClearance.id);
        if(selectedClearance?.description) setValue('clearanceDescription', selectedClearance.description);
        if(selectedClearance?.physician) setValue('physicianName', selectedClearance.physician);

        setSelectedClearanceId(selectedClearance.id);

    }

    // <> JSX
    return (
        <div className={`popup ${openPopup == Enums.PopupTags.AddEventClearance ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container">

                {/* Title */}
                <div className="popup__container__title">Add New Clearance & Checklist</div>

                {openPopupState && <form onSubmit={handleSubmit(onSubmit)} className="popup__container__form">

                    {/* Clearance */}
                    {clearances && <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Clearance</div>
                        <DropdownSearch
                            options={clearances.map(c => { return { value: c.id, label: c.name }})}
                            value={selectedClearanceId}
                            setter={onClearancePicked}
                            onManualInputFn={val => setClearanceName(val)}
                            />
                        
                        <input type="hidden" {...register('clearanceId')} />
                    </div>}
                    <div className="popup__container__form__input__error">{errors?.clearanceName?.message}</div>

                    {/* Clearance Description */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Description</div>
                        <textarea 
                            className="popup__container__form__input__control"
                            rows="3"
                            {...register('clearanceDescription', { required: 'clearance description required', minLength: { value: 20, message: 'description must be at least 20 characters long' }})}></textarea>
                        <div className="popup__container__form__input__error">{errors?.clearanceDescription?.message}</div>
                    </div>

                    {/* Physician */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Physician</div>
                        <input 
                            className="popup__container__form__input__control"
                            {...register('physicianName')} />
                        <div className="popup__container__form__input__error">{errors?.physicianName?.message}</div>
                    </div>

                    {/* Assignee */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Assignee</div>
                        <div>{openPopupState.assigneeName}</div>
                    </div>

                    {/* Due Date */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Due Date <span className="small-note">(by default 3 days before the event)</span></div>
                        <div>{moment(openPopupState.dueDate).format(Configs.formats.date)}</div>
                    </div>

                    {/* Submit Btn */}
                    <div className="popup__container__form__submit-btn">
                        <IconButton
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={() => handleSubmit(onSubmit)}
                            isLoading={isLoading} />
                    </div>

                </form>}

                {/* Success Pane */}
                {success && (
                    <div className="popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="popup__container__success-pane__icon" />
                        <div className="popup__container__success-pane__message">{success}</div>
                        <button className="popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="popup__container__failure-pane__icon" />
                        <div className="popup__container__failure-pane__message">{failure}</div>
                        <button className="popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={dismissFailure}>Back</button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default AddEventClearance;