import React from 'react';
import './App.sass';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from '../../components/Header/Header';
import SideMenu from '../../components/SideMenu/SideMenu';
import Login from '../Login/Login';
import Register from '../Register/Register';
import Logout from '../Logout/Logout';
import Homepage from '../Homepage/Homepage';
import Patients from '../Patients/Patients';
import NotFound from '../NotFound/NotFound';
import '../../utils/axios.interceptor';
import { UserProvider } from '../../context/user.context';
import { MiscProvider } from '../../context/misc.context';
import Calendar from '../Calendar/Calendar';
import PatientDetails from '../Patients/PatientDetails/PatientDetails';
import AddEventPopup from '../../components/AddEventPopup/AddEventPopup';
import AddUserPopup from '../../components/AddUserPopup/AddUserPopup';
import Alerts from '../../components/Alerts/Alerts';
import Init from '../../components/Init/Init';
import { AddPatientPopup } from '../../components/AddPatientDataPopups/AddPatientDataPopups';
import MasterData from '../MasterData/MasterData';
import { AddEventTypePopup, AddFacilityPopup, AddProcedurePopup, AddDiagnosisPopup, AddBloodThinnerPopup, AddPriorityPopup, AddCarrierPopup, AddClearancePopup, AddPatientCommunicationPopup, AddBoardingLink } from '../../components/AddMasterDataPopups/AddMasterDataPopups';
import ManageUsers from '../ManageUsers/ManageUsers';
import ManageUser from '../ManageUsers/ManageUser/ManageUser';
import Physicians from '../Physicians/Physicians';
import PhysicianDetails from '../Physicians/PhysicianDetails/PhysicianDetails';
import History from '../History/History';
import Profile from '../Profile/Profile';
import Events from '../Events/Events';
import Settings from '../Settings/Settings';
import ManageEvent from '../Events/ManageEvent/ManageEvent';
import AddChecklistItem from '../../components/_popups/AddChecklistItem/AddChecklistItem';
import ConfirmationPopup from '../../components/_popups/ConfirmationPopup/ConfirmationPopup';
import AddPatientAllergy from '../../components/_popups/_addPatientData/AddPatientAllergyPopup/AddPatientAllergy';
import AddPatientSmokingStatus from '../../components/_popups/_addPatientData/AddPatientSmokingStatus/AddPatientSmokingStatus';
import AddPatientHealthConcern from '../../components/_popups/_addPatientData/AddPatientHealthConcern/AddPatientHealthConcern';
import AddPatientImmunization from '../../components/_popups/_addPatientData/AddPatientImmunization/AddPatientImmunization';
import AddPatientGoal from '../../components/_popups/_addPatientData/AddPatientGoal/AddPatientGoal';
import AddPatientAssessment from '../../components/_popups/_addPatientData/AddPatientAssessment/AddPatientAssessment';
import AddPatientImplantedDevice from '../../components/_popups/_addPatientData/AddPatientImplantedDevice/AddPatientImplantedDevice';
import AddPatientMedication from '../../components/_popups/_addPatientData/AddPatientMedication/AddPatientMedication';
import PatientsSearch from '../Patients/PatientsSearch';
import PromptPopup from '../../components/_popups/PromptPopup/PromptPopup';
import CodeEvent from '../../components/_popups/CodeEvent/CodeEvent';
import RescheduleEventPopup from '../../components/_popups/RescheduleEventPopup/RescheduleEventPopup';
import Sprite from '../../components/Sprite/Sprite';
import AddEventClearance from '../../components/_popups/AddEventClearance/AddEventClearance';
import EventConfirmWithReschedulePopup from '../../components/_popups/EventConfirmWithReschedulePopup/EventConfirmWithReschedulePopup';
import Footer from '../../components/Footer/Footer';
import Statistics from '../Statistics/Statistics';
import AddEventPatientCommunication from '../../components/_popups/AddEventPatientCommunication/AddEventPatientCommunication';
import ChangeEventPatientCommunicationFile from '../../components/_popups/ChangeEventPatientCommunicationFile/ChangeEventPatientCommunicationFile';
import SendPatientCommunicationPopup from '../../components/_popups/SendPatientCommunicationPopup/SendPatientCommunicationPopup';
import AddEventBloodThinner from '../../components/_popups/AddEventBloodThinner/AddEventBloodThinner';

function App() {
  return (
    <div className="App" id="app-container">
		<MiscProvider>
		<UserProvider>
		<Router>
			<Init />
			<Sprite />
			<Header />
			<SideMenu />
			<AddEventPopup />
			<AddUserPopup />
			<AddPatientPopup />
			<AddFacilityPopup />
			<AddEventTypePopup />
			<AddProcedurePopup />
			<AddDiagnosisPopup />
			<AddBloodThinnerPopup />
			<AddPriorityPopup />
			<AddCarrierPopup />
			<AddClearancePopup />
			<AddPatientCommunicationPopup />
			<AddChecklistItem />
			<ConfirmationPopup />
			<AddPatientAllergy />
			<AddPatientSmokingStatus />
			<AddPatientHealthConcern />
			<AddPatientImmunization />
			<AddPatientGoal />
			<AddPatientAssessment />
			<AddPatientImplantedDevice />
			<AddPatientMedication />
			<PromptPopup />
			<CodeEvent />
			<RescheduleEventPopup />
			<AddEventClearance />
			<EventConfirmWithReschedulePopup />
			<AddEventPatientCommunication />
			<ChangeEventPatientCommunicationFile />
			<SendPatientCommunicationPopup />
			<AddBoardingLink />
			<AddEventBloodThinner />
			<div className="content-container">
				<Switch>
					<Route path="/" exact component={Events} />
					<Route path="/login" component={Login} />
					<Route path="/register" component={Register} />
					<Route path="/logout" component={Logout} />
					<Route path="/events" exact component={Events} />
					<Route path="/event/details/" component={ManageEvent} />
					<Route path="/calendar" component={Calendar} />
					<Route path="/patients" exact component={PatientsSearch} />
					<Route path="/patients/" component={PatientDetails} />
					<Route path="/physicians" exact component={Physicians} />
					<Route path="/physicians/details" component={PhysicianDetails} />
					<Route path="/master-data/" component={MasterData} />
					<Route path="/manage-users/:id" component={ManageUser} />
					<Route path="/manage-users" component={ManageUsers} />
					<Route path="/history" exact component={History} />
					<Route path="/profile" exact component={Profile} />
					<Route path="/settings/" component={Settings} />
					<Route path="/statistics" exact component={Statistics} />
					<Route path="/" component={NotFound} />
				</Switch>
			</div>
			<Footer />
			<Alerts />
		</Router>
		</UserProvider>
		</MiscProvider>
    </div>
  );
}

export default App;
