import React, { useContext, useEffect, useState } from 'react';
import './DayEvents.sass';
import moment from 'moment';
import { useHistory } from 'react-router';
import Loader from '../Loader/Loader';
import { UserContext } from '../../context/user.context';
import { MiscContext } from '../../context/misc.context';
import { useForm } from 'react-hook-form';
import EventNoteService from '../../services/event.note.service';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import EventCard from './EventCard/EventCard';

const slots = ['12 am', '1 am', '2 am', '3 am', '4 am', '5 am', '6 am', '7 am', '8 am', '9 am', '10 am', '11 am', '12 pm', '1 pm', '2 pm', '3 pm', '4 pm', '5 pm', '6 pm', '7 pm', '8 pm', '9 pm', '10 pm', '11 pm']

const DayEvents = ({ dayEvents, isLoading, refresh }) => {

    // <> States
    const [timeline, setTimeline] = useState([]);

    // <> Effects
    useEffect(() => {

        const tl = [];

        for (let i = 0; i < 24; i++) {
            const slot = slots[i];
            tl.push({
                hour: slot,
                events: dayEvents.filter(e => moment(e.dateFrom).hour() === i).sort((a, b) => moment(a.dateFrom).unix() - moment(b.dateFrom).unix())
            })
        }

        setTimeline(tl);
    }, [dayEvents]);


    // <> JSX
    return (
        <div className="day-events">

            <Loader isLoading={isLoading} />

            {timeline && timeline.map((t, index) => (

                // Slot
                <div key={index} className="day-events__slot">

                    {/* Slot Hour */}
                    <span className="day-events__slot__hour">{t.hour}</span>

                    {/* Events In That Slot */}
                    <div className="day-events__slot__events">
                        {t.events.map((e, index2) => (
                            <EventCard eventDetails={e} refresh={refresh} />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DayEvents;