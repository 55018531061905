import Configs from '../../config.js';
import { TableColumn, TableColumns } from '../../components/_tables/TableColumns';
import moment from 'moment';


// Map Patient Data to Desired Display Values
function presentData(physicians) {

    return physicians.map(physician => {
        physician.createdOn = moment(physician.createdOn).format(Configs.formats.date);

        return physician;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')
        .setSearchKey('id')
        .setSortKey('id')

    const emailCol = new TableColumn()
        .setKey('email')
        .setDisplayName('Email')
        .setSearchKey('email')
        .setSortKey('email')

    const phoneCol = new TableColumn()
        .setKey('phone')
        .setDisplayName('Phone #')
        .setSearchKey('phone')

    const createdOnCol = new TableColumn()
        .setKey('createdOn')
        .setDisplayName('Date Created')


    const tableCols = new TableColumns([
        emailCol,
        phoneCol,
        createdOnCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}