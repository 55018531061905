import axios from 'axios';
import Configs from '../config';


function addOne(formData) {
    return axios.post(`${Configs.apiUrl}/events/patientCommunications/addOne`, formData);
}

function updateOne(id, { title, description }) {
    return axios.put(`${Configs.apiUrl}/events/patientCommunications/updateOne`, { id, title, description });
}

function deleteOne(id) {
    return axios.post(`${Configs.apiUrl}/events/patientCommunications/deleteOne`, { id });
}


/**
 * Exports
 */
const EventPatientCommunicationService = {
    addOne,
    updateOne,
    deleteOne
}
export default EventPatientCommunicationService;