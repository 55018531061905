/**
 * @description custom HTTP errors for this app
 */


class HttpError extends Error {
    constructor(message, originalError = null) {
        super(message);
        this.name = "HttpError";
        this.originalError = originalError;
    }
}

class UnauthorizedHttpError extends HttpError {
    constructor(message, originalError = null) {
        super(message, originalError);
        this.name = "UnauthorizedHttpError";
    }
}

class UnauthenticatedHttpError extends HttpError {
    constructor(message, originalError = null) {
        super(message, originalError);
        this.name = "UnauthenticatedHttpError";
    }
}

class RequestNotSentHttpError extends HttpError {
    constructor(message, originalError = null) {
        super(message, originalError);
        this.name = "RequestNotSentHttpError";
    }
}

class ResponseNotReceivedHttpError extends HttpError {
    constructor(message, originalError = null) {
        super(message, originalError);
        this.name = "ResponseNotReceivedHttpError";
    }
}

class HttpResponseError extends HttpError {
    constructor(message, originalError = null, status = null) {
        super(message, originalError);
        this.name = "HttpResponseError";
        this.status = status;
    }
}

class InternalServerHttpError extends HttpError {
    constructor(message, originalError = null) {
        super(message, originalError);
        this.name = "InternalServerHttpError";
    }
}


/**
 * Exports
 */
const HttpErrors = {
    HttpError,
    UnauthorizedHttpError,
    UnauthenticatedHttpError,
    RequestNotSentHttpError,
    ResponseNotReceivedHttpError,
    HttpResponseError,
    InternalServerHttpError
}

export default HttpErrors;