import { faCheckCircle, faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Configs from '../../../config';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import EventBloodThinnerService from '../../../services/event.bloodThinner.service';
import EventChecklistService from '../../../services/event.checklist.service';
import EventClearanceService from '../../../services/event.clearance.service';
import MasterDataService from '../../../services/masterData.service';
import DropdownSearch from '../../_controls/DropdownSearch/DropdownSearch';
import IconButton from '../../_controls/IconButton/IconButton';
import '../Popup.sass';

const AddEventBloodThinner = () => {

    // <> Context
    const { addAlert, openPopup, openPopupState, setOpenPopup, setOpenPopupState } = useContext(MiscContext);

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [failure, setFailure] = useState(null);

    // <> History
    const history = useHistory();
    
    // <> Form
    const { handleSubmit, register, reset, formState: { errors } } = useForm();

    // <> Actions
    async function onSubmit(data) {
        
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            const params = {
                eventId: openPopupState.eventId,
                bloodThinnerName: data.bloodThinnerName,
                bloodThinnerDescription: data.bloodThinnerDescription,
            }
            if(data.bloodThinnerCode) params.bloodThinnerCode = data.bloodThinnerCode;

            // -> Add
            await EventBloodThinnerService.addOne(params);
            
            // -> Callback
            setSuccess('Event blood thinner added successfully');
            if(openPopupState.onSuccess) openPopupState.onSuccess();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        setOpenPopup('');
        setOpenPopupState({});
        setSuccess('');
        setFailure('');
        reset();
    }

    function dismissFailure() {
        setFailure('');
    }

    // <> JSX
    return (
        <div className={`popup ${openPopup == Enums.PopupTags.AddEventBloodThinner ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container">

                {/* Title */}
                <div className="popup__container__title">Add New Event Blood Thinner</div>

                {openPopupState && <form onSubmit={handleSubmit(onSubmit)} className="popup__container__form">

                    {/* Code */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Code</div>
                        <input 
                            className="popup__container__form__input__control"
                            {...register('bloodThinnerCode')} />
                        <div className="popup__container__form__input__error">{errors?.bloodThinnerCode?.message}</div>
                    </div>

                    {/* Name */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Name</div>
                        <input 
                            className="popup__container__form__input__control"
                            {...register('bloodThinnerName', { required: 'name required' })} />
                        <div className="popup__container__form__input__error">{errors?.bloodThinnerName?.message}</div>
                    </div>

                    {/* Description */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Description</div>
                        <textarea 
                            className="popup__container__form__input__control"
                            rows="3"
                            {...register('bloodThinnerDescription', { required: 'description required', minLength: { value: 20, message: 'description must be at least 20 characters long' }})}></textarea>
                        <div className="popup__container__form__input__error">{errors?.bloodThinnerDescription?.message}</div>
                    </div>

                    {/* Assignee */}
                    {/* <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Assignee</div>
                        <div>{openPopupState.assigneeName}</div>
                    </div> */}

                    {/* Due Date */}
                    {/* <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">Due Date <span className="small-note">(by default 3 days before the event)</span></div>
                        <div>{moment(openPopupState.dueDate).format(Configs.formats.date)}</div>
                    </div> */}

                    {/* Submit Btn */}
                    <div className="popup__container__form__submit-btn">
                        <IconButton
                            text={'Add'}
                            icon={faPlus}
                            onClickFn={() => handleSubmit(onSubmit)}
                            isLoading={isLoading} />
                    </div>

                </form>}

                {/* Success Pane */}
                {success && (
                    <div className="popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="popup__container__success-pane__icon" />
                        <div className="popup__container__success-pane__message">{success}</div>
                        <button className="popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="popup__container__failure-pane__icon" />
                        <div className="popup__container__failure-pane__message">{failure}</div>
                        <button className="popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={dismissFailure}>Back</button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default AddEventBloodThinner;