import Configs from '../../../../config.js';
import { TableColumn, TableColumns } from '../../../../components/_tables/TableColumns';
import moment from 'moment';


function presentData(items) {

    return items.map(item => {
        item.date = moment(item.date).format(Configs.formats.date);

        return item;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const nameCol = new TableColumn()
        .setKey('name')
        .setDisplayName('Name')
        .setEditKey('name')

    const dateCol = new TableColumn()
        .setKey('date')
        .setType('date')
        .setDisplayName('Date')
        .setEditKey('date')

    const statusCol = new TableColumn()
        .setKey('status')
        .setDisplayName('Status')
        .setEditKey('status')

    const quantityCol = new TableColumn()
        .setKey('quantity')
        .setDisplayName('Quantity')
        .setEditKey('quantity')

    const unitCol = new TableColumn()
        .setKey('unit')
        .setDisplayName('Unit')
        .setEditKey('unit')

    const cvxCodeCol = new TableColumn()
        .setKey('cvxCode')
        .setDisplayName('CVX Code')
        .setEditKey('cvxCode')

    const detailsCol = new TableColumn()
        .setKey('details')
        .setDisplayName('Details')
        .setEditKey('details')

    const tableCols = new TableColumns([
        nameCol,
        dateCol,
        statusCol,
        quantityCol,
        unitCol,
        cvxCodeCol,
        detailsCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}