import { faCheck, faCheckCircle, faExclamationCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import PatientCommunicationService from '../../../services/patientCommunication.service';
import Loader from '../../Loader/Loader';
import IconButton from '../../_controls/IconButton/IconButton';
import '../Popup.sass';

const ChangeEventPatientCommunicationFile = () => {

    // <> Context
    const { addAlert, openPopup, openPopupState, setOpenPopup, setOpenPopupState } = useContext(MiscContext);

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [success, setSuccess] = useState(null);
    const [failure, setFailure] = useState(null);

    // <> History
    const history = useHistory();
    
    // <> Form
    const { handleSubmit, register, reset, formState: { errors } } = useForm({ mode: 'onChange' });

    // <> Actions
    async function onSubmit(data) {
        
        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // Params
            const formData = new FormData();
            formData.append('rowId', openPopupState.id);
            if(selectedFile) formData.append('file', selectedFile);

            // -> Add
            await PatientCommunicationService.changeFile(formData);
            
            // -> Callback
            setSuccess('Patient communication file changed successfully');
            if(openPopupState.onSuccess) openPopupState.onSuccess();

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        setOpenPopup('');
        setOpenPopupState({});
        setSuccess('');
        setFailure('');
        setSelectedFile(null);
        reset();
    }

    function dismissFailure() {
        setFailure('');
    }

    function onFileChosen(ev) {

        console.log(ev.target.files);
        const chosenFile = ev.target.files[0];
        setSelectedFile(chosenFile);

    }

    // <> JSX
    return (
        <div className={`popup ${openPopup == Enums.PopupTags.ChangePatientCommunicationFile ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container">

                {/* Loader */}
                <Loader isLoading={isLoading} />

                {/* Title */}
                <div className="popup__container__title">Change Patient Communication File</div>

                {openPopupState && <form onSubmit={handleSubmit(onSubmit)} className="popup__container__form">

                    {/* File */}
                    <div className="popup__container__form__input">
                        <div className="popup__container__form__input__label">File</div>
                        <div className={`popup__container__form__input-file-wrapper ${selectedFile ? 'popup__container__form__input-file-wrapper--active' : ''}`}>
                            {openPopupState.file && <div className="popup__container__form__input-file-wrapper__old">{openPopupState.file}</div>}
                            <div className="popup__container__form__input-file-wrapper__text">{selectedFile?.name || 'Drop file to upload'}</div>
                            <input 
                                type="file"
                                {...register('file')}
                                onChange={onFileChosen} />
                        </div>
                        <div className="popup__container__form__input__error">{errors?.file?.message}</div>
                    </div>

                    {/* Submit Btn */}
                    <div className="popup__container__form__submit-btn">
                        <IconButton
                            text={'Save'}
                            icon={faCheck}
                            onClickFn={() => handleSubmit(onSubmit)}
                            isDisabled={!selectedFile}
                            isLoading={isLoading} />
                    </div>

                </form>}

                {/* Success Pane */}
                {success && (
                    <div className="popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="popup__container__success-pane__icon" />
                        <div className="popup__container__success-pane__message">{success}</div>
                        <button className="popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="popup__container__failure-pane__icon" />
                        <div className="popup__container__failure-pane__message">{failure}</div>
                        <button className="popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={dismissFailure}>Back</button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default ChangeEventPatientCommunicationFile;