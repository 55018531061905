import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MiscContext } from '../../context/misc.context';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import HistoryService from '../../services/history.service';
import './Profile.sass';
import Configs from '../../config.js';
import Enums from '../../enums';
import * as Presenter from './Profile.presenter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLock, faMinusCircle, faPlus, faSignOutAlt, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/_controls/IconButton/IconButton';
import BrowserStorage from '../../utils/browser.storage';
import UserService from '../../services/user.service';
import { UserContext } from '../../context/user.context';

const Profile = () => {

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    // <> Context
    const { addAlert } = useContext(MiscContext); 
    const { user } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> Effects
    useEffect(() => {
        getData();
    }, []);

    // <> Actions
    async function getData() {

        try {

            // -> Set Loader ON
            setIsLoading(true);

            // -> Fetch Profile From API
            
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function logout() {
        
        try {

            // -> Turn Loader ON
            setIsLoggingOut(true);

            // -> Logout using API
            await UserService.logout();

            // -> Clear Tokens From Browser Storage
            BrowserStorage.clear(Enums.BrowserStorage.AccessToken.Key);
            BrowserStorage.clear(Enums.BrowserStorage.RefreshToken.Key);

            // -> Navigate To Login Page
            history.push('/login');
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoggingOut(false);
        }
    }

    // <> JSX
    return (
        <div className="profile-page">

            {/* Top */}
            <div className="profile-page__top">
                <div className="profile-page__top__left">
                    <span className="profile-page__top__left__title">Profile</span>
                    <span className="profile-page__top__left__subtitle">Your profile.</span>
                </div>
                <div className="profile-page__top__right">
                    <IconButton
                        text={'Logout'}
                        icon={faSignOutAlt}
                        btnStyle={'danger'}
                        onClickFn={logout}
                        isLoading={isLoggingOut} />
                </div>
                
            </div>

            {/* Content */}
            <div className="profile-page__content">

                {/* Name */}
                <div className="profile-page__content__info">
                    <div className="profile-page__content__info__label">Name</div>
                    <div className="profile-page__content__info__value">{user?.name}</div>
                </div>

                {/* Change Password */}
                <div className="profile-page__content__info">
                    <div className="profile-page__content__info__label">Password</div>
                    <div className="profile-page__content__info__value">
                        <IconButton
                            text={'Change Password'}
                            icon={faLock}
                            btnStyle={'danger'}
                            onClickFn={() => {}} />
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Profile;