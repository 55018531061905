import axios from 'axios';
import Configs from '../config.js';


function login(email, password) {
    return axios.post(`${Configs.apiUrl}/auth/login`, { email, password });
}

function autoLogin(refreshToken) {
    return axios.get(`${Configs.apiUrl}/auth/autoLogin`, { headers: { 'refresh-token': refreshToken } });
}

function register(email, password, phone) {
    return axios.post(`${Configs.apiUrl}/auth/register`, { email, password, phone });
}

function logout() {
    return axios.get(`${Configs.apiUrl}/auth/logout`);
}

function getUserData() {
    return axios.get(`${Configs.apiUrl}/auth/myDetails`);
}

function refresh(refreshToken) {
    return axios.get(`${Configs.apiUrl}/auth/refresh`, { headers: { 'refresh-token': refreshToken } });
}


/**
 * Exports
 */
const UserService = {
    login,
    autoLogin,
    register,
    logout,
    getUserData,
    refresh
}
export default UserService;