import Configs from '../../../config.js';
import { TableColumn, TableColumns } from '../../../components/_tables/TableColumns';
import moment from 'moment';
import Tag from '../../../components/Tag/Tag.js';


// Map Data to Desired Display Values
function presentData(data, setAsDefault) {

    return data.map(d => {
        d.createdOn = moment(d.createdOn).format(Configs.formats.dateTime);

        const labelExtraStyles = { display: 'block', width: '100%', textAlign: 'center' };
        d.isDefaultLabel = !!d.isDefault
            ? <div><Tag tagStyle={'green'} size={'xsm'} text={'Yes'} extraStyles={labelExtraStyles} /></div>
            : <div className="clickable" onClick={() => setAsDefault(d.id)}><Tag tagStyle={'gray'} size={'xsm'} text={'No'} extraStyles={labelExtraStyles} /></div>

        return d;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const codeCol = new TableColumn()
        .setKey('code')
        .setDisplayName('Code')
        .setEditKey('code')

    const nameCol = new TableColumn()
        .setKey('name')
        .setDisplayName('Name')
        .setEditKey('name')

    const priorityCol = new TableColumn()
        .setKey('priority')
        .setType('number')
        .setDisplayName('Priority')
        .setEditKey('priority')

    const defaultCol = new TableColumn()
        .setKey('isDefaultLabel')
        .setDisplayName('Is Default')
        .setCustomStyles({ width: '1%' })

    const tableCols = new TableColumns([
        codeCol,
        nameCol,
        priorityCol,
        defaultCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}