import Configs from '../../../../config.js';
import { TableColumn, TableColumns } from '../../../../components/_tables/TableColumns';
import moment from 'moment';


function presentData(items) {

    return items.map(item => {
        item.date = moment(item.date).format(Configs.formats.date);

        return item;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const idCol = new TableColumn()
        .setKey('id')
        .setDisplayName('#')

    const concernCol = new TableColumn()
        .setKey('concern')
        .setDisplayName('Concern')
        .setEditKey('concern')

    const dateCol = new TableColumn()
        .setKey('date')
        .setType('date')
        .setDisplayName('Date')
        .setEditKey('date')

    const tableCols = new TableColumns([
        concernCol,
        dateCol
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}