import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MiscContext } from '../../context/misc.context';
import './AddMasterDataPopups.sass';
import Enums from '../../enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import { useHistory } from 'react-router';
import MasterDataService from '../../services/masterData.service';
import PhoneNumberInput from '../_controls/PhoneNumberInput/PhoneNumberInput';
import PatientCommunicationService from '../../services/patientCommunication.service';


/**
 * Add Facility
 */
const AddFacilityPopup = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Master Data using API
            const newData = await MasterDataService.add(Enums.MasterData.Facility, data);
    
            setOpenSuccess(true);
            reset();

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.Facility ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Facility</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Code */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Code</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('code', { required: 'code required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.code?.message}</label>
                    </div>

                    {/* Name */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Name</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('name', { required: 'name required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.name?.message}</label>
                    </div>

                    {/* Address */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Address</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('address', { required: 'address required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.address?.message}</label>
                    </div>

                    {/* Scheduling Office Phone */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Scheduling Office Phone Number</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('schedulingOfficePhone', { required: 'scheduling office phone number required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.schedulingOfficePhone?.message}</label>
                    </div>

                    {/* Fax */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Fax Number</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('fax', { required: 'fax number required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.fax?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added facility</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add facility</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};


/**
 * Add Event Type
 */
const AddEventTypePopup = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Master Data using API
            const newData = await MasterDataService.add(Enums.MasterData.EventType, data);
    
            setOpenSuccess(true);
            reset();

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.EventType ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Department</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Code */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Code</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('code', { required: 'code required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.code?.message}</label>
                    </div>

                    {/* Name */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Name</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('name', { required: 'name required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.name?.message}</label>
                    </div>

                    {/* Short Name */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Short Name</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('shortname', { required: 'short name required', maxLength: { value: 10, message: 'short name can be 10 characters long at most' } })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.shortname?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added department</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add department</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};


/**
 * Add Procedure
 */
const AddProcedurePopup = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Master Data using API
            const newData = await MasterDataService.add(Enums.MasterData.Procedures, data);
    
            setOpenSuccess(true);
            reset();

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.Procedures ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Procedure</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Code */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Code</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('code', { required: 'code required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.code?.message}</label>
                    </div>

                    {/* Name */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Name</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('name', { required: 'name required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.name?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added procedure</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add procedure</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};


/**
 * Add Diagnosis
 */
const AddDiagnosisPopup = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Master Data using API
            const newData = await MasterDataService.add(Enums.MasterData.Diagnosis, data);
    
            setOpenSuccess(true);
            reset();

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.Diagnosis ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Diagnosis</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Code */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Code</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('code', { required: 'code required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.code?.message}</label>
                    </div>

                    {/* Description */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Description</label>
                        <textarea 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('description', { required: 'description required' })}
                        ></textarea>
                        <label className="add-master-data-popup__container__form__field__error">{errors?.description?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added diagnosis</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add diagnosis</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};


/**
 * Add Blood Thinner
 */
const AddBloodThinnerPopup = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Master Data using API
            const newData = await MasterDataService.add(Enums.MasterData.BloodThinner, data);
    
            setOpenSuccess(true);
            reset();

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.BloodThinner ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Blood Thinner</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Code */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Code</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('code', { required: 'code required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.code?.message}</label>
                    </div>

                    {/* Name */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Name</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('name', { required: 'name required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.name?.message}</label>
                    </div>

                    {/* Description */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Description</label>
                        <textarea 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('description', { required: 'description required' })}
                        ></textarea>
                        <label className="add-master-data-popup__container__form__field__error">{errors?.description?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added blood thinner</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add blood thinner</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};


/**
 * Add Priority
 */
const AddPriorityPopup = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Master Data using API
            const newData = await MasterDataService.add(Enums.MasterData.Priority, data);
    
            setOpenSuccess(true);
            reset();

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.Priority ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Priority</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Code */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Code</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('code', { required: 'code required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.code?.message}</label>
                    </div>

                    {/* Name */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Name</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('name', { required: 'name required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.name?.message}</label>
                    </div>

                    {/* Priority */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Priority (0: High ::: 5 Low)</label>
                        <input 
                            type="number"
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('priority', { required: 'priority required', min: { value: 0, message: 'must be 0 or greater' }, max: { value: 5, message: 'must be 5 or less' } })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.priority?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added priority</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add priority</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};


/**
 * Add Carrier
 */
 const AddCarrierPopup = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Master Data using API
            const newData = await MasterDataService.add(Enums.MasterData.Carrier, data);
    
            setOpenSuccess(true);
            reset();

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.Carrier ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Carrier</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Code */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Code</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('code', { required: 'code required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.code?.message}</label>
                    </div>

                    {/* Name */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Name</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('name', { required: 'name required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.name?.message}</label>
                    </div>

                    {/* Authorization URL */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Authorization URL</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('authorizationUrl')}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.authorizationUrl?.message}</label>
                    </div>

                    {/* Authorization Phone Number */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Authorization Phone Number</label>
                        <PhoneNumberInput defaultValue={''} extra={register('authorizationPhone', { minLength: { value: 10, message: 'phone number must be correct format' }, maxLength: { value: 10, message: 'phone number must be correct format' } })} />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.authorizationPhone?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added carrier</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add carrier</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};


/**
 * Add Clearance
 */
 const AddClearancePopup = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Master Data using API
            const newData = await MasterDataService.add(Enums.MasterData.Clearance, data);
    
            setOpenSuccess(true);
            reset();

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.Clearance ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Clearance</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Code */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Code</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('code', { required: 'code required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.code?.message}</label>
                    </div>

                    {/* Name */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Name</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('name', { required: 'name required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.name?.message}</label>
                    </div>

                    {/* Description */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Description</label>
                        <textarea 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('description', { required: 'description required' })}
                        ></textarea>
                        <label className="add-master-data-popup__container__form__field__error">{errors?.description?.message}</label>
                    </div>

                    {/* Physician */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Physician</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('physician')}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.physician?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added clearance</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add clearance</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};


/**
 * Add Patient Communication
 */
 const AddPatientCommunicationPopup = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ mode: 'onChange' });

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    // <> Form Submit
    async function onSubmit(data) {

        console.log(data);

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            const formData = new FormData();
            formData.append('title', data.title);
            if(data.description) formData.append('description', data.description);
            if(selectedFile) formData.append('file', selectedFile);
    
            // -> Add Master Data using API
            await PatientCommunicationService.add(formData);
    
            setOpenSuccess(true);
            reset();
            setSelectedFile(null);

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
        setSelectedFile(null);
    }

    // <> Helpers
    function onFileChosen(ev) {

        console.log(ev.target.files);
        const chosenFile = ev.target.files[0];
        setSelectedFile(chosenFile);

    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.PatientCommunication ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Patient Communication</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Title */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Title</label>
                        <input 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('title', { required: 'title required' })}
                            />
                        <label className="add-master-data-popup__container__form__field__error">{errors?.title?.message}</label>
                    </div>

                    {/* Description */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Description</label>
                        <textarea 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('description', { minLength: { value: 20, message: 'description must be 20 characters or more' } })}
                        ></textarea>
                        <label className="add-master-data-popup__container__form__field__error">{errors?.description?.message}</label>
                    </div>

                    {/* File */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">File</label>
                        <div className={`add-master-data-popup__container__form__field__input-file-wrapper ${selectedFile ? 'add-master-data-popup__container__form__field__input-file-wrapper--active' : ''}`}>
                            <div className="add-master-data-popup__container__form__field__input-file-wrapper__text">{selectedFile?.name || 'Drop file to upload'}</div>
                            <input 
                                type="file"
                                {...register('file')}
                                onChange={onFileChosen}
                                />
                        </div>
                        <label className="add-master-data-popup__container__form__field__error">{errors?.file?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added patient communication</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add patient communication</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};


/**
 * Add Boarding Link
 */
 const AddBoardingLink = () => {

    // <> Context
    const { addMasterDataOpenedPopup, addMasterDataOpenedPopupState, setMasterDataOpenedPopup, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [facilities, setFacilities] = useState(null);
    const [eventTypes, setEventTypes] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ mode: 'onChange' });

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [addMasterDataOpenedPopup])

    useEffect(() => {
        if(addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.BoardingLink) getData();
    }, [addMasterDataOpenedPopup])

    // <> Actions
    async function getData() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Get Master Data From API
            const prioritiesResponse = await MasterDataService.getTables([
                Enums.MasterData.BoardingLink,
                Enums.MasterData.Facility,
                Enums.MasterData.EventType,
            ]);
            const facilitiesRes = prioritiesResponse.data.data[Enums.MasterData.Facility];
            const eventTypesRes = prioritiesResponse.data.data[Enums.MasterData.EventType];
    
            // -> Set States
            setFacilities(facilitiesRes);
            setEventTypes(eventTypesRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Form Submit
    async function onSubmit(data) {

        try {

            setIsLoading(true);
    
            // -> Add Master Data using API
            await MasterDataService.add(Enums.MasterData.BoardingLink, data);
    
            setOpenSuccess(true);
            reset();

            if(addMasterDataOpenedPopupState.onSuccessFn) addMasterDataOpenedPopupState.onSuccessFn();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            setFailure(e.message);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        setMasterDataOpenedPopup('');
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-master-data-popup" style={{ display: addMasterDataOpenedPopup == Enums.AddMasterDataPopupTags.BoardingLink ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-master-data-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-master-data-popup__container">

                {/* Title */}
                <div className="add-master-data-popup__container__title">Add Patient Communication</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-master-data-popup__container__form">

                    {/* Facility */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Facility</label>
                        <select 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('facilityId', { required: 'facility required' })}
                        >
                            {facilities && facilities.map(f => (
                                <option value={f.id}>{f.name}</option>
                            ))}
                        </select>
                        <label className="add-master-data-popup__container__form__field__error">{errors?.facilityId?.message}</label>
                    </div>

                    {/* Event Type */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">Department</label>
                        <select 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('eventTypeId', { required: 'department required' })}
                        >
                            {eventTypes && eventTypes.map(e => (
                                <option value={e.id}>{e.name}</option>
                            ))}
                        </select>
                        <label className="add-master-data-popup__container__form__field__error">{errors?.eventTypeId?.message}</label>
                    </div>

                    {/* PDFs */}
                    <div className="add-master-data-popup__container__form__field">
                        <label className="add-master-data-popup__container__form__field__label">PDF</label>
                        <select 
                            className="add-master-data-popup__container__form__field__input" 
                            {...register('linkId', { required: 'pdf required' })}
                        >
                            {getPdfOptions().map(e => (
                                <option value={e.value}>{e.label}</option>
                            ))}
                        </select>
                        <label className="add-master-data-popup__container__form__field__error">{errors?.linkId?.message}</label>
                    </div>

                    {/* Submit */}
                    <button type="submit" className="add-master-data-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-master-data-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-master-data-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-master-data-popup__container__success-pane__icon" />
                        <div className="add-master-data-popup__container__success-pane__message">Successfully added boarding link</div>
                        <button className="add-master-data-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-master-data-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-master-data-popup__container__failure-pane__icon" />
                        <div className="add-master-data-popup__container__failure-pane__message">Failed to add boarding link</div>
                        <div className="add-master-data-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-master-data-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};

function getPdfOptions() {
    const keys = Object.keys(Enums.PdfTypes);
    const options = [];
    for(let i = 0; i < keys.length; i++) {
        options.push({
            value: Enums.PdfTypes[keys[i]].id,
            label: Enums.PdfTypes[keys[i]].label,
        })
    }
    console.log(keys);
    console.log(options);
    return options;
}


/**
 * Exports
 */
export {
    AddFacilityPopup,
    AddEventTypePopup,
    AddProcedurePopup,
    AddDiagnosisPopup,
    AddBloodThinnerPopup,
    AddPriorityPopup,
    AddCarrierPopup,
    AddClearancePopup,
    AddPatientCommunicationPopup,
    AddBoardingLink
}