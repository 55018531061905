import { faCheck, faCheckCircle, faExclamation, faExclamationCircle, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import IconButton from '../../_controls/IconButton/IconButton';
import '../Popup.sass';
import './ConfirmationPopup.sass';


const ConfirmationPopup = () => {

    // <> Context
    const { openPopup, openPopupState, setOpenPopup } = useContext(MiscContext);

    // <> Actions
    function closePopup() {
        setOpenPopup('');
    }

    function onOk() {
        if(openPopupState.onOk) openPopupState.onOk();
        setOpenPopup('');
    }

    // <> JSX
    return (
        <div className={`confirmation-popup popup ${openPopup == Enums.PopupTags.Confirmation ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container confirmation-popup__container">

                {/* Icon */}
                <div className={`confirmation-popup__container__icon ${openPopupState.level ? `confirmation-popup__container__icon--${openPopupState.level}` : ''}`}>
                    {openPopupState.level == 'info' && <FontAwesomeIcon icon={faInfoCircle} />}
                    {openPopupState.level == 'success' && <FontAwesomeIcon icon={faCheckCircle} />}
                    {openPopupState.level == 'warning' && <FontAwesomeIcon icon={faExclamationCircle} />}
                    {openPopupState.level == 'danger' && <FontAwesomeIcon icon={faExclamationCircle} />}
                </div>

                {/* Body */}
                <div className="confirmation-popup__container__body">

                    {/* Top */}
                    <div className="confirmation-popup__container__body__top">
                        <div className="confirmation-popup__container__body__top__title">{openPopupState.title}</div>
                    </div>

                    {/* Content */}
                    <div className="confirmation-popup__container__body__content">
                        <div className="confirmation-popup__container__body__content__message">{openPopupState.message}</div>
                    </div>

                    {/* Actions */}
                    <div className="confirmation-popup__container__body__actions">
                        <IconButton
                            text={openPopupState.cancelButtonText || 'Cancel'}
                            icon={faTimes}
                            btnStyle={'faded'}
                            onClickFn={closePopup} />
                        <IconButton
                            text={openPopupState.okButtonText || 'Ok'}
                            icon={faCheck}
                            btnStyle={'success'}
                            onClickFn={onOk} />
                    </div>

                </div>


            </div>
        </div>
    );
};

export default ConfirmationPopup;