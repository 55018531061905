import { faList, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Enums from '../../../enums';
import BrowserStorage from '../../../utils/browser.storage';
import BasicSelector from '../BasicSelector/BasicSelector';
import DropdownSearch from '../DropdownSearch/DropdownSearch';
import './SelectorSearchHybrid.sass';


/**
 * a basic dropdown selector
 * @param {{ name: string, options: [{ value, label }], value: number, setter: function, enableDefault: boolean? }} params 
 */
const SelectorSearchHybrid = ({ name, options, value, setter, icon = null, enableDefault = true, defaultOptionText = '-- Select --' }) => {

    // <> States
    const [mode, setMode] = useState(BrowserStorage.get(Enums.BrowserStorage.SelectorSearchHybridMode.Key) || Enums.SelectorSearchModes.Select);

    // <> Effects
    useEffect(() => {
        BrowserStorage.set(Enums.BrowserStorage.SelectorSearchHybridMode.Key, mode);
    }, [mode])

    // <> JSX
    return (
        <div className="selector-search-hybrid">

            {/* <div className="selector-search-hybrid__mode-switch">

                
                {mode === Enums.SelectorSearchModes.Select && (
                    <div className="selector-search-hybrid__mode-switch__icon" onClick={() => setMode(Enums.SelectorSearchModes.Search)}>
                        <FontAwesomeIcon icon={faSearch} /> Search
                    </div>
                )}

                
                {mode === Enums.SelectorSearchModes.Search && (
                    <div className="selector-search-hybrid__mode-switch__icon" onClick={() => setMode(Enums.SelectorSearchModes.Select)}>
                        <FontAwesomeIcon icon={faList} /> List
                    </div>
                )}

            </div>

            <div className="selector-search-hybrid__element">

                
                {mode === Enums.SelectorSearchModes.Select && <BasicSelector
                    name={name}
                    options={options}
                    value={value}
                    setter={setter}
                    enableDefault={enableDefault}
                    defaultOptionText={defaultOptionText} />}

                
                {mode === Enums.SelectorSearchModes.Search && <DropdownSearch
                    name={name}
                    options={options}
                    value={value}
                    setter={setter}
                    icon={icon} />}

            </div> */}

            <div className="selector-search-hybrid__search">
                {/* Search */}
                <DropdownSearch
                    name={name}
                    options={options}
                    value={value}
                    setter={setter}
                    icon={icon} />
            </div>
                

            <div className="selector-search-hybrid__select">
                {/* Selector */}
                <BasicSelector
                    name={name}
                    options={options}
                    value={value}
                    setter={setter}
                    enableDefault={enableDefault}
                    defaultOptionText={defaultOptionText} />

            </div>

        </div>
    );
};

export default SelectorSearchHybrid;