import Configs from '../../../../config.js';
import { TableColumn, TableColumns } from '../../../../components/_tables/TableColumns';
import moment from 'moment';


function presentData(items) {

    return items.map(item => {
        item.date = moment(item.date).format(Configs.formats.date);

        return item;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const deviceId = new TableColumn()
        .setKey('deviceId')
        .setDisplayName('Device ID')
        .setEditKey('deviceId')

    const issuingAgency = new TableColumn()
        .setKey('issuingAgency')
        .setDisplayName('Issuing Agency')
        .setEditKey('issuingAgency')

    const siteName = new TableColumn()
        .setKey('siteName')
        .setDisplayName('Site Name')
        .setEditKey('siteName')

    const siteCode = new TableColumn()
        .setKey('siteCode')
        .setDisplayName('Site Code')
        .setEditKey('siteCode')

    const deviceName = new TableColumn()
        .setKey('deviceName')
        .setDisplayName('Device Name')
        .setEditKey('deviceName')

    const deviceDescription = new TableColumn()
        .setKey('deviceDescription')
        .setDisplayName('Device Description')
        .setEditKey('deviceDescription')

    const manufacturer = new TableColumn()
        .setKey('manufacturer')
        .setDisplayName('Manufacturer')
        .setEditKey('manufacturer')

    const model = new TableColumn()
        .setKey('model')
        .setDisplayName('Model')
        .setEditKey('model')

    const serialNumber = new TableColumn()
        .setKey('serialNumber')
        .setDisplayName('Serial Number')
        .setEditKey('serialNumber')

    const lot = new TableColumn()
        .setKey('lot')
        .setDisplayName('lot')
        .setEditKey('lot')

    const isActive = new TableColumn()
        .setKey('isActive')
        .setDisplayName('Is Active')
        .setEditKey('isActive')

    const date = new TableColumn()
        .setKey('date')
        .setType('date')
        .setDisplayName('Date')
        .setEditKey('date')

    const tableCols = new TableColumns([
        deviceId,
        issuingAgency,
        siteName,
        siteCode,
        deviceName,
        deviceDescription,
        manufacturer,
        model,
        serialNumber,
        lot,
        isActive,
        date
    ])

    return tableCols;

}

/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions
}