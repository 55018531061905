import React, { Component } from 'react'
import Configs from '../config';
import Enums from '../enums';

const UserContext = React.createContext()

class UserProvider extends Component {

	// Context state
	defaultUser = {
		loggedIn: false,
		userId: null,
		email: null,
		name: null,
		phone: null,
		fax: null,
		createdOn: null,
		roles: []
	}

	state = {
		user: this.defaultUser
	}

	// Method to update state
	setUser = (user) => {
		this.setState((prevState) => ({ user }));
	}

	clearUser = () => {
		this.setState((prevState) => ({ user: this.defaultUser }));
	}

	isOnlyPhysician = () => {
		return this.state?.user?.roles && this.state.user.roles.length == 1 && this.state.user.roles.includes(Configs.userRoles.PHYSICIAN);
	}

	render() {
		const { children } = this.props;
		const { user } = this.state;
		const { setUser, clearUser, isOnlyPhysician } = this;

		return (
			<UserContext.Provider
				value={{
					user,
					setUser,
					clearUser,
					isOnlyPhysician
				}}
			>
				{children}
			</UserContext.Provider>
		)
	}
}

export { UserProvider, UserContext };