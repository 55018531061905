import React, { useContext } from 'react';
import * as Presenter from './SettingsGeneral.presenter';
import '../SettingsPane.sass';
import BrowserStorage from '../../../utils/browser.storage';
import Enums from '../../../enums';
import { UserContext } from '../../../context/user.context';
import Toggle from '../../../components/_controls/Toggle/Toggle';

const SettingsGeneral = () => {

    // <> Contexts
    const { user } = useContext(UserContext);

    // <> Actions
    function changeStartupPage(newPage) {
        BrowserStorage.set(Enums.BrowserStorage.StartupPage.Key, newPage);
    }

    // <> JSX
    return (
        <div className="settings-pane">
            
            {/* Startup Page */}
            <div className="settings-pane__section">
                <div className="settings-pane__section__title">Receive Notifications</div>
                <div className="settings-pane__section__body">
                    <div className="settings-pane__section__body__item">
                        <div className="settings-pane__section__body__item__label">Opt in to receive notifications</div>
                        <div className="settings-pane__section__body__item__control">
                            <Toggle />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SettingsGeneral;