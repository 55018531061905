import axios from 'axios';
import Configs from '../config.js';


function search(searchCol, searchQuery, sortCol, sortIsAsc, skip, limit) {
    return axios.post(`${Configs.apiUrl}/patients/search`, { searchCol, searchQuery, sortCol, sortIsAsc, skip, limit });
}

function searchAdvanced(query, skip, limit) {
    return axios.post(`${Configs.apiUrl}/patients/searchAdvanced`, { query, skip, limit });
}

function findById(patientId) {
    return axios.post(`${Configs.apiUrl}/patients/findById`, { patientId });
}

function add({ firstName, middleName, lastName, address, address1, address2, state, city, zipcode, dateofbirth, email, phone, ssn, sex, carrierId, carrierCode, carrierName, subscriberId, groupName, groupNumber }) {
    return axios.post(`${Configs.apiUrl}/patients/add`, { firstName, middleName, lastName, address, address1, address2, state, city, zipcode, dateofbirth, email, phone, ssn, sex, carrierId, carrierCode, carrierName, subscriberId, groupName, groupNumber });
}

function updateOne(patientId, { firstName, middleName, lastName, address1, address2, state, city, zipcode, dateofbirth, email, phone, ssn, sex, carrierId, carrierCode, carrierName, subscriberId, groupName, groupNumber }) {
    return axios.put(`${Configs.apiUrl}/patients/updateOne`, { patientId, firstName, middleName, lastName, address1, address2, state, city, zipcode, dateofbirth, email, phone, ssn, sex, carrierId, carrierCode, carrierName, subscriberId, groupName, groupNumber });
}

function deleteOne(patientId) {
    return axios.post(`${Configs.apiUrl}/patients/deleteOne`, { patientId });
}


/**
 * Exports
 */
const PatientService = {
    search,
    searchAdvanced,
    findById,
    add,
    updateOne,
    deleteOne
}
export default PatientService;