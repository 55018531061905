const Configs = {
    apiUrl: `${process.env.NODE_ENV == 'production' ? 'https://galen.mycolonclinic.com/api' : (process.env.REACT_APP_TEST_API || 'http://localhost:3333/api')}`,
    apiPublicFolderUrl: `${process.env.NODE_ENV == 'production' ? 'https://galen.mycolonclinic.com/' : (process.env.REACT_APP_TEST_API_PUBLIC_FOLDER || 'http://localhost:3333/')}`,
    formats: {
        date: 'MM/DD/YYYY',
        time: 'hh:mm a',
        dateTime: 'MM/DD/YYYY hh:mm a',
        dateInputDate: 'YYYY-MM-DD',
        dateInputDateTime: 'YYYY-MM-DD[T]HH:mm',
        apiDateTime: 'YYYY-MM-DD HH:mm:ss',
        friendlyDate: 'dddd, MMMM Do, YYYY',
        isoDate: 'YYYY-MM-DD',
        headerDateTime: 'dddd, MMMM Do, YYYY hh:mm:ss a [GMT]ZZ'
    },
    userRoles: {
        ADMIN: 'admin',
        COORDINATOR: 'coordinator',
        PHYSICIAN: 'physician'
    },
    misc: {
        physiciansSearchMatchSensitivity: 0.7,
        historyPageSize: 10,
        patientsAdvancedSearchResultsLimit: 10,
        searchDelay: 300,
        dropdownSearchQueryMinLengthTrigger: 3,
        mobileBreakpoint: 480,
        alertTTL: {
            Short: 3000,
            Mid: 5000,
            Long: 10000
        }
    },
    departmentColors: ['#448866', '#884466', '#668822', '#99bb22', '#229966', '#882299', '#227722', '#995599']
    
}

export default Configs;