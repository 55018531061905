import axios from 'axios';
import Configs from '../config';


function getTable(table) {
    return axios.post(`${Configs.apiUrl}/masterData/get`, { table });
}

function getTables(tables) {
    return axios.post(`${Configs.apiUrl}/masterData/getMany`, { tables });
}

function add(formData) {
    return axios.post(`${Configs.apiUrl}/patientCommunications/add`, formData);
}

function changeFile(formData) {
    return axios.post(`${Configs.apiUrl}/patientCommunications/changeFile`, formData);
}

function updateCol(table, rowId, col, val) {
    return axios.put(`${Configs.apiUrl}/masterData/updateCol`, { table, rowId, col, val });
}

function deleteRow(table, rowId) {
    return axios.delete(`${Configs.apiUrl}/masterData/deleteRow/${table}/${rowId}`);
}


/**
 * Exports
 */
const PatientCommunicationService = {
    getTable,
    getTables,
    add,
    changeFile,
    updateCol,
    deleteRow
}
export default PatientCommunicationService;