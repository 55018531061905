import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import GlobalHttpErrorHandler from "../../errors/globalHttpErrorHandler";
import { MiscContext } from '../../context/misc.context';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import './AddUserPopup.sass';
import ManageUserService from "../../services/manageUser.service";
import HttpErrors from "../../errors/HttpErrors";
import Enums from '../../enums';
import PhoneNumberInput from "../_controls/PhoneNumberInput/PhoneNumberInput";


const AddUserPopup = () => {

    // <> Context
    const { isAddUserPopupOpen, toggleAddUserPopup, addUserSuccessFn, setAddUserSuccessFn, addAlert } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [failure, setFailure] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');

    // <> Form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // <> Effects
    useEffect(() => {
        setIsLoading(false);
        setOpenSuccess(false);
        reset();
    }, [isAddUserPopupOpen])

    // <> Form Submit
    async function onSubmit(data) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);
    
            // -> Add User using API
            const newUserRes = await ManageUserService.addUser(data);
            const newUserId = newUserRes.data.data.user.id;
    
            setOpenSuccess(true);
            reset();

            if(addUserSuccessFn) addUserSuccessFn(newUserId);
        }
        catch(e) {
            if(e instanceof HttpErrors.HttpResponseError && e.status == Enums.HttpCodes.BadRequest) {
                setErrorMsg(e.message);
            }
            else {
                GlobalHttpErrorHandler(e, history, addAlert);
                setFailure(e.message);
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Actions
    function closePopup() {
        toggleAddUserPopup();
        setAddUserSuccessFn(null);
        setFailure(null);
    }

    // <> JSX
    return (
        <div className="add-user-popup" style={{ display: isAddUserPopupOpen ? 'flex' : 'none' }}>

            {/* Overlay */}
            <div className="add-user-popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="add-user-popup__container">

                {/* Title */}
                <div className="add-user-popup__container__title">Add User</div>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="add-user-popup__container__form">

                    {/* Email */}
                    <div className="add-user-popup__container__form__field">
                        <label className="add-user-popup__container__form__field__label">Email</label>
                        <input 
                            className="add-user-popup__container__form__field__input" 
                            {...register('email', { required: 'email required' })}
                            />
                        <label className="add-user-popup__container__form__field__error">{errors?.email?.message}</label>
                    </div>

                    {/* Name */}
                    <div className="add-user-popup__container__form__field">
                        <label className="add-user-popup__container__form__field__label">Name</label>
                        <input 
                            className="add-user-popup__container__form__field__input" 
                            {...register('name', { required: 'name required' })}
                            />
                        <label className="add-user-popup__container__form__field__error">{errors?.name?.message}</label>
                    </div>

                    {/* Password */}
                    <div className="add-user-popup__container__form__field">
                        <label className="add-user-popup__container__form__field__label">Password</label>
                        <input 
                            className="add-user-popup__container__form__field__input" 
                            {...register('password', { required: 'password required' })}
                            />
                        <label className="add-user-popup__container__form__field__error">{errors?.password?.message}</label>
                    </div>

                    {/* Phone */}
                    <div className="add-user-popup__container__form__field">
                        <label className="add-user-popup__container__form__field__label">Phone Number</label>
                        <PhoneNumberInput addClassName={'add-user-popup__container__form__field__input'} extra={register('phone', { required: 'phone required' })} />
                        <label className="add-user-popup__container__form__field__error">{errors?.phone?.message}</label>
                    </div>

                    {/* Fax */}
                    <div className="add-user-popup__container__form__field">
                        <label className="add-user-popup__container__form__field__label">Fax</label>
                        <PhoneNumberInput addClassName={'add-user-popup__container__form__field__input'} extra={register('fax')} />
                        <label className="add-user-popup__container__form__field__error">{errors?.fax?.message}</label>
                    </div>

                    {/* Errors */}
                    {errorMsg && <span className="error-msg">{errorMsg}</span>}

                    {/* Submit */}
                    <button type="submit" className="add-user-popup__container__form__submit-btn btn btn-primary" disabled={isLoading}>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} className="add-user-popup__container__form__spinner" />}
                        <span>Add</span>
                    </button>
                    

                </form>

                {/* Success Pane */}
                {openSuccess && (
                    <div className="add-user-popup__container__success-pane">
                        <FontAwesomeIcon icon={faCheckCircle} className="add-user-popup__container__success-pane__icon" />
                        <div className="add-user-popup__container__success-pane__message">Successfully added user</div>
                        <button className="add-user-popup__container__success-pane__close-btn btn btn-lg btn-success" onClick={closePopup}>Close</button>
                    </div>
                )}

                {/* Failure Pane */}
                {failure && (
                    <div className="add-user-popup__container__failure-pane">
                        <FontAwesomeIcon icon={faExclamationCircle} className="add-user-popup__container__failure-pane__icon" />
                        <div className="add-user-popup__container__failure-pane__message">Failed to add user</div>
                        <div className="add-user-popup__container__failure-pane__message2">{failure}</div>
                        <button className="add-user-popup__container__failure-pane__close-btn btn btn-lg btn-danger" onClick={closePopup}>Close</button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default AddUserPopup;