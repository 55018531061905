import Configs from '../../config.js';
import { TableColumn, TableColumns } from '../../components/_tables/TableColumns';
import moment from 'moment';
import Enums from '../../enums';
import ClearanceStatusPicker from '../ClearanceStatusPicker/ClearanceStatusPicker.js';
import StringFormatter from '../../utils/string.formats.js';
import Tag from '../Tag/Tag.js';
import EventStatusPicker from '../EventStatusPicker/EventStatusPicker.js';


// Map Patient Data to Desired Display Values
function presentData(events, openReschedulePopup, refresh, isOnlyPhysician) {

    return events.map(event => {

        // Preserve the event original params before formatting
        const originalEvent = {...event};

        // Tag Styles
        const tagExtraStyles = { display: 'block', width: '100%', textAlign: 'center' };

        // Coordinator name (shortened)
        const coordinatorNameParts = event.coordinatorName.split(' ');
        if(coordinatorNameParts.length > 1) event.coordinatorName = [coordinatorNameParts[0], ...coordinatorNameParts.splice(1).map(n => `${n.charAt(0)}.`)].join(' ');

        // Event Duration
        event.duration = StringFormatter.timeDifference(event.dateFrom, event.dateTo);

        // Start Date (pops up reschedule if user is admin or coordinator)
        event.dateFrom = isOnlyPhysician 
            ? moment(event.dateFrom).format(Configs.formats.dateTime)
            : <div class="clickable" onClick={() => openReschedulePopup(event.id)}>{moment(event.dateFrom).format(Configs.formats.dateTime)}</div>;

        // Date to
        event.dateTo = moment(event.dateTo).format(Configs.formats.dateTime);

        // Patient
        event.patientName = {
            link: { pathname: `/patients/demographics`, state: { patientId: event.patientId, patientName: event.patientName } },
            text: `${event.patientFirstName} ${event.patientMiddleName} ${event.patientLastName}`
        }

        // RTMU
        event.isEmergencyLbl = event.isEmergency 
            ? <Tag text={'Yes'} tagStyle={'green'} extraStyles={tagExtraStyles} />
            : <div style={{color: 'var(--faded-color'}}>No</div>;

        
        // Event Type (department)
        event.eventTypeShortname = <span style={{ color: event.color || '#000000' }}>{event.eventTypeShortname}</span>

        // Clearance pickers
        event.medicalClearanceIcon = <ClearanceStatusPicker eventDetails={originalEvent} clearanceType={'medicalClearanceStatus'} />;
        event.cardiacClearanceIcon = <ClearanceStatusPicker eventDetails={originalEvent} clearanceType={'cardiacClearanceStatus'} />;
        event.otherClearanceIcon = <ClearanceStatusPicker eventDetails={originalEvent} clearanceType={'otherClearanceStatus'} />;

        // Event status picker
        event.statusPicker = <EventStatusPicker eventDetails={originalEvent} tagExtraStyles={tagExtraStyles} refresh={refresh} />

        // Insurance Auth
        switch(event.insuranceAuthorizationStatus) {
            case Enums.InsuranceAuthorizationStatuses.NotRequired:
                event.insuranceAuthorizationStatus = <Tag text={'Not Required'} tagStyle={'gray'} size={'xsm'} extraStyles={tagExtraStyles} />;
                break;
            case Enums.InsuranceAuthorizationStatuses.TBD:
                event.insuranceAuthorizationStatus = <Tag text={'TBO'} tagStyle={'yellow'} size={'xsm'} extraStyles={tagExtraStyles} />;
                break;
            case Enums.InsuranceAuthorizationStatuses.Requested:
                event.insuranceAuthorizationStatus = <Tag text={'Requested'} tagStyle={'orange'} size={'xsm'} extraStyles={tagExtraStyles} hoverText={(
                    <>
                        <span>{event.insuranceAuthorizationStatusUpdaterName}</span>
                        <span>{moment(event.insuranceAuthorizationStatusUpdatedOn).format(Configs.formats.dateTime)}</span>
                    </>
                )} />;
                break;
            case Enums.InsuranceAuthorizationStatuses.Approved:
                event.insuranceAuthorizationStatus = <Tag text={'Approved'} tagStyle={'green'} size={'xsm'} extraStyles={tagExtraStyles} hoverText={(
                    <>
                        <span>{event.carrierName}</span>
                        <span>{event.insuranceAuthorizationNumber}</span>
                    </>
                )} />;
                break;
            case Enums.InsuranceAuthorizationStatuses.Rejected:
                event.insuranceAuthorizationStatus = <Tag text={'Rejected'} tagStyle={'red'} size={'xsm'} extraStyles={tagExtraStyles} />;
                break;
            default:
                break;
        }

        // Extra Clearances
        let extraClearancesCount = 0;
        let extraClearanceStatuses = [];
        if(event.clearanceStatuses) {
            extraClearanceStatuses = event.clearanceStatuses.split(',');
            extraClearancesCount = extraClearanceStatuses.length;
        }
        if(extraClearancesCount == 0)                                               event.extraClearances = <Tag text={` - `} tagStyle={'gray'} size={'xsm'} extraStyles={tagExtraStyles} link={{ pathname: '/event/details', state: { eventId: event.id, scrollToSection: Enums.EventDetailsPageSections.Clearances } }} />;
        else if(extraClearanceStatuses.includes(Enums.ClearanceStatuses.Rejected))  event.extraClearances = <Tag text={`View (${extraClearancesCount})`} tagStyle={'red'} size={'xsm'} extraStyles={tagExtraStyles} link={{ pathname: '/event/details', state: { eventId: event.id, scrollToSection: Enums.EventDetailsPageSections.Clearances } }} />;
        else if(extraClearanceStatuses.includes(Enums.ClearanceStatuses.Pending))   event.extraClearances = <Tag text={`View (${extraClearancesCount})`} tagStyle={'yellow'} size={'xsm'} extraStyles={tagExtraStyles} link={{ pathname: '/event/details', state: { eventId: event.id, scrollToSection: Enums.EventDetailsPageSections.Clearances } }} />;
        else                                                                        event.extraClearances = <Tag text={`View (${extraClearancesCount})`} tagStyle={'green'} size={'xsm'} extraStyles={tagExtraStyles} link={{ pathname: '/event/details', state: { eventId: event.id, scrollToSection: Enums.EventDetailsPageSections.Clearances } }} />;

        return event;
    });
}

// Table Pagination Columns Scheme
function tableColumnDefinitions() {

    const dateFromCol = new TableColumn()
        .setKey('dateFrom')
        .setDisplayName('Date of Service')
        .setSortKey('dateFrom')
        .setCustomStyles({ width: '1%' })

    const durationCol = new TableColumn()
        .setKey('duration')
        .setDisplayName('Duration')
        .setCustomStyles({ width: '1%', padding: '0.5em', fontWeight: 'bold' })

    const isEmergencyCol = new TableColumn()
        .setKey('isEmergencyLbl')
        .setDisplayName('RTMU')
        .setCustomStyles({ width: '1%', textAlign: 'center' })

    const patientCol = new TableColumn()
        .setKey('patientName')
        .setDisplayName('Patient')
        .setSortKey('patientName')
        .setIsLink(true)
        .setCustomStyles({ fontWeight: 'bold' })

    const physicianCol = new TableColumn()
        .setKey('physicianName')
        .setDisplayName('Physician')
        .setSortKey('physicianName')

    const facilityCol = new TableColumn()
        .setKey('facilityName')
        .setDisplayName('Facility')
        .setSortKey('facilityName')

    const coordinatorCol = new TableColumn()
        .setKey('coordinatorName')
        .setDisplayName('Coordinator')
        .setSortKey('coordinatorName')

    const eventTypeCol = new TableColumn()
        .setKey('eventTypeShortname')
        .setDisplayName('Type')
        .setCustomStyles({ fontWeight: 'bold', width: '1%', padding: '0 1em' })

    const statusCol = new TableColumn()
        .setKey('statusPicker')
        .setDisplayName('Status')
        .setCustomStyles({ width: '1%', padding: '0 1em' })

    const medicalClearanceCol = new TableColumn()
        .setKey('medicalClearanceIcon')
        .setDisplayName('MEDCLR')
        .setIsCentered(true)
        .setCustomStyles({ width: '1%' })

    const cardiacClearanceCol = new TableColumn()
        .setKey('cardiacClearanceIcon')
        .setDisplayName('CRDCLR')
        .setIsCentered(true)
        .setCustomStyles({ width: '1%' })

    const otherClearanceCol = new TableColumn()
        .setKey('otherClearanceIcon')
        .setDisplayName('PLMCLR')
        .setIsCentered(true)
        .setCustomStyles({ width: '1%' })

    const extraClearancesCol = new TableColumn()
        .setKey('extraClearances')
        .setDisplayName('OTHCLR')
        .setIsCentered(true)
        .setCustomStyles({ width: '1%', fontSize: '1.0em' })

    const insuranceAuthorizationStatusCol = new TableColumn()
        .setKey('insuranceAuthorizationStatus')
        .setDisplayName('AUTH')
        .setCustomStyles({ width: '1%' })

    const eventTableCols = new TableColumns([
        dateFromCol,
        durationCol,
        isEmergencyCol,
        patientCol,
        physicianCol,
        facilityCol,
        coordinatorCol,
        eventTypeCol,
        statusCol,
        medicalClearanceCol,
        cardiacClearanceCol,
        otherClearanceCol,
        extraClearancesCol,
        insuranceAuthorizationStatusCol
    ])

    return eventTableCols;

}

function formatFacilitiesForSelector(facilitiesArg) {
    return facilitiesArg.map(f => { return { value: f.id, label: f.name } });
}

function formatCoordinatorsForSelector(coordinators) {
    return coordinators.map(c => { return { value: c.id, label: c.name } });
}

function formatEventTypesForSelector(types) {
    return types.map(t => { return { value: t.id, label: t.name } });
}

function formatPhysiciansForSelector(physiciansArg, myId = null) {
    let result = physiciansArg.map(p => { return { value: p.id, label: p.name } });

    if(myId) {
        result = result.filter(i => i.value != myId);
        result.unshift({ value: myId, label: 'Me' });
    }

    return result;
}

function statusesForSelector() {
    return [
        {
            value: Enums.EventStatuses.Pending,
            label: 'Event Created'
        },
        {
            value: Enums.EventStatuses.BoardingSlipGenerated,
            label: 'Boarding Slip Generated'
        },
        {
            value: Enums.EventStatuses.WaitingForConfirmation,
            label: 'Awaiting Confirmation'
        },
        {
            value: Enums.EventStatuses.Confirmed,
            label: 'Confirmed'
        },
        {
            value: Enums.EventStatuses.Completed,
            label: 'Completed'
        },
        {
            value: Enums.EventStatuses.Cancelled,
            label: 'Canceled'
        },
    ]
}

function rtmuForSelector() {
    return [
        {
            value: 0,
            label: 'No'
        },
        {
            value: 1,
            label: 'Yes'
        }
    ]
}


/**
 * Exports
 */
export {
    presentData,
    tableColumnDefinitions,
    formatFacilitiesForSelector,
    formatCoordinatorsForSelector,
    formatPhysiciansForSelector,
    statusesForSelector,
    rtmuForSelector,
    formatEventTypesForSelector
}