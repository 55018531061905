import React from 'react';
import './Tag.sass';
import { Link } from 'react-router-dom';

const Tag = ({ text, tagStyle = 'primary', size = 'md', extraStyles = {}, link = null, hoverText = null }) => {

    // <> JSX
    return (<>
        <div className="simple-tag__wrapper">
            {!link && (
                <span className={`simple-tag simple-tag--${tagStyle} simple-tag--${size}`} style={extraStyles}>
                    {text}
                </span>
            )}
            {link && (
                <Link to={link}>
                    <span className={`simple-tag simple-tag--${tagStyle} simple-tag--${size}`} style={extraStyles}>
                        {text}
                    </span>
                </Link>
            )}

            {/* Hover Text */}
            {hoverText && (
                <div className="simple-tag__wrapper__hover-text">
                    {hoverText}
                </div>
            )}
        </div>
    </>);
};

export default Tag;