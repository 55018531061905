import { faCheck, faCheckCircle, faCheckSquare, faExclamation, faExclamationCircle, faInfoCircle, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Configs from '../../../config';
import { MiscContext } from '../../../context/misc.context';
import Enums from '../../../enums';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import EventService from '../../../services/event.service';
import Loader from '../../Loader/Loader';
import IconButton from '../../_controls/IconButton/IconButton';
import Toggle from '../../_controls/Toggle/Toggle';
import '../Popup.sass';
import './EventConfirmWithReschedulePopup.sass';


const EventConfirmWithReschedulePopup = () => {

    // <> Context
    const { addAlert, openPopup, openPopupState, setOpenPopup } = useContext(MiscContext);

    // <> History
    const history = useHistory();

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [durationHours, setDurationHours] = useState(1);
    const [durationMinutes, setDurationMinutes] = useState(0);
    const [sendPatientComEmail, setSendPatientComEmail] = useState(false);
    const [sendPatientComSms, setSendPatientComSms] = useState(false);
    const [selectedPatientComIds, setSelectedPatientComIds] = useState([]);

    // <> Effects
    useEffect(() => {

        if(openPopup == Enums.PopupTags.EventConfirmWithReschedule && openPopupState?.eventId) {
            getEvent(openPopupState.eventId);
        }

    }, [openPopup, openPopupState]);

    useEffect(() => {

        if(eventDetails) {
            setStartDate(moment(eventDetails.dateFrom).format(Configs.formats.dateInputDateTime));
            setDurationHours(moment(eventDetails.dateTo).diff(moment(eventDetails.dateFrom), 'hours'));
            setDurationMinutes(moment(eventDetails.dateTo).diff(moment(eventDetails.dateFrom), 'minutes') % 60);

            if(eventDetails?.patientCommunications?.length > 0) {
                setSendPatientComEmail(true);
                setSendPatientComSms(true);
                setSelectedPatientComIds(eventDetails.patientCommunications.map(pc => pc.id));
            }
        }
    }, [eventDetails])

    // <> Actions
    async function getEvent(eventId) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Fetch Promises
            const eventResponse = await EventService.getWithDetails(eventId);
            const eventRes = eventResponse.data.data.event;

            // -> Set State
            setEventDetails(eventRes);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function setAsConfirmed() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as Confirmed in API
            await EventService.setAsConfirmed({ 
                eventId: eventDetails.id, 
                sendPatientComEmail, 
                sendPatientComSms, 
                patientComIds: selectedPatientComIds 
            });

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully set as confirmed'
            })

            // -> Reset
            reset();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            throw e;
        }
        finally {
            setIsLoading(false);
        }
    }

    async function setAsConfirmedAndReschedule() {

        try {

            const endDate = moment(startDate).add(durationHours, 'hours').add(durationMinutes, 'minutes');

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as Confirmed & Reschedule in API
            await EventService.setAsConfirmedAndReschedule({
                eventId: eventDetails.id, 
                dateFrom: moment(startDate).format(Configs.formats.apiDateTime), 
                dateTo: moment(endDate).format(Configs.formats.apiDateTime),
                sendPatientComEmail, 
                sendPatientComSms, 
                patientComIds: selectedPatientComIds
            });

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully rescheduled and set as confirmed'
            })

            // -> Reset
            reset();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
            throw e;
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function closePopup() {
        reset();
        setOpenPopup('');
    }

    function reset() {
        setIsLoading(false);
        setEventDetails(null);
        setStartDate(null);
        setDurationHours(1);
        setDurationMinutes(0);
        setSendPatientComEmail(false);
        setSendPatientComSms(false);
        setSelectedPatientComIds([]);
    }

    async function onOk() {

        try {

            // -> Check if Reschedule Needed
            const endDate = moment(startDate).add(durationHours, 'hours').add(durationMinutes, 'minutes');
            const doReschedule = (
                !moment(startDate).isSame(moment(openPopupState.dateFrom))
                || !moment(endDate).isSame(moment(openPopupState.dateTo))
            );
    
            // -> Set as Confirmed & Reschedule if Requested
            if(!doReschedule) await setAsConfirmed();
            else await setAsConfirmedAndReschedule();
                
            // -> Close & Run Success Callback if Defined
            setOpenPopup('');
            if(openPopupState.onSuccess) openPopupState.onSuccess();
        }
        catch(e) {
            // Already handled in the called functions
        }
    }

    function onComChecked(comId) {
        if(!selectedPatientComIds.includes(comId)) setSelectedPatientComIds(prev => [...prev, comId]);
        else setSelectedPatientComIds(prev => prev.filter(c => c != comId));
    }

    function selectAllComs() {
        setSelectedPatientComIds(eventDetails.patientCommunications.map(c => c.id));
    }

    function selectNoComs() {
        setSelectedPatientComIds([]);
    }

    // <> JSX
    return (
        <div className={`event-confirm-reschedule-popup popup ${openPopup == Enums.PopupTags.EventConfirmWithReschedule ? 'popup--show' : ''}`}>

            {/* Overlay */}
            <div className="popup__overlay" onClick={closePopup}></div>

            {/* Container */}
            <div className="popup__container event-confirm-reschedule-popup__container">

                {/* Loader */}
                <Loader isLoading={isLoading} />

                {/* Icon */}
                <div className={`event-confirm-reschedule-popup__container__icon event-confirm-reschedule-popup__container__icon--info`}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </div>

                {/* Body */}
                {eventDetails && <div className="event-confirm-reschedule-popup__container__body">

                    {/* Top */}
                    <div className="event-confirm-reschedule-popup__container__body__top">
                        <div className="event-confirm-reschedule-popup__container__body__top__title">Set as Confirmed</div>
                    </div>

                    {/* Content */}
                    <div className="event-confirm-reschedule-popup__container__body__content">
                        
                        <div className="event-confirm-reschedule-popup__container__body__content__message">Are you sure you want to set this event as confirmed and schedule it?</div>
                        <br />

                        {/* Reschedule */}
                        {/* <label><input type="checkbox" value={reschedule} onChange={ev => setReschedule(ev.target.checked)} /> Reschedule</label> */}


                        <div className="popup__container__form">
                            
                            {/* Start Date Time */}
                            <div className="popup__container__form__input">
                                <div className="popup__container__form__input__label">Start Date-Time</div>
                                <input 
                                    className="popup__container__form__input__control" 
                                    type="datetime-local" 
                                    min={moment().subtract(150, 'years').format(Configs.formats.dateInputDateTime)}
                                    max={moment().add(10, 'years').format(Configs.formats.dateInputDateTime)}
                                    value={startDate} 
                                    onChange={ev => setStartDate(ev.target.value)} />
                            </div>

                            {/* Duration */}
                            <div className="popup__container__form__input">
                                <div className="popup__container__form__input__label">Duration</div>
                                <div className="event-confirm-reschedule-popup__container__body__content__input-group">
                                    <input className="popup__container__form__input--small" type="number" min="0" max="30" value={durationHours} onChange={ev => setDurationHours(ev.target.value)} />
                                    <span>h</span>
                                    <input className="popup__container__form__input--small" type="number" min="0" max="59" value={durationMinutes} onChange={ev => setDurationMinutes(ev.target.value)} />
                                    <span>m</span>
                                </div>
                            </div>

                        </div>

                        {eventDetails?.patientCommunications?.length > 0 && (
                            <>
                                <hr />
                                
                                {/* Title */}
                                <div className="event-confirm-reschedule-popup__container__body__content__title">
                                    Patient Notification
                                </div>

                                {/* Coms Table Selection Assistants */}
                                <div className="event-confirm-reschedule-popup__container__body__content__selection-buttons">
                                    <IconButton
                                        text={'Select All'}
                                        icon={faCheckSquare}
                                        btnStyle={'info'}
                                        size={'xsm'}
                                        isDisabled={!sendPatientComEmail && !sendPatientComSms}
                                        onClickFn={selectAllComs} />
                                    <IconButton
                                        text={'Select None'}
                                        icon={faSquare}
                                        btnStyle={'info'}
                                        size={'xsm'}
                                        isDisabled={!sendPatientComEmail && !sendPatientComSms}
                                        onClickFn={selectNoComs} />
                                </div>

                                {/* Patient Com Selection */}
                                <table className={`event-confirm-reschedule-popup__container__body__content__coms-table`}>
                                    <thead>
                                        <th></th>
                                        <th>Title</th>
                                        <th>Description</th>
                                    </thead>
                                    <tbody>
                                    {eventDetails.patientCommunications.map((c, i) => (
                                        <tr index={i}>
                                            <td center-col="true">
                                                <input 
                                                    type="checkbox" 
                                                    disabled={!sendPatientComEmail && !sendPatientComSms}
                                                    checked={selectedPatientComIds.includes(c.id)}
                                                    onChange={ev => onComChecked(c.id)} />
                                            </td>
                                            <td>{c.title}</td>
                                            <td>{c.description}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                {/* Send Patient Communications Email & SMS */}
                                <div className="event-confirm-reschedule-popup__container__body__content__toggles">
                                    <label>
                                        <input type="checkbox" checked={sendPatientComEmail} onChange={ev => setSendPatientComEmail(ev.target.checked)} />
                                        Send patient communications email
                                    </label>
                                    <label>
                                        <input type="checkbox" checked={sendPatientComSms} onChange={ev => setSendPatientComSms(ev.target.checked)} />
                                        Send patient communications sms
                                    </label>
                                </div>
                            </>
                        )}
                        
                    </div>

                    {/* Actions */}
                    <div className="event-confirm-reschedule-popup__container__body__actions">
                        <IconButton
                            text={'Cancel'}
                            icon={faTimes}
                            btnStyle={'faded'}
                            onClickFn={closePopup} />
                        <IconButton
                            text={'Confirm & Schedule'}
                            icon={faCheck}
                            btnStyle={'success'}
                            onClickFn={onOk} />
                    </div>

                </div>}


            </div>
        </div>
    );
};

export default EventConfirmWithReschedulePopup;