import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { MiscContext } from '../../context/misc.context';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import HistoryService from '../../services/history.service';
import './History.sass';
import Configs from '../../config.js';
import * as Presenter from './History.presenter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCaretDown, faCheckCircle, faClock, faHospital, faInfoCircle, faMinusCircle, faSpinner, faStethoscope, faUser, faUserInjured } from '@fortawesome/free-solid-svg-icons';
import BasicSelector from '../../components/_controls/BasicSelector/BasicSelector';
import ManageUserService from '../../services/manageUser.service';
import moment from 'moment';
import StaticAlert from '../../components/StaticAlert/StaticAlert';
import IconButton from '../../components/_controls/IconButton/IconButton';
import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const History = () => {

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [entries, setEntries] = useState([]);
    const [page, setPage] = useState(0);
    const pageSize = Configs.misc.historyPageSize;

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [dateRanges, setDateRanges] = useState([{startDate: moment().toDate(), endDate: moment().toDate(), key: 'selection'}]);
    const [users, setUsers] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);

    // <> Context
    const { addAlert } = useContext(MiscContext); 

    // <> History
    const history = useHistory();

    // <> Location
    const location = useLocation();

    // <> Effects
    useEffect(() => {
        getUsers();
    }, [])

    useEffect(() => {
        console.log('a ' + page)
        getData();
    }, [page]);

    useEffect(() => {
        getData(true);
    }, [fromDate, toDate, selectedUserId])

    // <> Actions
    async function getData(renew = false) {

        console.log(`--- GET HISTORY ----`)

        try {

            // -> Set Loader ON
            setIsLoading(true);

            // -> Fetch Data From API
            const historyResponse = await HistoryService.search({
                skip: renew ? 0 : page * pageSize, 
                limit: pageSize,
                fromDate: fromDate ? moment(fromDate).format(Configs.formats.apiDateTime) : null,
                toDate: toDate ? moment(toDate).format(Configs.formats.apiDateTime) : null,
                userId: selectedUserId && selectedUserId != 0 ? selectedUserId : null,
                eventId: location?.state?.eventId ?? null,
                patientId: location?.state?.patientId ?? null,
            });
            const historyEntriesRes = historyResponse.data.data.historyEntries;

            // -> Format
            const historyEntriesPresentable = Presenter.presentData(historyEntriesRes);

            // -> Set States
            if(renew) setEntries(historyEntriesPresentable);
            else setEntries(prev => [...prev, ...historyEntriesPresentable]);

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function getUsers() {

        try {

            // -> Set Loader ON
            setIsLoading(true);

            // -> Fetch Users From API
            const usersResponse = await ManageUserService.getAll();
            const usersRes = usersResponse.data.data.users;

            // -> Set States
            setUsers(usersRes);

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    function nextPage() {
        setPage(prev => page + 1);
    }

    function dateRangeHandler(range) {

        let start = moment(range.selection.startDate);
        let end = moment(range.selection.endDate);
        if(start.isSame(end)) end = end.add(1, 'day');

        // Set States
        setFromDate(start);
        setToDate(end);
        setDateRanges([range.selection]);
    }

    // <> JSX
    return (
        <div className="history-page">

            {/* Page Title */}
            <div className="history-page__top">
                {/* <span className="history-page__top__title">Audit Trail</span>
                <span className="history-page__top__subtitle">View actions performed by the users.</span> */}
                {location?.state?.eventId && <StaticAlert level={'info'} message={`Only entries belonging to event #${location.state.eventId} are shown`} />}
                {location?.state?.patientName && <StaticAlert level={'info'} message={`Only entries belonging to patient ${location.state.patientName} are shown`} />}
            </div>

            {/* Content */}
            <div className="history-page__content">

                {/* Filters */}
                <div className="history-page__content__filters">

                    {/* Date Range */}
                    <div className="history-page__content__filters__filter history-page__content__filters__date-range-filter">
                        <div className="history-page__content__filters__filter__label">Date Range</div>
                        <IconButton
                            text={isDatePickerOpen ? 'Close' : 'Open'}
                            icon={faCalendar}
                            size={'sm'}
                            onClickFn={() => setIsDatePickerOpen(prev => !prev)} />
                        {isDatePickerOpen && (
                            <div className="history-page__content__filters__date-range-filter__wrapper">
                                <div className={`history-page__content__filters__date-range-filter__wrapper__overlay history-page__content__filters__date-range-filter__wrapper__overlay${isDatePickerOpen ? '--show' : '--hide'}`} onClick={() => setIsDatePickerOpen(false)}></div>
                                <div className="history-page__content__filters__date-range-filter__wrapper__inner">
                                    <div className="history-page__content__filters__date-range-filter__wrapper__inner__holder">
                                        {window.screen.width > Configs.misc.mobileBreakpoint && <DateRangePicker
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            direction="horizontal"
                                            ranges={dateRanges}
                                            onChange={dateRangeHandler} />}
                                        {window.screen.width <= Configs.misc.mobileBreakpoint && <DateRange
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            ranges={dateRanges}
                                            onChange={dateRangeHandler} />}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* User Filter */}
                    {users && <div className="history-page__content__filters__filter">
                        <div className="history-page__content__filters__filter__label">User</div>
                        <div className="history-page__content__filters__filter__control">
                            <BasicSelector
                                options={Presenter.usersForSelector(users)}
                                value={selectedUserId}
                                setter={setSelectedUserId}
                                defaultOptionText={'-- All --'} />
                        </div>
                    </div>}

                </div>

                {/* Entries List */}
                <div className="history-page__content__list">
                    {entries && entries.length > 0 && entries.map((entry, index) => (
                        <div key={index} className={`history-page__content__list__item history-page__content__list__item--${entry.level}`}>

                            <div className={`history-page__content__list__item__icon history-page__content__list__item__icon--${entry.level}`}>
                                {entry.level == 'info' && <FontAwesomeIcon icon={faInfoCircle} />}
                                {entry.level == 'success' && <FontAwesomeIcon icon={faCheckCircle} />}
                                {entry.level == 'danger' && <FontAwesomeIcon icon={faMinusCircle} />}
                            </div>
                            <div className="history-page__content__list__item__content">
                                <span className="history-page__content__list__item__content__title">{entry.title}</span>
                                <span className="history-page__content__list__item__content__user"><FontAwesomeIcon icon={faUser} /> {entry.userName ?? 'N/A'} on {entry.createdOn}</span>
                                <p className="history-page__content__list__item__content__description">{entry.description}</p>
                                
                                {/* Event Details (if present) */}
                                {entry.patientName && (
                                    <div className="history-page__content__list__item__content__event">
                                        <div className="history-page__content__list__item__content__event__detail">
                                            <FontAwesomeIcon icon={faStethoscope} />
                                            <span>{entry.physicianName}</span>
                                        </div>
                                        <div className="history-page__content__list__item__content__event__detail">
                                            <FontAwesomeIcon icon={faUserInjured} />
                                            <span>{entry.patientName}</span>
                                        </div>
                                        <div className="history-page__content__list__item__content__event__detail">
                                            <FontAwesomeIcon icon={faHospital} />
                                            <span>{entry.facilityName}</span>
                                        </div>
                                        <div className="history-page__content__list__item__content__event__detail">
                                            <FontAwesomeIcon icon={faClock} />
                                            <span>{moment(entry.dateFrom).format(Configs.formats.dateTime)}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Empty */}
                {!isLoading && entries.length == 0 && (
                    <div className="history-page__content__empty">No recorded history</div>
                )}

                {/* Page Controls */}
                <div className="history-page__content__controls">
                    <div className={`history-page__content__controls__control${isLoading ? ' history-page__content__controls__control--disabled' : ''}`} onClick={nextPage} block={isLoading}>
                        {!isLoading && <FontAwesomeIcon icon={faCaretDown} />}
                        {isLoading && <div className="spinner">
                            <FontAwesomeIcon icon={faSpinner} />
                        </div>}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default History;