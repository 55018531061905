import { faCheck, faCheckDouble, faFax, faFileAlt, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Configs from '../../config';
import { MiscContext } from '../../context/misc.context';
import Enums from '../../enums';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import EventService from '../../services/event.service';
import IconButton from '../../components/_controls/IconButton/IconButton';
import * as Presenter from './EventStatusPicker.presenter';
import './EventStatusPicker.sass';
import { UserContext } from '../../context/user.context';

const EventStatusPicker = ({ eventDetails, refresh, tagExtraStyles = null }) => {

    // <> Context
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { isOnlyPhysician } = useContext(UserContext);

    // <> History
    const history = useHistory();

    // <> States
    const [showStatusSelector, setShowStatusSelector] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // <> Actions
    async function setAsBSG() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as BSG in API
            await EventService.setAsBSG(eventDetails.id);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully set as Boarding Slip Generated'
            })

            // -> Refresh
            refresh();
            setShowStatusSelector(false);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function setAsWFC() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as WFC in API
            await EventService.setAsWFC(eventDetails.id);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully set as Waiting for Confirmation'
            })

            // -> Refresh
            refresh();
            setShowStatusSelector(false);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function setAsCompleted() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as Completed in API
            await EventService.setAsCompleted(eventDetails.id);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully set as completed'
            })

            // -> Refresh
            refresh();
            setShowStatusSelector(false);
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function cancelEvent(reason) {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Set as Completed in API
            await EventService.cancelEvent(eventDetails.id, reason);

            // -> Alert
            addAlert({
                level: 'success',
                ttl: Configs.misc.alertTTL.Mid,
                message: 'Event successfully cancelled'
            })

            // -> Refresh
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    // <> Helpers
    async function setAsBSGHandler() {

        setOpenPopupState({
            title: 'Generate Boarding Slip',
            message: `Are you sure you want to generate the boarding slip and set this event's status as boarding slip generated?`,
            level: 'info',
            onOk: setAsBSG
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    async function setAsWFCHandler() {

        setOpenPopupState({
            title: 'Set as Waiting for Confirmation',
            message: 'Are you sure you want to set this event as waiting for confirmation?',
            level: 'info',
            onOk: setAsWFC
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    async function setAsConfirmedHandler() {

        setOpenPopupState({
            eventId: eventDetails.id,
            onSuccess: refresh
        })
        setOpenPopup(Enums.PopupTags.EventConfirmWithReschedule);
    }

    async function setAsCompletedHandler() {

        setOpenPopupState({
            title: 'Set as Complete',
            message: 'Are you sure you want to set this event as completed?',
            level: 'info',
            onOk: setAsCompleted
        })
        setOpenPopup(Enums.PopupTags.Confirmation);
    }

    function cancelEventHandler() {

        setOpenPopupState({
            title: 'Cancel Event',
            message: 'Provide a cancellation reason',
            level: 'danger',
            onOk: reason => cancelEvent(reason)
        })
        setOpenPopup(Enums.PopupTags.Prompt);
    }

    // <> JSX
    return (
        <div className="event-status-picker">

            {eventDetails && (
                <div className={`${isOnlyPhysician() ? '' : 'clickable'}`} onClick={() => { if(!isOnlyPhysician()) setShowStatusSelector(prev => !prev) }}>
                    {Presenter.createStatusTag(eventDetails.status, tagExtraStyles)}
                </div>
            )}

            <div className={`event-status-picker__btn-group__wrapper ${showStatusSelector ? 'event-status-picker__btn-group__wrapper--show' : 'event-status-picker__btn-group__wrapper--hide'}`}>
                {showStatusSelector && (
                    <div className="event-status-picker__overlay" onClick={() => setShowStatusSelector(false)}></div>
                )}
                <div className={`event-status-picker__btn-group`}>
                
                    <div className="event-status-picker__close-btn" onClick={() => setShowStatusSelector(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>

                    {eventDetails && <h3>Current event status is {Presenter.createStatusTag(eventDetails.status)}</h3>}
                    <div className="hr"></div>
                    
                    {eventDetails && eventDetails.status != Enums.EventStatuses.BoardingSlipGenerated && <IconButton
                        text={'Set as Boarding Slip Generated'}
                        icon={faFileAlt}
                        btnStyle={'warning'}
                        size={'lg'}
                        onClickFn={setAsBSGHandler}
                        isLoading={isLoading} />}
                    {eventDetails && eventDetails.status != Enums.EventStatuses.WaitingForConfirmation && <IconButton
                        text={'Set as Awaiting Confirmation'}
                        icon={faFax}
                        btnStyle={'warning'}
                        size={'lg'}
                        onClickFn={setAsWFCHandler}
                        isLoading={isLoading} />}
                    {eventDetails && eventDetails.status != Enums.EventStatuses.Confirmed && <IconButton
                        text={'Set as Confirmed'}
                        icon={faCheck}
                        btnStyle={'success'}
                        size={'lg'}
                        onClickFn={setAsConfirmedHandler}
                        isLoading={isLoading} />}
                    {eventDetails && eventDetails.status != Enums.EventStatuses.Completed && <IconButton
                        text={'Set as Completed'}
                        icon={faCheckDouble}
                        btnStyle={'purple'}
                        size={'lg'}
                        onClickFn={setAsCompletedHandler}
                        isLoading={isLoading} />}
                    {eventDetails?.status != Enums.EventStatuses.Pending && eventDetails?.status != Enums.EventStatuses.Cancelled && eventDetails?.status != Enums.EventStatuses.Completed && (
                        <IconButton
                            text={'Cancel Event'}
                            icon={faTimes}
                            btnStyle={'danger'}
                            size={'lg'}
                            onClickFn={cancelEventHandler}
                            isLoading={isLoading} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EventStatusPicker;