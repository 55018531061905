import axios from 'axios';
import Configs from '../config.js';


function search({ query, limit }) {
    return axios.post(`${Configs.apiUrl}/diagnosis/search`, { query, limit });
}


/**
 * Exports
 */
const DiagnosisService = {
    search
}
export default DiagnosisService;