import axios from 'axios';
import Configs from '../config';


function addOne(data) {
    return axios.post(`${Configs.apiUrl}/events/bloodThinners/addOne`, data);
}

function updateOne(data) {
    return axios.post(`${Configs.apiUrl}/events/bloodThinners/updateOne`, data);
}

function get(eventId) {
    return axios.post(`${Configs.apiUrl}/events/bloodThinners/get`, { eventId });
}

function deleteOne(id) {
    return axios.post(`${Configs.apiUrl}/events/bloodThinners/deleteOne`, { id });
}

function deleteAllFromEvent(eventId) {
    return axios.post(`${Configs.apiUrl}/events/bloodThinners/deleteAllFromEvent`, { eventId });
}


/**
 * Exports
 */
const EventBloodThinnerService = {
    addOne,
    updateOne,
    get,
    deleteOne,
    deleteAllFromEvent
}
export default EventBloodThinnerService;