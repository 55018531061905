import React, { useContext, useEffect, useState } from 'react';
import './EventProcedures.sass';
import '../EventForms.sass';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import { faHandHoldingMedical, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MiscContext } from '../../../../context/misc.context';
import { useHistory } from 'react-router';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import EventProcedureService from '../../../../services/event.procedure.service';
import DropdownSearch from '../../../../components/_controls/DropdownSearch/DropdownSearch';
import Loader from '../../../../components/Loader/Loader';
import EditableField from '../../../../components/_controls/EditableField/EditableField';
import Enums from '../../../../enums';
import { UserContext } from '../../../../context/user.context';

const EventProcedures = ({ isLoading, eventDetails, procedures, refresh }) => {

    // <> Contexts
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { isOnlyPhysician } = useContext(UserContext);

    // <> States
    const [isAdding, setIsAdding] = useState(false);
    const [deletingWithId, setDeletingWithId] = useState(null);
    const [availableProcedures, setAvailableProcedures] = useState([]);

    // <> History
    const history = useHistory();

    // <> Effects
    useEffect(() => {
        if(eventDetails && eventDetails.procedures && procedures) setAvailableProcedures(procedures.filter(p => !eventDetails.procedures.some(p2 => p2.procedureId == p.id)));
    }, [eventDetails, procedures])

    // <> Actions
    async function addProcedure(selectedProcedureId, manualProcedure) {

        try {

            // -> Turn Loader ON
            setIsAdding(true);

            const params = {
                eventId: eventDetails.id
            }

            if(manualProcedure) {
                params.procedureName = manualProcedure;
            }
            else {
                const selectedProcedure = procedures.find(p => p.id == selectedProcedureId);
                params.procedureId = selectedProcedure.id;
                params.procedureCode = selectedProcedure.code;
                params.procedureName = selectedProcedure.name;
            }

            // -> Add
            await EventProcedureService.addOne(params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsAdding(false);
        }
    }

    async function updateProcedure(id, updateCols) {

        try {

            // -> Turn Loader ON
            setIsAdding(true);

            const params = {
                id
            }
            if(updateCols.procedureCode) params.procedureCode = updateCols.procedureCode;
            if(updateCols.procedureName) params.procedureName = updateCols.procedureName;

            // -> Add
            await EventProcedureService.updateOne(params);
            
            // -> Refresh Event
            refresh();
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsAdding(false);
        }
    }

    async function deleteProcedure(eventProcedureId) {

        // -> Confirm
        setOpenPopupState({
            level: 'danger',
            message: 'Are you sure you want to delete this event procedure?',
            onOk: async () => {

                try {
        
                    // -> Turn Loader ON
                    setDeletingWithId(eventProcedureId);
        
                    // -> Delete Procedure Event
                    await EventProcedureService.deleteOne(eventProcedureId);
                    
                    // -> Refresh Event
                    refresh();
                }
                catch(e) {
                    GlobalHttpErrorHandler(e, history, addAlert);
                }
                finally {
                    setDeletingWithId(null);
                }
                
            }
        })
        setOpenPopup(Enums.PopupTags.Confirmation);

    }

    // <> JSX
    return (
        <div className="event-procedures">
            {eventDetails && <>

                {/* Loader */}
                <Loader isLoading={isAdding} />

                {/* Title */}
                <div className="event-procedures__title">Procedures</div>

                {/* List */}
                <div className="event-procedures__list">
                    {eventDetails.procedures && eventDetails.procedures.map((p, index) => (
                        <div key={index} className="event-procedures__list__item">

                            <div className="event-procedures__list__item__code">
                                <EditableField
                                    isEditable={!isOnlyPhysician()}
                                    value={p.procedureCode}
                                    save={val => updateProcedure(p.id, { procedureCode: val })} />
                            </div>

                            <div className="event-procedures__list__item__text">
                                <EditableField
                                    isEditable={!isOnlyPhysician()}
                                    value={p.procedureName}
                                    rules={{ required: 'procedure name required' }}
                                    save={val => updateProcedure(p.id, { procedureName: val })} />
                            </div>

                            {!isOnlyPhysician() && <div className="event-procedures__list__item__delete">
                                <IconButton
                                    text={'Delete'}
                                    icon={faTimes}
                                    btnStyle={'danger'}
                                    onClickFn={() => deleteProcedure(p.id)}
                                    hideTextOnMobile={true}
                                    size={'sm'}
                                    isLoading={deletingWithId == p.id}
                                    isDisabled={deletingWithId == p.id} />
                            </div>}

                        </div>
                    ))}
                </div>

                {/* No Items Message */}
                <div hidden={eventDetails?.procedures?.length > 0 && !isLoading} className="event-procedures__empty">
                    No procedures added
                </div>

                {/* Add */}
                {!isOnlyPhysician() && availableProcedures && (
                    <div className="event-procedures__add">
                        <div className="event-procedures__add__label">Add a new procedure: </div>
                        <div className="event-procedures__add__selector">
                            <DropdownSearch
                                options={availableProcedures.map(p => { return { value: p.id, label: p.name } })}
                                setter={val => addProcedure(val, null)}
                                onManualInputFn={val => addProcedure(null, val)}
                                hideSelection={true} />

                        </div>
                    </div>
                )}

            </>}
            
        </div>
    );
};

export default EventProcedures;