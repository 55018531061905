import Configs from "../../../config"


function startupOptions(userRoles) {

    const options = [
        {
            value: '/',
            label: 'Events'
        },
        {
            value: '/calendar',
            label: 'Calendar'
        },
        {
            value: '/patients',
            label: 'Patients'
        }
    ]

    // if(hasARole(userRoles, [Configs.userRoles.ADMIN, Configs.userRoles.PHYSICIAN])) {
    //     options.push(
    //         {
    //             value: '/statistics',
    //             label: 'Statistics'
    //         }
    //     )
    // }

    // if(hasARole(userRoles, [Configs.userRoles.ADMIN])) {
    //     options.push(
    //         {
    //             value: '/master-data',
    //             label: 'Master Files'
    //         }
    //     )
    // }

    // if(hasARole(userRoles, [Configs.userRoles.ADMIN])) {
    //     options.push(
    //         {
    //             value: '/history',
    //             label: 'Audit Trail'
    //         }
    //     )
    // }
            
    return options;
}


function hasARole(userRoles, roles) {
    return roles.some(r => userRoles.includes(r));
}

/**
 * Exports
 */
export {
    startupOptions
}