import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import IconButton from '../../../components/_controls/IconButton/IconButton';
import { UserContext } from '../../../context/user.context';
import './PhysicianDetails.sass';
import Configs from '../../../config.js';
import { MiscContext } from '../../../context/misc.context';
import GlobalHttpErrorHandler from '../../../errors/globalHttpErrorHandler';
import ManageUserService from '../../../services/manageUser.service';
import * as Presenter from './PhysicianDetails.presenter';
import MonthSchedule from '../../../components/_calendars/MonthSchedule/MonthSchedule';
import Calendar from '../../Calendar/Calendar';
import MonthEvents from '../../../components/MonthEvents/MonthEvents';
import EventsTable from '../../../components/EventsTable/EventsTable';

const PhysicianDetails = () => {

    // <> Context
    const { user } = useContext(UserContext);
    const { addAlert } = useContext(MiscContext);

    // <> States
    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = useState(null);
    const [roles, setRoles] = useState(null);

    // <> Location
    const location = useLocation();
    const { physicianId, physicianEmail } = location.state;

    // <> History
    const history = useHistory();

    // <> Actions
    async function getProfile() {

        try {

            // -> Turn Loader ON
            setIsLoading(true);

            // -> Fetch Physician Profile
            const profileResponse = await ManageUserService.getUserDetails(physicianId);
            const profileRes = profileResponse.data.data.userDetails;
            const rolesRes = profileResponse.data.data.userRoles;

            // -> Make Data Presentable
            const profilePresentable = Presenter.presentProfile(profileRes);
            const rolesPresentable = Presenter.presentRoles(rolesRes);

            // -> Set States
            setProfile(profilePresentable);
            setRoles(rolesPresentable);

        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsLoading(false);
        }
    }

    function goToManagePage() {
        history.push(`/master-data/manage-users/${physicianId}`);
    }

    // <> JSX
    return (
        <div className="physician-details-page">

            {/* Top */}
            <div className="physician-details-page__top">
                <div className="physician-details-page__top__title">Details for physician <span className="primary-color">{physicianEmail}</span></div>
                <div className="physician-details-page__top__actions">
                    {user?.roles?.includes(Configs.userRoles.ADMIN) && <IconButton
                        text={'Manage'}
                        icon={faInfoCircle}
                        onClickFn={goToManagePage} />}
                </div>
            </div>

            {/* Profile */}
            <div className="physician-details-page__container">

                {/* Container Top */}
                <div className="physician-details-page__container__top">
                    <div className="physician-details-page__container__top__title">Profile</div>
                    <div className="physician-details-page__container__top__actions">

                    </div>
                </div>

                {/* Container Content */}
                <div className="physician-details-page__container__content">
                    
                </div>

            </div>

            {/* Events */}
            <div className="physician-details-page__container">

                {/* Container Top */}
                <div className="physician-details-page__container__top">
                    <div className="physician-details-page__container__top__title">Events</div>
                    <div className="physician-details-page__container__top__actions">

                    </div>
                </div>

                {/* Container Content */}
                <div className="physician-details-page__container__content">
                    <EventsTable physicianId={physicianId} />
                </div>

            </div>
        </div>
    );
};

export default PhysicianDetails;