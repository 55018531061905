import React, { useContext, useState } from 'react';
import './EventNotes.sass';
import '../EventForms.sass';
import moment from 'moment';
import Configs from '../../../../config.js';
import IconButton from '../../../../components/_controls/IconButton/IconButton';
import { faChevronLeft, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { MiscContext } from '../../../../context/misc.context';
import { UserContext } from '../../../../context/user.context';
import { useHistory, useLocation } from 'react-router';
import GlobalHttpErrorHandler from '../../../../errors/globalHttpErrorHandler';
import EventNoteService from '../../../../services/event.note.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Enums from '../../../../enums';

const EventNotes = ({ isLoading, eventDetails, refresh }) => {

    // <> Contexts
    const { addAlert, setOpenPopup, setOpenPopupState } = useContext(MiscContext);
    const { user } = useContext(UserContext);

    // <> States
    const [isAdding, setIsAdding] = useState(false);
    const [deletingWithId, setDeletingWithId] = useState(null);
    const [newNote, setNewNote] = useState('');

    // <> History
    const history = useHistory();

    // <> Location
    const location = useLocation();

    // <> Actions
    async function addNote() {

        try {

            // -> Turn Loader ON
            setIsAdding(true);

            // -> Add Note
            await EventNoteService.addOne({ eventId: eventDetails.id, userId: user.userId, note: newNote });
            
            // -> Refresh Event
            refresh();
            setNewNote('');
        }
        catch(e) {
            GlobalHttpErrorHandler(e, history, addAlert);
        }
        finally {
            setIsAdding(false);
        }
    }

    async function deleteNote(eventNoteId) {

        // -> Confirm
        setOpenPopupState({
            level: 'danger',
            message: 'Are you sure you want to delete this event diagnosis?',
            onOk: async () => {

                try {
        
                    // -> Turn Loader ON
                    setDeletingWithId(eventNoteId);
        
                    // -> Delete Note Event
                    await EventNoteService.deleteOne(eventNoteId);
                    
                    // -> Refresh Event
                    refresh();
                }
                catch(e) {
                    GlobalHttpErrorHandler(e, history, addAlert);
                }
                finally {
                    setDeletingWithId(null);
                }
                
            }
        })
        setOpenPopup(Enums.PopupTags.Confirmation);

    }

    // <> JSX
    return (
        <div className="event-notes">
            {eventDetails && <>

                {/* Title */}
                <div className="event-notes__title">Internal Notes</div>

                {/* Notes */}
                <div className="event-notes__list">
                    {eventDetails.notes && eventDetails.notes.map((n, index) => (
                        <div key={index} className="event-notes__list__item">
                            <div className="event-notes__list__item__sender"><FontAwesomeIcon icon={faUser} /> {n.userName} at {moment(n.createdOn).format(Configs.formats.dateTime)}</div>
                            <div className="event-notes__list__item__note">{n.note}</div>

                        </div>
                    ))}
                </div>

                {eventDetails?.notes?.length == 0 && <div className="event-notes__empty">No notes added</div>}

                {/* Add New Note */}
                <div className="event-notes__add">
                    <div className="event-notes__add__label">Add a new note: </div>
                    <div className="event-notes__add__input">
                        <textarea value={newNote} onChange={ev => setNewNote(ev.target.value)} rows="5"></textarea>
                    </div>
                    <IconButton
                        text={'Add Note'}
                        icon={faPlus}
                        onClickFn={addNote}
                        isDisabled={!newNote || newNote.length < 5}
                        isLoading={isAdding} />
                </div>

                
            </>}
            
        </div>
    );
};

export default EventNotes;