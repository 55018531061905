import { faInfo, faTrash } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import EditableField from '../../_controls/EditableField/EditableField';
import IconButton from '../../_controls/IconButton/IconButton';
import './EditableTable.sass';

const EditableTable = ({
    items,
    columnDefinitions,
    updateField,
    deleteRow = null,
    detailsAction = null,
    detailsActionText = null,
    isLoading = false
}) => {


    // <> Helpers
    const fullColSpan = (detailsAction || deleteRow ? columnDefinitions.columns.length + 1 : columnDefinitions.columns.length);

    // <> JSX
    return (
        <div className={`table editable-table${isLoading ? ' table--loading' : ''}`}>

            {/* Overlay */}
            <div className="table__overlay"></div>

            {/* Wrapper */}
            <div className="table__table-wrapper">
                <table>
                    <thead>
                        <tr>
                            {/* Loop through column display names */}
                            {columnDefinitions.columns.map(col => (
                                <th>{col.displayName}</th>
                            ))}

                            {/* Actions */}
                            {(detailsAction || deleteRow) && (
                                <th className="actions-th">Actions</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {/* Loop through items */}
                        { items && items.map(item => 
                            (<tr key={item.id}>

                                {/* Loop through column keys */}
                                {columnDefinitions.columns.map(col => (
                                    <td
                                        style={col.customStyles}
                                    >
                                        {col.isLink && (
                                            <Link to={item[col.key].link}>{item[col.key].text}</Link>
                                        )}
                                        {col.editKey && (
                                            <EditableField 
                                                value={item[col.key]}
                                                shownValue={(col.shownValueKey && item[col.shownValueKey]) || col.options?.find(o => o.value == item[col.key])?.label || item[col.key]}
                                                rules={col.editRules || null}
                                                type={col.type}
                                                options={col.options}
                                                save={newVal => updateField(item.id, col.editKey, newVal)}
                                            />
                                        )}
                                        {(!col.isLink && !col.editKey) && ((col.shownValueKey && item[col.shownValueKey]) || item[col.key])}
                                    </td>
                                ))}

                                {/* Add Details Btn if Enabled */}
                                {(detailsAction || deleteRow) && (
                                    <td className="table-action-btns">
                                        {detailsAction && <IconButton
                                            text={detailsActionText}
                                            icon={faInfo}
                                            btnStyle={'info'}
                                            size={'sm'}
                                            hideTextOnMobile={true}
                                            onClickFn={() => detailsAction(item.id)} />}

                                        {deleteRow && <IconButton
                                            text={'Delete'}
                                            icon={faTrash}
                                            btnStyle={'danger'}
                                            size={'sm'}
                                            hideTextOnMobile={true}
                                            onClickFn={() => deleteRow(item.id)} />}
                                    </td>
                                )}

                            </tr>))
                        }

                        {/* Empty Table */}
                        {(!isLoading && items && items.length == 0) && (
                            <tr>
                                <td className="no-data" colSpan={fullColSpan}>No data</td>
                            </tr>
                        )}

                    </tbody>

                </table>
            </div>
        </div>
    );
};

export default EditableTable;