import React from 'react';
import './SimpleSortPicker.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const SimpleSortPicker = ({ status, setStatus }) => {

    // <> Actions
    function toggleStatus() {
        setStatus(status == null ? false : !status);
    }

    // <> JSX
    return (
        <button className={`simple-sort-picker${status != null ? ' simple-sort-picker--selected' : ''}`} onClick={toggleStatus}>
            <FontAwesomeIcon className="simple-sort-picker__icon" icon={status == null ? faCaretDown : (status == true ? faCaretUp : faCaretDown) } />
        </button>
    );
};

export default SimpleSortPicker;