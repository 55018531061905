import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import DetailedEventCard from '../../components/DetailedEventCard/DetailedEventCard';
import MonthSchedule from '../../components/_calendars/MonthSchedule/MonthSchedule';
import './Calendar.sass';
import Configs from '../../config.js';
import MonthEvents from '../../components/MonthEvents/MonthEvents';
import { UserContext } from '../../context/user.context';
import AddressSearch from '../../components/AddressSearch/AddressSearch';

const Calendar = () => {

    // <> Context
    const { user } = useContext(UserContext);

    
    // <> JSX
    return (
        <div className="calendar-page">

            {/* Page Title */}
            {/* <div className="page-title">
                <span className="title-text">Calendar</span>
                <span className="title-subtext">Events in a monthly or weekly calendar.</span>
            </div> */}

            {/* Month Events */}
            <MonthEvents />
                

        </div>
    );
};

export default Calendar;