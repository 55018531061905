/**
 * @description module for interacting with the browser's storage
 * @note uses local storage
 * @note used to save tokens
 */


/**
 * store a key-value pair into the browser storage
 * @param {string} key 
 * @param {string} value 
 */
function set(key, value) {
    localStorage.setItem(key, value);
}


/**
 * get a stored value from the browser storage using its key
 * @param {string} key 
 * @returns {string} stored value
 */
function get(key) {
    return localStorage.getItem(key);
}


/**
 * clear a value from the browser storage using its key
 * @param {string} key 
 */
function clear(key) {
    localStorage.removeItem(key);
}


/**
 * Exports
 */
const BrowserStorage = {
    set,
    get,
    clear
}

export default BrowserStorage;