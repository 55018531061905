import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../context/user.context';
import BrowserStorage from '../../utils/browser.storage';
import Enums from '../../enums';
import UserService from '../../services/user.service';
import GlobalHttpErrorHandler from '../../errors/globalHttpErrorHandler';
import { MiscContext } from '../../context/misc.context';
import Configs from '../../config';

const Init = () => {

    // <> History
    const history = useHistory();
    const { user, setUser } = useContext(UserContext);

    // <> Context
    const { addAlert } = useContext(MiscContext);

    // <> Effects
    useEffect(() => {
        auth();
        calendarModes();
    }, []);

    // <> Actions
    function auth() {

        // -> Get Refresh Token From Browser Storage
        const refreshToken = BrowserStorage.get(Enums.BrowserStorage.RefreshToken.Key);

        // -> If user not logged in and has no refresh token, navigate to login
        if(!user?.loggedIn && !refreshToken) {
            history.push('/login');
        }
        // -> If user not logged in and has refresh token, auto login
        else if(!user?.loggedIn && refreshToken) {
            autoLogin(refreshToken);
        }
    }

    async function autoLogin(refreshToken) {

        try{

            // -> Auto Login to API (using refresh)
            const refreshResult = await UserService.refresh(refreshToken);

            // -> Extract New Access Token
            const newAccessToken = refreshResult.data.data.accessToken;

            // -> Store New Access Token in Browser Storage
            BrowserStorage.set(Enums.BrowserStorage.AccessToken.Key, newAccessToken);

            // -> Get logged in User Data
            const userData = (await UserService.getUserData()).data.data;

            // -> Set User Data to Context
            setUser({
                loggedIn: true,
                userId: userData.user.id,
                roles: userData.userRoles,
                ...userData.user
            });
        }
        catch(e) {

            GlobalHttpErrorHandler(e, history, addAlert);
        }
    }

    /**
     * If device is mobile and calendar modes were not selected yet, default to the mobile friendly modes
     **/
    function calendarModes() {

        const calendarIsWeekMode = BrowserStorage.get(Enums.BrowserStorage.CalendarIsWeekMode.Key);
        const dayEventsIsCompactMode = BrowserStorage.get(Enums.BrowserStorage.DayEventsIsCompactMode.Key);

        // If device is mobile
        if(window.screen.width <= Configs.misc.mobileBreakpoint) {

            if(!calendarIsWeekMode) {
                BrowserStorage.set(Enums.BrowserStorage.CalendarIsWeekMode.Key, Enums.BrowserStorage.CalendarIsWeekMode.Values.True);
            }

            if(!dayEventsIsCompactMode) {
                BrowserStorage.set(Enums.BrowserStorage.DayEventsIsCompactMode.Key, Enums.BrowserStorage.DayEventsIsCompactMode.Values.True);
            }

        }
    }


    // <> JSX
    return <></>;
};

export default Init;