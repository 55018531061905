import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './ColorPicker.sass';

const ColorPicker = ({ options, value, setter, isLoading }) => {

    // <> States
    const [isOpen, setIsOpen] = useState(false);

    // <> Helpers
    function onPickerClicked() {
        setIsOpen(prev => !prev);
    }

    function onOptionClicked(color) {
        setter(color);
        setIsOpen(false);
    }

    // <> JSX
    return (
        <div className="color-picker">
            <div hidden={isLoading} className="color-picker__chosen" style={{ backgroundColor: value }} onClick={onPickerClicked}></div>
            <div hidden={isLoading} className={`color-picker__selector ${isOpen ? 'color-picker__selector--open' : 'color-picker__selector--closed'}`}>
                {options && options.map((o, i) => (
                    <div 
                        key={i}
                        className="color-picker__selector__color" 
                        style={{ backgroundColor: o }}
                        onClick={() => onOptionClicked(o)}
                    ></div>
                ))}
            </div>

            {/* Loader */}
            <div className="color-picker__loader" hidden={!isLoading}>
                <FontAwesomeIcon icon={faSpinner} />
            </div>
        </div>
    );
};

export default ColorPicker;