/**
 * @description global error handler for HTTP errors
 */


import HttpErrors from './HttpErrors';
import Enums from '../enums';
import Configs from '../config';


/**
 * global http error handler
 * @param {*} error thrown error
 * @param {*} history history object to navigate to screens
 * @param {*} addAlert function to add an alert message to the user
 */
function handle(error, history, addAlert) {

    console.error('Global HTTP Error Handler');
    console.error(error);

    // () If HTTP Error
    if(error instanceof HttpErrors.HttpError) {

        // () Request not sent
        if(error instanceof HttpErrors.RequestNotSentHttpError) {
            addAlert({
                level: 'warning',
                preventDuplicates: true,
                message: 'Issue connecting to the server'
            })
        }

        // () Response not received
        else if(error instanceof HttpErrors.ResponseNotReceivedHttpError) {
            addAlert({
                level: 'warning',
                preventDuplicates: true,
                message: 'Issue connecting to the server'
            })
        }
    
        // () Internal server error
        else if(error instanceof HttpErrors.InternalServerHttpError) {
            addAlert({
                level: 'warning',
                preventDuplicates: true,
                message: 'Something went wrong, please try again later. If this persists, contact the maintainers.'
            })
        }
    
        // () Unauthenticated
        else if(error instanceof HttpErrors.UnauthenticatedHttpError) {
            addAlert({
                level: 'info',
                ttl: Configs.misc.alertTTL.Long,
                preventDuplicates: true,
                message: 'Session expired, please log in again'
            })
            history.push('/login');
        }
    
        // () Http Response Error
        else if(error instanceof HttpErrors.HttpResponseError) {

            // () Forbidden Error
            if(error.status == Enums.HttpCodes.Forbidden) {

                addAlert({
                    level: 'danger',
                    ttl: Configs.misc.alertTTL.Long,
                    preventDuplicates: true,
                    message: 'Attempted action or access is forbidden'
                })
            }
            else {

                addAlert({
                    level: 'warning',
                    ttl: Configs.misc.alertTTL.Long,
                    preventDuplicates: true,
                    message: (!!error.message.trim() ? error.message.trim() : 'Something went wrong, please try again later.')
                })
            }
        }
    }

}


/**
 * Exports
 */
const GlobalHttpErrorHandler = handle;
export default GlobalHttpErrorHandler;